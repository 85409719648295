import React, { Component } from 'react'
import { TextField, IconButton, Tooltip, Box, Typography, Button, ButtonGroup, Menu, MenuItem, Stack, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, FormControl, Select, InputLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { AppointmentCard, CommonGridToolBarWithFilterTextCustom, CommonPatientDetails, ReasonPopup, PovEditPopup, NewCommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import { DataGrid, gridNumberComparator } from '@mui/x-data-grid';
import CommonGridHeader from '../../../../Components/Common Components/CommonComponents';
import { Colors } from '../../../../Styles/Colors';
import { withTranslation } from 'react-i18next';
import NextAppointments from '../../../FrontOffice/OutPatients/NextAppointments';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { clearCachevalue, getCachevalue, localGetItem, localSetItem, setCachevalue } from '../../../../Utility/Services/CacheProviderService';
import moment from 'moment/moment';
import { CurrencySymbol, appointment_Status_opt, cmt_Char_limit } from '../../../../Utility/Constants';
import { formatToCustomDateTime } from '../../../../Components/CommonFunctions/CommonFunctions';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

const DilationList = ["Tropicamide Plus", "Homide", "Cylopentolate"]
class DoctorHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datePicker: new Date(),
      AptTblData: [],
      patientCount: {},
      totalAppointment: {},
      anchorEl: null,
      selectedRow: null,
      selectedOption: "",
      isSelected: false,
      selectedID: "",
      selectedCard: '',
      SelectedPatientDetails: {},
      waitingCmts: false,
      deletedPopup: false,
      cancelAppointment: false,
      page: 0,
      pageSize: 10,
      waitCmtText: '',
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      timeDiff: null,
      SelectedDoctorID: "",
      DoctorsList: [],
      DatePickerOpen: false,
      tokenNum: '',
      barcodeHeader: false,
      loading: false,
      SelectedDoctorClinic: '',
      isOPT: false,
      isOthometry: false,
      unlockDate: "",
      timer: '',
      currentTime: new Date(),
      povText: {},
      ispovPopOpen: false,
      onChangePov: false,
      isDocOnEmergency: false,
      slctMenuOption: "",
      isRemarks: false,
      remarkText: '',
      isRemarkId: null,
      isDocOnEmergency: false,
    }
    this.TokenScreen = null;
    this.EmergencySocket = null;
    this.PaitentCall = null;
    this.DocCallPatient  = null;
  }

  componentDidMount() {
    clearCachevalue("DoctorPatientData")
    clearCachevalue("CurrentDoctorPatientData")
    clearCachevalue('updatePatient')
    var DoctorClinicId = localGetItem('SelectedDoctorClinicId')
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    if (UserData?.is_qms_enabled) {
      this.WebSocketConnection(UserData)
    }
    if (DoctorClinicId !== this.state.SelectedDoctorClinic && DoctorClinicId) {
      this.setState({ SelectedDoctorClinic: DoctorClinicId, isOPT: UserData.service_type === "OPT" ? true : false, isOthometry: UserData.service_type === "OPTOMETRY" }, () => {
        setTimeout(() => {
          this.getPatientDetails()
          this.getPatientAppointmentStatusCount()
          this.getDoctorsList()
          if (UserData?.module_name === "Doctor") {
            this.updateAutolock()
          }
        }, 1000)
      })
    }
    localSetItem('DoctorAppointmentDate', this.state.datePicker)
    if ((UserData.service_type === "OPTOMETRY") || (UserData.service_type === "OPT")) {
      this.intervalId = setInterval(() => {
        this.setState({ currentTime: formatToCustomDateTime(new Date()) });
      }, 1000);
    }
  }


  componentDidUpdate(prevProps) {
    let DoctorID = JSON.parse(localGetItem("SelectedDoctorID"))
    var DoctorClinicId = getCachevalue('SelectedDoctorClinic')
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    if (prevProps.history?.location?.state?.NewAppointmentCreated) {
      this.successMsgFunc(prevProps.history?.location?.state?.NewMessage)
      this.props.history.push({ state: { NewAppointmentCreated: false } })
      this.getPatientDetails()
      this.getPatientAppointmentStatusCount()
    }
    if (DoctorID !== this.state.SelectedDoctorID && DoctorID) {
      this.setState({ SelectedDoctorID: DoctorID }, () => {
        setTimeout(() => {
          this.getPatientDetails()
          this.getPatientAppointmentStatusCount()
        }, 1500)
      })
    }
    if (DoctorClinicId !== this.state.SelectedDoctorClinic && DoctorClinicId) {
      this.setState({ SelectedDoctorClinic: DoctorClinicId, isOPT: UserData.service_type === "OPT" ? true : false, isOthometry: UserData.service_type === "OPTOMETRY" }, () => {
        setTimeout(() => {
          this.getPatientDetails()
          this.getPatientAppointmentStatusCount()
          this.getDoctorsList()
          if (UserData?.module_name === "Doctor") {
            this.updateAutolock()
          }
        }, 1000)
      })
    }
    if ((this.props.changeDate !== this.state.unlockDate) && this.state.SelectedDoctorClinic) {
      this.setState({
        unlockDate: this.props.changeDate,
        datePicker: this.props.changeDate
      }, () => {
        this.getchangePatientAppointmentDate()
        this.getChangedAppointmentStatusCount()
      })
    }
    if(prevProps?.CallPatientTrigger !== this.props?.CallPatientTrigger && this.props?.CallPatientTrigger){
       this.getPatientDetails()
       this.props.ClearCalledTrigger()
    }
  }

  componentWillUnmount = () => {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    if (myUser?.is_qms_enabled) {
      if(this.TokenScreen){
        this.TokenScreen.close()
      }
      if(this.EmergencySocket){
        this.EmergencySocket.close()
      }
      if(this.PaitentCall){
        this.PaitentCall.close()
      }
      if(this.DocCallPatient){
        this.DocCallPatient.close()
      }
    }
  }

  WebSocketConnection = (userData) => {
    let emergencyChannel = userData?.emergency_channel_name
    let docCallChannel = userData?.doctor_call_channel_name
    let tokenScreen = userData?.token_screen_channel_name

    if (this.EmergencySocket === null) {
      this.EmergencySocket = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_EMERGENCY_WEBSOCKET}${emergencyChannel}/`);
    }
    if (this.DocCallPatient === null) {
      this.DocCallPatient = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_PATIENTCALL_WEBSOCKET}${docCallChannel}/`);
    }
    if (this.TokenScreen === null && tokenScreen) {
      this.TokenScreen = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.TOKENSCREEN_WEBSOCKET}${tokenScreen}/`);
    }

    const socketMapping = {
      EmergencySocket: this.EmergencySocket,
      PaitentCall: this.PaitentCall,
      DocCallPatient: this.DocCallPatient,
      TokenScreen: this.TokenScreen,
    };

    Object.entries(socketMapping)?.forEach(([name, socket]) => {
      if (socket) {
        socket.onopen = () => {
          console.log(`${name} WebSocket connected`);
        }
        socket.onclose = () => {
          console.log(`${name} WebSocket disconnected`);
          name = null
        }
        socket.onerror = (error) => {
          console.log(`${name} WebSocket Error`, error);
          socket.close()
        }
        socket.onmessage = (e) => {
          const data = JSON.parse(e.data);
          console.log(`${name} WebSocket Message`, data);
          if (name === "PaitentCall" && data) {
            // this.getPatientDetails()
            // if (data?.status === "Cancelled" && this.TokenScreen) {
            //   this.TokenScreen.send(JSON.stringify({
            //     message_id: data?.message_id,
            //     action: "remove_message"
            //   }))
            // }
            // enqueueSnackbar(data?.message)
          }
        }
      }
    })
  }

  errorMsgFunc = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMsgFunc = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  getchangePatientAppointmentDate = () => {
    try {
      let states = this.state
      let clinicId = localGetItem('SelectedDoctorClinicId') !== null ? (localGetItem('SelectedDoctorClinicId') ? localGetItem('SelectedDoctorClinicId') : getCachevalue('SelectedDoctorClinic')) : this.state.SelectedDoctorClinic
      let ApiUrl = ""
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      if (UserData?.service_type === "OPTOMETRY") {
        ApiUrl = Serviceurls.DOC_OPTHOMETRY_APPOINTMENT_LIST + "?appointment_date=" + states?.unlockDate + "&appointment_status=" + '&doctor_id=' + (this.state.SelectedDoctorClinic === 'all' ? "" : this.state.SelectedDoctorClinic)
      } else {
        ApiUrl = Serviceurls.DOC_APPOINTMENT_LIST + "?appointment_date=" + states?.unlockDate + "&appointment_status=" + '&clinic_id=' + this.state.SelectedDoctorClinic
      }
      RestAPIService.getAll(ApiUrl)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              AptTblData: response.data.data ? response.data.data : [],
              patientCount: response.data.patient_arrived_count ? response.data.patient_arrived_count : {}
            }, () => { });
          }
        }).catch(e => {
          if (e?.response?.data?.status === "fail") {
            this.errorMsgFunc(e.response.data.message)
          } else {
            this.errorMsgFunc(e.message)
          }
        })
    } catch (e) {
      this.errorMsgFunc(e.message)
    }
  }

  getPatientDetails = () => {
    try {
      let states = this.state
      let StateDate = new Date(states.datePicker)
      let date = states.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null
      setCachevalue(date, 'OpAppDate')
      setCachevalue(states.selectedCard, 'OPStatus')
      // let ClinicId = (JSON.parse(getCachevalue("SelectedDoctorClinic")) === 0 || JSON.parse(getCachevalue("SelectedDoctorClinic")) === null || JSON.parse(getCachevalue("SelectedDoctorClinic")) === undefined) ? "" : JSON.parse(getCachevalue("SelectedDoctorClinic"));
      // let DoctorClinicId = localGetItem('SelectedDoctorClinicId')
      // let SelectedClinicId = ClinicId ? ClinicId : DoctorClinicId
      let clinicId = localGetItem('SelectedDoctorClinicId') !== null ? (localGetItem('SelectedDoctorClinicId') ? localGetItem('SelectedDoctorClinicId') : getCachevalue('SelectedDoctorClinic')) : this.state.SelectedDoctorClinic
      if (date === 'Invalid DateTime') {
      } else {
        this.setState({ loading: true })
        let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
        let ApiUrl = ""
        if (UserData?.service_type === "OPTOMETRY") {
          ApiUrl = Serviceurls.DOC_OPTHOMETRY_APPOINTMENT_LIST + "?appointment_date=" + date + "&appointment_status=" + states.selectedCard + '&doctor_id=' + (clinicId === 'all' ? "" : clinicId)
        } else {
          ApiUrl = Serviceurls.DOC_APPOINTMENT_LIST + "?appointment_date=" + date + "&appointment_status=" + states.selectedCard + '&clinic_id=' + clinicId
        }
        RestAPIService.getAll(ApiUrl)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                AptTblData: response.data.data ? response.data.data : [],
                patientCount: response.data.patient_arrived_count ? response.data.patient_arrived_count : {},
                loading: false,
                isDocOnEmergency: response.data?.out_for_emergency ? response.data?.out_for_emergency : false,
              }, () => {
                this.state.AptTblData.forEach((element)=>{
                   element.patientDetails = `${element.patient_name} ${element.patient_account_number} ${element.patient_mobile_number}` 
                })
                let waitingPatientData = JSON.parse(getCachevalue('WaitingPatientData'))
                let waitingTime = JSON.parse(getCachevalue('waitingTime'))
                if (waitingPatientData?.waiting_timer === null) {
                  let filterPatientData = this.state.AptTblData?.find(item => item?.patient_id === waitingPatientData?.patient_id)
                  if (filterPatientData?.waiting_timer === null && filterPatientData?.appointment_status === 'With Doctor') {
                    this.postPatientStatus('', filterPatientData?.appointment_id, filterPatientData?.waiting_comment, waitingTime, null, true)
                  } else if (filterPatientData?.waiting_timer === null && filterPatientData?.appointment_status === 'Completed') {
                    this.postPatientStatus('Completed', filterPatientData?.appointment_id, filterPatientData?.waiting_comment, waitingTime, null, true)
                  }
                }
                setTimeout(() => {
                  clearCachevalue('WaitingPatientData')
                  clearCachevalue('waitingTime')
                }, 1000)
              }
                //Commented for auto calling
                // , () => {
                //   // setTimeout(() => {
                //     //this.getPatientDetails()
                //   }, 40000
              );
              // })
            }
          }).catch(e => {
            if (e?.response?.data?.status === "fail") {
              this.setState({ loading: false })
              this.errorMsgFunc(e.response.data.message)
            } else {
              this.setState({ loading: false })
              this.errorMsgFunc(e.message)
            }
          })
      }
    } catch (e) {
      this.setState({ loading: false })
      this.errorMsgFunc(e.message)
    }
  }

  updateAutolock = () => {
    try {
      let DoctorClinicId = localGetItem('SelectedDoctorClinicId')
      RestAPIService.getAllTwo(Serviceurls.DOC_NOTES_AUTO_LOCK + `?clinic_id=${DoctorClinicId}`)
        .then(response => {
          if (response.data.status === "success") {

          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMsgFunc(error.response.data.message)
          }
        });
    } catch (e) {
      this.errorMsgFunc(e.message);
    }
  }

  getPatientAppointmentStatusCount = () => {
    try {
      let states = this.state
      let StateDate = new Date(states.datePicker)
      let date = states.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null
      let DoctorClinicId = localGetItem('SelectedDoctorClinicId')
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let ApiUrl = ""
      if (UserData?.service_type === "OPTOMETRY") {
        ApiUrl = Serviceurls.DOC_APPOINTMENT_STATUS + `?clinic_id=${UserData?.clinic_id}&doctor_id=${(DoctorClinicId === 'all' ? "" : DoctorClinicId)}&appointment_date=${date}`
      } else {
        ApiUrl = Serviceurls.DOC_APPOINTMENT_STATUS + `?clinic_id=${DoctorClinicId}&appointment_date=${date}`
      }
      RestAPIService.getAll(ApiUrl)
        .then((response => {
          if (response?.data?.status === 'success') {
            this.setState({
              totalAppointment: response.data.data ? response.data.data : {},
            })
          }
        })).catch(e => {
          if (e?.response?.data?.status === "fail") {
            this.setState({ loading: false })
            this.errorMsgFunc(e.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMsgFunc(e.message)
          }
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
    }
  }

  getChangedAppointmentStatusCount = () => {
    try {
      let states = this.state
      let DoctorClinicId = localGetItem('SelectedDoctorClinicId')
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let ApiUrl = ""
      if (UserData?.service_type === "OPTOMETRY") {
        ApiUrl = Serviceurls.DOC_APPOINTMENT_STATUS + `?doctor_id=${DoctorClinicId === 'all' ? "" : DoctorClinicId}&clinic_id=${UserData?.clinic_id}&appointment_date=${states?.unlockDate}`
      } else {
        ApiUrl = Serviceurls.DOC_APPOINTMENT_STATUS + `?doctor_id=${DoctorClinicId}&clinic_id=${DoctorClinicId}&appointment_date=${states?.unlockDate}`
      }
      RestAPIService.getAll(ApiUrl)
        .then((response => {
          if (response?.data?.status === 'success') {
            this.setState({
              totalAppointment: response.data.data ? response.data.data : {},
            })
          }
        })).catch(e => {
          if (e?.response?.data?.status === "fail") {
            this.setState({ loading: false })
            this.errorMsgFunc(e.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMsgFunc(e.message)
          }
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
    }
  }

  postPatientStatus = (status, id, comments, time, waitTime, forWaitingTimer) => {
    try {
      var states = this.state
      var data = {
        "appointment_id": id,
        "appointment_status": status,
        "waiting_timer": waitTime ? waitTime : time ? time : null,
        "for_waiting_time": forWaitingTimer ? true : false,
        "clinic_id" : this.state.SelectedDoctorClinic
      }
      if (states.waitingCmts) {
        data['waiting_comment'] = comments
      }
      if (states.cancelAppointment) {
        data['cancel_reason'] = comments.replace(/\s+/g, ' ').trim()
        data['is_transfered'] = false
        data['transfer_doctor_id'] = null
      }
      RestAPIService.create(data, Serviceurls.DOC_APPOINTMENT_STATUS_UPDATE)
        .then(response => {
          if (response.data.status == "success") {

            if ((status === "With Doctor" || status === 'Optometry Completed') && this.state.isOthometry) {
              let AptData = this.state.AptTblData.find((list) => (list?.appointment_id === id))
              let FirstDilationDetails = AptData?.dilation_details ? AptData?.dilation_details[0] : {}
              let SecondDilationDetails = AptData?.dilation_details ? AptData?.dilation_details[1] : null
              if (FirstDilationDetails?.start_time && !FirstDilationDetails?.end_time) {
                this.UpdateDilationTime(id, false, true, 0)
              } else if (SecondDilationDetails?.start_time && !SecondDilationDetails.end_time) {
                this.UpdateDilationTime(id, false, true, 1)
              } else {
                this.getPatientDetails()
                this.getPatientAppointmentStatusCount()
                this.setState({
                  waitCmtText: ''
                })
              }
            } else
              this.getPatientDetails()
            this.getPatientAppointmentStatusCount()
            this.setState({
              waitCmtText: ''
            })
          }
        }).catch(e => {
          if (e?.response?.data?.status === "fail") {
            this.errorMsgFunc(e.response.data.message)
          } else {
            this.errorMsgFunc(e.message)
          }
        })
    } catch (e) {
      this.errorMsgFunc(e.message)
    }
  }

  UpdateDilationTime = (id, StartTime = false, EndTime = false, index) => {
    try {
      let DilationObj = this.state.AptTblData.find((list) => (list?.appointment_id === id)) ? this.state.AptTblData.find((list) => (list?.appointment_id === id))?.dilation_details : null
      let DilationValue = DilationObj ? DilationObj[index] ? DilationObj[index]?.dilation : null : null
      if (DilationValue) {
        var data = {
          "appointment_id": id,
          "dilation": DilationValue,
          "set_start_time": StartTime,
          "set_end_time": EndTime
        }
        RestAPIService.create(data, Serviceurls.DOC_DILATION_TIMER_UPDATE)
          .then(response => {
            if (response.data.status == "success") {
              this.getPatientDetails()
              this.getPatientAppointmentStatusCount()
            }
          }).catch(e => {
            if (e?.response?.data?.status === "fail") {
              this.errorMsgFunc(e.response.data.message)
            } else {
              this.errorMsgFunc(e.message)
            }
          })
      } else {
        this.errorMsgFunc("Select the Dilation Type")
      }
    } catch (e) {
      this.errorMsgFunc(e.message)
    }
  }

  getDoctorsList() {
    try {
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + `?clinic_id=${UserData?.service_type === "OPTOMETRY" ? UserData?.clinic_id : this.state.SelectedDoctorClinic}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              DoctorsList: response.data.data.filter((item) => (item.specialization_active === true))
            })
          }
          else {
            this.errorMsgFunc(response.data.message)
          }
        }).catch((e) => {
          this.errorMsgFunc(e.message)
        })
    }
    catch (e) {
      this.errorMsgFunc(e.message)
    }
  }

  getPatientPrescriptionPrint = () => {
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    try {
      let APIUrl = ""
      if (this.state.isOPT || UserData?.service_type === "OPTOMETRY") {
        APIUrl = Serviceurls.OPT_PRESCRIPTION_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&clinic_id=" + this.state.SelectedDoctorClinic + "&language=english"
      }
      else {
        APIUrl = Serviceurls.PRINT_PRESCRIPTION + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&clinic_id=" + this.state.SelectedDoctorClinic + "&export_type=pdf"
      }
      this.setState({ selectedOption: "" })
      RestAPIService.getAllTwo(APIUrl).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "", slctMenuOption: "" })
    }
  }

  getPatientDoctorNotesPrint = () => {
    try {
      let APIUrl = ""
      if (this.state.isOPT) {
        APIUrl = Serviceurls.OPT_PATIENT_DOCTOR_NOTES_PRINT + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&clinic_id=" + this.state.SelectedDoctorClinic
      } else {
        APIUrl = Serviceurls.DOCTOR_NOTES_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&doctor_notes=all" + "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&clinic_id=" + this.state.SelectedDoctorClinic
      }
      this.setState({ selectedOption: "" })
      RestAPIService.getAll(APIUrl).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "", slctMenuOption: "" })
    }
  }

  getPatientOpCardPrint = (barcodeHeader) => {
    try {
      let Url = ""
      let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
      if (UserData?.service_type === "OPTOMETRY") {
        Url = Serviceurls.OP_CARD_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&clinic_id=" + UserData?.clinic_id
      }
      if (barcodeHeader) {
        Url = Serviceurls.OP_CARD_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&clinic_id=" + this.state.SelectedDoctorClinic + "&header=false"
      } else {
        Url = Serviceurls.OP_CARD_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&clinic_id=" + this.state.SelectedDoctorClinic
      }
      RestAPIService.getAll(Url).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "", slctMenuOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getPatientOpBillDetailPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.OP_DETAILED_BILL_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&clinic_id=" + this.state.SelectedDoctorClinic).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMsgFunc(error.response?.data?.message);
          } else {
            this.errorMsgFunc(error.message);
          }
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "", slctMenuOption: "" })
    }
  }
  getPatientPresTemplate = () => {
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    try {
      let token = this.state.tokenNum ? this.state.tokenNum : null
      this.setState({ selectedOption: ""});
      RestAPIService.getAll(Serviceurls.OP_PRES_TEMPLATE + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id +
        "&doctor_id=" + this.state.SelectedPatientDetails?.doctor_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id +
        "&token_number=" + token + "&clinic_id=" + (UserData?.service_type === "OPTOMETRY" ? UserData?.clinic_id : this.state.SelectedDoctorClinic))
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          this.errorMsgFunc(error.response?.data?.message);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.errorMsgFunc(error.message)
      this.setState({ selectedOption: "" })
    }
  }
  getCommonBarCodePrint = () => {
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    try {
      RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT + "?patient_id=" + this.state.SelectedPatientDetails?.patient_id + "&appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&clinic_id=" + (UserData?.service_type === "OPTOMETRY" ? UserData?.clinic_id : this.state.SelectedDoctorClinic)).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMsgFunc(error.response?.data?.message);
          } else {
            this.errorMsgFunc(error.message);
          }
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  getMedicalRecordPrint = () => {
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    try {
      RestAPIService.getAll(Serviceurls.MEDICAL_RECORD_PRINT + "?appointment_id=" + this.state.SelectedPatientDetails?.appointment_id + "&clinic_id=" + (UserData?.service_type === "OPTOMETRY" ? UserData?.clinic_id : this.state.SelectedDoctorClinic)).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ selectedOption: "", slctMenuOption: "" })
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMsgFunc(error.response?.data?.message);
          } else {
            this.errorMsgFunc(error.message);
          }
          this.setState({ selectedOption: "", slctMenuOption: "" })
        })
    } catch (error) {
      this.setState({ selectedOption: "", slctMenuOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  renderOPHeader = () => {
    const { t } = this.props
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    return (
      <div className='eMed_OP_AptHead'>
        <p>{t('Doctor Appointments')}</p>
        <div className='eMed_Header_OP_home_div'>
          <Button
            className={this.state.selectedCard === 'Transfered' ? "eMed_Header_OP_Noshow_btn_on" : 'eMed_Header_OP_Noshow_btn'} size='small'
            onClick={() => { this.setState({ selectedCard: 'Transfered' }, () => { this.getPatientDetails() }) }}>
            {`Transferred ( ${this.state.totalAppointment?.transfered ? this.state.totalAppointment?.transfered : 0} )`}
          </Button>
          {UserData?.service_type === "OPTOMETRY" ? null :
            UserData?.is_qms_enabled ?
              <Button
                color='error'
                variant='contained'
                startIcon={this.state.isDocOnEmergency ? <NotificationsOffIcon /> : <NotificationsActiveIcon />}
                sx={{ marginRight: "1vw", width: "12vw" }}
                size='small'
                emed_tid='doc_module_emergency_btn'
                disabled={this.state.disableBtn}
                onClick={() => { this.setState({ isDocOnEmergency: !this.state.isDocOnEmergency, disableBtn: true }, () => { this.onEmergency() }) }}>
                {this.state.isDocOnEmergency ? `Stop Emergency` : `Emergency`}
              </Button> : null}
          {/* {
            this.state.datePicker < new Date() && (DateTime.fromJSDate(new Date(this.state.datePicker)).toFormat('yyyy-MM-dd') !== DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')) && localGetItem("noShowStatus") === "true" ?
              <Button
                className={this.state.selectedCard === 'No Show' ? "eMed_Header_OP_Noshow_btn_on" : 'eMed_Header_OP_Noshow_btn'} size='small'
                onClick={() => { this.setState({ selectedCard: 'No Show' }, () => { this.getPatientDetails() }) }}>
                {`No Show ( ${this.state.totalAppointment?.no_show ? this.state.totalAppointment?.no_show : 0} )`}
              </Button> : null
          } */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={this.state.DatePickerOpen}
              onOpen={() => { this.setState({ DatePickerOpen: true }) }}
              onClose={() => { this.setState({ DatePickerOpen: false }) }}
              value={this.state.datePicker}
              name={"datePicker"}
              inputFormat='DD-MM-YYYY'
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  this.setState({
                    datePicker: newDate,
                    selectedCard: ''
                  }, () => {
                    this.getPatientDetails()
                    this.getPatientAppointmentStatusCount()
                    localSetItem('DoctorAppointmentDate', this.state.datePicker)
                  });
                }}
              renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    )
  }

  renderPatientType = () => {
    const { t } = this.props
    let { history } = this.props;
    var states = this.state
    // Opt Doc filter
    let is_opt = false
    let is_selectopt = false
    var is_optdoc = states.DoctorsList.find((item) => {
      if ((item.specialization_name === Serviceurls.ROLE_OPT || item.specialization_name === Serviceurls.ROLE_OPTOMETRY) && this.state.isOPT) {
        is_opt = true
      } else {
        is_opt = false
      }
      return is_opt
    }
    )
    // select Opt Doc filter
    var is_optselect = getCachevalue('SelectedDoctorID')
    var is_optDocselect = states.DoctorsList.filter((item) => (item.doctor_id === +is_optselect))
    var is_selectoptdoc = is_optDocselect.find((item) => {
      if ((item.specialization_name === Serviceurls.ROLE_OPT || item.specialization_name === Serviceurls.ROLE_OPTOMETRY) && this.state.isOPT) {
        is_selectopt = true
      } else {
        is_selectopt = false
        is_optdoc = false
      }
      return is_selectopt, is_optdoc
    }
    )
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let appointmentStatus = JSON.parse(localGetItem('appointment_status')) ? JSON.parse(localGetItem('appointment_status')) :
    JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status !== null ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status :
        appointment_Status_opt : appointment_Status_opt
    return (
      <div className='eMed_OP_AddPatient'>
        <div className='eMed_OP_PatientType_CreateBtn' >
          {UserData?.module_name !== 'nurse' && UserData?.service_type !== "OPTOMETRY" ?
            <div>
              <Tooltip title={t('CreatePatient')} placement='top' arrow>
                <IconButton onClick={() => { history.push({ pathname: "/DoctorHome/Doctor/Demographics", state: { fromCreate: true } }) }}>
                  <img className='eMed_OP_AddPatient_Icon' src={ImagePaths.AddNewPatient.default} alt={"Add Patient"} />
                </IconButton>
              </Tooltip>
            </div> : null}
          <div className='eMed_OP_PatientTypeCont' style={{ marginLeft: UserData?.service_type !== "OPTOMETRY" ? 0 : "1vw" }}>
            <p className='eMed_OP_TypeNew'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.NewPatient.default} alt={'New Patient'} /> {t('NewPatients') + ' (' + (states.patientCount.new_patient ? states.patientCount.new_patient : '0') + ')'}</p>
            <p className='eMed_OP_TypeEstablished'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.EstablishedPatient.default} alt={'Review Patient'} /> {t('ReviewPatients') + ' (' + (states.patientCount.review_patient ? states.patientCount.review_patient : '0') + ')'}</p>
            <p className='eMed_OP_TypeEstablished'><img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.ReviewPatient.default} alt={'Old Patient'} /> {t('Old Patients') + ' (' + (states.patientCount.old_patient ? states.patientCount.old_patient : '0') + ')'}</p>
          </div>
        </div>
        <div>
          <div className='eMed_OP_Apt_Cards'>
            <AppointmentCard onClick={() => { this.setState({ selectedCard: '' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.BookedPatientFill.default} count={states.totalAppointment.today_patients} label={t("All")} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Booked' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.BookedPatientFill.default} count={states.totalAppointment.booked} label={t("Booked")} color={"#616161"} isSelectedCard={states.selectedCard === "Booked" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Arrived' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusArrivedFill.default} count={states.totalAppointment.checked_in_patients} label={t("Arrived")} color={"#0461B7"} isSelectedCard={states.selectedCard === "Arrived" ? true : false} />
            {this.state.totalAppointment?.with_optometry > 0 && !appointmentStatus[2]?.is_checked ? 
            (this.state.isOthometry || ((is_optdoc || is_selectoptdoc) || this.state.isOPT) ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Optometry' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.OptometryFill.default} count={states.totalAppointment.with_optometry} label={t("With Optometry")} color={"#ffff33"} isSelectedCard={states.selectedCard === "Optometry" ? true : false} /> : null) : 
            appointmentStatus[2]?.is_checked ? 
            (this.state.isOthometry || ((is_optdoc || is_selectoptdoc) || this.state.isOPT) ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Optometry' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.OptometryFill.default} count={states.totalAppointment.with_optometry} label={t("With Optometry")} color={"#ffff33"} isSelectedCard={states.selectedCard === "Optometry" ? true : false} /> : null) : null}
             {((this.state.totalAppointment?.optometry_completed > 0 && !appointmentStatus[3]?.is_checked) || appointmentStatus[3]?.is_checked) && (this.state.isOthometry || ((is_optdoc || is_selectoptdoc) || this.state.isOPT)) ?
              <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Optometry Completed' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.withOptCompletedOn.default} count={states.totalAppointment?.optometry_completed || 0} label={t("Opthometry Completed")} color={"orange"} isSelectedCard={states.selectedCard === "Optometry Completed"} /> : null}
            {states.totalAppointment.with_nurse > 0 && !appointmentStatus[1]?.is_checked ?
              <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Nurse' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusNurseFill.default} count={states.totalAppointment.with_nurse} label={t("WithNurse")} color={"#AF2591"} isSelectedCard={states.selectedCard === "With Nurse" ? true : false} /> :
              appointmentStatus[1]?.is_checked ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Nurse' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusNurseFill.default} count={states.totalAppointment.with_nurse} label={t("WithNurse")} color={"#AF2591"} isSelectedCard={states.selectedCard === "With Nurse" ? true : false} /> : null}
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Doctor' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.StatusDoctorFill.default} count={states.totalAppointment.with_doctor} label={t("WithDoctor")} color={"#29B6F6  "} isSelectedCard={states.selectedCard === "With Doctor" ? true : false} />
            {states.totalAppointment.with_counsellor > 0 && !appointmentStatus[5]?.is_checked ?
              <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Counsellor' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.withCounsellorOn.default} count={states.totalAppointment.with_counsellor} label={t("With Counsellor")} color={"#29B6F6  "} isSelectedCard={states.selectedCard === "With Counsellor" ? true : false} /> :
              appointmentStatus[5]?.is_checked ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'With Counsellor' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.withCounsellorOn.default} count={states.totalAppointment.with_counsellor} label={t("With Counsellor")} color={"#29B6F6  "} isSelectedCard={states.selectedCard === "With Counsellor" ? true : false} /> : null}
            {this.state.totalAppointment?.scan > 0 && !appointmentStatus[6]?.is_checked ? 
            (this.state.isOthometry || ((is_optdoc || is_selectoptdoc) || this.state.isOPT) ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Scan/Surgery' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.ScanSurgeryFill.default} count={states.totalAppointment.scan} label={t("Scan/Surgery")} color={"#ff9900"} isSelectedCard={states.selectedCard === "Scan/Surgery" ? true : false} /> : null) : 
            appointmentStatus[6]?.is_checked ?
            (this.state.isOthometry || ((is_optdoc || is_selectoptdoc) || this.state.isOPT) ? <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Scan/Surgery' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.ScanSurgeryFill.default} count={states.totalAppointment.scan} label={t("Scan/Surgery")} color={"#ff9900"} isSelectedCard={states.selectedCard === "Scan/Surgery" ? true : false} /> : null) : null}
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Completed' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.CompletedPatientFill.default} count={states.totalAppointment.checked_out_patients} label={t("Completed")} color={"#1E972A"} isSelectedCard={states.selectedCard === "Completed" ? true : false} />
            <AppointmentCard onClick={() => { this.setState({ selectedCard: 'Cancelled' }, () => { this.getPatientDetails() }) }} icon={ImagePaths.CancelPatients.default} count={states.totalAppointment.cancelled_patients} label={t("Cancelled")} color={"#B8170D"} isSelectedCard={states.selectedCard === "Cancelled" ? true : false} />

          </div>
        </div>
      </div>
    )
  }

  AppointmentTypeIcon(type) {
    const { t } = this.props
    switch (type) {
      case "Telephonic Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("TelephonicAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Telephonic.default}></img></Tooltip></div>
      case "Mobile Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("MobileAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Phone.default}></img></Tooltip></div>
      case "Online Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("Online Appointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Web.default}></img></Tooltip></div>
      case "Web Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("WebAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Web.default}></img></Tooltip></div>
      case "Walk in Appointment": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("WalkAppointment")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Walkin.default}></img></Tooltip></div>
      case "transfer": return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={t("Transfered")} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.Transfer.default}></img></Tooltip><span id='eMed_OP_AptTbl_AptText' style={{ fontWeight: '600' }}>{" (Transfered)"}</span></div>
      default: return <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={type === null ? t("Others") : type} arrow><img className='eMed_OP_Patient_Iconcircle_Appointment' src={ImagePaths.DefaultAppointType.default}></img></Tooltip></div>
    }
  }

  PaymentStatus(status, amount) {
    const { t } = this.props
    switch (status) {
      case "partial":
        return <Tooltip placement='top' title={t("PaymentPending")} arrow>
          <div className='eMed_OP_APtTbl_PayPending'>
            <img className='eMed_OP_AptTbl_PayIcon' src={amount >= 0 ? ImagePaths.PayPending.default : ImagePaths.PendingNega.default}></img>
            <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: amount >= 0 ? "green" : amount == 0 ? 'black' : "Red" }}>{CurrencySymbol} {amount}</Typography>
          </div></Tooltip>
      case "paid":
        return <Tooltip placement='top' title={t("PaymentCompleted")} arrow>
          <div className='eMed_OP_APtTbl_PayPending'>
            <img className='eMed_OP_AptTbl_PayCompletedIcon' src={ImagePaths.PayCompleted.default}></img>
            <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: Colors.themeDark }}>{CurrencySymbol} {amount}</Typography>
          </div></Tooltip>
      case "saved":
        return <Tooltip placement='top' title={t("Unpaid")} arrow>
          <div className='eMed_OP_APtTbl_PayPending'>
            <img className='eMed_OP_AptTbl_PayIcon' src={amount >= 0 ? ImagePaths.PayPending.default : ImagePaths.PendingNega.default}></img>
            <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: amount >= 0 ? "green" : amount == 0 ? 'black' : "Red" }}>{CurrencySymbol} {amount}</Typography>
          </div></Tooltip>
      case "unpaid":
        return <Tooltip placement='top' title={t("PaymentPending")} arrow>
          <div className='eMed_OP_APtTbl_PayPending'>
            <img className='eMed_OP_AptTbl_PayIcon' src={amount >= 0 ? ImagePaths.PayPending.default : ImagePaths.PendingNega.default}></img>
            <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: amount >= 0 ? "green" : amount == 0 ? 'black' : "Red" }}>{CurrencySymbol} {amount}</Typography>
          </div></Tooltip>
      case "Not billed":
        return <Tooltip placement='top' title={t("NotBilled")} arrow>
          <div className='eMed_OP_APtTbl_PayPending'>
            <img className='eMed_OP_AptTbl_PayIcon' src={ImagePaths.Warning.default}></img>
            <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: "#B8170D" }}>{t("NotBilled")}</Typography>
          </div></Tooltip>
    }
  }

  calculateTimeDifference = (StartTime, StopTime) => {
    const start = new Date(StartTime);
    const end = new Date(StopTime);
    const difference = end.getTime() - start.getTime();
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return <Typography fontSize={'0.8vw'} fontWeight={600} color={difference > 1200000 ? "red" : "black"}>{difference > 0 ? `${hours} Hr : ${minutes} Min : ${seconds} Sec` : "0 Hr : 0 Min: 0 Sec"}</Typography>;
  };

  renderDilationSelect = (id, index) => {
    let DilationObj = this.state.AptTblData.find((list) => (list?.appointment_id === id)) ? this.state.AptTblData.find((list) => (list?.appointment_id === id))?.dilation_details : null
    let DilationValue = DilationObj ? DilationObj[index] ? DilationObj[index]?.dilation : null : null
    let DilationValueDisable = DilationObj ? DilationObj[index] ? DilationObj[index]?.end_time : false : false
    return (
      <FormControl variant="standard" size='small' sx={{ width: '15vw', padding: 0 }}>
        <InputLabel>{'Dilation'}</InputLabel>
        <Select
          label={'Dilation'}
          value={DilationValue}
          disabled={(DilationValueDisable) || (this.state.isOPT) }
          onChange={(e) => {
            let data = this.state.AptTblData
            if (data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details) {
              data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details[index].dilation = e.target.value
            } else {
              data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details = [
                {
                  "dilation": e.target.value,
                  "end_time": "",
                  "start_time": ""
                }
              ]
            }
            this.setState({ AptTblData: data })
          }}
          inputProps={{ style: { padding: 0, fontSize: '0.9vw' } }}
        >
          {DilationList?.map((item, index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }


  StatusBtns(data, id) {
    var { AptTblData } = this.state
    const { t } = this.props
    let FirstDilationDetails = data?.dilation_details ? data?.dilation_details[0] : {}
    let SecondDilationDetails = data?.dilation_details ? data?.dilation_details[1] : null
    let appointmentStatus = JSON.parse(localGetItem('appointment_status')) ? JSON.parse(localGetItem('appointment_status')) :
    JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status !== null ? JSON.parse(localGetItem('loggedInUserInfo'))?.appointment_status :
        appointment_Status_opt : appointment_Status_opt
    return (
      data.appointment_status === "Completed" ?
        <div><Button sx={{ textTransform: 'capitalize', width: "10vw", backgroundColor: Colors.themeDark }} size='small' disableElevation variant='contained'>{t("Completed")}</Button></div> :
        data?.transfer_appointment ?
          <div><Button sx={{ textTransform: 'capitalize', width: "10vw", backgroundColor: Colors.themeDark }} size='small' disableElevation variant='contained'>{"Transferred"}</Button></div> :
          data.appointment_status === "Cancelled" ?
            <Tooltip title={`Cancelled Due to ${data?.cancel_reason}`} arrow placement='top'>
              <div><Button size='small' color='error' sx={{ textTransform: 'capitalize', width: '10vw' }} variant='contained'>{t("Cancelled")}</Button></div>
            </Tooltip> :
            <div>
              <ButtonGroup size="small" sx={{ backgroundColor: '#E4F2F0' }}>
                <Tooltip title={t('Arrived')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' key="Arrived" onClick={() => {
                    var today = new Date()
                    var date = DateTime.fromJSDate(today).toFormat('yyyy-MM-dd')
                    var AptDate = new Date(data.appointment_date)
                    var AppointmentDate = DateTime.fromJSDate(AptDate).toFormat('yyyy-MM-dd')

                    if (date === AppointmentDate) {
                      this.postPatientStatus('Arrived', id, data.waiting_comment)
                    } else {
                      this.errorMsgFunc("Arrived status can't be changed for future or past dates.")
                    }

                  }
                  } disabled={(data.appointment_status === "Booked" || data.appointment_status === "On Queue") ? false : true}>
                    <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === 'Arrived' ||
                      data.appointment_status === "With Nurse" || data.appointment_status === 'With Counsellor' ||
                      data.appointment_status === "With Doctor" ||
                      data.appointment_status === "Optometry" || data.appointment_status === "Optometry Completed" ||
                      data.appointment_status === "Scan/Surgery") ? ImagePaths.StatusArrivedFill.default : ImagePaths.StatusArrived.default} alt='Arrived' />
                  </Button>
                </Tooltip>
                {this.state.totalAppointment?.with_nurse > 0 && !appointmentStatus[1]?.is_checked ?
                  <Tooltip title={t('WithNurse')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="WithNurse" onClick={() => {
                      this.postPatientStatus('With Nurse', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer)
                    }
                    } disabled={(data.appointment_status === "Arrived" || data.appointment_status === "Optometry" || data.appointment_status === "With Doctor") ? false : true}>
                      <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Nurse" ||
                        data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor' ||
                        data.appointment_status === "Optometry" || data.appointment_status === "Optometry Completed" ||
                        data.appointment_status === 'Scan/Surgery') ? ImagePaths.StatusNurseFill.default : ImagePaths.StatusNurse.default} alt='With Nurse' />
                    </Button>
                  </Tooltip> :
                  appointmentStatus[1]?.is_checked ?
                    <Tooltip title={t('WithNurse')} placement='top' arrow>
                      <Button id='eMed_OP_Btn' key="WithNurse" onClick={() => {
                        this.postPatientStatus('With Nurse', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer)
                      }
                      } disabled={(data.appointment_status === "Arrived" || data.appointment_status === "Optometry" || data.appointment_status === "With Doctor") ? false : true}>
                        <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "With Nurse" ||
                          data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor' ||
                          data.appointment_status === "Optometry" || data.appointment_status === "Optometry Completed" ||
                          data.appointment_status === 'Scan/Surgery') ? ImagePaths.StatusNurseFill.default : ImagePaths.StatusNurse.default} alt='With Nurse' />
                      </Button>
                    </Tooltip> : null}

                {/* For Optometry Icon start */}
                {(this.state.totalAppointment?.with_optometry > 0 && !appointmentStatus[2]?.is_checked && (this.state.isOPT || this.state.isOthometry)) ?
                  <Tooltip title={t('Optometry')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="Optometry" onClick={() => {
                      this.postPatientStatus('Optometry', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer)
                    }
                    }
                      disabled={appointmentStatus[1]?.is_checked ?
                          (((data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn) :
                          ((data.appointment_status === "Arrived" || data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn
                      }
                    // disabled={(data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true}
                    >
                      <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Optometry" || data.appointment_status === "With Doctor" || data.appointment_status === "Scan/Surgery" || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed") ? ImagePaths.OptometryFill.default : ImagePaths.Optometry.default} alt='With Nurse' />
                    </Button>
                  </Tooltip> :
                  (appointmentStatus[2]?.is_checked && (this.state.isOPT || this.state.isOthometry)) ?
                    <Tooltip title={t('Optometry')} placement='top' arrow>
                      <Button id='eMed_OP_Btn' key="Optometry" onClick={() => {
                        this.postPatientStatus('Optometry', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer)
                      }
                      }
                        disabled={appointmentStatus[1]?.is_checked ?
                          (((data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn) :
                          ((data.appointment_status === "Arrived" || data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true) || this.state.DisableBtn
                        }
                      // disabled={(data.appointment_status === "With Nurse" || data.appointment_status === "With Doctor") ? false : true}
                      >
                        <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Optometry" || data.appointment_status === "With Doctor" || data.appointment_status === "Scan/Surgery" || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed") ? ImagePaths.OptometryFill.default : ImagePaths.Optometry.default} alt='With Nurse' />
                      </Button>
                    </Tooltip> : null}
                {/* For Optometry Icon end */}
                {/* For Optometry completed start */}
                {((this.state.totalAppointment?.optometry_completed > 0 && !appointmentStatus[3]?.is_checked) || (appointmentStatus[3]?.is_checked)) && (this.state.isOPT || this.state.isOthometry) ?
                  <Tooltip title={t('Optometry Completed')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="Optometry Completed" onClick={() => {
                     this.postPatientStatus('Optometry Completed', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer) 
                    }}
                      disabled={(((data.appointment_status === "Optometry") ? false : true) || this.state.DisableBtn)}
                    >
                      <img className='eMed_OP_AptTbl_StatusCom_Icon' src={(data.appointment_status === "Optometry Completed" || data.appointment_status === "With Doctor" || data.appointment_status === "Scan/Surgery" || data.appointment_status === 'With Counsellor') ? ImagePaths.withOptCompletedOn.default : ImagePaths.withOptCompletedOff.default} alt='Opt Complete' />
                    </Button>
                  </Tooltip> : null}
                {/* For Optometry completed end */}
                <Tooltip title={t('WithDoctor')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' key="WithDoctor" onClick={() => {
                    this.postPatientStatus('With Doctor', id, data.waiting_comment, (data.waiting_timer ? null : data.timer), data.waiting_timer)
                  }
                  }
                    disabled={this.state.totalAppointment?.with_nurse > 0 && !appointmentStatus[1]?.is_checked ?
                      ((data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed" ? false : true) || this.state.DisableBtn) :
                      appointmentStatus[1]?.is_checked ?
                        ((data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor' || data.appointment_status === "Optometry Completed" ? false : true) || this.state.DisableBtn) :
                        ((data.appointment_status === "Arrived" || data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor'|| data.appointment_status === "Optometry Completed" ? false : true) || this.state.DisableBtn)
                    }
                  //  disabled={data.appointment_status === "With Nurse" || data.appointment_status === 'Optometry' || data.appointment_status === 'Scan/Surgery' ? false : true}
                  >
                    <img className='eMed_OP_AptTbl_Status_Icon' src={data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor' || data.appointment_status === "Scan/Surgery" ? ImagePaths.StatusDoctorFill.default : ImagePaths.StatusDoctor.default} alt='With Doctor' />
                  </Button>
                </Tooltip>
                {this.state.totalAppointment?.with_counsellor > 0 && !appointmentStatus[5]?.is_checked ?
                  <Tooltip title={t('With Counsellor')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="WithCounsellor" onClick={() => {
                      this.postPatientStatus('With Counsellor', id, data.waiting_comment)
                    }}
                      disabled={((data.appointment_status === "With Doctor" || data.appointment_status === 'Scan/Surgery' ? false : true) || this.state.DisableBtn)}
                    // disabled={(data.appointment_status === "With Doctor" ? false : true) || this.state.DisableBtn}
                    >
                      <img className='eMed_OP_AptTbl_Status_Icon' src={data.appointment_status === "With Counsellor" || data.appointment_status === "Scan/Surgery" ? ImagePaths.withCounsellorOn.default : ImagePaths.withCounsellorOff.default} alt='With Counsellor' />
                    </Button>
                  </Tooltip> :
                  appointmentStatus[5]?.is_checked ?
                    <Tooltip title={t('With Counsellor')} placement='top' arrow>
                      <Button id='eMed_OP_Btn' key="WithCounsellor" onClick={() => {
                        this.postPatientStatus('With Counsellor', id, data.waiting_comment)
                      }
                      }
                        disabled={((data.appointment_status === "With Doctor" || data.appointment_status === 'Scan/Surgery' ? false : true) || this.state.DisableBtn)}
                      // disabled={(data.appointment_status === "With Doctor" ? false : true) || this.state.DisableBtn}
                      >
                        <img className='eMed_OP_AptTbl_Status_Icon' src={data.appointment_status === "With Counsellor" || data.appointment_status === "Scan/Surgery" ? ImagePaths.withCounsellorOn.default : ImagePaths.withCounsellorOff.default} alt='With Counsellor' />
                      </Button>
                    </Tooltip> : null}
                {/* For Optometry Icon start */}
                {this.state.totalAppointment?.scan > 0 && !appointmentStatus[6]?.is_checked && (this.state.isOPT || this.state.isOthometry) ?
                  <Tooltip title={t('Scan/Surgery')} placement='top' arrow>
                    <Button id='eMed_OP_Btn' key="Scan/Surgery" onClick={() => {
                      this.postPatientStatus('Scan/Surgery', id, data.waiting_comment)
                    }
                    } disabled={(data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor') ? false : true}>
                      <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Scan/Surgery") ? ImagePaths.ScanSurgeryFill.default : ImagePaths.ScanSurgery.default} alt='With Nurse' />
                    </Button>
                  </Tooltip> :
                  (appointmentStatus[6]?.is_checked && (this.state.isOPT || this.state.isOthometry)) ?
                    <Tooltip title={t('Scan/Surgery')} placement='top' arrow>
                      <Button id='eMed_OP_Btn' key="Scan/Surgery" onClick={() => {
                        this.postPatientStatus('Scan/Surgery', id, data.waiting_comment)
                      }
                      } disabled={(data.appointment_status === "With Doctor" || data.appointment_status === 'With Counsellor') ? false : true}>
                        <img className='eMed_OP_AptTbl_Status_Icon' src={(data.appointment_status === "Scan/Surgery") ? ImagePaths.ScanSurgeryFill.default : ImagePaths.ScanSurgery.default} alt='With Nurse' />
                      </Button>
                    </Tooltip> : null}
                {/* For Optometry Icon end */}
                <Tooltip title={t('Completed')} placement='top' arrow>
                  <Button id='eMed_OP_Btn' key="Completed" onClick={() => {
                    this.postPatientStatus('Completed', id, data.waiting_comment)
                  }
                  } disabled={data.appointment_status === 'With Doctor' || data.appointment_status === 'Scan/Surgery' || data.appointment_status === 'With Counsellor' ? false : true} >
                    <img className='eMed_OP_AptTbl_StatusCom_Icon' src={ImagePaths.StatusCompleted.default} alt='Completed' />
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box display={(((this.state.isOthometry) || (this.state.isOPT)) && data.appointment_status === 'Optometry' && FirstDilationDetails) ? "flex" : "none"} sx={{ flexDirection: 'column', alignItems: "center", justifyContent: "space-between", height: "7vh", marginTop: '0.5vw' }}>
                  {this.renderDilationSelect(id, 0)}
                  <Box sx={{ display: 'flex', justifyContent: "space-around", alignItems: 'center' }}>
                    {FirstDilationDetails && FirstDilationDetails?.start_time && !FirstDilationDetails?.end_time ? this.calculateTimeDifference(FirstDilationDetails?.start_time, this.state.currentTime) : (FirstDilationDetails?.start_time && FirstDilationDetails?.end_time ? this.calculateTimeDifference(FirstDilationDetails?.start_time, FirstDilationDetails?.end_time) : <Typography fontSize={'0.8vw'} fontWeight={600}>{"0 Hr : 0 Min: 0 Sec"}</Typography>)}
                    {!FirstDilationDetails?.start_time ? <Button disabled={this.state.isOPT} size='small' sx={{ textTransform: "capitalize", fontWeight: 600 }} onClick={() => { this.UpdateDilationTime(id, true, false, 0) }}>Start</Button> : null}
                    {FirstDilationDetails?.start_time && !FirstDilationDetails?.end_time ? <Button disabled={this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' color='error' onClick={() => { this.UpdateDilationTime(id, false, true, 0) }}>Stop</Button> : null}
                    {FirstDilationDetails?.end_time && !SecondDilationDetails ? <Button disabled={this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' color='info' onClick={() => {
                      let data = this.state.AptTblData
                      data[this.state.AptTblData.findIndex((list) => (list?.appointment_id === id))].dilation_details[1] = {
                        "dilation": "",
                        "end_time": "",
                        "start_time": ""
                      }
                      this.setState({ AptTblData: data })
                    }}>Add New</Button> : null}
                  </Box>
                </Box>
                <Box display={(((this.state.isOPT) || (this.state.isOthometry)) && (data.appointment_status === 'Optometry') && SecondDilationDetails) ? "flex" : "none"} sx={{ flexDirection: 'column', alignItems: "center", justifyContent: "space-between", height: "7vh", marginTop: "0.5vw" }}>
                  {this.renderDilationSelect(id, 1)}
                  <Box sx={{ display: 'flex', justifyContent: "space-around", alignItems: 'center' }}>
                    {SecondDilationDetails && SecondDilationDetails?.start_time && !SecondDilationDetails.end_time ? this.calculateTimeDifference(SecondDilationDetails?.start_time, this.state.currentTime) : (SecondDilationDetails?.start_time && SecondDilationDetails.end_time ? this.calculateTimeDifference(SecondDilationDetails?.start_time, SecondDilationDetails.end_time) : <Typography fontSize={'0.8vw'} fontWeight={600}>{"0 Hr : 0 Min: 0 Sec"}</Typography>)}
                    {!SecondDilationDetails?.start_time ? <Button disabled = {this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' onClick={() => { this.UpdateDilationTime(id, true, false, 1) }}>Start</Button> : null}
                    {SecondDilationDetails?.start_time && !SecondDilationDetails.end_time ? <Button disabled = {this.state.isOPT} sx={{ textTransform: "capitalize", fontWeight: 600 }} size='small' color='error' onClick={() => { this.UpdateDilationTime(id, false, true, 1) }}>Stop</Button> : null}
                  </Box>
                </Box>
              </Box>
            </div>

    )
  }

  DocPatientCall = (appDetail) => {
    try {
      let data = {
        appointment_id: appDetail?.appointment_id ? appDetail?.appointment_id : null
      }
      RestAPIService.create(data, Serviceurls.DOC_CALL_PATIENT)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let id = response?.data?.data?.id ? response?.data?.data?.id : null
            this.successMsgFunc(response.data.message)
            if(this.DocCallPatient){
              this.DocCallPatient.send(JSON.stringify({ id: id }))
            }
            if(this.TokenScreen){
              this.TokenScreen.send(JSON.stringify({ id: id }))
            }
            this.getPatientDetails()
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMsgFunc(error.response.data.message)
            if(error.response.data.message?.includes("Patient already assigned")){
              this.getPatientDetails()
            }
          } else {
            this.errorMsgFunc(error.message)
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMsgFunc(error.message)
    }
  }

  onEmergency = () => {
    try {
      let clinicId = JSON.parse(localGetItem("SelectedDoctorClinicId"))
      let data = {
        out_for_emergency: this.state.isDocOnEmergency,
        clinic_id: clinicId ? clinicId : null 
      }
      RestAPIService.create(data, Serviceurls.DOC_WENT_FOR_EMERGENCY)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMsgFunc(response.data.message)
            if(this.EmergencySocket){
              this.EmergencySocket.send(JSON.stringify({ id: response?.data?.data?.id ? response?.data?.data?.id : null }))
            }
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMsgFunc(error.response.data.message)
          } else {
            this.errorMsgFunc(error.message)
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMsgFunc(error.message)
    }
  }

  ActionBtn = (data) => {
    const { t } = this.props
    var states = this.state
    let multi_option = [
      { label: "NextAppointment", value: "NextAppointment" },
      { label: "PrintDoctorNotes", value: "PrintDoctorNotes" },
      { label: "PrintPrescription", value: "PrintPrescription" },
      { label: "OpBarcode", value: "OpBarcode" },
      // { label: "BillDetails", value: "BillDetails" },
      { label: "PresTem", value: "PrescriptionTemplate" },
      { label: "BarCode", value: "CommonBarcode" },
      { label: "MedicalRecord", value: "MedicalRecord" },
      { label: "Patient Remarks", value: "PatientRemarks" },
    ]
    let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
    // if (data.row.appointment_status === "Booked" || data.row.appointment_status === "Arrived") {
    //   multi_option.push({ label: "Reschedule", value: "Reschedule" })
    //   multi_option.push({ label: "PrintDoctorNotes", value: "PrintDoctorNotes" })
    // }
    // else {
    // multi_option.push({ label: "NextAppointment", value: "NextAppointment" })
    // multi_option.push({ label: "PrintDoctorNotes", value: "PrintDoctorNotes" })
    // }
    if (data?.row?.is_emergency) {
      multi_option?.push({ label: 'Disable Highlight', value: 'ChangeTag' })
    }
    let isTodayDate = DateTime.fromJSDate(new Date(this.state?.datePicker)).toFormat('yyyy-MM-dd') === DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
    return (
      <div className='eMed_OP_Apt_ActionBtn' style={{ width: '6vw' }}>
        {/* <Tooltip title={t('CreateBill')} placement='top' arrow>
          <div>
            <IconButton disabled={(data.row.appointment_status === "Cancelled" || data.row.appointment_status === "No Show") ? true : false} onClick={() => {
              let { history } = this.props;
              setCachevalue(JSON.stringify(data?.row), "DoctorPatientData")
              history.push({ pathname: '/DoctorHome/Billing' })
            }}
            ><img className='eMed_OP_AptTbl_Status_Icon' src={(data.row.appointment_status === "Cancelled" || data.row.appointment_status === "No Show") ? ImagePaths.CreateBill.default : ImagePaths.BillCreated.default} alt='Create Bill' /></IconButton>
          </div>
        </Tooltip> */}
        {/* {
          this.state.isOPT || this.state.isOthometry ? null :
            <Tooltip title={t('CreateBill')} placement='top' arrow>
              <div>
                <IconButton disabled={(data.row.appointment_status === "Cancelled") ? true : false} onClick={() => {
                  let { history } = this.props;
                  setCachevalue(JSON.stringify(data?.row), "DoctorPatientData")
                  setCachevalue(JSON.stringify(data?.row), "WaitingPatientData")
                  setCachevalue(JSON.stringify(data?.row?.timer), "waitingTime")
                    history.push({ pathname: "/DoctorHome/Billing" })
                    setCachevalue(true, 'updatePatient')
                }}
                ><img className='eMed_OP_AptTbl_Status_Icon' src={(data.row.appointment_status === "Cancelled") ? ImagePaths.DisabledCreateBill.default : ImagePaths.BillCreated.default} alt='Create Bill' /></IconButton>
              </div>
            </Tooltip>
        } */}
        {UserData?.is_qms_enabled && isTodayDate ?
          <Tooltip title={t('Call Patient')} placement='top' arrow>
            <div>
              <IconButton emed_tid="docHome_patientCall_Btn_testID" disabled={(this.state.disableBtn || data?.row?.is_called || (data.row.appointment_status !== "Arrived" && data.row.appointment_status !== "With Nurse"))} onClick={() => {
                this.setState({ SelectedPatientDetails: data.row, disableBtn: true }, () => { this.DocPatientCall(data.row) })
              }}>
                <img className='eMed_OP_AptTbl_Status_Icon' src={(this.state.disableBtn || data?.row?.is_called || (data.row.appointment_status !== "Arrived" && data.row.appointment_status !== "With Nurse")) ? ImagePaths.DocCallOffIcon.default : ImagePaths.DocCallOnIcon.default} alt='Call Patient' />
              </IconButton>
            </div>
          </Tooltip> : null}
        <Tooltip title={t('CancelAppontment')} placement='top' arrow>
          <div>
            <IconButton disabled={data.row.appointment_status === "Booked" || data.row.appointment_status === "Arrived" ? false : true} onClick={() => {
              this.setState({ cancelAppointment: true, SelectedPatientDetails: data.row })
            }}>
              <img className='eMed_OP_AptTbl_Status_Icon' src={data.row.appointment_status === "Booked" || data.row.appointment_status === "Arrived" ? ImagePaths.CancelAppoinment.default : ImagePaths.CancelAppDisabled.default} alt='Cancel Appointments' />
            </IconButton>
          </div>
        </Tooltip>
        <div>
          <Tooltip title={t("More")} placement='top' arrow>
            <IconButton
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  selectedRow: data.id,
                  isSelected: true,
                  remarkText: data?.comments
                })
              }}
              disabled={(data.row.appointment_status === "Cancelled" || data.row.appointment_status === "No Show" || data.row.transfer_appointment) ? true : false}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          {(this.state.isSelected && this.state.selectedRow == data.id) ?
            <Menu
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={() => {
                this.setState({ anchorEl: null, })
              }}
            >
              {multi_option.map((option, i) => (
                <MenuItem key={i}
                  disabled={(this.state.slctMenuOption === option.value) ||
                    (((data.row.appointment_status !== "Completed" && data.row.appointment_status !== "With Doctor") ||
                      data.row.is_nurse) && (option.label === "PrintDoctorNotes" || option.label === "PrintPrescription")) ||
                    (data.row.is_nurse && option.label === "PresTem") ||
                    (option.label === "TransAppoint" && data.row.appointment_status === "Completed")
                    ? true : false}
                  onClick={() => {
                    this.setState({
                      selectedOption: option.value, anchorEl: null,
                      SelectedPatientDetails: data.row, tokenNum: data.row.token_number,
                      slctMenuOption: option.value,
                    })
                  }}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Menu> : null}
        </div>
      </div>
    )
  }

  routeToDemograhics = (params, MoveToDocu = false) => {
    let { history } = this.props;
    if (params?.row?.doctor_id) {
      if (MoveToDocu) {
        setCachevalue(JSON.stringify(params?.row), "DoctorPatientData")
        setCachevalue(JSON.stringify(params?.row), "WaitingPatientData")
        setCachevalue(JSON.stringify(params?.row?.timer), "waitingTime")
        history.push({ pathname: "/DoctorHome/PatientDocuments" })
        setCachevalue(true, 'updatePatient')
      } else {
        if (params.field === "patientDetails" || (params.field === "purpose_of_visit" && !this.state.onChangePov) || params.field === "appointment_start_time" || params.field === "bill_amount" || (params.field === "token_number" && !this.state.onAddComment)) {
          setCachevalue(JSON.stringify(params?.row), "DoctorPatientData")
          setCachevalue(JSON.stringify(params?.row), "WaitingPatientData")
          setCachevalue(JSON.stringify(params?.row?.timer), "waitingTime")
          history.push({ pathname: "/DoctorHome/DoctorNotes" })
          setCachevalue(true, 'updatePatient')
        }
      }
    } else {
      if (params.field === "patientDetails" || (params.field === "purpose_of_visit" && !this.state.onChangePov) || params.field === "appointment_start_time" || params.field === "bill_amount" || (params.field === "token_number" && !this.state.onAddComment)) {
        this.errorMsgFunc("The patient is not assigned to any doctor. Click the Call button to assign.")
      }
    }
  }
  handleClose = () => {
    this.setState({
      waitingCmts: false
    })
  }

  addComments = (value) => {
    var states = this.state
    if (this.state.waitCmtText) {
      var data1 = value?.waiting_comment
      var data2 = []
      var date = new Date()
      var options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
      var timeString = date.toLocaleTimeString('en-IN', options)
      data2.push({
        "date": DateTime.fromJSDate(date).toFormat('dd-MM-yyyy'),
        "time": timeString,
        "comments": states.waitCmtText.replace(/\s+/g, ' ').trim(),
      })
      var cmtData = [...data1, ...data2]
      this.setState({
        waitingCmts: false,
      })
      this.postPatientStatus('', states.SelectedPatientDetails.appointment_id, cmtData)
    } else {
      this.errorMsgFunc('Please Enter The Comment')
    }
  }

  povPopClose = () => {
    this.setState({
      povText: {},
      ispovPopOpen: false,
      onChangePov: false,
    })
  }

  renderWaitingCmt = (data) => {
    const { t } = this.props
    var states = this.state
    return (
      states.SelectedPatientDetails.patient_id == data.patient_id ?
        <Dialog
          open={this.state.waitingCmts}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
            <div>
              {t("WaitingComments")}
            </div>
            <div className="eMed_DialogBox_Close">
              <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => { this.setState({ waitingCmts: false, waitCmtText: '' }) }} />
            </div>
          </div>
          {
            data.appointment_status !== "Completed" ?
              <div>
                <DialogContent>
                  <TextField
                    sx={{ width: '25vw' }}
                    multiline
                    inputProps={{ maxLength: cmt_Char_limit }}
                    rows={3}
                    placeholder={t("AddComments")}
                    variant="outlined"
                    value={this.state.waitCmtText}
                    onChange={(e) => {
                      this.setState({ waitCmtText: e.target.value })
                    }} />
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                  <Button size="small" variant='outlined' id="eMed_Dia_Btn" onClick={() => { this.setState({ waitCmtText: '' }) }}>
                    {t('Clear')}
                  </Button>
                  <Button size="small" variant='contained' id="eMed_Dia_Btn" onClick={() => { this.addComments(data) }}>
                    {t('Add')}
                  </Button>

                </DialogActions>
              </div> : null
          }
          {data?.waiting_comment?.length > 0 ?
            <DialogContent id='eMed_OP_oldComments_Con'>
              <div className='eMed_OP_oldComments_Con1'>
                {data?.waiting_comment?.map((item) => (
                  <div style={{ height: 'auto' }} >
                    <p className='eMed_OP_Cmts_Text'>{item?.comments}</p>
                    <p className='eMed_OP_Cmts_Text1'>{item?.time + ' | ' + item?.date}</p>
                    <hr />
                  </div>
                ))}
              </div>
            </DialogContent> :
            <DialogContent id='eMed_OP_no_comments_div'>
              <Typography className='eMed_OP_no_comments_txt'>No Comments</Typography>
            </DialogContent>}
        </Dialog> : null
    )
  }
  waitTime = (data, id) => {
    var { AptTblData } = this.state;

    const convertTime12to24 = moment(data.patient_arrived_time, ["h:mm A"]).format("HH:mm");
    var year = moment(new Date(data.appointment_date)).format("LL") + " " + convertTime12to24 + ":00"
    var currnet_date = new Date();
    var arrived_date = new Date(year);
    var res = Math.abs(currnet_date - arrived_date) / 1000;
    var days = Math.floor(res / 86400);
    var hours = Math.floor(res / 3600) % 24;
    var minutes = Math.floor(res / 60) % 60;
    var seconds = res % 60;

    // const newDate = new Date().toLocaleTimeString('en-US', { hour12: false, })
    // var startTime = moment(data.patient_arrived_time != null ? ":00 PM" : '', "HH:mm:ss");
    // var endTime = moment(newDate, "HH:mm:ss");
    // var duration = moment.duration(endTime.diff(startTime));
    // var hours = parseInt(duration.asHours());
    // var minutes = parseInt(duration.asMinutes()) - hours * 60;
    var result = hours + ' Hr ' + minutes + ' Mins.'
    var time = data.patient_arrived_time ? "Arrived: " + data.patient_arrived_time + " | " + (data.waiting_timer ? data.waiting_timer : result) : "-"

    AptTblData && AptTblData.length > 0 && (AptTblData).map((element, index) => {
      if (id === element.patient_id && element.timer != result && element.timer === undefined) {
        AptTblData[index]["timer"] = result === "NaN Hr NaN Mins." ? "0 Hr 0 Mins." : result
        this.setState({
          AptTblData
        })
      }
    })
    const isRedColor = data?.waiting_timer ? data?.waiting_timer?.split(" ")[0] : data?.timer?.split(" ")[0]
    const waitingTimer = result === "NaN Hr NaN Mins." ? "0 Hr 0 Mins." : result;
    // const isRedColor = this.parseWaitingTimer(waitingTimer) > 60; // Check if waiting time is greater than 1 hour

    return (
      // <Typography id='eMed_OP_AptTbl_AptText1'>{time}</Typography>
      <div id='eMed_OP_AptTbl_AptText1' style={{ marginTop: "0.2vw", fontWeight:'bold' }}>
        {`${time.split("|")[0]} | `}<span id='eMed_OP_AptTbl_AptText1' style={{ color: isRedColor >= 1 ? 'red' : 'black' }}>{time.split("|")[1]}</span>
      </div>
    )
  }

  parseWaitingTimer(timer) {
    const [hours, mins] = timer.match(/\d+/g).map(Number);
    return hours * 60 + mins;
  }

  numberSort = (v1, v2) => {
    let sorted = gridNumberComparator(v1, v2);
    return sorted;
  }
  renderAptTable = () => {
    const { t } = this.props
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    const loggedInUserInfo = myUser?.appointment_status;
    const appointmentStatus = (loggedInUserInfo !== null ? loggedInUserInfo : appointment_Status_opt);
    const checkedCount = appointmentStatus?.filter(item => item.is_checked).length;
    const columns = [
      {
        field: 'patientDetails', headerName: t('Patient Details'), headerClassName: 'eMed_Apt_TblHead', flex: 0.170,
        renderCell: (params) => (
          <NewCommonPatientDetails data={params.row} showTag={UserData?.service_type === "OPT" ? false : true} fromOp={true} />
        ), density: 'compact'
      },
      {
        field: 'token_number', headerName: t('AppointmentDetails'), headerClassName: 'eMed_Apt_TblHead', flex: 0.140, density: 'compact',
        renderCell: (params) => {
          let priorityLvl = params?.row?.appointment_type_priority ? params?.row?.appointment_type_priority : ''
          let bgColor = priorityLvl === "Priority_Level_1" ? "#1E972A" : priorityLvl === "Priority_Level_2" ? "#0461B7" : priorityLvl === "Priority_Level_3" ? "#DE7D24" : ''
          return (
            <div>
              <div className='eMed_OP_PatientType'> <Typography id='eMed_OP_AptTbl_AptText' sx={{ fontWeight: '600', color: bgColor ? 'white' : 'black', backgroundColor: bgColor, padding: bgColor ? '0.2vw' : '0', borderRadius: bgColor ? '0.4vw' : '0' }}>{'Token :' + (params.row.token_number ? params.row.token_number : '-')}</Typography>
                <div>{this.AppointmentTypeIcon(params.row.is_transfered ? "transfer" : params.row.patient_appointment_types)}</div>
                <div className='eMed_OP_AptType_Icon'><Tooltip placement='top' title={params.row.patient_documents ? "Documents Uploaded" : "Document Not Uploaded"} arrow>
                  <img className='eMed_OP_Patient_Iconcircle' src={params.row.patient_documents ? ImagePaths.isDocUploaded.default : ImagePaths.isDocNotUploaded.default} onMouseOver={() => { this.setState({ onAddComment: true }) }} onMouseLeave={() => { this.setState({ onAddComment: false }) }}
                    onClick={() => {
                      if (params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show" || !params.row.patient_documents) {

                      } else {
                        this.routeToDemograhics(params, true)
                      }
                    }}>
                  </img></Tooltip></div></div>
              <div className='eMed_OP_AptTbl_Comment'>
                {(params.row?.waiting_comment?.length > 0 && params.row?.waiting_comment[params.row?.waiting_comment?.length - 1]?.comments) ? (params.row?.waiting_comment[params.row?.waiting_comment?.length - 1]?.comments)?.length > 12 ?
                  <Tooltip title={params.row?.waiting_comment[params.row?.waiting_comment?.length - 1]?.comments} placement='top' arrow>
                    <div><Typography id='eMed_OP_AptTbl_AptText'>{`WC.:${params.row?.waiting_comment[params.row?.waiting_comment?.length - 1]?.comments.slice(0, 12) + '...'}`}</Typography></div>
                  </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText'>{`WC.:${params.row?.waiting_comment[params.row?.waiting_comment?.length - 1]?.comments}`}</Typography> : <Typography id='eMed_OP_AptTbl_AptText'>{"WC.:-"}</Typography>
                }
                <Tooltip title={'Add Comments'} placement='top' arrow>
                  <div onClick={() => { this.setState({ waitingCmts: true, SelectedPatientDetails: params.row }) }} onMouseOver={() => { this.setState({ onAddComment: true }) }} onMouseLeave={() => { this.setState({ onAddComment: false }) }}>{params.row.appointment_status === "Cancelled" ? null : <img className='eMed_OP_Patient_Iconcircle' src={ImagePaths.Comments.default} alt='Comments' />}</div>
                </Tooltip>
                {this.state.waitingCmts ? this.renderWaitingCmt(params.row) : []}
              </div>
              <div>{params.row.doctor_name?.length > 12 ?
                <Tooltip title={params.row.doctor_name} placement={'top'} arrow>
                  <Typography id='eMed_OP_AptTbl_AptText'>{'Consultant :' + (params.row.doctor_name ? params.row.doctor_name.slice(0, 12) + '...' : '-')}</Typography>
                </Tooltip> : <Typography id='eMed_OP_AptTbl_AptText'>{'Consultant :' + (params.row.doctor_name ? params.row.doctor_name : '-')}</Typography>} </div>

            </div>)
        }, valueGetter: (params) => params.row.token_number, sortComparator: this.numberSort, sortingOrder: ['asc', 'desc']
      },
      {
        field: 'appointment_start_time', headerName: t('Arriving/WaitingTime'), headerClassName: 'eMed_Apt_TblHead', flex: 0.136, density: 'compact',
        renderCell: (params) => (
          <div>
            {this.waitTime(params.row, params.row.patient_id)}
            <Typography id='eMed_OP_AptTbl_AptText1' >{`Booked: ${params.row.appointment_start_time}`}</Typography>
          </div>
        )
      },
      {
        field: 'bill_amount', headerName: t('PaymentStatus'), headerClassName: 'eMed_Apt_TblHead', flex: 0.125, density: 'compact',
        renderCell: (params) => (
          <div>{this.PaymentStatus(params.row?.bill_status, params.row?.bill_amount)}</div>)
      },
      {
        field: 'purpose_of_visit', headerName: 'Purpose of Visit', headerClassName: 'eMed_Apt_TblHead', flex: 0.124, density: 'compact',
        renderCell: (params) => (
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {params.row.purpose_of_visit?.length > 14 ?
            <Tooltip title={params.row.purpose_of_visit} placement={'top'} arrow>
              <Typography id=''>{(params.row.purpose_of_visit ? params.row.purpose_of_visit.slice(0, 12) + '...' : '-')}</Typography>
            </Tooltip> : <Typography id=''>{(params.row.purpose_of_visit ? params.row.purpose_of_visit : '-')}</Typography>}
            {(params.row.appointment_status !== "Cancelled" && params.row.appointment_status !== "Completed") ?
            <Tooltip title={'Edit'} placement='top' arrow>
              <div emed_tid={"fo_pov_edit"} size="small"
                onClick={() => this.setState({
                  povText: params.row,
                  ispovPopOpen: true,
                })}
                onMouseOver={() => { this.setState({ onChangePov: true }) }}
                onMouseLeave={() => { this.setState({ onChangePov: false }) }}>
                <img src={ImagePaths.UserEditOn.default} className='eMed_list_image' alt='edit' />
              </div>
            </Tooltip> : null}
          </Box>)
      },
      {
        field: 'appointment_status', headerName: t('status'), headerClassName: 'eMed_Apt_TblHead', flex: checkedCount > 5 && checkedCount <= 8 ? 0.24 : 0.15, density: 'compact',
        renderCell: (params) => (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div>{this.StatusBtns(params.row, params.row.appointment_id)}</div>
            {this.state.isOthometry && params.row.recall_status === "pending" ? <Tooltip title={params?.row?.recall_notes} placement='top' arrow>
              <img src={ImagePaths.SyncWarning.default} alt={"Warning"} style={{ height: "2vw", width: "2.5vw" }} />
            </Tooltip> : null}
          </div>
        )
      },
      {
        field: 'action', headerName: t('Action'), headerClassName: 'eMed_Apt_TblHead', flex: 0.09, headerAlign: 'center', density: 'compact', sortable: false, hideable: false, align: 'center',
        renderCell: (params) => (
          <div>{this.ActionBtn(params)}</div>
        )
      }
    ]
    this.state.AptTblData.forEach((element, index) => { element.sno = index + 1 })
    return (
      <div>
        <Box className='eMed_AptTbl_Con'>
          <DataGrid
            rows={this.state.AptTblData}
            columns={columns}
            getRowId={(row) => row['sno']}
            pageSize={this.state.pageSize}
            page={this.state.page}
            rowsPerPageOptions={[10, 20, 30]}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            density={'compact'}
            getRowHeight={(params) => {
              if (((this.state.isOthometry) || (this.state.isOPT)) && params?.model?.appointment_status === 'Optometry') {
                if (params?.model?.dilation_details && params?.model?.dilation_details?.length > 1) {
                  return 185
                } else {
                  return 140
                }
              } else {
                return 80
              }
            }}
            onCellClick={(params) => (params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show") ? null : this.routeToDemograhics(params)}
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => { this.setState({ pageSize: newPageSize }) }}
            getRowClassName={(params) => {
              if (params.row.appointment_status === "Cancelled" || params.row.appointment_status === "No Show") {
                return 'eMed_OP_CancelPatient'
              } else {
                return null
              }
            }}
            disableSelectionOnClick
            loading={this.state.loading}
          />
        </Box>
      </div>
    )
  }
  NxtAppiontmentClose() {
    setTimeout(() => { this.setState({ selectedOption: "" }) }, 1000)
  }
  NxtAppointmentSuccess(message) {
    this.setState({ selectedOption: "" }, () => {
      this.successMsgFunc(message)
      this.getPatientDetails()
    })
  }
  renderNxtApointmtPopup = () => {
    if (this.state.selectedOption === "NextAppointment") {
      return <NextAppointments
        FromDoctor={true}
        clinic_id={this.state.SelectedDoctorClinic}
        PostSuccess={this.NxtAppointmentSuccess.bind(this)}
        NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
        ScreenName={"NextAppointment"}
        PatientDetails={this.state.SelectedPatientDetails} />
    }
    else if (this.state.selectedOption === "Reschedule") {
      return <NextAppointments
        PostSuccess={this.NxtAppointmentSuccess.bind(this)}
        NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
        ScreenName={"RescheduleAppiontment"}
        PatientDetails={this.state.SelectedPatientDetails} />
    }
    else if (this.state.selectedOption === "TransAppoint") {
      return <NextAppointments
        FromDocTrans={true}
        clinic_id={this.state.SelectedDoctorClinic}
        PostSuccess={this.NxtAppointmentSuccess.bind(this)}
        NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
        ScreenName={"TransAppoint"}
        PatientDetails={this.state.SelectedPatientDetails}
        AppointmentType={true} />

    }
    else if (this.state.selectedOption === "PrintDoctorNotes") {
      this.getPatientDoctorNotesPrint()
    }
    else if (this.state.selectedOption === "PrintPrescription") {
      this.getPatientPrescriptionPrint()
    }
    else if (this.state.selectedOption === "OpBarcode") {
      this.getPatientOpCardPrint(false)
    }
    else if (this.state.selectedOption === "OpCard") {
      this.getPatientOpCardPrint(true)

    }
    else if (this.state.selectedOption === "BillDetails") {
      this.getPatientOpBillDetailPrint()
    }
    else if (this.state.selectedOption === "PrescriptionTemplate") {
      this.getPatientPresTemplate()
    }
    else if (this.state.selectedOption === "CommonBarcode") {
      this.getCommonBarCodePrint()
    }
    else if (this.state.selectedOption === "MedicalRecord") {
      this.getMedicalRecordPrint()
    }
    else if (this.state.selectedOption === 'ChangeTag') {
      this.postChangeTagNormal()
    }
    else if (this.state.selectedOption === 'PatientRemarks') {
      this.setState({ isRemarks: true, selectedOption: "", slctMenuOption: "", remarkText: this.state.SelectedPatientDetails?.comments ? this.state.SelectedPatientDetails?.comments : '' })
    }
  }

  renderPatientRemarks = () => {
    const { t } = this.props
    var states = this.state
    return (
        <Dialog
          open={this.state.isRemarks}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
            <div>
              {t("Patient Remarks")}
            </div>
            <div className="eMed_DialogBox_Close">
              <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => { this.setState({ isRemarks: false, remarkText: '' }) }} />
            </div>
          </div>
              <div>
                <DialogContent>
                  <TextField
                    sx={{ width: '25vw' }}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    rows={3}
                    placeholder={t("Add Remarks")}
                    variant="outlined"
                    value={this.state.remarkText}
                    onChange={(e) => {
                      this.setState({ remarkText: e.target.value })
                    }} />
                </DialogContent>
                <DialogActions id="eMed_DialogBox_Btn">
                  <Button size="small" variant='outlined' id="eMed_Dia_Btn" onClick={() => { this.setState({ remarkText: '' }) }}>
                    {t('Clear')}
                  </Button>
                  <Button size="small" variant='contained' id="eMed_Dia_Btn" onClick={() => { this.postInternalCmt() }}>
                    {t('Add')}
                  </Button>

                </DialogActions>
              </div>
        </Dialog>
    )
  }

  postInternalCmt = () => {
    try {
      let data = {
        "comments": this.state.remarkText,
        "appointment_id": this.state.SelectedPatientDetails?.appointment_id
      }
      RestAPIService.create(data, Serviceurls.FO_INTERNAL_CMT)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMsgFunc(response?.data?.message)
            this.setState({
              isRemarks: false,
            })
            this.getPatientDetails()
          }
        })
        .catch((e) => {
          if (e.response.data.status === 'fail') {
            this.errorMsgFunc(e.response.data.message)
          } else {
            this.errorMsgFunc(e.message)
          }
        })
    } catch (e) {
      this.errorMsgFunc(e.message)
    }
  }

  postChangeTagNormal = () => {
    try {
      let data = {
        appointment_id: this.state.SelectedPatientDetails?.appointment_id,
        is_emergency: false
      }
      RestAPIService.create(data, Serviceurls.FO_IP_OP_PATIENT_TAG_CHANGE_NORMAL)
        .then((response) => {
          if (response?.data?.status === 'Success') {
            this.successMsgFunc(response.data.message)
            this.getPatientDetails()
            this.setState({ selectedOption: "", slctMenuOption: "" })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ selectedOption: "", slctMenuOption: "" })
            this.errorMsgFunc(error.response.data.message)
          } else {
            this.errorMsgFunc(error.message)
            this.setState({ selectedOption: "", slctMenuOption: "" })
          }
        })
    } catch (error) {
      this.setState({ selectedOption: "", slctMenuOption: "" })
      this.errorMsgFunc(error.message)
    }
  }

  popupClose = () => {
    this.setState({
      waitingCmts: false,
      cancelAppointment: false
    })
  }
  addCancelComments = (comments) => {
    var states = this.state
    this.postPatientStatus('Cancelled', states.SelectedPatientDetails.appointment_id, comments, states.SelectedPatientDetails.timer)
    this.setState({
      cancelAppointment: false
    })
  }

  gridToolBar = () => {
    // let TextArray = [
    //   { label: "From Date", value: formatDate(this.state.fromDate) },
    //   { label: "To Date", value: formatDate(this.state.toDate) },
    // ]
    var ExportData = []
    this.state.AptTblData?.length > 0 && this.state.AptTblData.map((item) => 
    ExportData.push({"Patient Name":item?.patient_name,"Appointment Details":item?.token_number,"Arriving/Waiting Time":item?.patient_arrived_time,"Payment Status":item?.bill_status,"Purpose of visit":item?.purpose_of_visit, "Status": item?.appointment_status })
    )

    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom  data={ExportData} title={"DotorModule Home"} filename={"DotorModule Home"}/>
      </Box>

    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    return (
      <div className='eMed_OP_Appointment'>
        {this.renderOPHeader()}
        {this.renderPatientType()}
        {this.renderAptTable()}
        {this.state.selectedOption != "" ? this.renderNxtApointmtPopup() : null}
        {this.state.isRemarks ? this.renderPatientRemarks() : null}

        {/* {this.state.selectedOption === "TransAppoint" ?
          <NextAppointments
            PostSuccess={this.NxtAppointmentSuccess.bind(this)}
            NxtAppiontmentClose={this.NxtAppiontmentClose.bind(this)}
            getPatientDetails={this.getPatientDetails.bind(this)}
            ScreenName={"TransAppoint"}
            PatientDetails={this.state.SelectedPatientDetails}
            AppointmentType={true} /> : null

        } */}
        <SnackbarProvider maxSnack={4} variant='info' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={4000} />
        {this.state.cancelAppointment ? <ReasonPopup
          title={"Are you sure? Do you want to cancel the appointment"}
          AlertPopupClose={this.popupClose.bind(this)}
          label={"Enter the Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.addCancelComments.bind(this)} /> : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.ispovPopOpen ?
          <PovEditPopup
            povEditText={this.state.povText.purpose_of_visit}
            povData={this.state.povText}
            getTableData={this.getPatientDetails.bind(this)}
            AlertPopupClose={this.povPopClose.bind(this)}
            successMsg={this.successMsgFunc.bind(this)}
            errorMsg={this.errorMsgFunc.bind(this)}
          /> : null}
      </div>

    )
  }
}

export default withTranslation()(DoctorHome);