import { Box, Button, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import CommonGridHeader, { CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../../Components/Loader';
import { Colors } from '../../../../Styles/Colors'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { getClinicS3Storage } from '../../../../Components/CommonFunctions/CommonFunctions'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { DataGrid } from '@mui/x-data-grid'
import { Bucket_Name } from '../../../../Utility/Constants';
import Webcam from "react-webcam";

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  signatureVersion: 'v4',
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1",
  params: { Bucket: Bucket_Name },
});

export default class DocPatientDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      imageUploaderList: [],
      S3bucketsize: 0,
      page: 0,
      pageSize: 10,
      selectedKey: null,
      deletePopup: false,
      removeData: false,

      files: [],
      PatientDocuments: [],
      uploading: false,
      imageuploadList: [],
      loading: false,
      openPopUp: false,
      selectedViewImage: null,
      fileName: "",
      CapturedImage: null,
      tempCaptureImage: null,
      ProfilePicClicked: false,
      btnDisable:false
    }
    this.uploadImage = React.createRef();
    this.webcamRef = React.createRef();
  }

  componentDidMount() {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    let clinicId = getCachevalue('SelectedDoctorClinic') ? getCachevalue('SelectedDoctorClinic') : (myUser?.clinic_id ? myUser?.clinic_id  : "")
    let appointmentId = this.props?.FromDoctor ? JSON.parse(getCachevalue('IppatientData')) : this.props.fromOP ? JSON.parse(getCachevalue('patientData')) : JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id ? appointmentId?.appointment_id : null,
      patientId: appointmentId?.patient_id ? appointmentId?.patient_id : null
    }, () => {
      this.getPatientDetailData()
      let S3bucketsize = getClinicS3Storage(this.state.clinicId);
      S3bucketsize.then((val) => {
        this.setState({
          S3bucketsize: val
        })
        return val;
      });
      this.GetPatientDocumentData()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getS3Key(doc_key, id) {
    new Promise(resolve => {
      var parms = {
        Bucket: Bucket_Name,
        Key: doc_key, Expires: 300
      };
      s3.getSignedUrl("getObject", parms, (err, url) => {
        if (err) {
          resolve(err)
        } else {
          window.open(url)
        }
      })
    })
  }

  GetPatientDocumentData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DOCUMENT_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              PatientDocuments: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  PostPatientDocumentData = () => {
    try {
      const data = new FormData();
      let fileName = ""
      if (this.state.CapturedImage) {
        fileName = (this.state.fileName + '.jpeg')
      } else {
        fileName = this.state.fileName != "" ? (this.state.fileName + '.pdf') : this.state.imageuploadList[0].name
      }
      data.append('patient_id', this.state.patientId);
      data.append('clinic_id', this.state.clinicId);
      // data.append('appointment_id', this.state.appointmentId);
      data.append('doc_name', fileName);
      data.append('doc_key', this.state.CapturedImage ? this.dataURItoBlob(this.state.CapturedImage) : this.state.imageuploadList[0]);
      data.append('comments', "");
      if(this.props?.FromFO){
        let Data = JSON.parse(getCachevalue('IppatientData'))
        data.append('doctor_id', Data?.doctor_id);
      }else if(this.props.fromOP){
        let Data = JSON.parse(getCachevalue('patientData'))
        data.append('doctor_id', Data?.doctor_id);
      }
      RestAPIService.create(data, Serviceurls.DOC_PATIENT_DOCUMENT_GET)
        .then(response => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({
              imageuploadList: [],
              fileName: "",
              imageuploadList: [],
              fileName: "",
              CapturedImage: null,
              tempCaptureImage: null,
              ProfilePicClicked: false,
              btnDisable:false
            })
            this.GetPatientDocumentData();
          }
        })
        .catch(error => {
          this.setState({ btnDisable: false })
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message);
          }
        });
    } catch (error) {
      this.setState({btnDisable:false})
      this.errorMessage(error.message)
    }
  }

  deleteObject = () => {
    try {
      RestAPIService.delete(Serviceurls.DOC_PATIENT_DOCUMENT_GET + "?id=" + this.state.selectedKey?.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({
              selectedKey: null,
              deletePopup: false,
              removeData: false,
            })
            this.GetPatientDocumentData()
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })

    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage('error', error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      this.setState({
        deletePopup: true,
        selectedKey: item
      })

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      this.state.PatientDocuments?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'doc_name', headerName: 'Document Name', flex: 0.25,
          renderCell: (params) => <Box>
            {params?.row?.doc_name ? params?.row?.doc_name : ""}
          </Box>
        },
        {
          field: 'created_date', headerName: 'Document Uploaded Date', flex: 0.25, align: 'center', headerAlign: 'center',
          renderCell: (params) => {
            return (
              <Box>{params?.row?.created_date ? params?.row?.created_date : '-'}</Box>
            )
          }
        },
        {
          field: 'action', headerName: 'Action', flex: 0.25, align: 'center', headerAlign: 'center',
          renderCell: (params) => (
            <Box>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => { this.getS3Key(params?.row.doc_key, params?.row.id) }}
              >
                <Box component={'img'} src={ImagePaths.Download.default} height={'1.2vw'} width={'1.2vw'} />
              </Button>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.onDeleteHandler(params?.row)}
              >
                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
              </Button>
            </Box>
          )
        },
      ]
      return (
        <Box border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }} height={'31vw'} mr={'0.5vw'} overflow={'scroll'}>
          <DataGrid
            rows={this.state.PatientDocuments}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            sx={{ backgroundColor: Colors.white }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Box className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Box>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.loading}
          />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onImageHandler = async (e) => {
    const imageList = [];
    let imageFiles = [...e.target.files];
    let clearedArr = imageFiles?.filter((image, i) => {
      let file = image?.name;
      return String(file).toLowerCase() !== String(imageFiles[i + 1]?.name).toLowerCase()
    });
    // convert images to base64;
    for (let i = 0; i < clearedArr.length; i++) {
      imageList.push(clearedArr[i])
    }
    const newList = await Promise.all(imageList)
    e.target.value = ''
    this.setState({
      imageuploadList: newList
    })
  }

  renderRightSide = () => {
    try {
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'1vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Patient Document</Typography>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white, paddingLeft: "1vw", paddingTop: '0.75vw', paddingBottom: "0.75vw" }}>
            <TextField
              size='small'
              sx={{ width: '18vw' }}
              value={this.state.fileName}
              label='Document Name'
              onChange={(e) => this.setState({ fileName: e.target.value })}
            />
          </Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'1vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Upload Your Documents Here</Typography>
          </Box>
          <Box component={'div'} height={'20vw'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'}>
            <Box component={'div'} height={'20vw'} display={(this.state.imageuploadList?.length > 0 || this.state.CapturedImage) ? 'none' : 'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
              <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'14vw'} width={'15vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Box sx={{ display: 'none' }}
                  // multiple
                  accept="application/pdf"
                  ref={this.uploadImage}
                  onChange={this.onImageHandler}
                />
                <Button sx={{ textTransform: 'capitalize' }}
                  disabled={this.state.CapturedImage}
                  onClick={() => { this.setState({ ProfilePicClicked: true }) }}                >
                  <Box component={'div'} borderRadius={'10px'} border={'1px dotted gray'} height={'10vw'} width={'13vw'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Box component={'img'} src={this.state.CapturedImage ? ImagePaths.DocumentPreview.default : ImagePaths.CameraCapture.default} height={'2vw'} width={'2vw'} />
                    <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600} mt={'0.5vw'}>{this.state.CapturedImage ? "File Added" : "Capture Image"}</Typography>
                  </Box>
                </Button>
              </Box>
              <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'14vw'} width={'15vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Box component={'input'} type='file' sx={{ display: 'none' }}
                  // multiple
                  accept="application/pdf"
                  ref={this.uploadImage}
                  onChange={this.onImageHandler}
                />
                <Button sx={{ textTransform: 'capitalize' }}
                  disabled={this.state.imageuploadList?.length > 0}
                  onClick={() => this.uploadImage.current.click()}
                >
                  <Box component={'div'} borderRadius={'10px'} border={'1px dotted gray'} height={'10vw'} width={'13vw'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Box component={'img'} src={this.state.imageuploadList?.length > 0 ? ImagePaths.DocumentPreview.default : ImagePaths.LabUploadButton.default} height={'2vw'} width={'2vw'} />
                    <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600} mt={'0.5vw'}>{this.state.imageuploadList?.length > 0 ? "File Added" : "Browse Files"}</Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
            {this.state.imageuploadList?.length > 0 ?
              <Box component={'div'} display={'flex'} maxWidth={'29vw'} alignItems={'center'} justifyContent={'center'}>
                {this.state.imageuploadList?.map((image, index) => (
                  <Box component={'div'} key={index} mr={'0.5vw'} height={'5vw'} display={'flex'} alignItems={'center'}>
                    <Tooltip title={typeof (image) === "string" ? null : image?.name} arrow placement='top'>
                      <Box component={'img'}
                        onClick={() => {
                          if (typeof (image) === "string") {
                            if (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png") {
                              this.setState({ isImgClicked: true, popUpOpen: true, selectedImage: image })
                            }
                            else {
                              window.open(image)
                            }
                          }
                        }}
                        src={typeof (image) === "string" ?
                          (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png" ?
                            image : ImagePaths.DocumentPreview.default) : ((image?.type === "image/jpeg" || image?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                        height={'3.5vw'} width={'2.8vw'}
                      />
                    </Tooltip>
                    <Box><IconButton sx={{ position: 'relative', bottom: '1.2vw', right: '1.4vw' }} size="small"
                      onClick={() => {
                        this.setState({
                          imageuploadList: [],
                        })
                      }}>
                      <CancelTwoToneIcon fontSize='small' color="error" />
                    </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box> :
              this.state.CapturedImage ?
                <Box component={'div'} display={'flex'} maxWidth={'29vw'} alignItems={'center'} justifyContent={'center'}>
                  <Box component={'div'} mr={'0.5vw'} height={'5vw'} display={'flex'} alignItems={'center'}>
                    <Box component={'img'}
                      onClick={() => {
                        // if (typeof (image) === "string") {
                        //   if (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png") {
                        this.setState({ isImgClicked: true, popUpOpen: true, selectedImage: this.state.CapturedImage })
                        // }
                        // else {
                        //   window.open(image)
                        // }
                        // }
                      }}
                      src={ImagePaths.ImagePreview.default}
                      height={'3.5vw'} width={'2.8vw'}
                    />
                    <Box><IconButton sx={{ position: 'relative', bottom: '1.2vw', right: '1.4vw' }} size="small"
                      onClick={() => {
                        this.setState({ CapturedImage: null, tempCaptureImage : null })
                      }}>
                      <CancelTwoToneIcon fontSize='small' color="error" />
                    </IconButton>
                    </Box>
                  </Box>
                </Box> : null
            }
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.setState({
                imageuploadList: [],
                fileName: "",
                CapturedImage: null,
                tempCaptureImage: null,
                ProfilePicClicked: false,
              })}
            >Clear</Button>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              disabled={this.state.loading || this.state.btnDisable || (this.state.CapturedImage === null && this.state.imageuploadList.length === 0)}
              onClick={() => {
                this.setState({
                  btnDisable:true
                },() => {
                  if (this.state.CapturedImage !== null && !this.state.fileName) {
                    this.errorMessage("Enter the document name")
                  } else {
                    this.PostPatientDocumentData(this.state.imageuploadList)
                  }
                })
              }}
            >Add</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  OpenWebCamPopUP = () => {
    try {
      return (
        <Box>
          <Modal open={true}>
            <Box className="eMed_WebCamPop_Main">
              <Box component={'div'} className="eMed_WebCam_Header">
                <Typography variant='h6'>{"Capture Image"}</Typography>
                <Button onClick={() => { this.setState({ tempCaptureImage: this.state.CapturedImage, ProfilePicClicked: false }) }} >
                  <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                </Button>
              </Box>
              <Box component={'div'}>
                {
                  this.state.tempCaptureImage === null ?
                    <Webcam
                      className='eMed_WebCam_Div'
                      audio={false}
                      mirrored={true}
                      ref={this.webcamRef}
                      screenshotFormat="image/jpeg"
                    // videoConstraints={videoConstraints}
                    /> :
                    <img className='eMed_WebCam_Img_Div' src={this.state.tempCaptureImage} />
                }
              </Box>
              <Box component={'div'} className="eMed_WebCam_Btn_div">
                <Button disabled={this.state.tempCaptureImage !== null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ tempCaptureImage: this.webcamRef.current.getScreenshot() }) }}>Capture</Button>
                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ tempCaptureImage: null }) }}>Retake</Button>
                <Button disabled={this.state.tempCaptureImage === null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ CapturedImage: this.state.tempCaptureImage, ProfilePicClicked: false }) }}>Add Image</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      );
    } catch (e) { }
  };

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'80vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'68vh'}>
          <Box component={'div'} flex={0.65}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteObject.bind(this)}
          disable={this.state.removeData}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.ProfilePicClicked ? this.OpenWebCamPopUP() : null}

      </Box>
    )
  }
}
