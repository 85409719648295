import { Box, Button, Divider, Drawer, IconButton, Paper, Stack, Tooltip, Typography, Menu, MenuItem } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../Styles/Colors'
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid'
import { CommonStockTransferPopup } from '../../../Components/Common Components/SubLocCommonComponents';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { AddCircle } from '@material-ui/icons';
import TransitDetails from '../../SubLocation/StockTransfer/TransitDetails';
import Loader from '../../../Components/Loader';
import SubLocReportFilter from '../../SubLocation/Reports/SubLocReportFilter';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';

let RoleData = null;
let userAccess = null;
class PharmaOutbound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DataList: [],
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
      },
      totalBill: 0,
      totalAmount: 0,
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      pageName:'PharmaOutbound',
      isApproveClick: false,
      selectedData: {},
      selectedRowId: null,
    }
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    let pharmacy_type = RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy"
    let branch = RoleData?.branch_to_branch_stock_transfer
    userAccess = branch ? CheckAccessFunc( pharmacy_type, "Stock Transfer", 'Sub Location Transfer', 'Outbound', "SubTab") : CheckAccessFunc( pharmacy_type, "Stock Transfer", 'Outbound', null, "Tab");
    this.getInboundList()
  }

  getInboundList = () => {
    try {
      this.LoaderFunction(true)
      var {filterDatas} = this.state
      RestAPIService.getAll(Serviceurls.SUBLOC_OUTBOUND + 
        `?from_location=${filterDatas.toLocationName ? filterDatas.toLocationName.id: ''}&status=${filterDatas.status ? filterDatas.status : ''}&from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}`).
          then((response) => {
              if (response.data.status === "success") {
                this.LoaderFunction(false)
                var Data = response?.data?.data ? response.data.data : []
                  this.setState({
                    DataList: Data
                  })
              }
          }).catch((error) => {
              if (error?.response?.data?.message) {
                this.LoaderFunction(false)
                  this.errorMessage(error.response.data.message)
              } else {
                this.LoaderFunction(false)
                  this.errorMessage(error.message)
              }
          })
  } catch (error) {
    this.LoaderFunction(false)
      this.errorMessage(error.message)
  }
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
        this.getInboundList()
    })
  }
  transitClosePop = () => {
    this.setState({
      isTransit: false
    }, ()=>{
      this.getInboundList()
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  getPrint = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.SUBLOC_LINE_OF_ITEM + `?request_id=${id}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((e) => {
          if (e?.reponse?.data?.message) {
            this.errorMessage(e?.response?.data?.message)
          } else {
            this.errorMessage(e?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }


  MoreMenus = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "Print", label: t("Print"), dis: false },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data?.row?.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data?.row?.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
              {multi_option.map((option) => (
            <MenuItem disabled={option.dis} onClick={() => {
              if(option.value === "ViewCancel"){
                this.setState({
                  isReceiveClicked: true,
                  selectedData: data?.row,
                  selectedRowId: data?.row?.id,
                  is_cancel: true
                })
              }else if(option.value === "Print"){
                this.getPrint(data?.row?.id)
              }
            }}>
              {option.label}
            </MenuItem>))}
          </Menu>
          : null}
      </div>
    )
  }

  renderTable = () => {
    const { t } = this.props
    let states = this.state;
    const columns = [
      {
        field: "request_date", flex: 0.11, headerName: t("ReqDate"),
        renderCell: (params) => (params?.row?.request_date?.length > 14 ?
          <Tooltip placement="top" title={params?.row?.request_date} arrow><div>{params?.row?.request_date.slice(0, 13) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.request_date ? params?.row?.request_date : "-"}</Box>)
      },
      {
        field: "request_number", flex: 0.11, headerName: t("ReqNum"),
        renderCell: (params) => (params?.row?.request_number?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.request_number} arrow><div>{params?.row?.request_number.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.request_number ? params?.row?.request_number : "-"}</Box>)
      },
      {
        field: "transfer_from", flex: 0.13, headerName: t("ReqFrom"), headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.transfer_from?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.transfer_from} arrow><div>{params?.row?.transfer_from.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.transfer_from ? params?.row?.transfer_from : "-"}</Box>)
      },
      {
        field: "transfer_to", flex: 0.13, headerName: t("ReqTo"), headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.transfer_to?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.transfer_to} arrow><div>{params?.row?.transfer_to.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.transfer_to ? params?.row?.transfer_to : "-"}</Box>)
      },
      {
        field: "transit_name", flex: 0.14, headerName: t("TransitDetail"), headerAlign: "center", align: "center",
        renderCell: (params) => ((params.row.transit_name && params.row.transit_number) ? 
        <Box>
            {params?.row?.transit_name?.length > 15 ? 
            <Tooltip title={params?.row?.transit_name} placement='top'><Typography sx={{fontWeight: 'bold'}}>{params?.row?.transit_name.slice(0, 15) + "..."}</Typography></Tooltip> : params?.row?.transit_name ? params?.row?.transit_name : '-'}
            <Typography sx={{color: Colors.themeDark}}>{params?.row?.transit_number ? params.row.transit_number : '-'}</Typography>
        </Box> : 
          params.row.status === 'In Transit' ? 
          <Box>
            <Button disabled = {!userAccess?.editAccess} size="small" variant='oulined' sx={{color: Colors.themeDark, fontSize: '1vw', textTransform: 'capitalize'}} onClick={()=>{this.setState({isTransit: true, selectedRowId: params.row.id})}} className='Common_Btn_Min_Width' startIcon={<AddCircle/>}>{'Add Transit Details'}</Button>
          </Box> : '-')      },
      {
        field: "no_of_product", flex: 0.10, headerName: t("NoOfProd"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.no_of_product ? params?.row?.no_of_product : "-"}</Box>)
      },
      {
        field: "status", flex: 0.13, headerName: t("Status"), hideable: false, headerAlign: "center", align: "center",
        renderCell: (params) => (params?.row?.status?.length > 18 ?
          <Tooltip placement="top" title={params?.row?.status} arrow><div>{params?.row?.status.slice(0, 17) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.status ? params?.row?.status : "-"}</Box>)
      }
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: "action", flex: 0.15, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Button emed_tid='subLoc_inbound_reqst' disabled={params?.row?.status === 'In Transit'} className='eMed_Filter_Btns' variant='contained' size='small' 
            onClick={() => {   
              var status = params?.row?.status
              if(status === 'Initiated'){
                this.props.history.push({ pathname: "/SubLocationStockTransfer/RaiseRequest", state: {page: "pharma_outbound", tranferData: params.row  } })
              }else if(status === 'Approved'){
                this.setState({
                  isApproveClick: true,
                  selectedData: params?.row,
                  selectedRowId: params?.row?.id
                })
              }         
             }}
            >{params?.row?.status === 'In Transit' ? t("Transfered") : params?.row?.status === 'Approved' ? t("Transfer") : t("Approve") }</Button>
            {this.MoreMenus(params)}
          </Box>
        }
      );
    }

    return (
      <Box component={'div'}>
        <Paper sx={{ height: '69vh' }}>
          <DataGrid
            rows={states.DataList}
            columns={columns}
            getRowId={(row) => row.id}
            page={states.page}
            pageSize={states.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
        </Paper>
      </Box>
    )
  }

  popupClose = () => {
    this.setState({
      isApproveClick: false,
      selectedData: {},
      selectedRowId: null,
    },()=>{
      this.getInboundList()
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  render() {
    const { t } = this.props;
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
         {RoleData?.permission_access?.pharma?.isApprover || !RoleData?.is_user ?
            <><Button emed_tid='subLoc_inbound_reqst' className='eMed_Filter_Btns' variant='contained' size='small'
              onClick={() => {
                this.props.history.push({ pathname: "/SubLocationStockTransfer/RaiseRequest", state: { page: "pharma_outbound" } })
              }}
            >{t("SendRqst")}</Button>
              {/* <Divider orientation='vertical' sx={{ marginRight: "0.5vw" }} /> */}
              </>
            : null}
          <Box borderRight={'2px solid gray'} pr={'1vw'}>
            <Button
              emed_tid={'outbond_ftr'}
              className='eMed_common'
              sx={{ width: '2vw', height: '2vw', mr: '1vw' }}
              onClick={() => this.setState({ filterOpen: true })}
            >
              <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Box>
          {/* need to work start */}
          {/* <IconButton emed_tid={"subLoc_inbound_print"} className='eMed_Pharma_rts_btn' onClick={() => { }}>
            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
          </IconButton>
          <IconButton emed_tid={"subLoc_inbound_export"} className='eMed_Pharma_rts_btn' onClick={() => { }}>
            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
          </IconButton> */}
          {/* need to work end */}
        </Box>
        <Box component={'div'} height={'73vh'} p={'1vw'}>
          {this.renderTable()}
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <SubLocReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        {this.state.isApproveClick ? 
        <CommonStockTransferPopup 
        Data = {this.state.selectedData}
        title={"Transfer"}
        handlePopUpClose={this.popupClose}
        RowId={this.state.selectedRowId}
        />
        : null}

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.state.isTransit ? 
          <TransitDetails
          transitClose={this.transitClosePop}
          id={this.state.selectedRowId}
          /> : null}
          <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}

export default withTranslation()(PharmaOutbound);
