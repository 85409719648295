import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Modal, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Paper, IconButton, Stepper, Step, StepLabel, Stack, Grid, Dialog, DialogContent, DialogActions, FormControlLabel, Checkbox, TablePagination, InputAdornment, Chip } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ResultData } from './data'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { ImagePaths } from '../../../Utility/ImagePaths'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { EditRounded } from '@mui/icons-material';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { ReasonPopup } from '../../../Components/Common Components/CommonComponents'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Code_Length, CurrencySymbol } from '../../../Utility/Constants'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import { Close as CloseIcon } from '@mui/icons-material';



const Gender_List = [
  { label: "Male", value: 'M' },
  { label: "Female", value: 'F' },
  { label: "Common", value: 'C' }
]

const Days_List = [
  { label: "Days", value: 'days' },
  { label: "Weeks", value: 'weeks' },
  { label: "Months", value: 'months' },
  { label: "Years", value: 'years' }
]

const Referance_Type_List = [
  { label: ">", value: '>' },
  { label: "<", value: '<' },
  { label: "≥", value: '≥' },
  { label: "≤", value: '≤' },
  { label: "=", value: '=' }
]

const Result_Type_List = [
  { label: 'Range Based', value: 1 },
  { label: 'Positive/Negative', value: 2 },
  { label: 'Free Text Based ', value: 5 },
  { label: 'Culture Test', value: 3 },
  { label: 'Present/Absent', value: 6 },
  { label: 'Greater than/Less than', value: 7 },
  { label: 'Graph Based', value: 8 },
  { label: 'Calculation', value: 9 },
]

const Calculation_Based_Type = ['Triglycerides', 'Total Cholesterol | HDL Ratio', 'Total Cholesterol | HDL',
  'LDL | HDL', 'Total Protein | Albumin', 'Total Billirubin | Direct Billrubin', 'Albumin | Globulin'
]


export default class RangeBasedConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultConfigData: [],
      openPopUp: false,
      selectedData: null,
      range_data: {
        gender: '',
        age_from: '',
        age_to: '',
        age_from_unit: 'years',
        age_to_unit: 'years'
      },
      ref_data: [{
        reference_from: '',
        reference_to: '',
        description: '',
        unit: ''
      }],
      ref_type: [{
        reference_type: '',
        reference_range: '',
        description: '',
        unit: ''
      }],
      testNameLength: [],
      selectedIndex: "",
      isEdit: false,
      editRangeData: null,
      originalEditData: null,
      refUnitList: [],
      isPrintOrderEdit: false,
      oldSelectedData: '[]',
      selectedEditIndex: null,
      departmentListData: [],
      duplicateResult: '[]',
      departmentName: null,
      grpListData: [],
      testListData: [],
      groupName: null,
      NewgrpListData: [],
      NewtestListData: [],
      newTestNameLength: [],
      isEditTestDetails: false,
      addTestPopup: false,
      stepNumber: 0,
      ViewScreen: 0,
      DepList: [],
      deletePopup: false,
      DltTitle: "",
      testName: null,
      GroupAmt: 0,
      TestAmt: 0,
      NewdepartmentName: null,
      NewgroupName: null,
      NewSpecimen: "",
      NewtestName: "",
      NewMethod: "",
      NewresultType: "",
      newPrintOrder: null,
      receivedData: {},
      EditTitle: "",
      EditPopup: false,
      EditDepName: "",
      EditGrpName: "",
      EditGrpAmt: "",
      EditDepId: "",
      EditGrpId: "",
      EditPrintOrder: "",
      EditTestName: "",
      EditTestAmt: "",
      EditTestId: "",
      isEditDepart: false,
      isEditGrp: false,
      DepPrintOrder: null,
      NewEditTestDetails: false,
      isBillable: false,
      EditIsBillable: false,
      AntibioticList: [],
      BacteriaList: [],
      CulturalTestData: [
        {
          AntibioticData: null,
          BacteriaData: null,
          zone_size_s: "",
          zone_size_i: "",
          zone_size_r: "",
          mic_s: "",
          mic_i: "",
          mic_r: "",
        }
      ],
      containers: [
        {
          id: 1
        }
      ],
      EditCultureTest: false,
      page: 0,
      rowsPerPage: 10,
      CulturalTestList: [],
      selectedIndex: "",
      isEditCulture: false,
      disableBtn: false,
      grpCode:'',
      testCode:'',
      hasCode:false,
      EditGrpCode:'',
      EditTestCode:'',
      graphBased: {
        xLabelName: '',
        yLabelName: '',
        xAxisUnit: '',
        yAxisUnit: '',
        xValues: [
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
        graph_ref_range: [{
          ref_from: '',
          ref_to: '',
          ref_type: '',
        }],
      },
      dupRangeRef: [
        { ref_from: '', ref_to: '', ref_type: '' }
      ],
      graphData: [],

      // Calculation States
      cal_Data: [],
      selectedType: '',
      selectedRefData: null,
      openCalculationEdit: false,
      calEditData: null,
      editRefFrom: '',
      editRefTo: '',
      editRefstatus: '',
      editCalculationTest: false,
      calculationList: [],
      addNewRefFrom: '',
      addNewRefTo: '',
      addNewRefStatus: '',
      calTestData: null,
      postLoad: false,
      dupCalEditData: '{}',
      calGrpData: null,
      calTstData: null,
      addNewRefCalData: '[]',
      openAddNewCalReference: false
    }
  }

  componentDidMount() {
    this.getDeptList()
    this.getNewReferrenceUnitData()
    this.getBacteriaList()
    this.getAntiBacteriaList()
    let setData = [];
    Calculation_Based_Type?.forEach(element => {
      setData?.push({
        cal_ref_type: element,
        is_cal_ref_data: false,
        cal_ref_data: [
          {
            cal_ref_from: '',
            cal_ref_to: '',
            cal_ref_unit: element === 'LDL | HDL' ? 'g/dL' : 'mg/dL',
            cal_ref_status: ''
          }
        ]
      })
    })
    this.setState({ cal_Data: setData, addNewRefCalData: JSON.stringify(setData) })
  }

  getDeptList = () => {
    let Deplength = []
    try {
      RestAPIService.getAll(Serviceurls.LAB_DEPT_LIST + "?dept_name=")
        .then((response) => {
          if (response.data.status === 'success' && response?.data?.data?.length > 0) {
            var DeptData = response.data?.data ? response.data?.data : []
            for (let i = 0; i <= DeptData?.length - 1; i++) {
              Deplength?.push(i + 1)
            }
            this.setState({
              departmentListData: DeptData,
              departmentName: DeptData[0] ? DeptData[0] : null,
              selectedDeptId: DeptData[0]?.id ? DeptData[0]?.id : null,
              DepartLength: Deplength
            }, () => {
              var { departmentName } = this.state
              if (departmentName?.dept_name) {
                this.getNewReferrenceData()
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getBacteriaList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_BACTERIA_GET).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ BacteriaList: response.data.data })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getAntiBacteriaList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_ANTI_BACTERIA_GET).
        then((response) => {
          this.setState({ AntibioticList: response.data.data })
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getResultConfigExcel = () => {
    try {
      var { NewdepartmentName } = this.state
      this.setState({ disableBtn: true })
      RestAPIService.excelGet(Serviceurls.NEW_LAB_RESULT_CONFIGURATION + "?dept_name=" + NewdepartmentName?.dept_name + "export_type=excel")
        .then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({ disableBtn: false })
    }
  }

  handleOpenPopup = (data, dataTwo, data3) => {
    try {
      let Data1 = JSON.parse(JSON.stringify(data))
      let Data2 = JSON.parse(JSON.stringify(dataTwo))
      let Data3 = JSON.stringify(dataTwo)
      let Data4 = JSON.stringify(data)
      if (data?.result_type == 3 && !this.state.isEditTestDetails) {
        this.setState({
          EditCultureTest: true,
          CulturalTestList: data?.test_data?.length > 0 ? data?.test_data : [],
          selectedData: Data1,
        })
      } else {
        if (this.state.isEdit) {
          let duplicateRefData = {
            reference_from: '',
            reference_to: '',
            description: '',
            unit: ''
          }
          let duplicateRefTypeData = {
            reference_type: '',
            reference_range: '',
            description: '',
            unit: ''
          }
          let duplicateValue = {
            reference_type: '',
            reference_range: '',
            description: '',
            unit: ''
          }
          var RefData = []
          var RefType = []
          if (data.result_type == 1) {
            RefData = [...Data2?.reference_data, duplicateRefData]
          } else if (data.result_type == 8) {
            let graphRange = []
            this.state.graphBased.xLabelName = Data2?.label_x
            this.state.graphBased.yLabelName = Data2?.label_y
            this.state.graphBased.xAxisUnit = Data2?.unit_x
            this.state.graphBased.yAxisUnit = Data2?.unit_y
            this.state.graphBased.xValues = Data2?.values?.map(value => ({ value: value.toString() }))
            this.state.graphBased.graph_ref_range = Data2?.ranges
            graphRange.push(Data2)
            this.state.graphData = graphRange
            this.setState({
              graphBased: this.state.graphBased,
              graphData: this.state.graphData
            })
          } else if (data.result_type == 7) {
            RefData = [...Data2?.reference_data, duplicateRefData]
            RefType = [...Data2?.reference_data, duplicateRefTypeData]
          } else {
            RefData = [...Data2?.reference_data]
          }
          this.setState({
            openPopUp: true,
            selectedData: Data1,
            oldSelectedData: JSON.stringify(Data1),
            editRangeData: Data2,
            originalEditData: Data3,
            ref_data: RefData,
            ref_type: RefType,
            duplicateSelected: Data4
          })
        } else {
          let numbers = [];
          for (let i = 0; i <= Data2?.grp_test_data.length - 1; i++) {
            numbers.push(i + 1)
          }
          if (data?.result_type == 8) {
            this.state.graphBased.xLabelName = data?.test_data[0]?.label_x
            this.state.graphBased.yLabelName = data?.test_data[0]?.label_y
            this.state.graphBased.xAxisUnit = data?.test_data[0]?.unit_x
            this.state.graphBased.yAxisUnit = data?.test_data[0]?.unit_y
            this.state.graphBased.xValues = data?.test_data[0]?.values?.map(value => ({ value: value.toString() }))
            this.state.graphBased.graph_ref_range = data?.test_data[0]?.ranges
            this.state.graphData = data?.test_data
            this.setState({
              graphBased: this.state.graphBased,
              graphData: this.state.graphData
            })
          }
          this.setState({
            openPopUp: true,
            selectedData: Data1,
            testNameLength: numbers,
            editRangeData: null,
            isEdit: false,
            duplicateSelected: Data4,
            EditDepName: data3?.dept_name,
            EditDepId: data3?.dept_id,
            EditGrpAmt: dataTwo?.grp_amt,
            EditGrpName: dataTwo?.grp_name,
            EditTestName: data?.test_name,
            EditTestAmt: data?.test_amt,
            EditTestId: data?.test_id,
            EditGrpId: dataTwo?.grp_id,
            EditIsBillable: !(data?.is_billable),
            EditGrpCode:dataTwo?.grp_test_code ? dataTwo?.grp_test_code : '',
            EditTestCode:data?.test_code ? data?.test_code : '',
            duplicate: { test_name: data?.test_name, test_amt: data?.test_amt, is_billable: !(data?.is_billable),test_code:data?.test_code }
          })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleClosePopup = () => {
    try {
      this.setState({ openPopUp: false, isEdit: false, isEditTestDetails: false, NewEditTestDetails: false }, () => this.resetNewReferrence())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names, index) => {
    try {
      let { selectedData, range_data, ref_data, isEdit, editRangeData, ref_type } = this.state
      let value = e.target.value
      switch (names) {
        case 'specimen':
          selectedData.specimen = value
          this.setState({ selectedData })
          break;
        case 'method':
          selectedData.method = value
          this.setState({ selectedData })
          break;
        case 'resultType':
          selectedData.result_type = value
          if (selectedData.result_type === 'range based') {
            this.setState({ selectedData })
          } else {
            let dup_ref_data = [
              {
                reference_from: '',
                reference_to: '',
                description: '',
                unit: ''
              }
            ]
            this.setState({ selectedData, ref_data: dup_ref_data })
          }
          break;
        case 'printOrder':
          selectedData.print_order = value
          this.setState({ selectedData })
          break;
        case 'NewSpecimen':
          this.setState({ NewSpecimen: value })
          break;
        case 'NewMethod':
          this.setState({ NewMethod: value })
          break;
        case 'NewresultType':
          if (this.state.NewresultType === 'range based') {
            this.setState({ NewresultType: value })
          } else {
            let dup_ref_data = [
              {
                reference_from: '',
                reference_to: '',
                description: '',
                unit: ''
              }
            ]
            this.setState({ NewresultType: value, ref_data: dup_ref_data })
          }
          break;
        case 'NewtestName':
          this.setState({ NewtestName: value })
          break;
        case 'printOrder':
          selectedData.print_order = value
          this.setState({ selectedData })
          break;
        case 'gender':
          if (isEdit) {
            editRangeData.gender = value
            this.setState({ editRangeData })
          } else {
            range_data.gender = value
            this.setState({ range_data })
          }
          break;
        case 'referanceType':
          ref_type[index].reference_type = value
          this.setState({ ref_type })
          break;
        case 'refRange':
          ref_type[index].reference_range = value
          this.setState({ ref_type })
          break;
        case 'refDescription':
          ref_type[index].description = value
          this.setState({ ref_type })
          break;
        case 'GroupAmt':
          if (CommonValidation.numberOnly(value) || value === '') {
            this.setState({ GroupAmt: +value })
          }
          break;
        case 'TestAmt':
          if (CommonValidation.numberOnly(value) || value === '') {
            this.setState({ TestAmt: +value })
          }
          break;
        case 'ageFrom':
          if ((CommonValidation.numberOnly(value) && +value <= 200) || value === '') {
            if (isEdit) {
              if (((editRangeData.age_from_unit === 'years' && +value <= 200) || (editRangeData.age_from_unit === 'months' && +value <= 11) || (editRangeData.age_from_unit === 'days' && +value <= 31) || (editRangeData.age_from_unit === 'weeks' && +value <= 7))) {
                editRangeData.age_from = value
                this.setState({ editRangeData })
              }
            } else {
              if (((range_data.age_from_unit === 'years' && +value <= 200) || (range_data.age_from_unit === 'months' && +value <= 11) || (range_data.age_from_unit === 'days' && +value <= 31) || (range_data.age_from_unit === 'weeks' && +value <= 7))) {
                range_data.age_from = value
                this.setState({ range_data })
              }
            }
          }
          break;
        case 'fromDays':
          if (isEdit) {
            editRangeData.age_from_unit = value
            editRangeData.age_from = ''
            this.setState({ editRangeData })
          } else {
            range_data.age_from_unit = value
            range_data.age_from = ''
            this.setState({ range_data })
          }
          break;
        case 'ageTo':
          if ((CommonValidation.numberOnly(value)) || value === '') {
            if (isEdit) {
              if (((editRangeData.age_to_unit === 'years' && +value <= 200) || (editRangeData.age_to_unit === 'months' && +value <= 11) || (editRangeData.age_to_unit === 'days' && +value <= 31) || (editRangeData.age_to_unit === 'weeks' && +value <= 7))) {
                editRangeData.age_to = value
                this.setState({ editRangeData })
              }
            } else {
              if (((range_data.age_to_unit === 'years' && +value <= 200) || (range_data.age_to_unit === 'months' && +value <= 11) || (range_data.age_to_unit === 'days' && +value <= 31) || (range_data.age_to_unit === 'weeks' && +value <= 7))) {
                range_data.age_to = value
                this.setState({ range_data })
              }
            }
          }
          break;
        case 'toDays':
          if (isEdit) {
            editRangeData.age_to_unit = value
            editRangeData.age_to = ''
            this.setState({ editRangeData })
          } else {
            range_data.age_to_unit = value
            range_data.age_to = ''
            this.setState({ range_data })
          }
        case 'rangeFrom':
          let resultType = (this.state.receivedData?.result_type === 1 || this.state.selectedData?.result_type === 1) ? CommonValidation.RangeDecimalNumberFiveNew(value) : CommonValidation.RangeDecimalNumberFive(value)
          if ((resultType) || value === '') {
            ref_data[index].reference_from = value
            this.setState({ ref_data, selectedIndex: index }, () => {
              if ((this.state.receivedData?.result_type === 1 || this.state.selectedData?.result_type === 1)) {
                ref_data[index].reference_to = ''
                this.setState({ ref_data })
              }
            })
          }
          break;
        case 'rangeTo':
          if ((CommonValidation.RangeDecimalNumberFive(value)) || value === '') {
            ref_data[index].reference_to = value
            this.setState({ ref_data })
          }
          break;
        case 'unit':
          ref_data[index].unit = value
          this.setState({ ref_data })
          break;
        case 'description':
          ref_data[index].description = value
          this.setState({ ref_data })
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputTextBox = (names, values, label, width, marginRight, index, disable) => {
    try {
      let {ref_data} = this.state
      return (
        <TextField
          autoComplete='off'
          disabled={disable ? disable : (names === 'rangeTo' ? (ref_data[index].reference_from?.includes('>') || ref_data[index].reference_from?.includes('<') ? true : false) : false)}
          sx={{ width: width, marginRight: marginRight, marginBottom: names == "NewtestName" ? 0 : '1vw', mt: names == "NewtestName" ? 0 : '0.5vw' }}
          label={label}
          value={values}
          size='small'
          onChange={(e) => this.onChangeHandler(e, names, index)}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddNewParameter = (key) => {
    try {
      let { ref_data, ref_type } = this.state
      let duplicateRefData = {
        reference_from: '',
        reference_to: '',
        description: '',
        unit: ''
      }

      let duplicateRefTypeData = {
        reference_type: '',
        reference_range: '',
        description: '',
        unit: ''
      }

      let Invalid = this.ValidateREfCheck(ref_data, ref_type, key)
      if (!Invalid) {
        ref_data = [...ref_data, duplicateRefData]
        ref_type = [...ref_type, duplicateRefTypeData]
        this.setState({ ref_data, ref_type })
      } else {
        this.errorMessage("Enter Referrence Details")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddNewReferance = () => {
    try {
      let { ref_type } = this.state
      let duplicateRefData = {
        reference_type: '',
        reference_range: '',
        description: '',
        unit: ''
      }

      let Invalid = this.ValidateReferanceCheck(ref_type)
      if (!Invalid) {
        ref_type = [...ref_type, duplicateRefData]
        this.setState({ ref_type })
      } else {
        this.errorMessage("Enter Referrence Details")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddNewValue = () => {
    try {
      let length = this.state.graphBased.xValues?.length
      if (length < 20) {
        if (this.state.graphBased.xValues[length - 1]?.value != "") {
          let newValue = {
            value: ''
          }
          this.state.graphBased.xValues?.push(newValue)
          this.setState({ graphBased: this.state.graphBased })

        } else {
          this.errorMessage("Enter the values")
        }
      } else {
        this.errorMessage("Values Limit Exist")
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  removeGraphValues = (index) => {
    if (this.state.graphBased.xValues?.length > 3) {
      this.state.graphBased.xValues?.splice(index, 1)
      this.setState({ graphBased: this.state.graphBased })
    }
  }

  onAddNewGraphRef = (from) => {
    try {
      let { graphBased, dupRangeRef } = this.state
      let length = graphBased.graph_ref_range?.length
      let length2 = dupRangeRef?.length
      let graph_ref_range = graphBased.graph_ref_range[length - 1]
      let duplicateRef = {
        ref_from: '',
        ref_to: '',
        ref_type: '',
      }
      if (this.state.isEdit || from == "new test") {
        if (graph_ref_range?.ref_from && graph_ref_range?.ref_to && graph_ref_range?.ref_type) {
          graphBased.graph_ref_range.push(duplicateRef)
          this.setState({
            graphBased
          })
        }
        else {
          this.errorMessage("Enter Reference Value")
        }
      }
      else {
        if (dupRangeRef[length2 - 1]?.ref_from && dupRangeRef[length2 - 1]?.ref_to && dupRangeRef[length2 - 1]?.ref_type) {
          dupRangeRef?.push(duplicateRef)
          this.setState({
            dupRangeRef
          })
        }
        else {
          this.errorMessage("Enter Reference Value")
        }
      }


    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  resetNewReferrence = () => {
    try {
      let resetNewRefData = [{
        reference_from: '',
        reference_to: '',
        description: '',
        unit: ''
      }]
      let resetRangeData = {
        gender: '',
        age_from: '',
        age_to: '',
        age_from_unit: 'years',
        age_to_unit: 'years'
      }
      let resetRefType = [{
        reference_type: '',
        reference_range: '',
        description: '',
        unit: ''
      }]
      if (this.state.isEdit) {
        if (this.state.selectedData?.result_type === 8) {
          this.state.graphBased.xLabelName = this.state.selectedData?.test_data[0]?.label_x
          this.state.graphBased.yLabelName = this.state.selectedData?.test_data[0]?.label_y
          this.state.graphBased.xAxisUnit = this.state.selectedData?.test_data[0]?.unit_x
          this.state.graphBased.yAxisUnit = this.state.selectedData?.test_data[0]?.unit_y
          this.state.graphBased.xValues = this.state.selectedData?.test_data[0]?.values?.map(value => ({ value: value.toString() }))
          this.state.graphBased.graph_ref_range = this.state.selectedData?.test_data[this.state.selectedEditIndex]?.ranges
          this.setState({ graphBased: this.state.graphBased, selectedIndex: '' })
        } else {
          let refData = JSON.parse(this.state.originalEditData)
          let editRefData = this.state.selectedData?.result_type === 1 ? [...refData.reference_data, resetNewRefData[0]] : [...refData.reference_data]
          this.setState({
            ref_data: editRefData,
            editRangeData: refData,
            selectedIndex: "",
          })
        }
      } else {
        if (this.state.selectedData) {
          this.state.graphBased.xLabelName = this.state.selectedData?.test_data[0]?.label_x
          this.state.graphBased.yLabelName = this.state.selectedData?.test_data[0]?.label_y
          this.state.graphBased.xAxisUnit = this.state.selectedData?.test_data[0]?.unit_x
          this.state.graphBased.yAxisUnit = this.state.selectedData?.test_data[0]?.unit_y
          this.state.graphBased.xValues = this.state.selectedData?.test_data[0]?.values?.map(value => ({ value: value?.toString() }))
          this.state.graphBased.graph_ref_range = this.state.selectedData?.test_data[this.state.selectedEditIndex]?.ranges

        }
        this.setState({
          selectedData: this.state.duplicateSelected ? JSON.parse(this.state.duplicateSelected) : null,
          ref_data: resetNewRefData,
          range_data: resetRangeData,
          ref_type: resetRefType,
          selectedIndex: "",
          EditTestName: this.state.duplicate?.test_name,
          EditTestAmt: this.state.duplicate?.test_amt,
          EditIsBillable: this.state.duplicate?.is_billable,
          EditTestCode:this.state.duplicate?.test_code ? this.state.duplicate?.test_code : '',
          dupRangeRef: [
            { ref_from: '', ref_to: '', ref_type: '' }
          ],
          graphBased: this.state.graphBased
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidateREfCheck = (data, ref_data, key) => {
    try {
      let isInValid = false
      let dataList = data
      if (key?.result_type === 7) {
        dataList = ref_data.map((item, index) => {
          return {
            reference_type: item.reference_type,
            reference_range: item.reference_range,
            description: data[index].description,
            unit: data[index].unit
          };
        });
      }
      dataList?.forEach((element, index) => {
        if ((!element.reference_from && element.reference_from !== 0) && (!element.reference_to && element.reference_to !== 0) && (!element.unit && element.unit !== 0) && (!element.description && element.description !== 0) && data.length > 1) {
          data.splice(index, 1)
          this.setState({
            ref_data: data
          })
        } else if (key?.result_type === 7) {
          if ((!element.reference_type && element.reference_type !== 0) && (!element.reference_range && element.reference_range !== 0) && (!element.unit && element.unit !== 0) && (!element.description && element.description !== 0) && data.length > 1) {
            data.splice(index, 1)
            this.setState({
              ref_data: data,
              ref_type: data
            })
          } else if ((!element.reference_type && element.reference_type !== 0) || (!element.reference_range && element.reference_range !== 0) || (!element.unit && element.unit !== 0)) {
            isInValid = true
          }
        } else if (((!element.reference_from && element.reference_from !== 0)) || (!element.reference_to && element.reference_to !== 0) || (!element.unit && element.unit !== 0)) {
          if(element?.reference_from.includes('>') || element?.reference_from?.includes('<')){
            isInValid = false
          }else{
            isInValid = true
          }
        }
      })
      return isInValid
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidateReferanceCheck = (data) => {
    try {
      let isInValid = false
      data?.forEach((element, index) => {
        if ((!element.reference_type && element.reference_type !== 0) && (!element.reference_range && element.reference_range !== 0) && (!element.unit && element.unit !== 0) && (!element.description && element.description !== 0) && data.length > 1) {
          data.splice(index, 1)
          this.setState({
            ref_type: data
          })
        } else if ((!element.reference_type && element.reference_type !== 0) || (!element.reference_range && element.reference_range !== 0) || (!element.unit && element.unit !== 0)) {
          isInValid = true
        }
      })
      return isInValid
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let error = ""
      let { selectedData, range_data, ref_data, isEdit, editRangeData, selectedEditIndex, isEditTestDetails, duplicateSelected } = this.state
      var RefEmptyCheck = this.ValidateREfCheck(ref_data)
      if (isEdit) {
        selectedData?.test_data.forEach((element, index) => {
          if(selectedData?.result_type === 5){
            let value = [];
            for (let i = +element.age_from; i <= +element.age_to; i++) {
              value.push(i)
            }

            if (editRangeData?.gender && ((!editRangeData.age_from && (editRangeData.age_from !== 0)) || (!editRangeData.age_to && (editRangeData.age_to !== 0)))) {
              error = "Kindly Enter the Age Value"
            } else if (editRangeData?.gender && (+editRangeData.age_from >= +editRangeData.age_to) && ((editRangeData.age_from_unit === 'months' && editRangeData.age_to_unit === 'days') || (editRangeData.age_from_unit === 'years' && (editRangeData.age_to_unit === 'days' || editRangeData.age_to_unit === 'months')))) {
              error = "Age From Value Should greater than Age To"
            }else{
              error = ''
            }
          }else{
          if ((element.gender === editRangeData.gender) && (index != selectedEditIndex)) {
            let value = [];
            for (let i = +element.age_from; i <= +element.age_to; i++) {
              value.push(i)
            }

            if ((value.includes(+editRangeData.age_to)) && (value.includes(+editRangeData.age_from)) && element.age_to_unit === editRangeData.age_from_unit && (element.age_from_unit === editRangeData.age_from_unit)) {
              error = 'Age Value Already Exist For This Gender'
            } else if ((value.includes(+editRangeData.age_to)) && ((element.age_to_unit === editRangeData.age_to_unit) || (element.age_from_unit === editRangeData.age_to_unit))) {
              error = 'Age To Value Already Exist For This Gender'
            } else if ((value.includes(+editRangeData.age_from)) && ((element.age_to_unit === editRangeData.age_from_unit) || (element.age_from_unit === editRangeData.age_from_unit))) {
              error = 'Age From Value Already Exist For This Gender'
            }

          } else if (!editRangeData.gender) {
            error = "Kindly Enter the Gender Value"
          } else if ((!editRangeData.age_from && (editRangeData.age_from !== 0)) || (!editRangeData.age_to && (editRangeData.age_to !== 0))) {
            error = "Kindly Enter the Age Value"
          } else if ((editRangeData.age_from >= editRangeData.age_to) && ((editRangeData.age_from_unit === 'months' && editRangeData.age_to_unit === 'days') || (editRangeData.age_from_unit === 'years' && (editRangeData.age_to_unit === 'days' || editRangeData.age_to_unit === 'months')))) {
            error = "Age From Value Should greater than Age To"
          } else if (RefEmptyCheck && selectedData?.result_type === 1) {
            error = "Reference Values and Unit Should not be empty"
          }
        }
        });
      } else if (isEditTestDetails) {
        if (!selectedData?.print_order) {
          error = "Kindly Select Print Order"
        }
      }
      else {
        if (selectedData?.test_data != null && selectedData?.test_data != undefined) {
          selectedData?.test_data.forEach(element => {
            if (selectedData?.result_type === 5) {
              let value = [];
              for (let i = +element.age_from; i <= +element.age_to; i++) {
                value.push(i)
              }

              if (range_data?.gender && ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0)))) {
                error = "Kindly Enter the Age Value"
              } else if (range_data?.gender && (+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
                error = "Age From Value Should greater than Age To"
              } else {
                error = ''
              }

            } else {
              if (element.gender === range_data.gender && selectedData.result_type === JSON.parse(duplicateSelected)?.result_type) {
                let value = [];
                for (let i = +element.age_from; i <= +element.age_to; i++) {
                  value.push(i)
                }

                if ((value.includes(+range_data.age_to)) && (value.includes(+range_data.age_from)) && element.age_to_unit === range_data.age_from_unit && (element.age_from_unit === range_data.age_from_unit)) {
                  error = 'Age Value Already Exist For This Gender'
                } else if ((value.includes(+range_data.age_to)) && ((element.age_to_unit === range_data.age_to_unit) || (element.age_from_unit === range_data.age_to_unit))) {
                  error = 'Age To Value Already Exist For This Gender'
                } else if ((value.includes(+range_data.age_from)) && ((element.age_to_unit === range_data.age_from_unit) || (element.age_from_unit === range_data.age_from_unit))) {
                  error = 'Age From Value Already Exist For This Gender'
                }
              } else if (!range_data.gender) {
                error = "Kindly Enter the Gender Value"
              } else if ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0))) {
                error = "Kindly Enter the Age Value"
              } else if ((range_data.age_from >= range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
                error = "Age From Value Should greater than Age To"
              } else if (RefEmptyCheck && selectedData?.result_type === 1) {
                error = "Reference Values and Unit Should not be empty"
              }
            }

          });
        }
      }

      return error
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getNewReferrenceUnitData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PRODUCT_UNIT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              refUnitList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getNewTestData = () => {
    try {
      var { NewdepartmentName } = this.state
      RestAPIService.getAll(Serviceurls.NEW_LAB_RESULT_CONFIGURATION + "?dept_name=" + NewdepartmentName?.dept_name)
        .then((response) => {
          if (response.data.status === 'success') {
            var Data = response.data?.data ? response.data?.data : []
            var grpData = []
            Data[0]?.grp_data.forEach((grp) => {
              grpData.push(grp)
            })
            this.setState({
              NewgrpListData: grpData
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getNewReferrenceData = () => {
    let Grplength = []
    try {
      var { departmentName } = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.NEW_LAB_RESULT_CONFIGURATION + "?dept_name=" + departmentName.dept_name)
        .then((response) => {
          if (response.data.status === 'success') {
            var Data = response.data?.data ? response.data?.data : []
            var grpData = []
            Data[0]?.grp_data.forEach((grp) => {
              grpData.push(grp)
            })
            for (let i = 0; i <= grpData?.length - 1; i++) {
              Grplength?.push(i + 1)
            }
            this.setState({
              resultConfigData: Data,
              duplicateResult: JSON.stringify(response.data.data),
              grpListData: grpData,
              groupName: null,
              testName: null,
              GrpLength: Grplength
            })
            this.LoaderFunction(false)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
            this.LoaderFunction(false)
          } else {
            this.errorMessage(error?.message)
            this.LoaderFunction(false)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidationForNewTest = () => {
    try {
      let error = ""
      let { range_data, ref_type, isEdit, editRangeData, receivedData } = this.state
      if (receivedData?.result_type === 9) {
        this.state.cal_Data?.forEach(element => {
          if (element?.is_cal_ref_data) {
            element?.cal_ref_data?.forEach(item => {
              if (item?.cal_ref_from === '' || item?.cal_ref_to === '' || item?.cal_ref_status === '') {
                error = 'Reference Range should not be empty'
              }
            })
          }
        })
      } else {
        if (receivedData?.result_type !== 5) {
          if(receivedData?.result_type === 7){
            if (!range_data.gender) {
              error = "Kindly Enter the Gender Value"
            } else if ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0))) {
              error = "Kindly Enter the Age Value"
            } else if ((+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
              error = "Age From Value Should greater than Age To"
            }else{
              ref_type?.forEach(element => {
                if (element?.reference_type === '' || element?.reference_range === '' || element?.unit === '') {
                  error = "Enter Reference Range and Unit"
                }else{
                error = ""
                } 
              })
            }
          }else{
            if (!range_data.gender) {
              error = "Kindly Enter the Gender Value"
            } else if ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0))) {
              error = "Kindly Enter the Age Value"
            } else if ((+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
              error = "Age From Value Should greater than Age To"
            }
          }
        } else if (receivedData?.result_type === 5) {
          if (range_data?.gender && ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0)))) {
            error = "Kindly Enter the Age Value"
          } else if (range_data?.gender && (+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
            error = "Age From Value Should greater than Age To"
          } else {
            error = ''
          }
        }
      }
      return error
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  NewpostNewReferenceData = () => {
    try {
      let { receivedData, testListData, ref_data, range_data, ref_type, graphBased } = this.state
      let data = {}
      let Error = (receivedData?.result_type === 3 || receivedData?.result_type === 8) ? false : this.ValidationForNewTest()
      if (Error) {
        this.errorMessage(Error)
      } else {
        var Details = []; var New_Details = ""; let checkRef = false
        if (receivedData.result_type === 1) {
          ref_data?.forEach(element => {
            if (element?.reference_from === '' || element?.reference_to === '' || element?.unit === '') {
              if((element?.reference_from?.includes('>') || element?.reference_from?.includes('<')) && element.unit !== ''){
                checkRef = true
              }else{
                checkRef = false
              }
            }else{
              checkRef = true
            }
          })
          range_data.reference_data = ref_data
          Details.push(range_data)
        } else if (receivedData?.result_type === 7) {
          ref_data = ref_type
          range_data.reference_data = ref_data
          Details.push(range_data)
        } else if (receivedData?.result_type === 8) {
          let data = {
            "label_x": graphBased.xLabelName,
            "label_y": graphBased.yLabelName,
            "unit_x": graphBased.xAxisUnit,
            "unit_y": graphBased.yAxisUnit,
            "values": [],
            "ranges": graphBased.graph_ref_range,
          }
          graphBased?.xValues.forEach((item) => {
            data.values.push(item.value)
          })
          Details.push(data)

        } else if (this.state.receivedData?.result_type === 9) {
          this.state.cal_Data?.forEach(element => {
            if (element.is_cal_ref_data) {
              Details?.push({
                cal_ref_type: element.cal_ref_type,
                cal_ref_data: element.cal_ref_data
              })
            }
          })
        } else if (receivedData.result_type !== 3) {
          range_data.reference_data = ref_data
          New_Details = [range_data]
        }

        if (receivedData.result_type === 3 && (this.state.CulturalTestData[0].AntibioticData == null || this.state.CulturalTestData[0].BacteriaData == null)) {
          this.errorMessage("Mandatory Fields Should not be empty")
        } else if (receivedData?.result_type === 1 && !checkRef) {
          this.errorMessage('Enter Reference Range and Unit')
        } else {
          data = {
            result_type: receivedData?.result_type,
            test_data: (receivedData.result_type === 3 && this.state.CulturalTestData[0].AntibioticData != null && this.state.CulturalTestData[0].BacteriaData != null) ? this.state.CulturalTestData : New_Details == "" ? Details : New_Details,
            method: this.state.NewMethod,
            print_order: this.state.newPrintOrder,
            specimen: this.state.NewSpecimen,
            test_amt: this.state.TestAmt,
            test_id: receivedData?.test_id,
            test_name: receivedData?.test_name
          }
          this.setState({ disableBtn: true })
          RestAPIService.create(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
            .then((response) => {
              if (response.data.status === 'success') {
                this.successMessage(response.data.message)
                this.getNewReferrenceData()
                this.setState({ isEdit: false, selectedData: null, openPopUp: false, isEditTestDetails: false, NewEditTestDetails: false, groupName: null, containers: [{ id: 1 }], EditCultureTest: false, isEditCulture: false, selectedIndex: "", disableBtn: false, selectedRefData: null, cal_Data: JSON.parse(this.state.addNewRefCalData) })
                this.resetNewReferrence()
                this.handleClose()
                this.ResetCultureReference()
                this.ResetGraphReference()
              }
            }).catch((error) => {
              if (error?.response?.data?.message) {
                this.errorMessage(error.response.data.message)
              } else {
                this.errorMessage(error.message)
              }
              this.setState({ disableBtn: false })
            })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkCalEditValidation = (fromAddNewRef) => {
    try {
      let message = null;
      if (this.state.calEditData?.cal_ref_data?.length === 0) {
        message = 'Add atleast one reference range'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postCalculationEditData = (fromAddNewRef) => {
    try {
      let data = {}
      if (fromAddNewRef) {
        let newCalRefData = []; let dupElement = new Set();
        this.state.cal_Data?.forEach(element => {
          element?.cal_ref_data?.forEach(item => {
            if (item?.cal_ref_from && item?.cal_ref_to && item?.cal_ref_status) {
              let checkDuplicate = element.cal_ref_type
              if (!dupElement.has(checkDuplicate)) {
                dupElement.add(checkDuplicate)
                newCalRefData?.push(element)
              }
            }
          })
        })
        this.state.calTstData?.test_data?.forEach(element => { newCalRefData?.push(element) })
        data = {
          is_billable: this.state.calTstData?.is_billable,
          method: this.state.calTstData?.method,
          print_order: this.state.calTstData?.print_order,
          result_type: this.state.calTstData?.result_type,
          specimen: this.state.calTstData?.specimen,
          test_amt: this.state.calTstData?.test_amt,
          test_data: newCalRefData,
          test_id: this.state.calTstData?.test_id,
          test_name: this.state.calTstData?.test_name
        }
      } else {
        let calData = [];
        this.state.calTestData?.test_data?.forEach(element => {
          if (element?.cal_ref_type === this.state.calEditData?.cal_ref_type) {
            calData?.push(this.state.calEditData)
          } else {
            calData?.push(element)
          }
        })
        data = {
          result_type: this.state.calTestData?.result_type,
          test_data: calData,
          method: this.state.calTestData?.method,
          print_order: this.state.calTestData?.print_order,
          specimen: this.state.calTestData?.specimen,
          test_amt: this.state.calTestData?.test_amt,
          test_id: this.state.calTestData?.test_id,
          test_name: this.state.calTestData?.test_name
        }
      }

      let checkCalEdit = this.checkCalEditValidation(fromAddNewRef)
      if (checkCalEdit) {
        this.errorMessage(checkCalEdit)
      } else {
        this.setState({ postLoad: true })
        RestAPIService.create(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getNewReferrenceData()
              this.setState({
                isEdit: false, selectedData: null,
                openCalculationEdit: false,
                calEditData: null, editRefFrom: '',
                editRefTo: '', editRefstatus: '',
                calTestData: null, postLoad: false,
                calTstData: null, calGrpData: null,
                cal_Data: JSON.parse(this.state.addNewRefCalData),
                openAddNewCalReference: false, selectedRefData: null
              })
            }
          }).catch((error) => {
            this.setState({ postLoad: false })
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  postNewReferenceData = () => {
    try {
      let { selectedData, ref_data, ref_type, range_data, isEdit, editRangeData, selectedEditIndex, isEditTestDetails, NewEditTestDetails, graphBased } = this.state
      let Error = (selectedData?.result_type === 3 || selectedData?.result_type === 8 || selectedData?.result_type === 9) ? false : this.checkValidation()
      var data = {}
      if (Error) {
        this.errorMessage(Error)
      } else if (NewEditTestDetails) {
        data = {
          "grp_id": this.state.EditGrpId,
          "test_id": this.state.EditTestId,
          "test_name": this.state.EditTestName,
          "test_amt": this.state.EditTestAmt,
          "print_order": selectedData?.print_order,
          "result_type": selectedData?.result_type,
          "specimen": selectedData?.specimen,
          "method": selectedData?.method,
          "dept_id": this.state.EditDepId,
          "dept_name": this.state.EditDepName,
          "grp_name": this.state.EditGrpName,
          "grp_amt": this.state.EditGrpAmt,
          'grp_test_code':this.state.EditGrpCode ? this.state.EditGrpCode : '',
          'test_code':this.state.EditTestCode ? this.state.EditTestCode : '',
          "is_billable": !this.state.EditIsBillable
        }
        if (selectedData?.result_type == 8) {
          let Data = this.state.graphData.map((item, i) => ({
            label_x: graphBased.xLabelName,
            label_y: graphBased.yLabelName,
            unit_x: graphBased.xAxisUnit,
            unit_y: graphBased.yAxisUnit,
            values: graphBased?.xValues.map(list => list.value),
            ranges: item.ranges
          }));
          data["test_data"] = Data
        }
        this.setState({ disableBtn: true })
        RestAPIService.updateWithOutId(data, Serviceurls.LAB_ADD_NEW_TEST).then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getNewReferrenceData()
            this.setState({ isEdit: false, selectedData: null, openPopUp: false, isEditTestDetails: false, groupName: null, NewEditTestDetails: false })
            this.resetNewReferrence()
            this.ResetGraphReference()
            this.closePopup()
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ disableBtn: false })
        })

      } else {
        if (selectedData?.result_type == 3) {
          selectedData["test_data"] = this.state.CulturalTestList
          data = selectedData
        } else if (selectedData?.result_type == 8) {
          let finalData = []

          selectedData?.test_data?.forEach((list) => { // for existing range ref data
            finalData.push(list)
          })

          let Data = this.state.graphData.map((item) => ({ // graphData have current edited data
            label_x: graphBased.xLabelName,
            label_y: graphBased.yLabelName,
            unit_x: graphBased.xAxisUnit,
            unit_y: graphBased.yAxisUnit,
            values: graphBased?.xValues.map(list => list.value) || [],
            ranges: item.ranges
          }));

          finalData[selectedEditIndex] = Data[0] //change existing data + current edited data

          if (this.state.dupRangeRef[0]?.ref_from != '') {
            let a = {
              "label_x": graphBased.xLabelName,
              "label_y": graphBased.yLabelName,
              "unit_x": graphBased.xAxisUnit,
              "unit_y": graphBased.yAxisUnit,
              "values": [],
              "ranges": this.state.dupRangeRef,
            }
            graphBased?.xValues.forEach((item) => {
              a.values?.push(item.value)
            })
            finalData.push(a) // for add new refernce data
          }

          selectedData["test_data"] = finalData
          data = selectedData

        } else {
          if (isEdit) {
            if (selectedData?.result_type === 7) {
              const mergedData = ref_data.map((item, index) => {
                return {
                  reference_type: ref_type[index].reference_type,
                  reference_range: ref_type[index].reference_range,
                  description: item.description,
                  unit: item.unit
                };
              });
              ref_data = mergedData
              editRangeData.reference_data = ref_data
              selectedData["test_data"][selectedEditIndex] = editRangeData
              data = selectedData
            } else {
              editRangeData.reference_data = ref_data
              selectedData["test_data"][selectedEditIndex] = editRangeData
              data = selectedData
            }
          } else if (isEditTestDetails) {
            data = selectedData
          } else {
            if (selectedData?.result_type === 7) {
              const mergedData = ref_data.map((item, index) => {
                return {
                  reference_type: ref_type[index].reference_type,
                  reference_range: ref_type[index].reference_range,
                  description: item.description,
                  unit: item.unit
                };
              });

              ref_data = mergedData;
              range_data.reference_data = ref_data;

              if (!selectedData.test_data) {
                selectedData.test_data = [];
              }

              selectedData.test_data.push({ ...range_data }); // Add a copy of range_data

              data = selectedData;
            } else {
              let duplicateSelected = JSON.parse(this.state.duplicateSelected)
              if (selectedData.result_type === 1 && selectedData.result_type === duplicateSelected.result_type) {
                range_data.reference_data = ref_data
                selectedData.test_data.push(range_data)
                data = selectedData
              } else {
                if (selectedData?.test_data?.length > 0 && selectedData.result_type === duplicateSelected.result_type) {
                  range_data.reference_data = ref_data
                  selectedData.test_data.push(range_data)
                } else {
                  range_data.reference_data = ref_data
                  selectedData.test_data = [range_data]
                }
                data = selectedData
              }
            }
          }
        }
        this.setState({ disableBtn: true })
        RestAPIService.create(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION).then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getNewReferrenceData()
            this.setState({ isEdit: false, selectedData: null, openPopUp: false, isEditTestDetails: false, groupName: null, NewEditTestDetails: false, EditCultureTest: false })
            this.resetNewReferrence()
            this.closePopup()
            this.ResetCultureReference()
            this.ResetGraphReference()
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ disableBtn: false })
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({ disableBtn: false })
    }
  }

  createNewTest = () => {
    try {
      let data = {}
      let states = this.state
      data = {
        "dept_id": states.NewdepartmentName?.id,
        "dept_name": states.NewdepartmentName?.dept_name,
        "grp_id": states.NewgroupName?.grp_id,
        "grp_name": states.NewgroupName?.grp_name,
        "grp_amt": states.GroupAmt,
        "test_name": states.NewtestName,
        "test_amt": states.TestAmt,
        "print_order": states.newPrintOrder,
        "specimen": states.NewSpecimen,
        "method": states.NewMethod,
        "result_type": states.NewresultType,
        "is_billable": !states.isBillable,
        'test_code':states.testCode ? states.testCode : '',
        'grp_test_code':states.grpCode ? states.grpCode : ''
      }
      this.setState({ disableBtn: true })
      RestAPIService.create(data, Serviceurls.LAB_ADD_NEW_TEST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getDeptList()
            this.setState({
              receivedData: {
                "result_type": response.data?.test_data?.result_type,
                "grp_name": response.data?.test_data?.grp_name,
                "test_name": response.data?.test_data?.test_name,
                "test_id": response.data?.test_data?.test_id,
              },
              isBillable: false,
              testCode:'',grpCode:'',hasCode:false
            }, () => { this.setState({ ViewScreen: 1, stepNumber: 1, disableBtn: false }) })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({ disableBtn: false })
    }
  }

  putNewReferenceData = () => {
    try {
      var { resultConfigData } = this.state
      let printOrder = []
      let groupData = []

      resultConfigData[0]?.grp_data.forEach((grp, index) => {
        groupData.push({ "grp_id": grp.grp_id, "print_order": grp.print_order })
        printOrder.push(grp.print_order)
      })
      var data = {
        "dept_id": resultConfigData[0]?.dept_id,
        "grp_data": groupData
      }

      //validation for duplicate print order

      const unique = Array.from(new Set(printOrder))

      if (unique?.length != printOrder?.length) {
        this.errorMessage("Multiple Group has same Print Order")
      } else {
        RestAPIService.updateWithOutId(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getNewReferrenceData()
              this.setState({
                isPrintOrderEdit: false
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteNewReferenceData = (testData, refIndex) => {
    try {
      testData?.test_data.splice(refIndex, 1)

      RestAPIService.create(testData, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getNewReferrenceData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  newReferrencePopup = () => {
    try {
      let { selectedData, ref_data, range_data, editRangeData } = this.state
      let disable = (this.state.isEdit || !this.state.isEditTestDetails) ? true : false
      let editTestDetails = this.state.isEditTestDetails ? true : false
      let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
      let RangeRef = []
      RangeRef = this.state.isEdit ? this.state.graphBased?.graph_ref_range : this.state.dupRangeRef
      return (
        <Modal open={this.state.openPopUp}>
          <Box component={'div'} className='eMed_lab_rest_New_Popup'>

            <Box component={'div'} sx={{ backgroundColor: Colors.Background, display: 'flex', alignItems: 'center' }} pl={'0.5vw'} height={'2.5vw'}>
              <Typography flex={'0.5'} component={'div'} fontWeight={600} color={Colors.SecondaryText}>Add New Reference</Typography>
              <Box component={'div'} flex={'0.5'} display={'flex'} marginRight={'1vw'} alignItems={'center'} justifyContent={'flex-end'} >
                <Button className='eMed_Lab_act_btn' onClick={() => this.handleClosePopup()}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} alt='close' height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
            </Box>
            <Box>
              {this.state.NewEditTestDetails ?
                <Box component={'div'} style={{ display: "flex", flexDirection: "row", margin: "1vw" }}>
                  <TextField
                    sx={{ width: '12vw', marginBottom: "0.5vw", marginRight: "0.5vw" }}
                    disable={true}
                    size='small'
                    label="Enter Test Name"
                    value={this.state.EditTestName}
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({ EditTestName: e.target.value })
                    }} />
                  <TextField
                    sx={{ width: '12vw', marginBottom: "0.5vw",marginRight: "0.5vw"  }}
                    size='small'
                    label="Test Amount"
                    value={this.state.EditTestAmt}
                    variant="outlined"
                    onChange={(e) => {
                      if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                        this.setState({ EditTestAmt: e.target.value })
                      }
                    }} />
                  {showCode ?
                    <TextField
                      sx={{ width: '9vw', marginBottom: "0.5vw" }}
                      size='small'
                      label="Test Code"
                      value={this.state.EditTestCode}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.target.value
                        if ((value?.length <= Code_Length) || value === '') {
                          this.setState({ EditTestCode: value })
                        }
                      }} /> : null}
                  <FormControlLabel style={{ marginLeft: "0.5vw", marginTop: "-0.5vw" }} control={<Checkbox checked={!this.state.EditIsBillable} onChange={(e) => { this.setState({ EditIsBillable: !this.state.EditIsBillable }) }} />} label={"IS BILLABLE"} />
                </Box> : null}
              <Box component={'div'} margin={'1vw'} borderBottom={'1px solid lightgray'} display={'flex'}>

                {this.inputTextBox('specimen', selectedData?.specimen, 'Specimen', '11vw', '0.5vw', null, disable)}
                {this.inputTextBox('method', selectedData?.method, 'Method', '11vw', '0.5vw', null, disable)}

                <FormControl size='small' sx={{ width: '11vw', marginRight: '0.5vw', marginBottom: '0.5vw', mt: '0.5vw' }}>
                  <InputLabel>Result Type</InputLabel>
                  <Select
                    disabled={editTestDetails || this.state.isEdit}
                    label='Result Type'
                    value={selectedData?.result_type}
                    onChange={(e) => { this.onChangeHandler(e, 'resultType') }}
                  >
                    {Result_Type_List?.map((item) => (
                      <MenuItem disabled={item.value == 3} value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Autocomplete
                  disabled={disable}
                  value={selectedData?.print_order}
                  options={this.state.testNameLength.map((item) => item)}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    selectedData.print_order = newValue
                    this.setState({ selectedData })
                  }}
                  renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '7vw', mt: '0.5vw' }} label='Print No.' />)}
                />

              </Box>

              {this.state.selectedData?.result_type == 3 || this.state.selectedData?.result_type == 8 || this.state.selectedData?.result_type === 9 ? null :
                <div>
                  <Box component={'div'} margin={'1vw'} borderBottom={'1px solid lightgray'} display={'flex'}>

                    <Box mr={'0.5vw'}>
                      <FormControl size='small' sx={{ width: '13vw', marginBottom: '1vw', mt: '0.5vw' }}>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          disabled={editTestDetails}
                          label='Gender'
                          value={this.state.isEdit ? editRangeData?.gender : range_data?.gender}
                          onChange={(e) => { this.onChangeHandler(e, 'gender') }}
                        >
                          {Gender_List?.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box mr={'0.5vw'}>
                      {this.inputTextBox('ageFrom', this.state.isEdit ? editRangeData?.age_from : range_data.age_from, "Age From", '7.8vw', null, null, editTestDetails)}
                      <FormControl size='small' sx={{ width: '6vw', marginBottom: '1vw', mt: '0.5vw' }}>
                        <Select
                          disabled={editTestDetails}
                          value={this.state.isEdit ? editRangeData?.age_from_unit : range_data?.age_from_unit}
                          onChange={(e) => { this.onChangeHandler(e, 'fromDays') }}
                        >
                          {Days_List?.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box mr={'0.5vw'}>
                      {this.inputTextBox('ageTo', this.state.isEdit ? editRangeData?.age_to : range_data.age_to, "Age To", '7.8vw', null, null, editTestDetails)}
                      <FormControl size='small' sx={{ width: '6vw', marginBottom: '1vw', mt: '0.5vw' }}>
                        <Select
                          disabled={editTestDetails}
                          value={this.state.isEdit ? editRangeData?.age_to_unit : range_data.age_to_unit}
                          onChange={(e) => { this.onChangeHandler(e, 'toDays') }}
                        >
                          {Days_List?.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                  </Box>

                  <Box component={'div'} ml={'1vw'} paddingTop={'0.5vw'} overflow={'scroll'} maxHeight={'20vw'}>

                    {this.state.selectedData?.result_type === 7 ?
                      <div>
                        {this.state.ref_data.map((item, index) => {
                          return (
                            <>
                              <Box key={index} display={'flex'}>
                                <FormControl disabled={editTestDetails} size='small' sx={{ width: '5vw', marginBottom: '1vw', mt: '0.5vw', marginRight: "0.5vw" }}>
                                  <InputLabel>Referance Type</InputLabel>
                                  <Select
                                    label='Referance Type'
                                    value={item?.reference_type}
                                    onChange={(e) => { this.onChangeHandler(e, 'referanceType', index) }}
                                  >
                                    {Referance_Type_List?.map((item) => (
                                      <MenuItem style={{ textAlign: "center", fontSize: "1vw" }} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {this.inputTextBox('refRange', item.reference_range, "Referance Range", '9.4vw', '0.5vw', index, editTestDetails)}
                                <Autocomplete
                                  disableClearable
                                  freeSolo
                                  disabled={editTestDetails}
                                  value={item.unit}
                                  options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                  getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                  onChange={(event, newValue) => {
                                    ref_data[index].unit = newValue.unit
                                    this.setState({ ref_data })
                                  }}
                                  onInputChange={(e, val) => {
                                    ref_data[index].unit = val
                                    this.setState({ ref_data })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: '9.5vw', mt: '0.5vw', mr: '0.5vw' }}
                                      label={'Unit'}
                                      size="small"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />

                                {this.inputTextBox('description', item.description, "Description", '18.5vw', '0.5vw', index, editTestDetails)}
                                {this.state.ref_data?.length > 1 ?
                                  <Button className='eMed_Lab_act_btn' onClick={() => {
                                    var { ref_data } = this.state
                                    ref_data.splice(index, 1)
                                    this.setState({
                                      ref_data
                                    })
                                  }}>
                                    <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                  </Button>
                                  : null}
                              </Box>
                            </>
                          )
                        })}
                      </div> :
                      <div>
                        {this.state.ref_data.map((item, index) => {
                          return (
                            <>
                              <Box key={index} display={'flex'}>
                                {this.inputTextBox('rangeFrom', item.reference_from, "Range From", '6vw', '0.5vw', index, editTestDetails)}
                                {this.inputTextBox('rangeTo', item.reference_to, "Range To", '6vw', '0.5vw', index, editTestDetails)}
                                <Autocomplete
                                  disableClearable
                                  freeSolo
                                  disabled={editTestDetails}
                                  value={item.unit}
                                  options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                  getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                  onChange={(event, newValue) => {
                                    ref_data[index].unit = newValue.unit
                                    this.setState({ ref_data })
                                  }}
                                  onInputChange={(e, val) => {
                                    ref_data[index].unit = val
                                    this.setState({ ref_data })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: '9.5vw', mt: '0.5vw', mr: '0.5vw' }}
                                      label={'Unit'}
                                      size="small"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />

                                {this.inputTextBox('description', item.description, "Description", '18.5vw', '0.5vw', index, editTestDetails)}
                                {this.state.ref_data?.length > 1 ?
                                  <Button className='eMed_Lab_act_btn' onClick={() => {
                                    var { ref_data } = this.state
                                    ref_data.splice(index, 1)
                                    this.setState({
                                      ref_data
                                    })
                                  }}>
                                    <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                  </Button>
                                  : null}

                              </Box>
                              {index === editRangeData?.ref_data?.length - 1 ?
                                <Divider>
                                  <Typography fontSize={'0.8vw'} color={'gray'}>New Parameters</Typography>
                                </Divider> : null}
                            </>
                          )
                        })}
                      </div>}

                    {selectedData?.result_type === 1 || selectedData?.result_type === 7 ?
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                          disabled={editTestDetails}
                          startIcon={<AddCircleIcon />}
                          className='eMed_Lab_act_btn'
                          sx={{ textTransform: 'capitalize' }}
                          onClick={() => { this.onAddNewParameter(selectedData) }}
                        >Add New Parameter</Button>
                      </Box> : null
                    }

                  </Box>
                </div>}
              {this.state.selectedData?.result_type == 8 ?
                <div style={{ height: "30vh", overflow: "scroll", margin: '1vw' }}>
                  <div style={{ display: 'flex', marginTop: '0.5vw' }}>
                    <TextField
                      sx={{ width: '12vw', marginBottom: "0.5vw", mr: '0.5vw' }}
                      size='small'
                      label="Label Name (X Axis)"
                      value={this.state.graphBased.xLabelName}
                      disabled={disable}
                      variant="outlined"
                      onChange={(e) => {
                        this.state.graphBased.xLabelName = e.target.value
                        this.setState({ graphBased: this.state.graphBased })
                      }} />
                    <Box mr={'0.5vw'}>
                      <FormControl size='small' sx={{ width: '5vw', marginBottom: '0.5vw' }}>
                        <InputLabel>Unit</InputLabel>
                        <Select
                          label='unit'
                          value={this.state.graphBased.xAxisUnit}
                          disabled={disable}
                          onChange={(e) => {
                            this.state.graphBased.xAxisUnit = e.target.value
                            this.setState({ graphBased: this.state.graphBased })
                          }}
                          MenuProps={{
                            style: { maxHeight: 300 },
                          }}
                        >
                          {this.state.refUnitList?.map((item) => (
                            <MenuItem value={item.unit}>{item.unit}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <div style={{ overflowX: 'scroll', maxWidth: '22vw', display: 'flex', marginRight: '0.5vw', paddingTop: '0.58vh' }}>

                      {this.state.graphBased.xValues?.map((item, i) => {
                        return (
                          <Box sx={{ display: 'flex', width: '6vw', mr: '0.5vw' }}>
                            <TextField
                              sx={{
                                width: '6vw',
                                '& .MuiInputBase-root': {
                                  height: '4.20vh',
                                },
                              }}
                              size='small'
                              label={`Value ${i + 1}`}
                              disabled={disable}
                              value={item?.value}
                              variant="outlined"
                              onChange={(e) => {
                                if (CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value === '') {
                                  item["value"] = e.target.value
                                  this.setState({ graphBased: this.state.graphBased })
                                }

                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {(i == 0 || i == 1 || i == 2 || i == 3) ? null :
                                      <IconButton
                                        disabled={disable}
                                        aria-label="clear text"
                                        onClick={() => { this.removeGraphValues(i) }}
                                        edge="end"
                                      >
                                        <CloseIcon color="error" fontSize='1vw' />
                                      </IconButton>}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        )
                      })}
                    </div>
                    {disable ? null :
                      <Button
                        startIcon={<AddCircleIcon />}
                        className='eMed_Lab_act_btn'
                        variant='outlined'
                        sx={{ textTransform: 'capitalize', mb: '0.5vw', width: '8vw', padding: '5px', fontSize: '0.7rem', height: '5vh' }}
                        disabled={disable}
                        onClick={() => { this.onAddNewValue() }}
                      > Add Value</Button>}
                  </div>
                  <div style={{ display: 'flex', marginTop: '1vw' }}>
                    <TextField
                      sx={{ width: '12vw', marginBottom: "0.5vw", mr: '0.5vw' }}
                      size='small'
                      label="Label Name (Y Axis)"
                      value={this.state.graphBased.yLabelName}
                      variant="outlined"
                      disabled={disable}
                      onChange={(e) => {
                        this.state.graphBased.yLabelName = e.target.value
                        this.setState({ graphBased: this.state.graphBased })
                      }} />
                    <Box mr={'0.5vw'}>
                      <FormControl size='small' sx={{ width: '5vw', marginBottom: '0.5vw' }}>
                        <InputLabel>Unit</InputLabel>
                        <Select
                          label='unit'
                          value={this.state.graphBased.yAxisUnit}
                          disabled={disable}
                          onChange={(e) => {
                            this.state.graphBased.yAxisUnit = e.target.value
                            this.setState({ graphBased: this.state.graphBased })
                          }}
                          MenuProps={{
                            style: { maxHeight: 300 },
                          }}
                        >
                          {this.state.refUnitList?.map((item) => (
                            <MenuItem value={item.unit}>{item.unit}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {RangeRef?.map((item, index) => {
                    return (
                      <>
                        <Box key={index} display={'flex'}>
                          <TextField
                            sx={{ width: '10vw', marginBottom: "0.5vw", marginRight: "0.5vw", mt: '0.5vw' }}
                            size='small'
                            label="Range From"
                            value={item?.ref_from}
                            disabled={editTestDetails}
                            variant="outlined"
                            onChange={(e) => {
                              if (CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value == '') {
                                item["ref_from"] = e.target.value
                                this.setState({ graphBased: this.state.graphBased, dupRangeRef: this.state.dupRangeRef })
                              }
                            }}
                            InputProps={{
                              endAdornment:
                                <Box width={'2vw'}>
                                  {this.state.graphBased.yAxisUnit?.length > 6 ? <Tooltip placement='top' title={this.state.graphBased.yAxisUnit} arrow><div>{this.state.graphBased.yAxisUnit?.slice(0, 3) + '...'}</div></Tooltip> :

                                    <Typography variant="body2">{this.state.graphBased.yAxisUnit}</Typography>
                                  }
                                </Box>
                            }}
                          />
                          <TextField
                            sx={{ width: '10vw', marginBottom: "0.5vw", marginRight: "0.5vw", mt: '0.5vw' }}
                            size='small'
                            label="Range To"
                            disabled={editTestDetails}
                            value={item?.ref_to}
                            variant="outlined"
                            onChange={(e) => {
                              if (CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value == '') {
                                item["ref_to"] = e.target.value
                                this.setState({ graphBased: this.state.graphBased, dupRangeRef: this.state.dupRangeRef })
                              }
                            }}
                            InputProps={{
                              endAdornment:
                                <Box width={'2vw'}>
                                  {this.state.graphBased.yAxisUnit?.length > 6 ? <Tooltip placement='top' title={this.state.graphBased.yAxisUnit} arrow><div>{this.state.graphBased.yAxisUnit?.slice(0, 3) + '...'}</div></Tooltip> :
                                    <Typography variant="body2">{this.state.graphBased.yAxisUnit}</Typography>
                                  }
                                </Box>
                            }}
                          />
                          <Typography sx={{ m: '1vw' }}>=</Typography>
                          <TextField
                            sx={{ width: '7.3vw', marginBottom: "0.5vw", marginRight: "0.5vw", mt: '0.5vw' }}
                            size='small'
                            label="Range Type"
                            disabled={editTestDetails}
                            value={item?.ref_type}
                            variant="outlined"
                            onChange={(e) => {
                              item["ref_type"] = e.target.value
                              this.setState({ graphBased: this.state.graphBased, dupRangeRef: this.state.dupRangeRef })
                            }}
                          />

                          {RangeRef?.length > 1 ?
                            <Button className='eMed_Lab_act_btn' disabled={editTestDetails} onClick={() => {
                              var { graphBased } = this.state
                              if (this.state.isEdit) {
                                graphBased.graph_ref_range?.splice(index, 1)
                                this.setState({
                                  graphBased
                                })
                              } else {
                                this.state.dupRangeRef?.splice(index, 1)
                                this.setState({
                                  dupRangeRef: this.state.dupRangeRef
                                })
                              }
                            }}>
                              <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                            </Button>
                            : null}
                        </Box>
                      </>
                    )
                  })}

                  <Box>
                    <Button
                      startIcon={<AddCircleIcon />}
                      className='eMed_Lab_act_btn'
                      sx={{ textTransform: 'capitalize' }}
                      disabled={editTestDetails}
                      onClick={() => { this.onAddNewGraphRef() }}
                    >Add New Parameter</Button>
                  </Box>
                </div>
                : null}
            </Box>

            <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'}>
              <Button
                sx={{ width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                size='small'
                className='eMed_Lab_act_btn'
                variant='outlined'
                onClick={() => this.resetNewReferrence()}
              >Reset</Button>
              <Button
                sx={{ width: '5vw', textTransform: 'capitalize', mr: "1vw" }}
                size='small'
                className='eMed_Lab_act_btn'
                variant='contained'
                emed_tid='ResultConfig_TestName_Edit_Save'
                disabled={this.state.disableBtn}
                onClick={() => this.postNewReferenceData()}
              >Save</Button>
            </Box>

          </Box>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderDropdown(label, Key, List, LabelKey, ValueKey, disable = false, width) {
    let states = this.state;
    try {
      return (
        <Autocomplete
          disabled={disable}
          noOptionsText={'No Data Found...'}
          options={List}
          getOptionLabel={(options) => typeof (options) === "string" ? options : options[LabelKey]}
          onChange={(e, value) => {
            states[Key] = value[ValueKey]
            this.setState({ states })
          }}
          clearIcon={false}
          size='small'
          sx={{ width: width, marginTop: '0.8vw' }}
          value={List.find((item) => (item[ValueKey] === states[Key])) ? List.find((item) => (item[ValueKey] === states[Key])) : null}
          renderInput={(params) => <TextField sx={{ width: width }} label={label} {...params} />}
        />
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  popupClose() {
    this.setState({
      deletePopup: false
    })
  }

  addCancelComments(comments) {
    var states = this.state;
    var data = {}

    try {
      if (this.state.DltTitle == "Department") {
        data = `?dept_id= ${states.selectedvalue} &reason= ${comments}`
      } else if (this.state.DltTitle == "Group") {
        data = `?grp_id= ${states.selectedvalue} &reason= ${comments}`
      } else { data = `?test_id=${states.selectedvalue}&reason=${comments}` }
      let URL = (this.state.DltTitle == "Department" || this.state.DltTitle == "Group") ? Serviceurls.LAB_GRP_EDIT_DELETE + data : Serviceurls.LAB_ADD_NEW_TEST + data
      this.setState({ disableBtn: true })
      RestAPIService.delete(URL)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ deletePopup: false ,grpCode:'',testCode:''}, () => { this.getDeptList() })
            this.handleClose()
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({ disableBtn: false })
    }
  }

  handleWarning = () => {
    this.setState({
      DltTitle: "Test",
      selectedvalue: this.state.receivedData?.test_id,
    }, () => { this.setState({ deletePopup: true }) })
  }

  handleClose = () => {
    this.setState({
      addTestPopup: false,
      GroupAmt: 0,
      TestAmt: 0,
      NewdepartmentName: null,
      NewgroupName: null,
      NewSpecimen: "",
      NewtestName: "",
      NewMethod: "",
      NewresultType: "",
      newPrintOrder: null,
      NewgrpListData: [],
      stepNumber: 0,
      ViewScreen: 0,
      grpCode:'',
      testCode:'',
      graphBased: {
        xLabelName: '',
        yLabelName: '',
        xAxisUnit: '',
        yAxisUnit: '',
        xValues: [
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
        graph_ref_range: [{
          ref_from: '',
          ref_to: '',
          ref_type: '',
        }],
      },
      dupRangeRef: [
        { ref_from: '', ref_to: '', ref_type: '' }
      ],
      graphData: [],
    })
  }

  closePopup = () => {
    this.setState({
      EditTitle: "",
      EditPopup: false,
      EditDepName: "",
      EditGrpName: "",
      EditGrpAmt: "",
      EditDepId: "",
      EditGrpId: "",
      EditPrintOrder: "",
      isEditDepart: false,
      isEditGrp: false,
      EditTestName: "",
      EditTestAmt: "",
      EditTestId: "",
      EditIsBillable: false,
      EditGrpCode:'',
      EditTestCode:''
    })
  }

  UpdateDeatails = () => {
    let data = {};
    try {
      if (this.state.isEditDepart) {
        if (this.state.DepPrintOrder == null || this.state.EditDepName == "") {
          this.errorMessage("Department details should Not be empty");
        } else {
          data = { dept_id: this.state.EditDepId, dept_name: this.state.EditDepName, print_order: this.state.DepPrintOrder }
          this.setState({ disableBtn: true })
          RestAPIService.patch(data, Serviceurls.LAB_GRP_EDIT_DELETE)
            .then(response => {
              if (response.data.status === "success") {
                this.successMessage(response.data.message);
                this.closePopup()
                this.getDeptList()
                this.setState({ disableBtn: false })
              }
            })
            .catch(error => {
              if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message);
                this.setState({ disableBtn: false })
              }
            });
        }
      } else if (this.state.isEditGrp) {
        if (this.state.EditGrpName == "" || this.state.EditGrpAmt == "") {
          this.errorMessage("Group details should Not be empty");
        } else {
          data = { dept_id: this.state.EditDepId, grp_id: this.state.EditGrpId, grp_name: this.state.EditGrpName, grp_amt: this.state.EditGrpAmt, print_order: this.state.EditPrintOrder,grp_test_code:this.state.EditGrpCode }
          this.setState({disableBtn: true})
          RestAPIService.updateWithOutId(data, Serviceurls.LAB_GRP_EDIT_DELETE)
            .then(response => {
              if (response.data.status === "success") {
                this.successMessage(response.data.message);
                this.closePopup()
                this.getDeptList()
                this.setState({ disableBtn: false })
              }
            })
            .catch(error => {
              if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message);
                this.setState({ disableBtn: false })
              }
            });
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  EditDialogBox = () => {
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code

    return (
      <Dialog open={this.state.EditPopup}>
        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
          <div>{this.state.isEditGrp ? "EDIT GROUP DETAILS" : "EDIT DEPARTMENT DETAILS"}</div>
          <div className="eMed_DialogBox_Close">
            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => { this.closePopup() }} />
          </div>
        </div>
        <DialogContent sx={{ width: "35vw" }}>
          {this.state.isEditDepart ?
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TextField
                sx={{ width: '18vw', marginRight: "0.5vw" }}
                size='small'
                label="Enter Department Name"
                value={this.state.EditDepName}
                variant="outlined"
                onChange={(e) => {
                  this.setState({ EditDepName: e.target.value })
                }} />
              <Autocomplete
                value={this.state.DepPrintOrder}
                options={this.state.DepartLength.map((item) => item)}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                  this.setState({ DepPrintOrder: newValue })
                }}
                renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '16vw' }} label='Print No.' />)}
              />
            </div>
            : null}
          {this.state.isEditGrp ?
            <div>
              <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Department and Group Details</div>
              <Box component={'div'} style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw" }}>
                <TextField
                  disabled={true}
                  size='small'
                  sx={{ width: '14vw', marginRight: "0.5vw" }}
                  label="Enter Department Name"
                  value={this.state.EditDepName}
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({ EditDepName: e.target.value })
                  }} />
                <TextField
                  sx={{ width: '14vw', marginBottom: "0.5vw" , marginRight: "0.5vw"}}
                  size='small'
                  label="Enter Group Name"
                  value={this.state.EditGrpName}
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({ EditGrpName: e.target.value })
                  }} />
                {showCode ?
                  <TextField
                    sx={{ width: '10vw', marginBottom: "0.5vw" }}
                    size='small'
                    label="Group Code"
                    value={this.state.EditGrpCode}
                    variant="outlined"
                    onChange={(e) => {
                      if(e.target.value?.length <= Code_Length || e.target.value === ''){
                        this.setState({ EditGrpCode: e.target.value })
                      }
                    }} /> : null}
              </Box>
              <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Group Amount</div>
              <Box component={'div'} style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw" }}>
                <TextField
                  sx={{ width: '15.7vw', marginTop: "0.75vw", marginRight: "0.5vw" }}
                  size='small'
                  label="Group Amount"
                  value={this.state.EditGrpAmt}
                  variant="outlined"
                  onChange={(e) => {
                    if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                      this.setState({ EditGrpAmt: e.target.value })
                    }
                  }} />
                <Autocomplete
                  value={this.state.EditPrintOrder}
                  options={this.state.GrpLength.map((item) => item)}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    this.setState({ EditPrintOrder: newValue })
                  }}
                  renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '16vw', marginTop: "0.75vw" }} label='Print No.' />)}
                />
              </Box>
            </div>
            : null}
        </DialogContent>
        <DialogActions id="eMed_DialogBox_Btn">
          <Button size="small" variant={'outlined'} id="eMed_Dia_Btn" color="error" onClick={() => { this.closePopup() }}>
            {"CANCEL"}
          </Button>
          <Button color={this.props.btncolor} size="small" variant={'contained'} id="eMed_Dia_Btn" emed_tid='ResultConfig_Edit_Save_Btn' disabled={this.state.disableBtn} onClick={() => { this.UpdateDeatails() }}>
            {"SAVE"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  onSave = () => {
    var inValid = this.validCheck()
    if (inValid) {
      this.errorMessage(inValid)
    } else {
      this.updateCultureReference()
    }
  }

  validCheck = () => {
    let { CulturalTestData, CulturalTestList } = this.state
    var saveErrorMsg = ''
    var states = this.state
    const isDuplicate = CulturalTestList.some(
      (detail) => detail.BacteriaData?.name === CulturalTestData?.BacteriaData?.name && detail.AntibioticData?.name === CulturalTestData?.AntibioticData?.name
    );
    if (!isDuplicate || (states.selectedIndex === 0 ? true : states.selectedIndex == "" ? false : states.selectedIndex != "")) {
      if (CulturalTestData?.BacteriaData == null) {
        saveErrorMsg = ('Select Bacteria Name')
      } else if (CulturalTestData?.AntibioticData == null) {
        saveErrorMsg = ('Select Anti-Biotic Name')
      } else if ((CulturalTestData?.BacteriaData != null && CulturalTestData?.AntibioticData != null) &&
        ((CulturalTestData?.zone_size_s == undefined && CulturalTestData?.zone_size_i == undefined && CulturalTestData?.zone_size_r == undefined &&
          CulturalTestData?.mic_s == undefined && CulturalTestData?.mic_i == undefined && CulturalTestData?.mic_r == undefined))) {
        saveErrorMsg = ('Enter Zone Size Value or MIC Break Point Value')
      }
    } else {
      saveErrorMsg = ('This Bacteria and Anti-Biotic Combination Already Exist')
    }
    return saveErrorMsg
  }

  updateCultureReference = () => {
    let { CulturalTestData, CulturalTestList } = this.state
    let data = []
    if (CulturalTestData?.AntibioticData != null && CulturalTestData?.BacteriaData != null) {
      data = {
        AntibioticData: CulturalTestData?.AntibioticData,
        BacteriaData: CulturalTestData?.BacteriaData,
        zone_size_s: CulturalTestData?.zone_size_s,
        zone_size_i: CulturalTestData?.zone_size_i,
        zone_size_r: CulturalTestData?.zone_size_r,
        mic_s: CulturalTestData?.mic_s,
        mic_i: CulturalTestData?.mic_i,
        mic_r: CulturalTestData?.mic_r,
      }
      if (this.state.isEditCulture) {
        CulturalTestList[this.state.selectedIndex] = data
      } else {
        CulturalTestList = [...CulturalTestList, data]
      }
      this.setState({
        CulturalTestList,
        isEditCulture: false,
        selectedIndex: "",
        CulturalTestData: [
          {
            AntibioticData: null,
            BacteriaData: null,
            zone_size_s: "",
            zone_size_i: "",
            zone_size_r: "",
            mic_s: "",
            mic_i: "",
            mic_r: "",
          }
        ]
      })
    } else {
      this.errorMessage("Please Select the Mandatory Fields")
    }
  }

  ResetCultureReference = () => {
    this.setState({
      CulturalTestData: [
        {
          AntibioticData: null,
          BacteriaData: null,
          zone_size_s: "",
          zone_size_i: "",
          zone_size_r: "",
          mic_s: "",
          mic_i: "",
          mic_r: "",
        }
      ]
    })
  }

  ResetGraphReference = () => {
    this.setState({
      graphBased: {
        xLabelName: '',
        yLabelName: '',
        xAxisUnit: '',
        yAxisUnit: '',
        xValues: [
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
        graph_ref_range: [{
          ref_from: '',
          ref_to: '',
          ref_type: '',
        }],
      },
      dupRangeRef: [
        { ref_from: '', ref_to: '', ref_type: '' }
      ],
      graphData: [],
    })
  }

  handleEdit = (item, index) => {
    this.setState({
      CulturalTestData:
      {
        AntibioticData: item?.AntibioticData,
        BacteriaData: item?.BacteriaData,
        zone_size_s: item?.zone_size_s,
        zone_size_i: item?.zone_size_i,
        zone_size_r: item?.zone_size_r,
        mic_s: item?.mic_s,
        mic_i: item?.mic_i,
        mic_r: item?.mic_r,
      },
      isEditCulture: true,
      selectedIndex: index
    });
  }

  handleDelete = (index) => {
    this.setState(prevState => {
      const updatedItems = [...prevState.CulturalTestList];
      updatedItems.splice(index, 1);
      return { CulturalTestList: updatedItems };
    });
  }

  EditCulturalTestBox = () => {
    let states = this.state
    try {
      return (
        <Modal open={true}>
          <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "60vw", height: "90vh" }}>
            <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <Typography sx={{ color: "#616161", fontWeight: 600 }}>{"Blood Culture Test"}</Typography>
              <IconButton onClick={() => { this.setState({ EditCultureTest: false, isEdit: false }, () => { this.ResetCultureReference() }) }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
            </div>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw" }}>
                <Autocomplete
                  size='small'
                  options={this.state.BacteriaList}
                  getOptionLabel={(option) => (option?.name)}
                  value={this.state?.CulturalTestData?.BacteriaData ? this.state?.CulturalTestData?.BacteriaData : null}
                  onChange={(event, newValue) => {
                    states.CulturalTestData.BacteriaData = newValue
                    this.setState({ states })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                      label={'Bacteria Name*'}
                    />
                  )}
                />
                <div style={{ fontSize: ".9vw", marginRight: "1vw" }}>Zone Size (mm)</div>
                {this.renderTextBox("S", '8.5vw', states?.CulturalTestData?.zone_size_s, 'zone_size_s')}
                {this.renderTextBox("I", '8.5vw', states?.CulturalTestData?.zone_size_i, 'zone_size_i')}
                {this.renderTextBox("R", '8.5vw', states?.CulturalTestData?.zone_size_r, 'zone_size_r')}
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw", marginTop: "-0.5vw" }}>
                <Autocomplete
                  size='small'
                  options={this.state?.AntibioticList}
                  getOptionLabel={(option) => (option?.name)}
                  value={this.state?.CulturalTestData?.AntibioticData ? this.state?.CulturalTestData?.AntibioticData : null}
                  onChange={(event, newValue) => {
                    states.CulturalTestData.AntibioticData = newValue
                    this.setState({ states })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                      label={'Antibiotic Name*'}
                    />
                  )}
                />
                <div style={{ fontSize: ".9vw", marginRight: "0.75vw" }}>MIC Break Point</div>
                {this.renderTextBox("S", '8.5vw', states?.CulturalTestData?.mic_s, 'mic_s')}
                {this.renderTextBox("I", '8.5vw', states?.CulturalTestData?.mic_i, 'mic_i')}
                {this.renderTextBox("R", '8.5vw', states?.CulturalTestData?.mic_r, 'mic_r')}
              </div>
            </div>
            <div style={{ display: 'flex',  flexDirection: 'column', padding: "0.75vw", borderLeft: '2px solid lightgray', marginTop: '.5vw', marginBottom: '.5vw' }}>
                <Button variant="contained" size="small" onClick={() => { this.onSave() }}>{"ADD TEST"}</Button>
                <Button size="small" sx={{ marginTop: "1vw" }} variant={"outlined"} onClick={() => { this.ResetCultureReference() }}>{"RESET"}</Button>
            </div>
            </Box>
            <div>
              <Paper elevation={0}>
                <TableContainer style={{ height: "58vh", overflowY: 'scroll' }}>
                  <Table stickyHeader size='small'>
                    <TableHead sx={{ height: '7.3vh' }}>
                      <TableRow>
                        <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{'SNo'}</TableCell>
                        <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{"Bacteria"}</TableCell>
                        <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{"Antibiotic"}</TableCell>
                        <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>
                          <div style={{ alignItems: "center", justifyContent: "center" }}>
                            <div>{"Zone size"}</div>
                            <div>{"S | I | R"}</div>
                          </div>
                        </TableCell>
                        <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>
                          <div style={{ alignItems: "center", justifyContent: "center" }}>
                            <div>{"MIC"}</div>
                            <div>{"S | I | R"}</div>
                          </div>
                        </TableCell>
                        <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{'Action'}</TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.CulturalTestList.map((data, index) => {
                      var bacteriaName = data?.BacteriaData?.name;
                      var antibioticName = data?.AntibioticData?.name;
                      var sno = (index + 1)
                      return (
                        <TableBody>
                          <TableRow sx={{ bgcolor: 'white' }}>
                            <TableCell align='left'><div>{sno}</div></TableCell>
                            <TableCell align='left'>
                              {bacteriaName?.length > 13 ?
                                <Tooltip placement="top" title={bacteriaName} arrow>
                                  <div >{(bacteriaName ? bacteriaName.slice(0, 13) + '..' : '-')}</div>
                                </Tooltip> : <div> {(bacteriaName ? bacteriaName : '-') + "-"}</div>}</TableCell>
                            <TableCell align='left'>
                              {antibioticName?.length > 13 ?
                                <Tooltip placement="top" title={antibioticName} arrow>
                                  <div >{(antibioticName ? antibioticName.slice(0, 13) + '..' : '-')}</div>
                                </Tooltip> : <div> {antibioticName ? antibioticName : '-'}</div>}</TableCell>
                            <TableCell align='center'>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <div>{data?.zone_size_s?.length > 5 ? <Tooltip placement="top" title={data?.zone_size_s} arrow><div>{data?.zone_size_s ? data?.zone_size_s.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.zone_size_s ? data?.zone_size_s : "-"}</div>}</div> |
                                <div>{data?.zone_size_i?.length > 5 ? <Tooltip placement="top" title={data?.zone_size_i} arrow><div>{data?.zone_size_i ? data?.zone_size_i.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.zone_size_i ? data?.zone_size_i : "-"}</div>}</div> |
                                <div>{data?.zone_size_r?.length > 5 ? <Tooltip placement="top" title={data?.zone_size_r} arrow><div>{data?.zone_size_r ? data?.zone_size_r.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.zone_size_r ? data?.zone_size_r : "-"}</div>}</div>
                              </div>
                            </TableCell>
                            <TableCell align='center'>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <div>{data?.mic_s?.length > 5 ? <Tooltip placement="top" title={data?.mic_s} arrow><div>{data.mic_s ? data.mic_s.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.mic_s ? data?.mic_s : "-"}</div>}</div> |
                                <div>{data?.mic_i?.length > 5 ? <Tooltip placement="top" title={data?.mic_i} arrow><div>{data.mic_i ? data.mic_i.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.mic_i ? data?.mic_i : "-"}</div>}</div> |
                                <div>{data?.mic_r?.length > 5 ? <Tooltip placement="top" title={data?.mic_r} arrow><div>{data.mic_r ? data.mic_r.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.mic_r ? data?.mic_r : "-"}</div>}</div>
                              </div>
                            </TableCell>
                            <TableCell align='center'>
                              <div className='eMed_TestConfig_Con'>
                                <IconButton size='small'>
                                  <img src={ImagePaths.Edit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                    onClick={() => { this.handleEdit(data, index) }}
                                  />
                                </IconButton>
                                <IconButton size='small'>
                                  <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                    onClick={() => { this.handleDelete(index) }}
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                    })
                    }
                  </Table>
                  {this.state?.CulturalTestList?.length === 0 ?
                    <div className='eMed_TestConfig_NoRecords'>
                      {'NoRecords Shown'}
                    </div> : null}
                </TableContainer>
              </Paper>
            </div>
            <Stack id="emedhub_popup_footer" direction="row" justifyContent={"flex-end"} alignItems="center" sx={{ height: "3vw" }}>
              <Button size="small" sx={{ marginRight: "1vw" }} variant={"outlined"} onClick={() => {
                this.setState({ EditCultureTest: false, isEdit: false }, () => { this.ResetCultureReference() })
              }}>
                {"Back"}
              </Button>
              <Button variant="contained" size="small" emed_tid='ResultConfig_Action_Update' disabled={this.state.disableBtn} onClick={() => { this.postNewReferenceData() }}>{"UPDATE"}</Button>
            </Stack>
          </Paper>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidateCultureCheck = (data) => {
    try {
      let isInValid = false
      let { CulturalTestData } = this.state
      const isDuplicate = CulturalTestData.some(
        (detail) => detail.BacteriaData?.name === data?.BacteriaData?.name && detail.AntibioticData?.name === data?.AntibioticData?.name
      );
      data?.forEach((element, index) => {
        if ((element.AntibioticData !== null) && (element.BacteriaData !== null) && data.length > 1) {
          this.setState({
            CulturalTestData: data
          })
        } else if ((element.AntibioticData == null) || (!element.BacteriaData && element.BacteriaData == null)) {
          isInValid = true
        } else if (isDuplicate) {
          this.errorMessage('This Bacteria and Anti-Biotic Combination Already Exist')
        }
      })
      return isInValid
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DeleteCulture = (index) => {
    this.setState(prevState => {
      const updatedContainers = prevState.containers.filter((container, i) => i !== index);
      const updatedCulturalTestData = prevState.CulturalTestData.filter((data, i) => i !== index);

      return {
        containers: updatedContainers,
        CulturalTestData: updatedCulturalTestData
      };
    });
  }

  addContainer = () => {
    let { CulturalTestData } = this.state
    let duplicate = {
      AntibioticData: null,
      BacteriaData: null,
      zone_size_s: "",
      zone_size_i: "",
      zone_size_r: "",
      mic_s: "",
      mic_i: "",
      mic_r: "",
    }
    let Invalid = this.ValidateCultureCheck(CulturalTestData)
    if (!Invalid) {
      CulturalTestData = [...CulturalTestData, duplicate]
      this.setState(prevState => ({
        containers: [
          ...prevState.containers,
          {
            id: prevState.containers.length + 1
          }
        ], CulturalTestData
      }));
    } else {
      this.errorMessage("Enter Culture Details")
    }
  }

  renderTextBox = (label, width, values, name, index) => {
    let states = this.state
    return (
      <div>
        <TextField
          size={'small'}
          sx={{ width: width, marginRight: "0.5vw" }}
          label={label}
          name={name}
          value={values ? values : ""}
          variant="outlined"
          onChange={(e) => {
            let value = e.target.value
            let names = e.target.name
            if ((names == "zone_size_s" || names == "zone_size_i" || names == "zone_size_r" || names == "mic_s" || names == "mic_i" || names == "mic_r") && (value.length <= 15 && !(/\s/.test(value)))) {
              if (index != undefined && index != null) {
                states.CulturalTestData[index][names] = value
                this.setState({ states })
              } else {
                states.CulturalTestData[names] = value
                this.setState({ states })
              }
            }
          }}
        />
      </div>
    )
  }

  onChangeValue = (e, item, names, listIndex, index) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'refFrom':
          if (CommonValidation.NumberWithDot(value) || value === '') {
            this.state.cal_Data[listIndex]['cal_ref_data'][index]['cal_ref_from'] = value
            this.state.cal_Data[listIndex]['is_cal_ref_data'] = value ? true : false
            this.setState({ cal_Data: this.state.cal_Data })
          }
          break;
        case 'refTo':
          if (CommonValidation.NumberWithDot(value) || value === '') {
            this.state.cal_Data[listIndex]['cal_ref_data'][index]['cal_ref_to'] = value
            this.state.cal_Data[listIndex]['is_cal_ref_data'] = value ? true : false
            this.setState({ cal_Data: this.state.cal_Data })
          }
          break;
        case 'status':
          this.state.cal_Data[listIndex]['cal_ref_data'][index]['cal_ref_status'] = value
          this.state.cal_Data[listIndex]['is_cal_ref_data'] = value ? true : false
          this.setState({ cal_Data: this.state.cal_Data })
          break;
        case 'editRefFrom':
          if (CommonValidation.NumberWithDot(value) || value === '') {
            this.setState({ editRefFrom: value })
          }
          break;
        case 'editRefTo':
          if (CommonValidation.NumberWithDot(value) || value === '') {
            this.setState({ editRefTo: value })
          }
          break;
        case 'editRefstatus':
          this.setState({ editRefstatus: value })
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextField = (names, values, labels, width, disable, show, listIndex, Index) => {
    try {
      return (
        <TextField
          autoComplete='off'
          disabled={disable}
          size='small'
          label={labels}
          sx={{ width: width ? width : '10vw', mr: '0.5vw' }}
          value={values}
          names={names}
          onChange={(e) => this.onChangeValue(e, values, names, listIndex, Index)}
          inputProps={{ emed_tid: names }}
          InputProps={show ? { endAdornment: <Typography>{((this.state.selectedRefData?.cal_ref_type === 'LDL | HDL') || this.state.calEditData?.cal_ref_type === 'LDL | HDL') ? 'g/dL' : 'mg/dL'}</Typography> } : null}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderAddNewTestPopup = () => {
    const steps = ['Test Details', 'Parameters'];
    let { GroupAmt, TestAmt } = this.state
    var numbers = [];

    let { ref_data, range_data, editRangeData, receivedData, ref_type } = this.state
    let states = this.state
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
    try {
      return (
        <Modal open={this.state.addTestPopup}>
          <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "60vw", height: "75vh" }}>
            <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <Typography sx={{ color: "#616161", fontWeight: 600 }}>{"Add New Test Details"}</Typography>
              <IconButton onClick={() => { ((this.state.ViewScreen == 1) && (receivedData?.result_type != 3)) ? this.handleWarning() : this.handleClose() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "1vw" }}>
              <Stepper activeStep={this.state.stepNumber} sx={{ width: "50%" }}>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {this.state.ViewScreen == 0 ?
              <Box component={'div'} sx={{ marginX: "1vw" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "1vw", marginTop: "0.5vw" }}>Department and Group Details</div>
                  <FormControlLabel control={<Checkbox checked={!this.state.isBillable} onChange={(e) => { this.setState({ isBillable: !this.state.isBillable }) }} />} label={"IS BILLABLE"} />
                </div>
                <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Autocomplete
                    size='small'
                    clearIcon
                    options={this.state.departmentListData}
                    getOptionLabel={(option) => option?.dept_name}
                    value={this.state.NewdepartmentName}
                    onChange={(e, newValue) => {
                      this.setState({ NewdepartmentName: newValue, NewgroupName: null, NewgrpListData: [], newTestNameLength: [], TestAmt: 0, GroupAmt: 0, newTestNameLength: [], NewtestListData: [], NewtestName: "", newPrintOrder: null, }, () => {
                        this.getNewTestData()
                      })
                    }}
                    sx={{ width: "15vw", marginRight: "0.5vw" }}
                    renderInput={(params) => (<TextField {...params} size='small' label='Enter Department Name *' onChange={(e) => {
                      var newDepart = { dept_name: e.target.value, id: "" }
                      this.setState({
                        NewdepartmentName: newDepart, NewgrpListData: [], NewgroupName: null, newTestNameLength: [], NewtestListData: [], NewtestName: "", TestAmt: 0, GroupAmt: 0
                      })
                    }} />)}
                  />
                  <Autocomplete
                    size='small'
                    options={this.state.NewdepartmentName !== null ? this.state.NewgrpListData : []}
                    getOptionLabel={(option) => option.grp_name}
                    value={this.state.NewgroupName}
                    onChange={(e, newValue) => {
                      this.setState({ NewgroupName: newValue, GroupAmt: newValue?.grp_amt ? newValue?.grp_amt : 0,hasCode: newValue?.grp_test_code ? true : false ,grpCode:newValue?.grp_test_code ? newValue?.grp_test_code : ''}, () => {
                        var duplicate = JSON.parse(this.state.duplicateResult)
                        if (this.state.NewdepartmentName) {
                          duplicate?.filter(element => element.dept_id === this.state.NewdepartmentName?.dept_id ? duplicate = [element] : duplicate)
                        }
                        let data = []
                        if (this.state.NewgroupName && duplicate?.length) {
                          duplicate[0].grp_data.forEach(element => {
                            if (element.grp_id === this.state.NewgroupName?.grp_id) {
                              data.push(element)
                            }
                          })
                          duplicate[0].grp_data = data
                        }
                        for (let i = 0; i <= this.state?.NewgroupName?.grp_test_data?.length; i++) {
                          numbers?.push(i + 1)
                        }
                        this.setState({ NewtestName: "", newTestNameLength: numbers, newPrintOrder: numbers.length, NewtestListData: [], TestAmt: 0 }, () => { this.setState({ NewtestListData: data?.[0]?.grp_test_data }) })
                      })
                    }}
                    sx={{ width: '15vw', marginRight: "0.5vw" }}
                    renderInput={(params) => (<TextField {...params} label='Enter Group Name *' onChange={(e) => {
                      var newGrp = { grp_name: e.target.value, grp_id: "" }
                      this.setState({
                        NewgroupName: newGrp, GroupAmt: 0, NewtestName: "", newPrintOrder: 1, NewtestListData: [], TestAmt: 0
                      })
                    }} />)}
                  />
                  {showCode ? 
                  <TextField
                    size='small'
                    sx={{ width: '10vw', mr: '0.5vw' }}
                    label='Group Code'
                    // disabled={this.state.hasCode}
                    value={this.state.grpCode}
                    onChange={(e) => {
                      let value = e.target.value
                      if (value?.length <= Code_Length || value === '') {
                        this.setState({ grpCode: value })
                      }
                    }}
                  /> : null}
                  {this.inputTextBox('NewtestName', this.state.NewtestName, 'Enter Test Name *', '15vw', '0', null, false)}
                  {showCode ? <TextField
                    size='small'
                    sx={{ width: '10vw', ml: '0.5vw' }}
                    label='Test Code'
                    value={this.state.testCode}
                    onChange={(e) => {
                      let value = e.target.value
                      if (value?.length <= Code_Length || value === '') {
                        this.setState({ testCode: value })
                      }
                    }}
                  /> : null}
                </Box>
                <Box component={'div'} sx={{ marginTop: "1vw", display: "flex", flexDirection: "row" }}>
                  {this.inputTextBox('NewSpecimen', this.state.NewSpecimen, 'Specimen', '14vw', '0.5vw', null, false)}
                  {this.inputTextBox('NewMethod', this.state.NewMethod, 'Method', '14vw', '0.5vw', null, false)}
                  <FormControl size='small' sx={{ width: '14vw', marginRight: '0.5vw', marginBottom: '0.5vw', mt: '0.5vw' }}>
                    <InputLabel>Result Type *</InputLabel>
                    <Select
                      label='Result Type'
                      value={this.state.NewresultType}
                      onChange={(e) => { this.onChangeHandler(e, 'NewresultType') }}
                    >
                      {Result_Type_List?.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Autocomplete
                    value={this.state.newPrintOrder}
                    disabled={true}
                    options={this.state.newTestNameLength.map((item) => item)}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      this.setState({ newPrintOrder: newValue })
                    }}
                    renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '14vw', mt: '0.5vw' }} label='Print No. *' />)}
                  />
                </Box>
                <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Test Amount</div>
                <Box component={'div'} sx={{ display: "flex", flexDirection: "row", marginBottom: "4.5vw", marginTop: "1vw" }}>
                  {this.inputTextBox('GroupAmt', GroupAmt, 'Enter Group Amount*', '14vw', '0.5vw', null, false)}
                  {this.inputTextBox('TestAmt', TestAmt, 'Enter Test Amount', '14vw', '0.5vw', null, false)}
                </Box>
              </Box>
              : null}
            {this.state.ViewScreen == 1 ?
              <Box component={'div'} sx={{ marginX: "1vw" }}>
                <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Test Name - <span style={{ fontSize: "1vw", color: "black" }}>{receivedData?.test_name}</span></div>
                <FormControl size='small' sx={{ width: '15vw', margin: "0.75vw" }}>
                  <InputLabel>Result Type</InputLabel>
                  <Select
                    label='Result Type'
                    disabled={true}
                    value={receivedData?.result_type}
                    onChange={(e) => { this.onChangeHandler(e, 'resultType') }}
                  >
                    {Result_Type_List?.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Add Parameters</div>
                {this.state?.receivedData?.result_type == 3 ?
                  <div style={{ height: "30vh", overflow: "scroll" }}>
                    {this.state.containers.map((container, index) => (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <div key={container.id}>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw" }}>
                            <Autocomplete
                              size='small'
                              options={this.state.BacteriaList}
                              getOptionLabel={(option) => (option?.name)}
                              value={this.state?.CulturalTestData[index]?.BacteriaData}
                              onChange={(event, newValue) => {
                                states.CulturalTestData[index].BacteriaData = newValue
                                this.setState({ states })
                              }}
                              renderInput={(params) => (
                                <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                                  label={'Bacteria Name*'}
                                />
                              )}
                            />
                            <div style={{ fontSize: "1vw", marginRight: "1vw" }}>Zone Size (mm)</div>
                            {this.renderTextBox("S", '8.5vw', states?.CulturalTestData[index]?.zone_size_s, 'zone_size_s', index)}
                            {this.renderTextBox("I", '8.5vw', states?.CulturalTestData[index]?.zone_size_i, 'zone_size_i', index)}
                            {this.renderTextBox("R", '8.5vw', states?.CulturalTestData[index]?.zone_size_r, 'zone_size_r', index)}
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw", marginTop: "-0.5vw" }}>
                            <Autocomplete
                              size='small'
                              options={this.state?.AntibioticList}
                              getOptionLabel={(option) => (option?.name)}
                              value={this.state?.CulturalTestData[index]?.AntibioticData}
                              onChange={(event, newValue) => {
                                states.CulturalTestData[index].AntibioticData = newValue
                                this.setState({ states })
                              }}
                              renderInput={(params) => (
                                <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                                  label={'Antibiotic Name*'}
                                />
                              )}
                            />
                            <div style={{ fontSize: "1vw", marginRight: "0.75vw" }}>MIC Break Point</div>
                            {this.renderTextBox("S", '8.5vw', states?.CulturalTestData[index]?.mic_s, 'mic_s', index)}
                            {this.renderTextBox("I", '8.5vw', states?.CulturalTestData[index]?.mic_i, 'mic_i', index)}
                            {this.renderTextBox("R", '8.5vw', states?.CulturalTestData[index]?.mic_r, 'mic_r', index)}
                          </div>
                        </div>
                        <Button disabled={index == 0 ? true : false} className='eMed_Lab_act_btn' onClick={() => { this.DeleteCulture(index) }}>
                          <Box component={'img'} src={index == 0 ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='close' height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                      </div>
                    ))}
                    <Box>
                      <Button
                        startIcon={<AddCircleIcon />}
                        className='eMed_Lab_act_btn'
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => { this.addContainer() }}
                      >Add New Parameter</Button>
                    </Box>
                  </div> :

                  this.state?.receivedData?.result_type == 8 ?
                    <div style={{ height: "30vh", overflow: "scroll", marginTop: '0.5vw' }}>
                      <div style={{ display: 'flex', paddingTop: '0.5vw' }}>
                        <TextField
                          sx={{ width: '12vw', marginBottom: "0.5vw", mr: '0.5vw' }}
                          size='small'
                          label="Label Name (X Axis)"
                          value={this.state.graphBased.xLabelName}
                          variant="outlined"
                          onChange={(e) => {
                            this.state.graphBased.xLabelName = e.target.value
                            this.setState({ graphBased: this.state.graphBased })
                          }} />
                        <Box mr={'0.5vw'}>
                          <FormControl size='small' sx={{ width: '5vw', marginBottom: '0.5vw' }}>
                            <InputLabel>Unit</InputLabel>
                            <Select
                              label='unit'
                              value={this.state.graphBased.xAxisUnit}
                              onChange={(e) => {
                                this.state.graphBased.xAxisUnit = e.target.value
                                this.setState({ graphBased: this.state.graphBased })
                              }}
                              MenuProps={{
                                style: { maxHeight: 300 },
                              }}
                            >
                              {this.state.refUnitList?.map((item) => (
                                <MenuItem value={item.unit}>{item.unit}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <div style={{ overflowX: 'scroll', maxWidth: '28vw', maxHeight: '8vh', display: 'flex', marginRight: '0.5vw', paddingTop: '0.55vh' }}>

                          {this.state.graphBased.xValues?.map((item, i) => {
                            return (
                              <Box sx={{ display: 'flex', width: '6.5vw', mr: '0.5vw' }}>
                                <TextField
                                  sx={{
                                    width: '6.5vw', padding: '0.5vh',
                                    '& .MuiInputBase-root': {
                                      height: '4.23vh',
                                    },
                                  }}
                                  size='small'
                                  label={`Value ${i + 1}`}
                                  value={item?.value}
                                  variant="outlined"
                                  onChange={(e) => {
                                    if (CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value === '') {
                                      // if((this.state.graphBased.xValues[i-1] < +e.target.value) || i==0){
                                      item["value"] = e.target.value
                                      this.setState({ graphBased: this.state.graphBased })
                                      // }
                                    }

                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {(i == 0 || i == 1 || i == 2 || i == 3) ? null :
                                          <IconButton
                                            aria-label="clear text"
                                            onClick={() => { this.removeGraphValues(i) }}
                                            edge="end"
                                          >
                                            <CloseIcon color="error" fontSize='1vw' />
                                          </IconButton>}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            )
                          })}
                        </div>
                        <Button
                          startIcon={<AddCircleIcon />}
                          className='eMed_Lab_act_btn'
                          variant='outlined'
                          sx={{ textTransform: 'capitalize', mb: '0.5vw', width: '6.8vw', padding: '5px', fontSize: '0.8rem' }}
                          onClick={() => { this.onAddNewValue() }}
                        >Add Value</Button>
                      </div>
                      <div style={{ display: 'flex', paddingTop: '0.5vw' }}>
                        <TextField
                          sx={{ width: '12vw', marginBottom: "0.5vw", mr: '0.5vw' }}
                          size='small'
                          label="Label Name (Y Axis)"
                          value={this.state.graphBased.yLabelName}
                          variant="outlined"
                          onChange={(e) => {
                            this.state.graphBased.yLabelName = e.target.value
                            this.setState({ graphBased: this.state.graphBased })
                          }} />
                        <Box mr={'0.5vw'}>
                          <FormControl size='small' sx={{ width: '5vw', marginBottom: '0.5vw' }}>
                            <InputLabel>Unit</InputLabel>
                            <Select
                              label='unit'
                              value={this.state.graphBased.yAxisUnit}
                              onChange={(e) => {
                                this.state.graphBased.yAxisUnit = e.target.value
                                this.setState({ graphBased: this.state.graphBased })
                              }}
                              MenuProps={{
                                style: { maxHeight: 300 },
                              }}
                            >
                              {this.state.refUnitList?.map((item) => (
                                <MenuItem value={item.unit}>{item.unit}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      {this.state.graphBased.graph_ref_range.map((item, index) => {
                        return (
                          <>
                            <Box key={index} display={'flex'} mt={'0.5vw'}>
                              <TextField
                                sx={{ width: '10vw', marginBottom: "0.5vw", marginRight: "0.5vw", mt: '0.5vw' }}
                                size='small'
                                label="Range From"
                                value={item?.ref_from}
                                variant="outlined"
                                onChange={(e) => {
                                  if (CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value == '') {
                                    item["ref_from"] = e.target.value
                                    this.setState({ graphBased: this.state.graphBased })
                                  }
                                }}
                                InputProps={{
                                  endAdornment:
                                    <Box width={'2vw'}>
                                      {this.state.graphBased.yAxisUnit?.length > 6 ? <Tooltip placement='top' title={this.state.graphBased.yAxisUnit} arrow><div>{this.state.graphBased.yAxisUnit?.slice(0, 3) + '...'}</div></Tooltip> :

                                        <Typography variant="body2">{this.state.graphBased.yAxisUnit}</Typography>
                                      }
                                    </Box>
                                }}
                              />
                              <TextField
                                sx={{ width: '10vw', marginBottom: "0.5vw", marginRight: "0.5vw", mt: '0.5vw' }}
                                size='small'
                                label="Range To"
                                value={item?.ref_to}
                                variant="outlined"
                                onChange={(e) => {
                                  if (CommonValidation.RangeDecimalNumber(e.target.value) || e.target.value == '') {
                                    item["ref_to"] = e.target.value
                                    this.setState({ graphBased: this.state.graphBased })
                                  }
                                }}
                                InputProps={{
                                  endAdornment:
                                    <Box width={'2vw'}>
                                      {this.state.graphBased.yAxisUnit?.length > 6 ? <Tooltip placement='top' title={this.state.graphBased.yAxisUnit} arrow><div>{this.state.graphBased.yAxisUnit?.slice(0, 3) + '...'}</div></Tooltip> :

                                        <Typography variant="body2">{this.state.graphBased.yAxisUnit}</Typography>
                                      }
                                    </Box>
                                }}
                              />
                              <Typography sx={{ m: '1vw' }}>=</Typography>
                              <TextField
                                sx={{ width: '7.3vw', marginBottom: "0.5vw", marginRight: "0.5vw", mt: '0.5vw' }}
                                size='small'
                                label="Range Type"
                                value={item?.ref_type}
                                variant="outlined"
                                onChange={(e) => {
                                  item["ref_type"] = e.target.value
                                  this.setState({ graphBased: this.state.graphBased })
                                }}
                              />

                              {this.state.graphBased.graph_ref_range?.length > 1 ?
                                <Button className='eMed_Lab_act_btn' onClick={() => {
                                  var { graphBased } = this.state
                                  graphBased.graph_ref_range?.splice(index, 1)
                                  this.setState({
                                    graphBased
                                  })
                                }}>
                                  <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                </Button>
                                : null}
                            </Box>
                          </>
                        )
                      })}

                      <Box>
                        <Button
                          startIcon={<AddCircleIcon />}
                          className='eMed_Lab_act_btn'
                          sx={{ textTransform: 'capitalize' }}
                          onClick={() => { this.onAddNewGraphRef("new test") }}
                        >Add New Parameter</Button>
                      </Box>
                    </div> :
                    this.state.receivedData?.result_type === 9 ?
                      <Box component={'div'} height={'35dvh'} overflow={'auto'}>
                        <Box component={'div'} borderBottom={'2px solid lightgray'}>
                          {this.state.cal_Data?.map((list, listIndex) => {
                            return (
                              <Chip
                                className={`eMed_chip ${((list?.cal_ref_type === this.state.selectedRefData?.cal_ref_type ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'))}`}
                                size="small"
                                label={list?.cal_ref_type}
                                variant={list?.cal_ref_type === this.state.selectedRefData?.cal_ref_type ? 'contained' : 'outlined'}
                                key={listIndex}
                                color={'primary'}
                                clickable
                                onClick={() => { this.setState({ selectedRefData: list }) }}
                                emed_tid={'ref_type'}
                              />
                            )
                          })}
                        </Box>
                        <Box component={'div'} p='0.5vw' height={'20.5dvh'} overflow={'auto'}>
                          {this.state.cal_Data?.map((list, listIndex) => {
                            return (
                              list?.cal_ref_data?.map((item, index) => {
                                if (list?.cal_ref_type === this.state.selectedRefData?.cal_ref_type) {
                                  return (
                                    <Box component={'div'} mb={'0.5vw'} display={'flex'} alignItems={'center'}>
                                      {this.renderTextField('unit', item.cal_ref_unit, 'Unit *', '10vw', true)}
                                      {this.renderTextField('refFrom', item.cal_ref_from, 'Ref. From *', '13vw', false, true, listIndex, index)}
                                      {this.renderTextField('refTo', item.cal_ref_to, 'Ref. To *', '13vw', false, true, listIndex, index)}
                                      {this.renderTextField('status', item.cal_ref_status, 'Status *', '13vw', false, false, listIndex, index)}
                                      <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw', width: '1.5vw' }}
                                        onClick={() => {
                                          if (this.state.cal_Data[listIndex]['cal_ref_data']?.length === 1) {
                                            let refData = [{
                                              cal_ref_from: '',
                                              cal_ref_to: '',
                                              cal_ref_status: '',
                                              cal_ref_unit: this.state.selectedRefData?.cal_ref_type === 'LDL | HDL' ? 'g/dL' : 'mg/dL'
                                            }]
                                            this.state.cal_Data[listIndex]['cal_ref_data'] = refData
                                            this.setState({ cal_Data: this.state.cal_Data })
                                          } else {
                                            this.state.cal_Data[listIndex]['cal_ref_data']?.splice(index, 1)
                                            this.setState({ cal_Data: this.state.cal_Data })
                                          }
                                        }}
                                      >
                                        <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                                      </Button>
                                    </Box>
                                  )
                                }
                              })
                            )
                          })}
                          {this.state.selectedRefData ?
                            <Box component={'div'}>
                              <Button size='small' className='Common_Btn_Min_Width'
                                startIcon={<AddCircleIcon color='primary' />}
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                  let refData = {
                                    cal_ref_from: '',
                                    cal_ref_to: '',
                                    cal_ref_status: '',
                                    cal_ref_unit: this.state.selectedRefData?.cal_ref_type === 'LDL | HDL' ? 'g/dL' : 'mg/dL'
                                  }
                                  let checkRefValue = this.checkRefValue()
                                  if (checkRefValue) {
                                    this.errorMessage(checkRefValue)
                                  } else {
                                    this.state.cal_Data?.filter(item => item?.cal_ref_type === this.state.selectedRefData?.cal_ref_type)?.forEach(element => {
                                      element?.cal_ref_data?.push(refData)
                                    })
                                    this.setState({ cal_Data: this.state.cal_Data })
                                  }
                                }}
                              >Add Reference Range</Button>
                            </Box> : null
                          }
                        </Box>
                      </Box>
                      : <div style={{ paddingLeft: "0.7vw" }}>
                        <Box component={'div'} borderBottom={'1px solid lightgray'} display={'flex'}>
                          <Box mr={'0.5vw'}>
                            <FormControl size='small' sx={{ width: '15vw', marginBottom: '1vw', mt: '0.5vw' }}>
                              <InputLabel>Gender</InputLabel>
                              <Select
                                label='Gender'
                                value={this.state.isEdit ? editRangeData?.gender : range_data?.gender}
                                onChange={(e) => { this.onChangeHandler(e, 'gender') }}
                              >
                                {Gender_List?.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>

                          <Box mr={'0.5vw'}>
                            {this.inputTextBox('ageFrom', this.state.isEdit ? editRangeData?.age_from : range_data.age_from, "Age From", '8vw', null, null, false)}
                            <FormControl size='small' sx={{ width: '7vw', marginBottom: '1vw', mt: '0.5vw', marginLeft: "0.5vw" }}>
                              <Select
                                value={this.state.isEdit ? editRangeData?.age_from_unit : range_data?.age_from_unit}
                                onChange={(e) => { this.onChangeHandler(e, 'fromDays') }}
                              >
                                {Days_List?.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>

                          <Box mr={'0.5vw'}>
                            {this.inputTextBox('ageTo', this.state.isEdit ? editRangeData?.age_to : range_data.age_to, "Age To", '8vw', null, null, false)}
                            <FormControl size='small' sx={{ width: '7vw', marginBottom: '1vw', mt: '0.5vw', marginLeft: "0.5vw" }}>
                              <Select
                                value={this.state.isEdit ? editRangeData?.age_to_unit : range_data.age_to_unit}
                                onChange={(e) => { this.onChangeHandler(e, 'toDays') }}
                              >
                                {Days_List?.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                        {this.state?.receivedData?.result_type === 7 ?
                          <div>
                            <Box component={'div'} paddingTop={'0.5vw'} overflow={'scroll'} maxHeight={'11vw'} height={"11vw"}>
                              {this.state.ref_type.map((item, index) => {
                                return (
                                  <>
                                    <Box key={index} display={'flex'}>
                                      <FormControl size='small' sx={{ width: '5vw', marginBottom: '1vw', mt: '0.5vw', marginRight: "0.5vw" }}>
                                        <InputLabel>Referance Type</InputLabel>
                                        <Select
                                          label='Referance Type'
                                          value={item.reference_type}
                                          onChange={(e) => { this.onChangeHandler(e, 'referanceType', index) }}
                                        >
                                          {Referance_Type_List?.map((item) => (
                                            <MenuItem style={{ textAlign: "center", fontSize: "1vw" }} value={item.value}>{item.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      {this.inputTextBox('refRange', item.reference_range, "Referance Range", '9.4vw', '0.5vw', index, false)}
                                      <Autocomplete
                                        disableClearable
                                        freeSolo
                                        value={item.unit}
                                        options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                        getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                        onChange={(event, newValue) => {
                                          ref_type[index].unit = newValue.unit
                                          this.setState({ ref_type })
                                        }}
                                        onInputChange={(e, val) => {
                                          ref_type[index].unit = val
                                          this.setState({ ref_type })
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            sx={{ width: '8vw', mt: '0.5vw', mr: '0.5vw' }}
                                            label={'Unit'}
                                            size="small"
                                            InputProps={{
                                              ...params.InputProps,
                                              type: 'search',
                                            }}
                                          />
                                        )}
                                      />

                                      {this.inputTextBox('refDescription', item.description, "Description", '23vw', '0.5vw', index, false)}
                                      {receivedData?.result_type === 7 ?
                                        this.state.ref_type?.length > 1 ?
                                          <Button className='eMed_Lab_act_btn' onClick={() => {
                                            var { ref_type } = this.state
                                            ref_type.splice(index, 1)
                                            this.setState({ ref_type })
                                          }}>
                                            <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                          </Button>
                                          : null : null}
                                    </Box>
                                  </>
                                )
                              })}
                              {receivedData?.result_type === 7 ?
                                <Box>
                                  <Button
                                    startIcon={<AddCircleIcon />}
                                    className='eMed_Lab_act_btn'
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={() => { this.onAddNewReferance() }}
                                  >Add New Parameter</Button>
                                </Box> : null
                              }
                            </Box>
                          </div> :
                          <Box component={'div'} paddingTop={'0.5vw'} overflow={'scroll'} maxHeight={'11vw'} height={"11vw"}>
                            {this.state.ref_data.map((item, index) => {
                              return (
                                <>
                                  <Box key={index} display={'flex'}>
                                    {this.inputTextBox('rangeFrom', item.reference_from, "Range From", '7.3vw', '0.5vw', index, false)}
                                    {this.inputTextBox('rangeTo', item.reference_to, "Range To", '7.3vw', '0.5vw', index, false)}
                                    <Autocomplete
                                      disableClearable
                                      freeSolo
                                      value={item.unit}
                                      options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                      getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                      onChange={(event, newValue) => {
                                        ref_data[index].unit = newValue.unit
                                        this.setState({ ref_data })
                                      }}
                                      onInputChange={(e, val) => {
                                        ref_data[index].unit = val
                                        this.setState({ ref_data })
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{ width: '8vw', mt: '0.5vw', mr: '0.5vw' }}
                                          label={'Unit'}
                                          size="small"
                                          InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                          }}
                                        />
                                      )}
                                    />

                                    {this.inputTextBox('description', item.description, "Descriptions", '23vw', '0.5vw', index, false)}
                                    {this.state.ref_data?.length > 1 ?
                                      <Button className='eMed_Lab_act_btn' onClick={() => {
                                        var { ref_data } = this.state
                                        ref_data.splice(index, 1)
                                        this.setState({
                                          ref_data
                                        })
                                      }}>
                                        <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                      </Button>
                                      : null}
                                  </Box>
                                </>
                              )
                            })}

                            {receivedData?.result_type === 1 ?
                              <Box>
                                <Button
                                  startIcon={<AddCircleIcon />}
                                  className='eMed_Lab_act_btn'
                                  sx={{ textTransform: 'capitalize' }}
                                  onClick={() => { this.onAddNewParameter() }}
                                >Add New Parameter</Button>
                              </Box> : null
                            }
                          </Box>
                        }
                      </div>
                }
              </Box>
              : null}
            <Stack id="emedhub_popup_footer" direction="row" justifyContent={"flex-end"} alignItems="center" sx={{ height: "3vw" }}>
              {this.state.ViewScreen == 1 ? null :
                <Button size="small" sx={{ marginRight: "1vw" }} variant={"outlined"} onClick={() => {
                  this.setState({
                    ViewScreen: 0,
                    stepNumber: 0,
                  }, () => { this.handleClose() })
                }}>
                  {"CANCEL"}
                </Button>}
              <Button variant="contained" size="small" emed_tid='ResultConfig_AddNewTest_Next_btn' disabled={this.state.disableBtn} onClick={() => { this.state.ViewScreen == 0 ? this.createNewTest() : this.NewpostNewReferenceData() }}>{this.state.ViewScreen == 0 ? "NEXT" : "ADD TEST"}</Button>
            </Stack>
          </Paper>
        </Modal>
      )
    }
    catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkRefValue = () => {
    try {
      let message = null;
      let filterData = this.state.cal_Data?.find(item => item?.cal_ref_type === this.state.selectedRefData?.cal_ref_type)
      if (
        (filterData?.cal_ref_data[filterData?.cal_ref_data?.length - 1]?.cal_ref_status === '') ||
        (filterData?.cal_ref_data[filterData?.cal_ref_data?.length - 1]?.cal_ref_from === '') ||
        (filterData?.cal_ref_data[filterData?.cal_ref_data?.length - 1]?.cal_ref_to === '')
      ) {
        message = 'Please enter all mandatory field in previous line'
      } else {
        filterData?.cal_ref_data?.forEach(element => {
          if (+element.cal_ref_to <= +element.cal_ref_from) {
            message = 'Reference to value should be greater than reference from value'
          }
        })
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  grpDataLength = (item) => {
    try {
      let length = []
      for (let i = 0; i <= item?.grp_data?.length - 1; i++) {
        length.push(i + 1)
      }
      return length
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkEditDataValidation = () => {
    try {
      let message = null;
      if (this.state.editRefTo === '' || this.state.editRefFrom === '' || this.state.editRefstatus === '') {
        message = 'Enter all mandatory field'
      } else if (+this.state.editRefTo <= +this.state.editRefFrom) {
        message = 'Reference from should be greater than reference to'
      } else if (this.state.editRefFrom && this.state.editRefTo) {
        this.state.calEditData?.cal_ref_data?.forEach(element => {
          if (this.state.editRefFrom === element.cal_ref_from && this.state.editRefTo === element.cal_ref_to) {
            message = 'Reference from and reference to already exists'
          }
        })
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  editCalculationPopup = () => {
    try {
      return (
        <>
          <Modal open={this.state.openCalculationEdit}>
            <Box component={'div'} className='eMed_cal_Edit_config_popup'>
              <Box component={'div'} borderBottom={'1px solid lightgray'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'}>
                <Typography fontWeight={600}>Calculation Based Result</Typography>
                <Button className='Common_Btn_Min_Width'
                  sx={{ width: '1vw', height: '1vw', mr: '0.5vw' }}
                  onClick={() => {
                    this.setState({
                      openCalculationEdit: false,
                      editRefFrom: '', editRefTo: '',
                      editRefstatus: ''
                    })
                  }}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'} p={'0.5vw'}>
                <Typography fontWeight={600} color={Colors.grayShade}>Reference Type : <Typography component={'span'} fontWeight={600} color={'black'}>{this.state.calEditData?.cal_ref_type}</Typography></Typography>
                <Box component={'div'} p={'0.5vw'}>
                  <TextField
                    disabled
                    sx={{ width: '10vw', mr: '0.5vw' }}
                    size='small'
                    label='Unit *'
                    value={this.state.calEditData?.cal_ref_type === 'LDL | HDL' ? 'g/dL' : 'mg/dL'}
                  />
                  {this.renderTextField('editRefFrom', this.state.editRefFrom, 'Ref. From *', '12vw', false, true)}
                  {this.renderTextField('editRefTo', this.state.editRefTo, 'Ref. To *', '12vw', false, true)}
                  {this.renderTextField('editRefstatus', this.state.editRefstatus, 'Status *', '12vw', false, false)}
                  <Button variant='contained' sx={{ textTransform: 'capitalize' }}
                    onClick={() => {
                      let checkEditData = this.checkEditDataValidation()
                      if (checkEditData) {
                        this.errorMessage(checkEditData)
                      } else {
                        this.state.calEditData?.cal_ref_data?.push({
                          cal_ref_from: this.state.editRefFrom,
                          cal_ref_to: this.state.editRefTo,
                          cal_ref_status: this.state.editRefstatus,
                          cal_ref_unit: this.state.calEditData?.cal_ref_type === 'LDL | HDL' ? 'g/dL' : 'mg/dL'
                        })
                        this.setState({ calEditData: this.state.calEditData }, () => {
                          this.setState({
                            editRefFrom: '',
                            editRefTo: '',
                            editRefstatus: ''
                          })
                        })
                      }
                    }}
                  >Add</Button>
                </Box>
              </Box>
              <Box component={'div'} p={'0.5vw'}>
                <Box component={'div'} border={'1px solid lightgray'} height={'30dvh'} overflow={'auto'}>
                  <Table stickyHeader size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'20%'} sx={{ fontWeight: '600' }}>Reference Unit</TableCell>
                        <TableCell width={'20%'} sx={{ fontWeight: '600' }}>Reference From</TableCell>
                        <TableCell width={'20%'} sx={{ fontWeight: '600' }}>Reference To</TableCell>
                        <TableCell width={'20%'} sx={{ fontWeight: '600' }}>Reference Status</TableCell>
                        <TableCell width={'20%'} sx={{ fontWeight: '600' }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.calEditData?.cal_ref_data?.length > 0 ? this.state.calEditData?.cal_ref_data?.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{item?.cal_ref_unit}</TableCell>
                            <TableCell>{item?.cal_ref_from}</TableCell>
                            <TableCell>{item?.cal_ref_to}</TableCell>
                            <TableCell>{item?.cal_ref_status}</TableCell>
                            <TableCell>
                              <Box>
                                <Button sx={{ height: '1.5vw', width: '1.5vw' }} className='Common_Btn_Min_Width'
                                  onClick={() => {
                                    this.state.calEditData?.cal_ref_data?.splice(index, 1)
                                    this.setState({ calEditData: this.state.calEditData })
                                  }}
                                >
                                  <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      }) : <TableRow>
                        <TableCell colSpan={5} align='center' sx={{ borderBottom: 'none' }}>No Records Found</TableCell>
                      </TableRow>}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'flex-end'} p={'0.5vw'}>
                <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '0.5vw' }}
                  onClick={() => {
                    this.setState({
                      calEditData: JSON.parse(this.state.dupCalEditData),
                      editRefFrom: '', editRefTo: '',
                      editRefstatus: ''
                    })
                  }}
                >Reset</Button>
                <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '0.5vw' }}
                  disabled={this.state.postLoad}
                  onClick={() => { this.postCalculationEditData() }}
                >Save</Button>
              </Box>
            </Box>
          </Modal>
        </>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addNewRefCalculationPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.openAddNewCalReference}>
            <Box component={'div'} className='emedhub_Cal_popup'>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'} borderBottom={'2px solid lightgray'}>
                <Typography fontWeight={600}>Add New Reference</Typography>
                <Button sx={{ height: '1vw', width: '1vw', mr: '0.5vw' }}
                  className='Common_Btn_Min_Width'
                  onClick={() => this.setState({
                    openAddNewCalReference: false,
                    cal_Data: JSON.parse(this.state.addNewRefCalData),
                    editRefFrom: '', editRefTo: '',
                    editRefstatus: ''
                  })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'} height={'55dvh'} overflow={'auto'}>
                <Box component={'div'} borderBottom={'2px solid lightgray'} p={'0.5vw'}>
                  <Typography fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}>Reference Types</Typography>
                  {this.state.cal_Data?.map((list, listIndex) => {
                    return (
                      <Chip
                        className={`eMed_chip ${((list?.cal_ref_type === this.state.selectedRefData?.cal_ref_type ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'))}`}
                        size="small"
                        label={list?.cal_ref_type}
                        variant={list?.cal_ref_type === this.state.selectedRefData?.cal_ref_type ? 'contained' : 'outlined'}
                        key={listIndex}
                        color={'primary'}
                        clickable
                        onClick={() => { this.setState({ selectedRefData: list }) }}
                      />
                    )
                  })}
                </Box>
                <Box component={'div'} p='0.5vw' height={'43dvh'} overflow={'auto'}>
                  {this.state.cal_Data?.map((list, listIndex) => {
                    return (
                      list?.cal_ref_data?.map((item, index) => {
                        if (list?.cal_ref_type === this.state.selectedRefData?.cal_ref_type) {
                          return (
                            <Box component={'div'} mb={'0.5vw'} display={'flex'} alignItems={'center'}>
                              {this.renderTextField('unit', item.cal_ref_unit, 'Unit *', '10vw', true)}
                              {this.renderTextField('refFrom', item.cal_ref_from, 'Ref. From *', '13vw', false, true, listIndex, index)}
                              {this.renderTextField('refTo', item.cal_ref_to, 'Ref. To *', '13vw', false, true, listIndex, index)}
                              {this.renderTextField('status', item.cal_ref_status, 'Status *', '13vw', false, false, listIndex, index)}
                              <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw', width: '1.5vw' }}
                                onClick={() => {
                                  if (this.state.cal_Data[listIndex]['cal_ref_data']?.length === 1) {
                                    let refData = [{
                                      cal_ref_from: '',
                                      cal_ref_to: '',
                                      cal_ref_status: '',
                                      cal_ref_unit: this.state.selectedRefData?.cal_ref_type === 'LDL | HDL' ? 'g/dL' : 'mg/dL'
                                    }]
                                    this.state.cal_Data[listIndex]['cal_ref_data'] = refData
                                    this.setState({ cal_Data: this.state.cal_Data })
                                  } else {
                                    this.state.cal_Data[listIndex]['cal_ref_data']?.splice(index, 1)
                                    this.setState({ cal_Data: this.state.cal_Data })
                                  }
                                }}
                              >
                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                              </Button>
                            </Box>
                          )
                        }
                      })
                    )
                  })}
                  {this.state.selectedRefData ?
                    <Box component={'div'}>
                      <Button size='small' className='Common_Btn_Min_Width'
                        startIcon={<AddCircleIcon color='primary' />}
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => {
                          let refData = {
                            cal_ref_from: '',
                            cal_ref_to: '',
                            cal_ref_status: '',
                            cal_ref_unit: this.state.selectedRefData?.cal_ref_type === 'LDL | HDL' ? 'g/dL' : 'mg/dL'
                          }
                          let checkRefValue = this.checkRefValue()
                          if (checkRefValue) {
                            this.errorMessage(checkRefValue)
                          } else {
                            this.state.cal_Data?.filter(item => item?.cal_ref_type === this.state.selectedRefData?.cal_ref_type)?.forEach(element => {
                              element?.cal_ref_data?.push(refData)
                            })
                            this.setState({ cal_Data: this.state.cal_Data })
                          }
                        }}
                      >Add Reference Range</Button>
                    </Box> : null
                  }
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'} borderTop={'2px solid lightgray'}>
                <Button
                  sx={{ textTransform: 'capitalize', mr: '0.5vw', height: '2vw' }}
                  variant='contained'
                  disabled={this.state.postLoad}
                  onClick={() => { this.postCalculationEditData(true) }}
                >Save</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderResultConfigTable = () => {
    let commonDisable = CheckAccessFunc("Laboratory", "Configuration", "Result Configuration",  null, "Tab")?.editAccess
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
    try {
      return (
        <Box component={'div'} className='eMed_Lab_Rst_Tbl_Container' overflow={'scroll'}>
          <Table stickyHeader size='small' >
            <TableHead>
              <TableRow>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '14vw' }}>Department</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '14vw' }}>Group Name</TableCell>
                {/* <TableCell className='eMed_Main_Tbl_head' sx={{ width: '10vw' }}>
                  <Box display={'flex'}>
                    <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Print Order No.</Typography>
                    {!this.state.isPrintOrderEdit && (this.state.resultConfigData?.length === 1) && (this.state.resultConfigData[0]?.grp_data?.length > 1)  ? 
                      <Button className='eMed_Lab_act_btn'
                        sx={{ width: '1vw', height: '1vw', paddingTop: '0.6vw', marginLeft: '0.3vw' }}
                        onClick={() => this.setState({ isPrintOrderEdit: true })}
                      >
                        <Box component={'img'} src={ImagePaths.Lab_New_Green_Edit.default} height={'1vw'} width={'1vw'} />
                      </Button> : (this.state.resultConfigData?.length === 1) && (this.state.resultConfigData[0]?.grp_data?.length > 1) ?
                        <Button className='eMed_Lab_act_btn'
                          sx={{ width: '1vw', height: '1vw', paddingTop: '0.6vw', marginLeft: '0.3vw' }}
                          onClick={() => this.putNewReferenceData()}
                        >
                          <Box component={'img'} src={ImagePaths.Lab_New_Save.default} height={'1vw'} width={'1vw'} />
                        </Button>
                        : null}
                  </Box>
                </TableCell> */}
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '18vw' }}>Test Name</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '10vw' }}>Gender/Age</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '12.5vw' }}>Referrence Range</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '15vw' }}>Description</TableCell>
                <TableCell className='eMed_Main_Tbl_head' align='center'>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.resultConfigData?.length > 0 ? this.state.resultConfigData.map((item, deptIndex) => (
                <TableRow>
                  <TableCell colSpan={8} key={deptIndex} sx={{ padding: '0vw' }}>
                    <Table key={deptIndex}>
                      <TableCell className='eMed_Tbl_rst_Cell_One' style={{ width: "14vw" }}>
                        <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "0.5vw" }}>Department Name</div>
                        <Typography>{item?.dept_name}</Typography>
                        <Box flex={0.5} sx={{ marginTop: "0.5vw" }}>
                          <Typography color={'gray'} fontSize={'0.8vw'}>Print Order No.</Typography>
                          {item?.print_order ?
                            <Typography fontWeight={600}>{item?.print_order}</Typography> : '-'}
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt={'0.5vw'} marginLeft={'0.5vw'}>
                          <Button
                            disabled={!commonDisable}
                            className='eMed_Lab_act_btn'
                            sx={{ height: '1.5vw', width: '1.5vw' }}
                            onClick={() => { this.setState({ EditDepName: item?.dept_name, EditDepId: item?.dept_id, DepPrintOrder: item?.print_order, isEditDepart: true }, () => { this.setState({ EditPopup: true }) }) }}
                          >
                            <Tooltip placement='top' title='Edit' arrow>
                              <Box component={'img'} src={commonDisable ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} mr={'1vw'} />
                            </Tooltip>
                          </Button>
                          <Button
                            disabled={!commonDisable}
                            className='eMed_Lab_act_btn'
                            sx={{ height: '1.5vw', width: '1.5vw' }}
                            onClick={() => { this.setState({ DltTitle: "Department", selectedvalue: item?.dept_id }, () => { this.setState({ deletePopup: true }) }) }}
                          >
                            <Tooltip placement='top' title='Delete' arrow>
                              <Box component={'img'} src={commonDisable ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                            </Tooltip>
                          </Button>
                        </Box>
                      </TableCell>

                      {item?.grp_data?.map((grpData, grpIndex) => (
                        <Table key={grpIndex}>
                          <TableCell className='eMed_Tbl_rst_Cell_One' style={{ width: "14vw" }}>
                            <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "0.5vw" }}>Group Name</div>
                            <Typography>{grpData?.grp_name}</Typography>
                            <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "0.5vw" }}>Group Amount</div>
                            <Typography>{CurrencySymbol} {(grpData?.grp_amt).toFixed(2)}</Typography>
                            <Box flex={0.5} sx={{ marginTop: "0.5vw" }}>
                              <Typography color={'gray'} fontSize={'0.8vw'}>Print Order No.</Typography>
                              {grpData?.print_order ?
                                <Typography fontWeight={600}>{grpData?.print_order}</Typography> : '-'}
                            </Box>
                            {showCode ?
                            <Box flex={0.5} sx={{ marginTop: "0.5vw" }}>
                              <Typography color={'gray'} fontSize={'0.8vw'}>Group Code</Typography>
                              {grpData?.grp_test_code ? <Typography fontWeight={600}>{grpData?.grp_test_code}</Typography> : '-'}
                            </Box> : null}
                            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt={'0.5vw'} marginLeft={'0.5vw'}>
                              <Button
                                disabled={!commonDisable}
                                className='eMed_Lab_act_btn'
                                sx={{ height: '1.5vw', width: '1.5vw' }}
                                onClick={() => {this.setState({ EditDepId: item?.dept_id, EditGrpId: grpData.grp_id, EditGrpAmt: grpData.grp_amt, EditGrpName: grpData.grp_name, EditPrintOrder: grpData.print_order, EditDepName: item.dept_name, isEditGrp: true, EditGrpCode:grpData?.grp_test_code ? grpData?.grp_test_code : '' }, () => { this.setState({ EditPopup: true }) }) }}
                              >
                                <Tooltip placement='top' title='Edit' arrow>
                                  <Box component={'img'} src={commonDisable ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} mr={'1vw'} />
                                </Tooltip>
                              </Button>
                              <Button
                                disabled={!commonDisable}
                                className='eMed_Lab_act_btn'
                                sx={{ height: '1.5vw', width: '1.5vw' }}
                                onClick={() => { this.setState({ DltTitle: "Group", selectedvalue: grpData?.grp_id }, () => { this.setState({ deletePopup: true }) }) }}
                              >
                                <Tooltip placement='top' title='Delete' arrow>
                                  <Box component={'img'} src={commonDisable ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                              </Button>
                            </Box>
                          </TableCell>

                          {/* <TableCell className='eMed_Tbl_rst_Cell_One'>
                            {this.state.isPrintOrderEdit ?
                              <Autocomplete
                                value={grpData?.print_order}
                                options={this.grpDataLength(item)}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  var { resultConfigData } = this.state
                                  resultConfigData[deptIndex].grp_data[grpIndex].print_order = newValue
                                  this.setState({
                                    resultConfigData
                                  })
                                }}
                                renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '5vw' }} label='Print No.' />)}
                              />
                              :
                              <Typography>{grpData?.print_order}</Typography>}

                          </TableCell> */}

                          <TableCell sx={{ padding: '0vw' }}>
                            {grpData?.grp_test_data?.map((testData, testIndex) => (
                              <Table key={testIndex}>

                                <TableCell className='eMed_Tbl_rst_Cell_Two'>
                                  <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} flexDirection={"row"} >
                                      <Box component={'div'} mb={'0.5vw'}>
                                        {testData?.test_name ? testData?.test_name?.length > 40 ?
                                          <Tooltip placement='top' title={testData?.test_name}><Box>{testData?.test_name.slice(0, 40) + '...'}</Box></Tooltip> :
                                          <Typography fontWeight={600}>{testData?.test_name}</Typography> : '-'}
                                      </Box>
                                      <img style={{ marginLeft: "0.5vw", marginTop: "0.1vw", width: "1.2vw", height: "1.2vw" }} className="eMed_DeletePopup_CrossIcon" src={ImagePaths.DeleteOutline.default} alt='Close'
                                        onClick={() => {
                                          if (commonDisable) {
                                            this.setState({ DltTitle: "Test", selectedvalue: testData?.test_id }, () => { this.setState({ deletePopup: true }) })
                                          }
                                        }} />
                                    </Box>
                                    <Box component={'div'} display={'flex'} mb={'0.5vw'}>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Specimen</Typography>
                                        {testData?.specimen ? testData?.specimen?.length > 12 ?
                                          <Tooltip placement='top' title={testData?.specimen}><Box>{testData?.specimen.slice(0, 12) + '...'}</Box></Tooltip> :
                                          <Typography fontWeight={600}>{testData?.specimen}</Typography> : '-'}
                                      </Box>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Amount</Typography>
                                        {testData?.test_amt ?
                                          <Typography fontWeight={600}>{testData?.test_amt}</Typography> : '-'}
                                      </Box>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mb={'0.5vw'}>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Result Type</Typography>
                                        {testData?.result_type ?
                                          <Tooltip placement='top' title={testData?.result_type === 1 ? 'Range Based' : testData?.result_type === 2 ? 'Positive/Negative' : testData?.result_type === 3 ? "Culture Test" : testData?.result_type === 5 ? 'Text Based' : testData?.result_type === 6 ? "Present/Absent" : testData?.result_type === 8 ? "Graph Based" : testData?.result_type === 9 ? 'Calculation' : "Greater than/Less than"}>
                                            <Box>{testData?.result_type === 1 ? 'Range Based' : testData?.result_type === 2 ? 'Positive/Neg...' : testData?.result_type === 3 ? "Culture Test" : testData?.result_type === 5 ? 'Text Based' : testData?.result_type === 6 ? "Present/Absent" : testData?.result_type === 8 ? "Graph Based" : testData?.result_type === 9 ? 'Calculation' : "Greater than/Les..."}</Box></Tooltip> : '-'}
                                      </Box>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Method</Typography>
                                        {testData?.method ? testData?.method?.length > 15 ?
                                          <Tooltip placement='top' title={testData?.method}><Box>{testData?.method.slice(0, 15) + '...'}</Box></Tooltip> :
                                          <Typography fontWeight={600}>{testData?.method}</Typography> : '-'}
                                      </Box>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mb={'0.5vw'}>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Print Order No.</Typography>
                                        {testData?.print_order ?
                                          <Typography fontWeight={600}>{testData?.print_order}</Typography> : '-'}
                                      </Box>
                                      {showCode ?
                                        <Box flex={0.5}>
                                          <Typography color={'gray'} fontSize={'0.8vw'}>Test Code</Typography>
                                          {testData?.test_code ? <Typography fontWeight={600}>{testData?.test_code}</Typography> : '-'}
                                        </Box> :
                                        <Box flex={0.5}>
                                          <Typography color={'gray'} fontSize={'0.8vw'}>Test ID</Typography>
                                          {testData?.test_id ? <Typography fontWeight={600}>{testData?.test_id}</Typography> : '-'}
                                        </Box>}
                                    </Box>
                                    <Box component={'div'} mb={'0.5vw'}>
                                      <Button startIcon={<EditRounded />}
                                        sx={{ textTransform: 'capitalize' }}
                                        disabled={!commonDisable}
                                        className='eMed_Lab_act_btn'
                                        onClick={() => {
                                          this.setState({
                                            isEditTestDetails: true,
                                            NewEditTestDetails: true
                                          }, () => {
                                            this.setState({
                                              ref_data: [{
                                                reference_from: '',
                                                reference_to: '',
                                                description: '',
                                                unit: ''
                                              }], range_data: {
                                                gender: '',
                                                age_from: '',
                                                age_to: '',
                                                age_from_unit: 'years',
                                                age_to_unit: 'years'
                                              },
                                              selectedData: null
                                            }, () => { this.handleOpenPopup(testData, grpData, item) })
                                          })
                                        }}
                                      >Edit Test Details</Button>
                                    </Box>
                                    <Box component={'div'} mb={'0.5vw'}>
                                      {testData?.result_type === 9 ?
                                        <Button startIcon={<AddCircleIcon />} emed_tid='cal_add_rfe_type'
                                          sx={{ textTransform: 'capitalize' }}
                                          disabled={!commonDisable}
                                          className='eMed_Lab_act_btn'
                                          onClick={() => {
                                            let filterCalData = []; let calHash = {};
                                            testData?.test_data?.forEach(element => calHash[element.cal_ref_type] = element)
                                            this.state.cal_Data?.forEach(element => {
                                              if (!calHash[element.cal_ref_type]) {
                                                filterCalData?.push(element)
                                              }
                                            })
                                            if (filterCalData?.length > 0) {
                                              this.setState({ cal_Data: filterCalData }, () => {
                                                this.setState({
                                                  openAddNewCalReference: true,
                                                  calGrpData: grpData,
                                                  calTstData: testData
                                                })
                                              })
                                            } else {
                                              this.errorMessage('You have already configured all Reference types')
                                            }
                                          }}
                                        >Add New Reference Type</Button>
                                        :
                                        <Button startIcon={<AddCircleIcon />}
                                          sx={{ textTransform: 'capitalize' }}
                                          disabled={!commonDisable}
                                          className='eMed_Lab_act_btn'
                                          onClick={() => { this.handleOpenPopup(testData, grpData) }}
                                        >Add New Referrence</Button>}
                                    </Box>
                                  </Box>
                                </TableCell>
                                {testData?.result_type === 9 ?
                                  <TableCell sx={{ padding: '0vw' }}>
                                    {testData?.test_data?.map((rangeData, rangeIndex) => {
                                      return (
                                        <Table key={rangeIndex}>
                                          <TableCell colSpan={3} style={{ fontSize: "1vw", fontWeight: "600" }}>{`Kindly click the action icon to view ${rangeData?.cal_ref_type}`}</TableCell>
                                          <TableCell>
                                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                              <Button className='eMed_Lab_act_btn' disabled={!commonDisable} emed_tid='cal_edit_btn'
                                                sx={{ height: '1.5vw', width: '1.5vw', mr: '0.5vw' }}
                                                onClick={() => {
                                                  this.setState({
                                                    openCalculationEdit: true,
                                                    calEditData: JSON.parse(JSON.stringify(rangeData)),
                                                    calTestData: testData,
                                                    dupCalEditData: JSON.stringify(rangeData),
                                                    dupCalTestData: JSON.stringify(testData)
                                                  })
                                                }}
                                              >
                                                <Box component={'img'} src={ImagePaths.Edit.default} height={'1.5vw'} width={'1.5vw'} />
                                              </Button>
                                              <Button className='eMed_Lab_act_btn' disabled={!commonDisable} emed_tid='cal_del_btn'
                                                sx={{ height: '1.5vw', width: '1.5vw', mr: '0.5vw' }}
                                                onClick={() => {
                                                  if (testData?.test_data?.length > 1) {
                                                    this.deleteNewReferenceData(testData, rangeIndex)
                                                  } else {
                                                    this.errorMessage('Reference Data Should be Atleast One')
                                                  }
                                                }}
                                              >
                                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                                              </Button>
                                            </Box>
                                          </TableCell>
                                        </Table>
                                      )
                                    })}
                                  </TableCell>
                                  :
                                  <TableCell sx={{ padding: '0vw' }}>
                                    {testData?.test_data?.map((rangeData, rangeIndex) => (
                                      <Table key={rangeIndex}>
                                        {testData?.result_type == 3 && rangeIndex == 0 ?
                                          <TableCell style={{ fontSize: "1vw", fontWeight: "600" }}>Kindly click the action icon to view the bacteria details</TableCell>
                                          : null}
                                        {testData?.result_type !== 3 ?
                                          <div>
                                            <TableCell className='eMed_Tbl_rst_Cell_One'>
                                              {rangeData?.gender ?
                                                <Box>
                                                  <Typography >
                                                    {`${rangeData?.gender === 'M' ? 'Male' : rangeData?.gender === 'F' ? 'Female' : 'Common'}`}
                                                  </Typography>
                                                  <Typography fontSize={'0.7vw'}>
                                                    {`${rangeData?.age_from} ${rangeData?.age_from_unit} - ${rangeData?.age_to} ${rangeData?.age_to_unit}`}
                                                  </Typography>
                                                </Box>
                                                : '-'}
                                            </TableCell>

                                            <TableCell sx={{ padding: '0vw' }}>
                                              {rangeData?.reference_data ?
                                                rangeData?.reference_data?.map((refData, refIndex) => (
                                                  <Table key={refIndex}>

                                                  <TableCell className='eMed_Tbl_rst_Cell_Three'>
                                                    {refData?.reference_type ?
                                                      (refData?.reference_type && refData?.reference_range) ? (refData?.unit + refData?.reference_type + refData?.reference_range)?.length > 18 ?
                                                        <Tooltip placement='top' title={`${refData?.reference_type ? refData?.reference_type : '-'}  ${refData?.reference_range ? refData?.reference_range : '-'} (${refData?.unit ? refData?.unit : '-'})`}>
                                                          <Box>{(`${refData?.reference_type ? refData?.reference_type : '-'}  ${refData?.reference_range ? refData?.reference_range : '-'} (${refData?.unit ? refData?.unit : '-'})`)?.slice(0, 18) + '...'}</Box>
                                                        </Tooltip> :
                                                        <Typography>{`${refData?.reference_type ? refData?.reference_type : '-'}  ${refData?.reference_range ? refData?.reference_range : '-'} (${refData?.unit ? refData?.unit : '-'})`}</Typography> : '-'
                                                      :
                                                      // (refData?.reference_from && refData?.reference_to) ? (refData?.unit + refData?.reference_from + refData?.reference_to)?.length > 18 ?
                                                      (refData?.reference_from || (refData?.reference_from && refData?.reference_to)) ? (refData?.unit + refData?.reference_from + refData?.reference_to)?.length > 18 ?
                                                        <Tooltip placement='top' title={`${refData?.reference_from ? refData?.reference_from : '-'} - ${refData?.reference_to ? refData?.reference_to : '-'} (${refData?.unit ? refData?.unit : '-'})`}>
                                                          <Box>{(`${refData?.reference_from ? refData?.reference_from : '-'} - ${refData?.reference_to ? refData?.reference_to : '-'} (${refData?.unit ? refData?.unit : '-'})`)?.slice(0, 18) + '...'}</Box>
                                                        </Tooltip> :
                                                        <Typography>{`${refData?.reference_from ? refData?.reference_from : '-'} - ${refData?.reference_to ? refData?.reference_to : '-'} (${refData?.unit ? refData?.unit : '-'})`}</Typography> : '-'
                                                    }
                                                  </TableCell>

                                                    <TableCell className='eMed_Tbl_rst_Cell_four'>
                                                      {refData?.description?.length > 25 ?
                                                        <Tooltip placement='top' title={refData?.description}><Box>{refData?.description.slice(0, 25) + '...'}</Box></Tooltip> :
                                                        <Typography>{refData?.description ? refData?.description : '-'}</Typography>}
                                                    </TableCell>

                                                  </Table>
                                                )) :
                                                rangeData?.ranges ?
                                                  rangeData?.ranges?.map((refData, refIndex) => (
                                                    <Table key={refIndex}>
                                                      <TableCell className='eMed_Tbl_rst_Cell_Three'>
                                                        {(refData?.ref_from && refData?.ref_to) ? (rangeData?.unit_y + refData?.ref_from + refData?.ref_to)?.length > 18 ?
                                                          <Tooltip placement='top' title={`${refData?.ref_from ? refData?.ref_from : '-'} - ${refData?.ref_to ? refData?.ref_to : '-'} (${rangeData?.unit_y ? rangeData?.unit_y : '-'})`}>
                                                            <Box>{(`${refData?.ref_from ? refData?.ref_from : '-'} - ${refData?.ref_to ? refData?.ref_to : '-'} (${rangeData?.unit_y ? rangeData?.unit_y : '-'})`)?.slice(0, 18) + '...'}</Box>
                                                          </Tooltip> :
                                                          <Typography>{`${refData?.ref_from ? refData?.ref_from : '-'} - ${refData?.ref_to ? refData?.ref_to : '-'} (${rangeData?.unit_y ? rangeData?.unit_y : '-'})`}</Typography> : '-'
                                                        }
                                                      </TableCell>

                                                      <TableCell className='eMed_Tbl_rst_Cell_four'>
                                                        {refData?.ref_type?.length > 25 ?
                                                          <Tooltip placement='top' title={refData?.ref_type}><Box>{refData?.ref_type?.slice(0, 25) + '...'}</Box></Tooltip> :
                                                          <Typography>{refData?.ref_type ? refData?.ref_type : '-'}</Typography>}
                                                      </TableCell>

                                                    </Table>
                                                  )) :
                                                  <div style={{ width: "22vw", paddingLeft: "35%" }}>-</div>}
                                            </TableCell>
                                          </div> : null}

                                        {testData?.result_type !== 3 || (testData?.result_type == 3 && rangeIndex == 0) ?
                                          <TableCell sx={{ paddingTop: '0vw' }}>
                                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                              <Button
                                                className='eMed_Lab_act_btn'
                                                disabled={!commonDisable}
                                                sx={{ height: '1.5vw', width: '1.5vw' }}
                                                onClick={() => {
                                                  this.setState({
                                                    isEdit: true,
                                                    selectedEditIndex: rangeIndex,
                                                  }, () => this.handleOpenPopup(testData, rangeData))
                                                }}
                                              >
                                                <Tooltip placement='top' title='Edit' arrow>
                                                  <Box component={'img'} src={commonDisable ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} mr={'1vw'} />
                                                </Tooltip>
                                              </Button>
                                              <Button
                                                disabled={!commonDisable}
                                                className='eMed_Lab_act_btn'
                                                sx={{ height: '1.5vw', width: '1.5vw' }}
                                                onClick={() => {
                                                  if (testData?.test_data?.length > 1) {
                                                    this.deleteNewReferenceData(testData, rangeIndex)
                                                  } else {
                                                    this.errorMessage('Reference Data Should be Atleast One')
                                                  }
                                                }}
                                              >
                                                <Tooltip placement='top' title='Delete' arrow>
                                                  <Box component={'img'} src={commonDisable ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                                                </Tooltip>
                                              </Button>
                                            </Box>
                                          </TableCell> : null}

                                      </Table>
                                    ))}
                                  </TableCell>
                                }
                              </Table>
                            ))}
                          </TableCell>
                        </Table>
                      ))}
                    </Table>
                  </TableCell>
                </TableRow>
              )) : <TableRow>
                <TableCell colSpan={8} sx={{ borderBottom: 'none' }}>
                  <Typography>No Records Found</Typography></TableCell>
              </TableRow>}
            </TableBody>

          </Table>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let commonDisable = CheckAccessFunc("Laboratory", "Configuration", "Result Configuration",  null, "Tab")?.editAccess
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'80vh'} p={'0.5vw'}>
        <Box component={'div'} p={'0.5vw'} sx={{ backgroundColor: 'white' }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box component={'div'} display={'flex'} alignItems={'center'}>
            <Box>
              <Autocomplete
                size='small'
                clearIcon={false}
                options={this.state.departmentListData}
                getOptionLabel={(option) => option?.dept_name}
                value={this.state.departmentName}
                onChange={(e, newValue) => {
                  this.setState({ departmentName: newValue, groupName: null, grpListData: [] }, () => {
                    this.getNewReferrenceData()
                  })
                }}
                sx={{ width: "20vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Select Department' onChange={(e) => {
                  if (e.target.value === "") {
                    this.setState({
                      groupName: null, grpListData: [], departmentName: this.state.departmentName
                    })
                  }
                }} />)}
              />
            </Box>
            <Box marginLeft={'1vw'}>
              <Autocomplete
                size='small'
                clearIcon
                disabled={this.state.departmentName == null ? true : false}
                options={this.state.departmentName !== null ? this.state.grpListData : []}
                getOptionLabel={(option) => `${showCode ? option?.grp_test_code ? `${option?.grp_test_code} / ` : '' : ''} ${option?.grp_name}`}
                value={this.state.groupName ? this.state.groupName : null}
                onChange={(e, newValue) => {
                  this.setState({ groupName: newValue }, () => {
                    var duplicate = JSON.parse(this.state.duplicateResult)
                    if (this.state.departmentName) {
                      duplicate?.filter(element => element.dept_id === this.state.departmentName?.dept_id ? duplicate = [element] : duplicate)
                    }
                    let data = []
                    if (this.state.groupName) {
                      duplicate[0].grp_data.forEach(element => {
                        if (element.grp_id === this.state.groupName?.grp_id) {

                          data.push(element)
                        }
                      })
                      duplicate[0].grp_data = data
                    }
                    this.setState({ resultConfigData: duplicate, testListData: [], testName: null }, () => { this.setState({ testListData: data?.[0]?.grp_test_data }) })
                  })
                }}
                sx={{ width: '20vw' }}
                renderInput={(params) => (<TextField {...params} label='Select Group Name' onChange={(e) => {
                  if (e.target.value === "") {
                    this.setState({
                      groupName: null, testListData: [], testName: null
                    })
                  }
                }} />)}
              />
            </Box>
            <Box marginLeft={'1vw'}>
              <Autocomplete
                size='small'
                disabled={this.state.groupName == null ? true : false}
                clearIcon
                options={(this.state?.grpListData != [] && this.state.grpListData.length > 0) ? this.state?.testListData : []}
                getOptionLabel={(option) => `${showCode ? option?.test_code ? `${option?.test_code} / ` : '' : ''}${option?.test_name}`}
                value={this.state?.testName ? this.state?.testName : null}
                onChange={(e, newValue) => {
                  this.setState({ testName: newValue }, () => {
                    var duplicate = JSON.parse(this.state.duplicateResult)
                    if (this.state.departmentName) {
                      duplicate?.filter(element => element.dept_id === this.state.departmentName?.id ? duplicate = [element] : duplicate)
                    }
                    if (this.state.groupName) {
                      duplicate[0]?.grp_data?.filter(element => element.grp_id === this.state.groupName?.grp_id ? duplicate[0].grp_data = [element] : duplicate)
                    }
                    let data = []
                    if (this.state.testName) {
                      duplicate[0].grp_data[0].grp_test_data.forEach(element => {
                        if (element.test_id === this.state?.testName?.test_id) {
                          data.push(element)
                        }
                      })
                      duplicate[0].grp_data[0].grp_test_data = data
                    }

                    this.setState({ resultConfigData: duplicate })
                  })
                }}
                sx={{ width: '20vw' }}
                renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
              />
            </Box>
          </Box>
          <Box display={'flex'} gap={'1vw'}>
            {/* <Tooltip title="Export" placement='top' arrow>
            <Button onClick={() => { this.getResultConfigExcel() }} disabled={this.state.disableBtn} className='eMed_usrconf_btn'>
              <img src={ImagePaths.ExportIcon.default} alt="upload" className='eMed_action_img' />
            </Button>
          </Tooltip> */}
            <Button className='eMed_Lab_act_btn' disabled={!commonDisable} variant='contained' onClick={() => {
              this.setState({
                ref_data: [{
                  reference_from: '',
                  reference_to: '',
                  description: '',
                  unit: ''
                }], range_data: {
                  gender: '',
                  age_from: '',
                  age_to: '',
                  age_from_unit: 'years',
                  age_to_unit: 'years'
                },
                selectedData: null
              }, () => { this.setState({ addTestPopup: true }) })
            }}>{"ADD NEW TEST"}</Button>
          </Box>
        </Box>
        <Box component={'div'}>
          {this.renderResultConfigTable()}
          {this.newReferrencePopup()}
        </Box>
        {this.state.EditPopup ?
          <div>
            {this.EditDialogBox()}
          </div>
          : null}
        {this.state.EditCultureTest ?
          <div>
            {this.EditCulturalTestBox()}
          </div> : null}
        {this.state.addTestPopup ?
          <Box component={'div'}>
            {this.renderAddNewTestPopup()}
          </Box>
          : null}
        {this.state.openCalculationEdit ? this.editCalculationPopup() : null}
        {this.state.openAddNewCalReference ? this.addNewRefCalculationPopup() : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <ReasonPopup
          title={`Are You Sure ? Do You Want to remove this ${this.state.DltTitle}`}
          AlertPopupClose={this.popupClose.bind(this)}
          label={'Reason'}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          DeleteNotify={''}
          btnvarient1={'contained'}
          sendCmt={this.addCancelComments.bind(this)}
          btntextDis={this.state.disableBtn}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
