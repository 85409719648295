import React, { Component } from 'react'
import { Box, Button, Drawer, Stack, Tooltip, Divider, Typography, TextField, Autocomplete } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc, formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { DateTime } from 'luxon';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CurrencySymbol } from '../../../Utility/Constants';


class PharmaSubStockList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      rowCount: 0,
      StockList: [],
      drug_name: "",
      subLocation: null,
      subLocData: [],
    }
  }

  componentDidMount() {
    this.getStockList()
    this.getSubLocData()    
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getStockList = () => {
    try {
      this.LoaderFunction(true);
      let subLocID = this.state.subLocation ? this.state.subLocation.id : ''
      let drugName = this.state.drug_name ? (this.state.drug_name?.replaceAll('&', '%26')).replaceAll(',', ' ') : ""
      RestAPIService.getAll(`${Serviceurls.PHARMACY_SUBLOC_STOCKLIST}?page=${this.state.page + 1}&page_size=${this.state.pageSize}&sublocation_id=${subLocID}&brand_name=${drugName}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              StockList: response.data ? response.data.data : [],
              rowCount: response.data?.page_count
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSubLocData = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.PHARMA_SUB_LOCATION}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              subLocData: response.data.data ? response.data.data.filter((item) => item.is_active == true) : [],
            })
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false);
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='st_close' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              options={this.state.subLocData}
              getOptionLabel={(option) => (option?.sublocation_name)}
              value={this.state.subLocation}
              onChange={(event, newValue) => {
                this.setState({ subLocation: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Select Sub Location')}
                />
              )}
            />
          </Box>
           <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("BrandName")}
              sx={{ width: "20vw" }}
              size="small"
              inputProps={{ emed_tid: 'BrandName' }}
              autoComplete="off"
              value={states.drug_name ? states.drug_name : ""}
              onChange={(e) => {
                this.setState({
                  drug_name: e.target.value
                })
              }}
            /> 
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button emed_tid='fil_clear' variant='outlined' size="small"
            onClick={() => {
              this.setState({
                drug_name: "",
                subLocation: null,
              }, () => this.getStockList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button emed_tid='fil_search' variant='contained' size="small"
            onClick={() => {
              this.getStockList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  gridToolBar = () => {
    const { t } = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText />
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  printStockList = () => {
    try {
      let subLocID = this.state.subLocation ? this.state.subLocation.id : ''
      let drugName = this.state.drug_name ? (this.state.drug_name?.replaceAll('&', '%26')).replaceAll(',', ' ') : ""
      RestAPIService.getAll(`${Serviceurls.PHARMACY_SUBLOC_STOCKLIST}?export_type=${"pdf"}&sublocation_id=${subLocID}&brand_name=${drugName}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let Settings = localGetItem("PharmaSettings") === null ? {} : JSON.parse(localGetItem("PharmaSettings"))
    let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    this.state.StockList.forEach((element, index) => {
      let page = this.state.page
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1)
      element.serial = serial
      // element.sno = (index + 1)
    })
    const { t } = this.props
    const columns = [
      { field: 'serial', headerName: t('SNo'), sortable: false, flex: 0.05 },
      {
        field: 'location_name', headerName: t('Location'), flex: 0.121,
        renderCell: (params) => (<div>{params?.row?.location_name?.length > 12 ? <Tooltip placement="top" title={params?.row?.location_name} arrow><div>{params?.row?.location_name.slice(0, 12) + "..."}</div></Tooltip> : params?.row?.location_name}</div>)
      },
      {
        field: 'brand_name', headerName: t('BrandName'), flex: 0.121,
        renderCell: (params) => (<div>{params?.row?.brand_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name.slice(0, 14) + "..."}</div></Tooltip> : params?.row?.brand_name}</div>)
      },
      {
        field: 'generic_name', headerName: t('GenericName'), flex: 0.121,
        renderCell: (params) => (<div>{params?.row?.generic_name?.length > 12 ? <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 12) + "..."}</div></Tooltip> : params?.row?.generic_name}</div>)
      },
      {
        field: 'dosage_type', headerName: t('DosageType'), flex: 0.111,
        renderCell: (params) => (<div>{params?.row?.dosage_type?.length > 12 ? <Tooltip placement="top" title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type.slice(0, 12) + "..."}</div></Tooltip>
          : params?.row?.dosage_type ? params?.row?.dosage_type : "-"}</div>)
      },
      {
        field: 'dosage_strength', headerName: t('DosageStrength'), flex: 0.125, headerAlign: "center", align: "center",
        renderCell: (params) => <div>{params?.row?.dosage_strength?.length > 13 ? <Tooltip placement="top" title={params?.row?.dosage_strength} arrow><div>{params?.row?.dosage_strength.slice(0, 13) + "..."}</div></Tooltip>
          : params?.row?.dosage_strength ? params?.row?.dosage_strength : "-"}</div>
      },
      { field: 'batch_no', headerName: t('BatchNo'), flex: 0.122, headerAlign: "center", align: "center" },
      { field: 'stock_in_quantity', headerName: t('Quantity'), flex: 0.10, type: "number" },
      {
        field: 'expiry_date', headerName: t('ExpiryDate'), flex: 0.11, headerAlign: "center", align: "center",
        renderCell: (params) => (<div>{params.row.expiry_date ? params.row.expiry_date : "-"}</div>)
      },
      { field: 'physical_box_no', headerName: t('Box No'), flex: 0.08, type: "number" },
      { field: 'mrp_per_quantity', headerName: t(`MRP/Qty (${CurrencySymbol})`), flex: 0.095, type: "number" },
      // {
      //   field: "action", flex: 0.09, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
      //   renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      //     <Tooltip title={t("View Stock")} placement="top" arrow>
      //       <Button emed_tid={'st_view_' + params?.id} className='eMed_usrconf_btn' onClick={() => {
      //         this.props.history.push({
      //           pathname: "/PharmacyInventory/StockList/StockReturn",
      //           state: {
      //             isReturn: false,
      //             stockId: params?.row?.id
      //           }
      //         })
      //       }}>
      //         <img src={ImagePaths.LabViewButton.default} alt="View" className='eMed_action_img' />
      //       </Button>
      //     </Tooltip>
      //   </Box>
      // }
    ]
    return (
      <Box component={'div'} className='eMed_rts_container'>
        <div className='eMed_Purchase_Table_top'>
          <Tooltip title="Export" placement='top' arrow>
            <Button emed_tid='st_export' className='eMed_usrconf_btn' onClick={() => this.setState({ FilterOpen: true })}>
              <img src={ImagePaths.Filter.default} alt="upload" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid='st_print' className='eMed_usrconf_btn' disabled={this.state.StockList?.length === 0} onClick={() => this.printStockList()}>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
        </div> 
        <Box component={'div'} className='eMed_rts_table' height={'68.8vh'}>
          <DataGrid
            rows={this.state.StockList}
            columns={columns}
            getRowId={(row) => row['serial']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage, selectedPage: newPage }, () => { this.getStockList() }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getStockList())}
            rowsPerPageOptions={[10, 20, 30]}
            rowCount={this.state.rowCount}
            pagination
            paginationMode='server'
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
            filterMode="server"
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PharmaSubStockList)
