import React, { Component } from 'react';
import './Config.css';
import {
    Button,
    Stack,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Tooltip,
    Box,
    InputAdornment,
    Autocomplete
} from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment/moment';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { City, Country, State } from 'country-state-city';
import Loader from '../../../Components/Loader';
class PatientDemographics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            title: '',
            gender: '',
            patientFistName: '',
            patientLastName: '',
            mobileNo1: '',
            mobileNo2: '',
            dataofBirth: null,
            age: '',
            email: '',
            occupation: '',
            martialStatus: '',
            fatherHusName: '',
            address1: '',
            address2: '',
            city: '',
            cityName: '',
            country_code: '',
            state_code: '',
            AllCountry: Country.getAllCountries(),
            AllStates: [],
            selectState: '',
            selectCountry: '',
            pincode: '',
            corporateName: '',
            empIdNo: '',
            emergengyName: '',
            emergencyMobilNo: '',
            relationship: '',
            tablePatientDetails: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            testNameTableValue: [],
            selectedEditID: '',
            selectedCartekerID: '',
            initial: '',
            approx_dob: '',
            area_name: '',
            reffered_by: '',
            referral_source: '',
            corporate_id: '',
            home_phone: '',
            searchKey: '',
            selectedIndex: '',
            SearchText:"",
            mother_name: "",
            mother_age: "",
            disableBtn: false,

        }
    }

    componentDidMount() {
        this.getPatientDetails()
    }

    // componentWillReceiveProps(props){
    //     if(props.search_key != this.state.searchKey){
    //         this.setState({
    //             searchKey: props.search_key
    //         },() => {
    //             this.getPatientDetails()
    //         })
    //     }
    // }

    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: "",
        })
      }
      LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }
    getPatientDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_DETAILS_GET + "?search_key=" + this.state.searchKey)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            tablePatientDetails: response.data ? response.data.data : []
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch(e => {
                    this.LoaderFunction(false)
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    postPatientDetails = () => {
        try {
            var states = this.state
            var dateOfBirth = typeof(states.dataofBirth) === 'string' ? states.dataofBirth: DateTime.fromJSDate(states.dataofBirth).toFormat('yyyy-MM-dd')
            var data = {
                "patient_id": states.selectedEditID ? states.selectedEditID : null,
                "caretaker_id": states.selectedCartekerID ? states.selectedCartekerID : null,
                "city_name": states.city ? states.city.replace(/\s+/g, ' ').trim() : '',
                "state_name": states.state_code ? states.AllStates.find((item)=>( item.isoCode === states.state_code))?.name : '',
                "first_name": states.patientFistName ? states.patientFistName.replace(/\s+/g, ' ').trim() : '',
                "last_name": states.patientLastName ? states.patientLastName.replace(/\s+/g, ' ').trim() : '',
                "initial": states.initial ? states.initial : '',
                "father_husband_name": states.fatherHusName ? states.fatherHusName.replace(/\s+/g, ' ').trim() : '',
                "gender": states.gender === "Male" ? "m" : states.gender === "Female" ? "f" : states.gender === "Trans Gender" ? "t" : '',
                "title": states.title ? states.title : '',
                "occupation": states.occupation ? states.occupation.replace(/\s+/g, ' ').trim() : '',
                "dob": dateOfBirth,
                "approx_dob": states.approx_dob ? states.approx_dob : null,
                "marital_status": states.martialStatus ? states.martialStatus : '',
                "home_phone": states.home_phone ? states.home_phone : '',
                "mobile_number": states.mobileNo1 ? states.mobileNo1 : '',
                "mobile_number_2": states.mobileNo2 ? states.mobileNo2 : '',
                "email": states.email ? states.email.replace(/\s+/g, ' ').trim(): '',
                "address_line_1": states.address1 ? states.address1.replace(/\s+/g, ' ').trim() : '',
                "address_line_2": states.address2 ? states.address2.replace(/\s+/g, ' ').trim() : '',
                "area_name": states.area_name ? states.area_name : '',
                "pincode": states.pincode ? states.pincode : '',
                "country": states.country_code ? states.AllCountry.find((item)=>( item.isoCode === states.country_code))?.name : '',
                "reffered_by": states.reffered_by ? states.reffered_by : '',
                "referral_source": states.referral_source ? states.referral_source : '',
                "photo_url": "",
                "corporate_id": states.corporate_id ? states.corporate_id : null,
                "corporate_name": states.corporateName ? states.corporateName : '',
                "employee_id": states.empIdNo ? states.empIdNo : null,
                "patient_age": states.age ? states.age : '',
                "caretaker_name": states.emergengyName ? states.emergengyName.replace(/\s+/g, ' ').trim() : '',
                "caretaker_mobile": states.emergencyMobilNo ? states.emergencyMobilNo : '',
                "caretaker_relationship": states.relationship ? states.relationship.replace(/\s+/g, ' ').trim() : '',
                "mother_name": states.mother_name ? states.mother_name.replace(/\s+/g, ' ').trim() : '',
                "mother_age": states.mother_age ? states.mother_age.replace(/\s+/g, ' ').trim() : null,
            }
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.LAB_PATIENT_DETAILS_GET)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: response.data.message
                        }, () => {
                            this.getPatientDetails()
                            this.setState({
                                title: '',
                                gender: '',
                                patientFistName: '',
                                patientLastName: '',
                                mobileNo1: '',
                                mobileNo2: '',
                                dataofBirth: null,
                                age: '',
                                email: '',
                                occupation: '',
                                martialStatus: '',
                                fatherHusName: '',
                                address1: '',
                                address2: '',
                                city: '',
                                state_code:'',
                                country_code:'',
                                pincode: '',
                                corporateName: '',
                                empIdNo: '',
                                emergengyName: '',
                                emergencyMobilNo: '',
                                relationship: '',
                                selectedEditID: '',
                                selectedCartekerID: '',
                                initial: '',
                                approx_dob: '',
                                area_name: '',
                                reffered_by: '',
                                referral_source: '',
                                corporate_id: '',
                                home_phone: '',
                                mother_name:"",
                                mother_age:"",
                                disableBtn: false,
                            })
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message,
                        disableBtn: false,
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                disableBtn: false,
            })
        }
    }

    editPatientDetails = (item, index) => {
        this.setState({
            selectedEditID: item.patient_id ? item.patient_id : null,
            selectedCartekerID: item.caretaker_id ? item.caretaker_id : null,
            title: item.title ? item.title : null,
            gender: item.gender === "m" ? "Male" : item.gender === "f" ? "Female" : item.gender === "t" ? 'Trans Gender' : '',
            patientFistName: item.first_name ? item.first_name : '',
            patientLastName: item.last_name ? item.last_name : '',
            mobileNo1: item.mobile_number ? item.mobile_number : '',
            mobileNo2: item.mobile_number_2 ? item.mobile_number_2 : '',
            dataofBirth: item.dob ? item.dob : null,
            age: item.dob ? this.handleAgeCalculation(new Date(item.dob)) : '',
            email: item.email ? item.email : '',
            occupation: item.occupation ? item.occupation : '',
            martialStatus: item.marital_status ? item.marital_status : '',
            fatherHusName: item.father_husband_name ? item.father_husband_name : '',
            address1: item.address_line_1 ? item.address_line_1 : '',
            address2: item.address_line_2 ? item.address_line_2 : '',
            pincode: item.pincode ? item.pincode : '',
            city: item.city_name ? item.city_name : '',
            country_code: this.state.AllCountry.find((list)=>(list.name === item.country))?.isoCode,
            AllStates: State.getStatesOfCountry(this.state.AllCountry.find((list)=>(list.name === item.country))?.isoCode),
            state_code: State.getStatesOfCountry(this.state.AllCountry.find((list)=>(list.name === item.country))?.isoCode).find((list)=>(list.name === item.state_name))?.isoCode,
            corporateName: item.corporate_name ? item.corporate_name : '',
            empIdNo: item.employee_id ? item.employee_id : null,
            emergengyName: item.caretaker_name ? item.caretaker_name : '',
            emergencyMobilNo: item.caretaker_mobile ? item.caretaker_mobile : '',
            relationship: item.caretaker_relationship ? item.caretaker_relationship : '',
            initial: item.initial ? item.initial : '',
            approx_dob: item.approx_dob ? item.approx_dob : '',
            area_name: item.area_name ? item.area_name : '',
            reffered_by: item.reffered_by ? item.reffered_by : '',
            referral_source: item.referral_source ? item.referral_source : '',
            corporate_id: item.corporate_id ? item.corporate_id : null,
            corporateName: item.corporate_name ? item.corporate_name : '',
            empIdNo: item.employee_id ? item.employee_id : null,
            home_phone: item.home_phone ? item.home_phone : '',
            selectedIndex: index,
            mother_name: item.mother_name ? item.mother_name : '',
            mother_age: item.mother_age ? item.mother_age : ''

        },()=>{
            this.setState({cityName: City.getCitiesOfState(this.state.country_code , this.state.state_code)})
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    textInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    };


    numInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var states = this.state
        const re = /^[0-9\b]+$/;
        if (((value === "" || re.test(value) ) && name != "age")) {
            this.setState({
                [name]: value,
            });
        } else if ((states.age <= 130 || value == "") && name == "age") {
            if(value >= 0 && value <=130 || value === ""){
            states['age'] = value
            states["dataofBirth"] = ''
            this.setState({
                states,
            }, () => {
                var approxDob = this.handleFindApproxDob(this.state.age);
                this.setState({
                    dataofBirth: this.state.age ? approxDob : null
                })
            })
        }
        }
        else if(value.includes("/") && name == "age"){
            this.setState({age:"", dataofBirth : null})
          }
    };
    letterInputcheck = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const re = /^[A-Za-z, '']+$/;
        if (value === "" || re.test(value) && value.length <= 50) {
            this.setState({
                [name]: value,
            });
        }
     }
    handleAgeCalculation = date => {
        // let age = "";
        // let dob = DateTime.fromJSDate(date);
        // var year_age = DateTime.now().diff(dob, 'years');
        // age = Math.floor(year_age.years) || 0;
        // return age;
        let age = "";
        let dob = new Date(date);
        let m = moment(dob, "DD-MM-YYYY");
        var end_date = moment();

        var year_age = end_date.diff(m, 'year') || 0;
        // reset(year) year with difference year
        m = moment(m).add(year_age, 'years');

        var month_age = end_date.diff(m, 'month') || 0;
        // reset(month) month with difference month
        m = moment(m).add(month_age, 'months');

        var day_age = end_date.diff(m, 'days') || 0;

        if (year_age > 0) {
            age = year_age || 0;
        } else {
            age = year_age + "Y/" + month_age + "M/" + day_age + "D";
        }
        return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
    }
    handleFindApproxDob = ageEntered => {

        var year = 0;
        var month = 0;
        var days = 0;
        var tempDate;
        
        year = +(ageEntered)

        tempDate = DateTime.now().minus({ years: year }).toBSON();

        return tempDate;
    }

    renderTextBox = (label, width, value, name) => {
        return (
            <div>
                <TextField
                    size={'small'}
                    sx={{ width: width }}
                    label={label}
                    rows={3}
                    name={name}
                    inputProps={{ maxLength: name === "mobileNo1" || name === "mobileNo2" || name === "emergencyMobilNo" ? 10 : name === "pincode" ? 6 : name === "age" || name === "mother_age" ? 3 : '' }}
                    value={value}
                    variant="outlined"
                    onChange={name === 'mobileNo1' ||
                        name === "mobileNo2" ||
                        name === "pincode" ||
                        name === "age" ||
                        name === "emergencyMobilNo" ||
                        name === "mother_age" ? this.numInputcheck : name === "patientFistName" ||
                        name === "patientLastName" ||
                        name === "fatherHusName" ||
                        name === "emergengyName" || 
                        name === "relationship" ||
                        name === "city" ||
                        name === "mother_name" ? this.letterInputcheck :
                         this.textInputcheck}
                />
            </div>
        )
    }

    renderLeftTable = () => {
        var states = this.state
        const { t } = this.props
        //  filter table data based on search Text
        const filtereddata = this.state.tablePatientDetails.filter((data) => {
            return (
                (data.first_name === null ? null : data.first_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.last_name === null ? null : data.last_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.address_line_1 === null ? null : data.address_line_1.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.address_line_2 === null ? null : data.address_line_2.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.city_name === null ? null : data.city_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.mobile_number === null ? null : data.mobile_number.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.patient_account_number === null ? null : data.patient_account_number.toLowerCase().includes(this.state.SearchText.toLowerCase()))
            );
        });
        return (
            <div>
                <Paper elevation={0}>
                <Box className="eMed_Search_Box_">
                        <TextField
                            variant='standard'
                            placeholder='Search'
                            value={this.state.SearchText}
                            onChange={(e) => { this.setState({ SearchText: e.target.value }) }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '15vw' }} />
                    </Box>
                    <TableContainer className='eMed_ConfigTable_Paper'>
                        <Table stickyHeader size='small'>
                            <TableHead sx={{ height: '7.3vh' }}>
                                <TableRow>
                                    <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{t('SNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t("PatientName")}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('MobileNo')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('UHIDNumber')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{t('EmergencyContact')}</TableCell>
                                    <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata && filtereddata.slice(states.page * states.rowsPerPage, states.page * states.rowsPerPage + states.rowsPerPage)
                                .map((data, index) => {
                                    var sno = (states.page * states.rowsPerPage) + (index + 1)
                                    return (
                                        <TableBody>
                                            <TableRow sx={{bgcolor: this.state.selectedEditID && this.state.selectedIndex == index ? '#e2e2e2' : 'white'}}>
                                                <TableCell align='left'>{sno}</TableCell>
                                                <TableCell align='left'>
                                                    {data.first_name || data.last_name || data.patient_age || data.address_line_1 || data.address_line_2 || data.city_name ?
                                                        <div>
                                                            <p className='eMed_PatientDemo_Name'>{data.first_name ? data.first_name : '' + " " + data.last_name ? data.last_name : '' + " (" + data.patient_age ? data.patient_age : '' + ")"}</p>
                                                            <p className='eMed_PatientDemo_Address1'>{(data.address_line_1 + data.address_line_2)?.length > 20 ? <Tooltip placement='top' title={data.address_line_1 + ", " + data.address_line_2}><div>{(data.address_line_1 + ", " + data.address_line_2).slice(0,18) + "..."}</div></Tooltip>  : data.address_line_1 + '' + ", " + data.address_line_2 ? data.address_line_2 : ''}</p>
                                                            <p className='eMed_PatientDemo_Address2'>{data.city_name?.length > 20 ? <Tooltip placement='top' title={data.city_name}><div>{data.city_name?.slice(0,18) + "..."}</div></Tooltip> :  data.city_name ? data.city_name : ''}</p>
                                                        </div>
                                                        : '-'}
                                                </TableCell>
                                                <TableCell align='left'>{data.mobile_number ? data.mobile_number : '-'}</TableCell>
                                                <TableCell align='center'>
                                                    {data.patient_account_number ? data.patient_account_number : '-'}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {data.caretaker_name || data.caretaker_relationship || data.caretaker_mobile ?
                                                        <div>
                                                            <p className='eMed_PatientDemo_Name'>{data.caretaker_name ? data.caretaker_name : '-'}</p>
                                                            <p className='eMed_PatientDemo_Address1'>{data.caretaker_relationship ? data.caretaker_relationship : ''}</p>
                                                            <p className='eMed_PatientDemo_Address2'>{data.caretaker_mobile ? data.caretaker_mobile : ''}</p>
                                                        </div>
                                                        : '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <div className='eMed_TestConfig_Con'>
                                                        <IconButton size='small'  disabled={!CheckAccessFunc("Laboratory", "Configuration", "Patient Demographics",  null, "Tab")?.editAccess}>
                                                            <img src={CheckAccessFunc("Laboratory", "Configuration", "Patient Demographics",  null, "Tab")?.editAccess ? ImagePaths.Edit.default : ImagePaths.DisabledDelete.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                                                onClick={() => { this.editPatientDetails(data, index) }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })
                            }
                        </Table>
                        {filtereddata && filtereddata.length === 0 ?
                            <div className='eMed_TestConfig_NoRecords'>
                                {t('NoRecordsFound')}
                            </div> : null}
                    </TableContainer>
                </Paper>
                <div className='eMed_TestConfig_LeftPageination'>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata.length}
                        rowsPerPage={states.rowsPerPage}
                        page={states.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </div>
            </div>
        )
    }

    clearValue = () => {
        this.setState({
            title: '',
            gender: '',
            patientFistName: '',
            patientLastName: '',
            mobileNo1: '',
            mobileNo2: '',
            dataofBirth: null,
            age: '',
            email: '',
            occupation: '',
            martialStatus: '',
            fatherHusName: '',
            address1: '',
            address2: '',
            city: '',
            state_code:'',
            country_code:'',
            pincode: '',
            corporateName: '',
            empIdNo: '',
            emergengyName: '',
            emergencyMobilNo: '',
            relationship: '',
            selectedEditID: '',
            selectedCartekerID: '',
            initial: '',
            approx_dob: '',
            area_name: '',
            reffered_by: '',
            referral_source: '',
            corporate_id: '',
            home_phone: '',
            mother_name:"",
            mother_age:""
        })
    }

    validCheck = () => {
        const {t} = this.props;
        var saveErrorMsg = ''
        var states = this.state
        if (!states.age ||
            !states.mobileNo1 ||
            !states.gender ||
            !states.dataofBirth ||
            !states.patientFistName) {
            saveErrorMsg = t('Fillmandatory')
        } else if (states.mobileNo1.length != 10) {
            saveErrorMsg = t('CheckMobileNo')
        } else if (states.emergencyMobilNo ? states.emergencyMobilNo.length != 10 : ''){
            saveErrorMsg = t('CheckEmergencyMobileNo')
        }
        return saveErrorMsg
    }

    onSave = () => {
        var inValid = this.validCheck()
        if (inValid) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: inValid
            })
        } else {
            this.postPatientDetails()
        }
    }
    selectCountry(val) {
        let states = this.state
        states["selectCountry"] = val
        this.setState({ states });
    }
    selectRegion(val) {
        let states = this.state
        states["selectState"] = val
        this.setState({ states });
    }

    render() {
        const { t } = this.props
        return (
            <div className='eMed_TestConfig_MainContainer'>
                <div className='eMed_TestConfig_Con'>
                    <div className='eMed_TestConfig_leftSide'>
                        {this.renderLeftTable()}
                    </div>
                    <div className='eMed_TestConfig_RightSide'>
                        <div className='eMed_TestConfig_RightHead'>
                            <p className='eMed_Testconfig_RightHead_text'>{t('PatientDemogrphics')}</p>
                        </div>
                        <div className='eMed_TestConfig_RightContentBox'>
                            <div className='eMed_TestConfig_RightMethod'>
                                <FormControl sx={{ width: '14vw' }} size='small'>
                                    <InputLabel id="demo-simple-select-label">{t('Title')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.title}
                                        name={'gender'}
                                        label={t('Title')}
                                        onChange={(e) => {
                                            this.setState({
                                                title: e.target.value
                                            }, () => {
                                                var states = this.state
                                                if (states.title == "Mr") {
                                                    states["gender"] = "Male";
                                                } else if (states.title == "Ms") {
                                                    states["gender"] = "Female";
                                                } else if (states.title == "Mrs") {
                                                    states["gender"] = "Female";
                                                    states["martialStatus"] = "Married"
                                                }
                                                this.setState({ states })
                                            })
                                        }}
                                    >
                                        <MenuItem key={1} value={"Dr"}>{t("Dr")}</MenuItem>
                                        <MenuItem key={2} value={"Mr"}>{t("Mr")}</MenuItem>
                                        <MenuItem key={3} value={"Ms"}>{t("Ms")}</MenuItem>
                                        <MenuItem key={4} value={"Mrs"}>{t("Mrs")}</MenuItem>
                                        <MenuItem key={5} value={"Baby of"}>{t("Babyof")}</MenuItem>
                                        <MenuItem key={6} value={"Master"}>{t("Master")}</MenuItem>
                                        <MenuItem key={7} value={"Baby"}>{t("Baby")}</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width: '14vw' }} size='small'>
                                    <InputLabel id="demo-simple-select-label">{t('Gender') + "*"}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.gender}
                                        name={'gender'}
                                        label={t('Gender') + "*"}
                                        onChange={(event) => {
                                            this.setState({
                                                gender: event.target.value
                                            })
                                        }}
                                    >
                                        <MenuItem key={1} value={"Male"}>{t("Male")}</MenuItem>
                                        <MenuItem key={2} value={"Female"}>{t("Female")}</MenuItem>
                                        <MenuItem key={2} value={"Trans Gender"}>{t("Trans Gender")}</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("PatientFirstName"), '14vw', this.state.patientFistName, 'patientFistName')}
                                {this.renderTextBox(t("PatientLastName"), '14vw', this.state.patientLastName, 'patientLastName')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("MobileNo") + "*", '14vw', this.state.mobileNo1, 'mobileNo1')}
                                {this.renderTextBox(t("MobileNo.2"), '14vw', this.state.mobileNo2, 'mobileNo2')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label={t("ApproxDOB")}
                                        value={this.state.dataofBirth}
                                        name={'dataofBirth'}
                                        inputFormat={"dd-MM-yyyy"}
                                        maxDate={DateTime.now()}
                                        onChange={(newDate) => {
                                            var age = this.handleAgeCalculation(newDate)
                                            this.setState({
                                                dataofBirth: newDate,
                                                age: age
                                            })
                                        }}
                                        renderInput={(params) => <TextField sx={{width:"14vw"}} size='small' {...params} />}
                                    />
                                </LocalizationProvider>
                                {this.renderTextBox(t("Age") + "*", '14vw', this.state.age, 'age')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Email"), '14vw', this.state.email, 'email')}
                                {this.renderTextBox(t("Occupation"), '14vw', this.state.occupation, 'occupation')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <FormControl sx={{ width: '14vw' }} size='small'>
                                    <InputLabel id="demo-simple-select-label">{t('MartialStatus')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.martialStatus}
                                        name={'martialStatus'}
                                        label={t('MartialStatus')}
                                        onChange={(event) => {
                                            this.setState({
                                                martialStatus: event.target.value
                                            })
                                        }}
                                    >
                                        <MenuItem key={1} value={"Single"}>{t("Single")}</MenuItem>
                                        <MenuItem key={2} value={"Married"}>{t("Married")}</MenuItem>
                                        <MenuItem key={2} value={"Divorced"}>{t("Divorced")}</MenuItem>
                                        <MenuItem key={2} value={"Widowed"}>{t("Widowed")}</MenuItem>

                                    </Select>
                                </FormControl>
                                {this.renderTextBox(t("Father/HusbandName"), '14vw', this.state.fatherHusName, 'fatherHusName')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("MotherName"), '14vw', this.state.mother_name, 'mother_name')}
                                {this.renderTextBox(t("MotherAge"), '14vw', this.state.mother_age, 'mother_age')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Address1"), '14vw', this.state.address1, 'address1')}
                                {this.renderTextBox(t("Address2"), '14vw', this.state.address2, 'address2')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Pincode"), '14vw', this.state.pincode, 'pincode')}
                                <Autocomplete 
                                    sx={{ width: "14vw" }}
                                    size="small"
                                    options={this.state.cityName ? this.state.cityName : []}
                                    getOptionLabel={(option) => typeof (option) === "string" ? option : option.name}
                                    onChange={(e, value) => {
                                    this.setState({
                                        city: value?.name ? value?.name : '',
                                        pincode: '',
                                    })
                                    }}
                                    clearIcon={false}
                                    value={this.state.city}
                                    renderInput={(params) => (
                                    <TextField 
                                        label="City" 
                                        InputProps={{ ...params.InputProps }} 
                                        onChange={(e)=>this.setState({city: e.target.value,pincode:''})} 
                                        {...params}
                                        />)}
                                    onKeyDown={(e)=>e.key === "Enter" ? e.preventDefault(): null}
                                />
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                <FormControl size='small'>
                                <InputLabel id="gender-select-label">State</InputLabel>
                                <Select
                                    sx={{ width: '14vw' }}
                                    MenuProps={{ style: { maxHeight: 250, maxWidth: '14vw' }}}
                                    labelId='gender-select-label'
                                    size='small'
                                    label="State"
                                    value={this.state.state_code}
                                    onChange={(e) => {
                                    this.setState({state_code: e.target.value},
                                        ()=>{
                                        this.setState({cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code),city:''})
                                    })
                                    }}
                                >
                                    {
                                    this.state.AllStates.map((list) => (
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    ))
                                    }
                                </Select>
                                </FormControl>
                                <FormControl size='small'>
                                <InputLabel id="gender-select-label">Country</InputLabel>
                                <Select
                                    sx={{ width: '14vw' }}
                                    MenuProps={{ style: { maxHeight: 250,maxWidth: '14vw' } }}
                                    labelId='gender-select-label'
                                    size='small'
                                    label="Country"
                                    value={this.state.country_code}
                                    onChange={(e) => {
                                    this.setState({country_code: e.target.value, state_code: "",city:''},()=>{
                                        this.setState({AllStates: State.getStatesOfCountry(this.state.country_code),cityName:''})
                                    })
                                    }}
                                >
                                    {
                                    this.state.AllCountry.map((list) => (
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    ))
                                    }
                                </Select>
                                </FormControl>

                                {/* <RegionDropdown
                                    className='eMed_Patient_Country'
                                    country={this.state.selectCountry}
                                    value={this.state.selectState}
                                    onChange={(val) => this.selectRegion(val)} />
                                <CountryDropdown className='eMed_Patient_Country'
                                    value={this.state.selectCountry}
                                    onChange={(val) => this.selectCountry(val)}
                                    priorityOptions={["IN"]}
                                    whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                                /> */}
                            </div>
                            {/* <div className='eMed_TestConfig_RightHead2'>
                                <p className='eMed_Testconfig_RightHead_text' style={{ color: Colors.HeadText }}>{t('CorporateDetails')}</p>
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("CorporateName"), '14vw', this.state.corporateName, 'corporateName')}
                                {this.renderTextBox(t("EmployeeIDNo"), '14vw', this.state.empIdNo, 'empIdNo')}
                            </div> */}
                            <div className='eMed_TestConfig_RightHead2'>
                                <p className='eMed_Testconfig_RightHead_text'>{t('EmergencyContact')}</p>
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Name"), '14vw', this.state.emergengyName, 'emergengyName')}
                                {this.renderTextBox(t("MobileNo"), '14vw', this.state.emergencyMobilNo, 'emergencyMobilNo')}
                            </div>
                            <div className='eMed_TestConfig_RightMethod'>
                                {this.renderTextBox(t("Relationship"), '14vw', this.state.relationship, 'relationship')}
                            </div>

                        </div>
                        <div className='eMed_TestConfig_RightBtnCon'>
                            <Stack spacing={2} direction="row">
                                <Button id='eMed_Btn_Text' variant='outlined' onClick={() => { this.clearValue() }}>{t("Clear")}</Button>
                                <Button id='eMed_Btn_Text' emed_tid='Patient_Demo_Edit_Save_Btn' variant='contained' disabled={this.state.disableBtn ? true : this.state.selectedEditID && this.state.selectedEditID ? false : true} onClick={() => { this.onSave() }}>{t("Save")}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </div>
        );
    }
}

export default withTranslation()(PatientDemographics);