import { CheckAccessFunc } from '../Components/CommonFunctions/CommonFunctions';
import { ImagePaths } from '../Utility/ImagePaths';
import { localGetItem } from './Services/CacheProviderService';

export class NewSideMenu {
    static lab = () => {
        // let loginData = localGetItem("loggedInUserInfo");
        // let loginResult = JSON.parse(loginData);
        // let newList = [];
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "Laboratory") {
            if (CheckAccessFunc("Laboratory", "Home", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 1, menuName: 'MainHome', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (CheckAccessFunc("Laboratory", "Reports", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 2, menuName: 'LabReports', OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: "Reports" })
            }
            if (CheckAccessFunc("Laboratory", "Inventory", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 3, menuName: 'Inventory', OnIcon: ImagePaths.LabInventryOn, OffIcon: ImagePaths.LabInventrytOff, label: "Inventory" })
            }
            if (CheckAccessFunc("Laboratory", "Purchase", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 4, menuName: 'Purchase', OnIcon: ImagePaths.LabPurchaseOn, OffIcon: ImagePaths.LabPurchaseOff, label: "Purchase" })
            }
            if (CheckAccessFunc("Laboratory", "Configuration", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 5, menuName: 'Config', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Configuration" })
            }
        }
        return newList;
    }

    static FO = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        var Enable_Insurance = JSON.parse(localGetItem("Enable_Insurance"))
        let newList = [];

        if (UserData?.module_name === "front_office") {
            if (CheckAccessFunc("front_office", "Home", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 1, menuName: 'OPHome', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (CheckAccessFunc("front_office", "In Patients", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 2, menuName: 'IPHome', OnIcon: ImagePaths.IpHomeOn, OffIcon: ImagePaths.IpHomeOff, label: "IPHome" })
            }
            if (CheckAccessFunc("front_office", "Billing", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 6, menuName: 'Billing', OnIcon: ImagePaths.BillOn, OffIcon: ImagePaths.BillOff, label: "Billing" })
            }
            if (CheckAccessFunc("front_office", "Billing Transaction", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 3, menuName: 'Billing Transaction', OnIcon: ImagePaths.BillingOn, OffIcon: ImagePaths.BillingOff, label: 'Billing Transaction' })
            }
            if (CheckAccessFunc("front_office", "Reports", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 4, menuName: 'FOReports', OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: 'Reports' })
            }
            if (CheckAccessFunc("front_office", "Settings", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 5, menuName: 'Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
            if (UserData?.is_user !== true) {
                newList.push({ id: 7, menuName: 'ChartDashboard', OnIcon: ImagePaths.ChartDashboardOn, OffIcon: ImagePaths.ChartDashboardOff, label: "Dashboard" })
            }
            if(Enable_Insurance){
                newList.push({ id: 8, menuName: 'Insurance', OnIcon: ImagePaths.InsuranceON, OffIcon: ImagePaths.InsuranceOFF, label: "Insurance" })
            }
        }
        return newList;
    }

    static Nurse = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "nurse") {
            if (CheckAccessFunc("front_office", "Home", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 1, menuName: 'OPHome', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (CheckAccessFunc("front_office", "In Patients", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 2, menuName: 'IPHome', OnIcon: ImagePaths.IpHomeOn, OffIcon: ImagePaths.IpHomeOff, label: "IPHome" })
            }
        }
        return newList;
    }

    static Pharmacy = () => {
        let myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        let UserData = myUser;
        let newList = [];
        let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";

        if (UserData?.module_name === 'Pharmacy') {
            if (CheckAccessFunc(pharmacy_type, "Billing", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 1, menuName: "Pharmacy Billing", OnIcon: ImagePaths.BillOn, OffIcon: ImagePaths.BillOff, label: "Billing" })
            }
            if (CheckAccessFunc(pharmacy_type, "Billing Transaction", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 2, menuName: "Pharmacy Invoice", OnIcon: ImagePaths.BillingOn, OffIcon: ImagePaths.BillingOff, label: "Billing Transaction" })
            }
            if (CheckAccessFunc(pharmacy_type, "Inventory", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 3, menuName: "Pharmacy Inventory", OnIcon: ImagePaths.LabInventryOn, OffIcon: ImagePaths.LabInventrytOff, label: "Inventory" })
            }
            if ((CheckAccessFunc(pharmacy_type, "Purchase", null, null, "Menu")?.viewAccess) && UserData?.is_po_enabled) {
                newList.push({ id: 4, menuName: "Pharmacy Purchase", OnIcon: ImagePaths.LabPurchaseOn, OffIcon: ImagePaths.LabPurchaseOff, label: "Purchase" })
            }
            if (CheckAccessFunc(pharmacy_type, "Reports", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 5, menuName: "Pharmacy Reports", OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: "Report" })
            }
            if (UserData?.pharmacy_type === "Inhouse Pharmacy" && CheckAccessFunc("Pharmacy", "Stock Transfer", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 6, menuName: 'Pharmacy Stock Transfer', OnIcon: ImagePaths.StkTransferOn, OffIcon: ImagePaths.StkTransferOff, label: 'Stock Transfer' })
            }
            if (CheckAccessFunc(pharmacy_type, "Settings", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 7, menuName: "Pharmacy Setting", OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
            return newList;
        }
    }

    static SubLocation = () => {
        let myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        let UserData = myUser;
        let newList = [];

        // if (UserData?.module_name === 'Pharmacy') {
        if (CheckAccessFunc("sub_location", "Home", null, null, "Menu")?.viewAccess) {
            newList.push({ id: 1, menuName: "SubLocation Billing", OnIcon: ImagePaths.BillOn, OffIcon: ImagePaths.BillOff, label: "Billing" })
        }
        if (CheckAccessFunc("sub_location", "Reports", null, null, "Menu")?.viewAccess) {
            newList.push({ id: 2, menuName: "SubLocation Reports", OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: "Report" })
        }
        if (CheckAccessFunc("sub_location", "Stock Transfer", null, null, "Menu")?.viewAccess) {
            newList.push({ id: 3, menuName: "SubLocation Stock Transfer", OnIcon: ImagePaths.StkTransferOn, OffIcon: ImagePaths.StkTransferOff, label: "Stock Transfer" })
        }
        return newList;
        // }
    }
    static OT = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "ot_management") {
            if (true) {
                newList.push({ id: 1, menuName: 'OTHome', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (true) {
                newList.push({ id: 2, menuName: 'OT Stocks', OnIcon: ImagePaths.BillOn, OffIcon: ImagePaths.BillOff, label: "OT Stocks" })
            }
            if (true) {
                newList.push({ id: 3, menuName: 'OT Inventory', OnIcon: ImagePaths.LabInventryOn, OffIcon: ImagePaths.LabInventrytOff, label: "Inventory" })
            }
            if (true) {
                newList.push({ id: 4, menuName: 'OT Stock Transfer', OnIcon: ImagePaths.StkTransferOn, OffIcon: ImagePaths.StkTransferOff, label: "Stock Transfer" })
            }
            if (true) {
                newList.push({ id: 5, menuName: 'OT Reports', OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: "Reports" })
            }
            if (true) {
                newList.push({ id: 6, menuName: 'OT Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
        }
        return newList;
    }

    static Counsellor = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "counsellor") {
            if (true) {
                newList.push({ id: 1, menuName: 'Counsellor', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOn, label: "Home" })
            }
            if (true) {
                newList.push({ id: 2, menuName: 'OTHome', OnIcon: ImagePaths.otListon, OffIcon: ImagePaths.otListoff, label: "OT List" })
            }
        }
        return newList;
    }

    static Doctor = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "Optometry") {
            if (true) {
                newList.push({ id: 1, menuName: 'Doctor Home', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (true) {
                newList.push({ id: 2, menuName: 'Optometry Reports', OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: "Reports" })
            }
            if (true) {
                newList.push({ id: 3, menuName: 'Optometry Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
        } else if (UserData?.module_name === "Doctor") {
            if (true) {
                newList.push({ id: 1, menuName: 'Doctor Home', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (UserData?.service_type !== "OPT") {
                newList.push({ id: 7, menuName: 'IP Doctor Home', OnIcon: ImagePaths.IpHomeOn, OffIcon: ImagePaths.IpHomeOff, label: "IPHome" })
                newList.push({ id: 2, menuName: 'Doctor Nutrition Chart', OnIcon: ImagePaths.DocNutritionOn, OffIcon: ImagePaths.DocNutritionOff, label: "Nutrition Chart" })
            }
            if (true) {
                newList.push({ id: 3, menuName: 'Doctor Registry', OnIcon: ImagePaths.DocRegistryOn, OffIcon: ImagePaths.DocRegistryOff, label: "Registry" })
            }
            if (UserData?.is_ot_mapped) {
                newList.push({ id: 4, menuName: 'OTHome', OnIcon: ImagePaths.otListon, OffIcon: ImagePaths.otListoff, label: "OT List" })
            }
            if (UserData?.service_type === "OPT") {
                newList.push({ id: 5, menuName: 'Doctor Reports', OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: "Reports" })
            }
            if (true) {
                newList.push({ id: 6, menuName: 'Doctor Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
        }
        return newList;
    }

    static Radiology = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === 'Radiology') {
            if (true) {
                newList.push({ id: 1, menuName: 'Radiology Home', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (CheckAccessFunc("Radiology", "Billing Transaction", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 2, menuName: 'Radiology Billing Transaction', OnIcon: ImagePaths.BillingOn, OffIcon: ImagePaths.BillingOff, label: 'Billing Transaction' })
            }
            if (CheckAccessFunc("Radiology", "Reports", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 3, menuName: 'Radiology Reports', OnIcon: ImagePaths.LabReportOn, OffIcon: ImagePaths.LabReportOff, label: 'Reports' })
            }
            if (CheckAccessFunc("Radiology", "Settings", null, null, "Menu")?.viewAccess) {
                newList.push({ id: 4, menuName: 'Radiology Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
        }
        return newList;
    }

    static OrganizationAdmin = () => {

        let newList = [
            { id: 1, menuName: 'AdminDashboard', OnIcon: ImagePaths.ChartDashboardOn, OffIcon: ImagePaths.ChartDashboardOff, label: "Dashboard" },
            { id: 2, menuName: 'Admin Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" }

        ] 
        return newList
    }
    
    static Asset = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "asset_management") {
            if (true) {
                newList.push({ id: 1, menuName: 'Asset Home', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (true) {
                newList.push({ id: 2, menuName: 'Asset Complaints', OnIcon: ImagePaths.Complaintson, OffIcon: ImagePaths.Complaintsoff, label: "Complaints" })
            }
            if (true) {
                newList.push({ id: 3, menuName: 'Asset Settings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
        }
        return newList;
    }

    static Canteen = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let newList = [];

        if (UserData?.module_name === "canteen") {
            if (true) {
                newList.push({ id: 1, menuName: 'CanteenHome', OnIcon: ImagePaths.LabHomeOn, OffIcon: ImagePaths.LabHomeOff, label: "Home" })
            }
            if (true) {
                newList.push({ id: 2, menuName: 'CanteenBillingTransaction', OnIcon: ImagePaths.BillingOn, OffIcon: ImagePaths.BillingOff, label: "Billing Transaction" })
            }
            if (true) {
                newList.push({ id: 3, menuName: 'CanteenSettings', OnIcon: ImagePaths.LabSettingOn, OffIcon: ImagePaths.LabSettingOff, label: "Settings" })
            }
        }
        return newList;
    }

}