import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import {
    Box, Button, Chip, Grid, Tab, Table,
    TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tabs, TextField, Tooltip, Typography, Checkbox,
    FormControl, FormControlLabel, Radio, RadioGroup, InputAdornment, Divider, Modal, Paper, IconButton, OutlinedInput, Select, MenuItem, Dialog, Autocomplete, Stack
} from '@mui/material'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import SearchIcon from '@mui/icons-material/Search';
import { AmountFormat, CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions'
import { DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { clearCachevalue, getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import Loader from '../../../../Components/Loader';
import { BillSuccessPoPUp, ReasonPopupWithRefund } from '../../../../Components/Common Components/CommonComponents';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import moment from 'moment';
import { AddCircle } from '@material-ui/icons';
import { CurrencyRupee } from '@mui/icons-material';
import PercentIcon from '@mui/icons-material/Percent';
import CircularProgress from '@mui/material/CircularProgress';
import { CurrencySymbol } from '../../../../Utility/Constants';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Unit_Types = [
    { label: `${CurrencySymbol}`, value: 'rupee' },
    { label: '%', value: 'percentage' }
]

class CommonOpBilling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            isRegular: true,
            isCashless: false,
            corporatePay: "",
            serviceData: [],
            templateData: [],
            serviceList: [],
            descriptionList: [],
            opBillingList: [],
            billPost: {},
            serviceAmount: 0,
            templateList: [],
            servcieName: "",
            templateName: "",

            value: JSON.parse(localGetItem('op_ip_service_code')) ? "three" : 'two',
            serviceTemplateData: [],
            serviceDescriptionData: [],
            serviceSearch: "",
            descriptionSerach: "",
            templateDate: new Date(),
            descripDate: new Date(),
            service: "",
            description: "",
            serviceAmount: "",
            descriptionAmount: "",
            serviceData: [],
            descriptionData: [],
            serviceList: [],
            descriptionList: [],
            billingList: [],
            totalServiceAmount: 0,
            isEditunit: false,
            isEditDiscount: false,
            isEditRate: false,
            totalDiscountedAmount: "",
            totalDiscountedPercentage: "",
            totalPayableAmount: 0,
            roundOff: "",
            data: {},
            service_amount: "",
            balance_amount: "",
            paid_amount: "",
            attender_mobile: "",
            attender_name: "",
            noDiscountAmount: 0,
            newService: [],
            newDescription: [],
            newDescriptionName: "",
            serviceListStringyfy: [],
            descriptionListStringyfy: {},
            isCredit: false,
            templateId: "",
            DemographicData: {},
            isSavedBillClicked: false,
            corporateList: this.props.corporateList,
            corporateName: "",
            BillData: {},
            FullAmountEntered: false,
            patientData: {},
            employerId: "",
            employerDiscount: "",
            //Patient saved/credit/completed - data
            opPatientSavedBill: this.props.opPatientSavedBill,
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: "",
            isDeleteAll: false,
            ClearClicked: false,
            onAddService: false,
            openDate: false,
            withOutAppointment: {},
            serviceTemplateDataNew: "[]",
            serviceDescriptionDataNew: "[]",
            isButtonDisabled: false,
            SuccessInvoice_ID: null,
            bill_receipt_id: null,
            autoBillData: [],
            DateOpen: false,
            CreatedPatientId: "",
            PatientID: null,
            forCancel: (this.props.forCancel && this.props?.isForEditBill !== true),
            cancelBillClicked: false,
            selectedRefundMode: '',
            upi_ref_num: '',
            selectedBillMode: 'cancel_bill',
            cancelReceipt: true,
            BillRefundData: {},
            per_access: '',
            removable_ids: [],
            Timepopup: false,
            selectedService: {},
            ChangedUnit: 0,
            hourField: null,
            minuteField: null,
            endTimeOpen: false,
            startTimeOpen: false,
            selectedIndex: null,
            discountReasonPopup: false,
            discountReason: '',
            serviceDiscountpopup: false,
            discountHospitalFee: '',
            discountDoctorFee: '',
            selectedType: 'rupee',
            discountIndex: null,
            dupliSelectedData: '{}',
            selectedData: null,
            discountPercentDoctorFee: '',
            discountPercentHospitalFee: '',
            serviceCatagory: '',
            isReceivePaymentClicked: false,
            minimum_amount: "",
            timeFormate: false,
            OutstandingAmount: 0,
            CancelledNetAmount: 0,
            CancelledBillNumber: "",
            dupNewDescription: '[]',
            searchServiceName: '',
            isForEditBill: this.props?.isForEditBill ? true : false,
            savedDiscountReason: '',
            BillReceivedAmount: 0,
            disableBtn: false,
            docSplitPopup: false,
            disableSplitSharePopup: localGetItem("allow_doctor_fees_split_up") === "false" ? true : false,
            isDiscountBlock: false,
            IndivDiscountComments: "",
            creditChange: false,
            paymentCreditType: this.props.opBillPatientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                this.props.opBillPatientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                    this.props.opBillPatientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
            insuranceData: [],
            corporateData: [],
            selectedNewShare: null,
            dupSelectedNewShare: '',
            DateTimeChanged: false,
            concessionRestrictAmount: 0,
            billConcessionType: '',
            DisableBtn: false,

            // ServiceCode State

            serviceCodeSearch: '',
            serviceCodeDataList: [],
            dupServiceCodeDataList: '[]',
            frequentServiceCodeList: [],
            selectedServiceCode: null,
            serviceCodeText: '',
            serviceNameCodeText: '',
            serviceTypeCodeText: '',
            serviceCodeAmt: 0,
            selectedServiceCodeWard: null,
            serviceCodeData: null,
            selectedServiceCodeText: '',
            selectedServiceNameCodeText: '',
            selectedServiceTypeCodeText: '',
            serviceCodeList: [],
            showCode: false,
            DisableBtn: false,
            isCreditBill: false,
        }
    }

    componentDidMount() {
        let concession = localGetItem('billing_concession_type')
        let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
        this.setState({
            billConcessionType: concession,
            showCode: showCode
        })
        if (!this.props.forCancel || this.props?.isForEditBill) { // No need for Cancel Bill Flow
            this.getServiceTemplateData()
            this.getServiceDescriptionData()
        }
        if (this.props.BillData && Object.keys(this.props.BillData).length < 0) {
            this.setState({
                billingList: this.props.BillData?.services,
                BillData: this.props.BillData
            })
        }
        let OpPatientData = getCachevalue("patientData")
        let PatientData = JSON.parse(OpPatientData)
        let appointmentDetail = getCachevalue("withoutAppointment");
        let details = JSON.parse(appointmentDetail);
        var Permission = JSON.parse(localGetItem("loggedInUserInfo"))
        this.setState({
            patientData: PatientData,
            withOutAppointment: details,
            per_access: Permission.permission_access

        }, () => {
            this.setState({
                corporateName: this.props.corporateName ? this.props.corporateName : this.state.patientData?.company_name,
                employerId: this.state.patientData?.employer_id,
                employerDiscount: this.state.patientData?.employer_discount,
                isButtonDisabled: this.state.patientData?.is_disable_bill ? this.state.patientData?.is_disable_bill : false
            })
            // Auto Billing on Hold
            // setTimeout(() => {
            // Fetching AutoBill for OP Billing
            if (this.props.BillData && Object.keys(this.props.BillData).length === 0 && !this.props.forCancel) {
                this.getAutoBillData(false, this.state.patientData?.patient_id, null, null, this.state.patientData?.doctor_id)
            }
            // }, 2000);
        })
        setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
            if (!this.state.DateTimeChanged) {
                const newMinute = new Date().getMinutes();
                if (this.state.templateDate?.getMinutes() && this.state.templateDate?.getMinutes() !== newMinute) {
                    this.setState({
                        templateDate: new Date(),
                    });
                }
                if (this.state.descripDate?.getMinutes() && this.state.descripDate?.getMinutes() !== newMinute) {
                    this.setState({
                        descripDate: new Date(),
                    });
                }
            }
        }, 1000);
        this.getServiceCodeListData()
    }

    componentDidUpdate(prevProps) {
        try {
            if (this.props.opBillPatientData && Object.keys(this.props.opBillPatientData).length > 0 && !this.props.forCancel && prevProps.opBillPatientData?.primary_payment_type !== this.props.opBillPatientData?.primary_payment_type) {
                let patientData = this.props.opBillPatientData
                if ((patientData.primary_payment_type === "Corprate credit" && patientData.company_name === '') || patientData.primary_payment_type === "Insurance credit" && patientData.insurance_name === '') {
                    this.setState({
                        selPayemtnCreditType: 'patientcredit',
                        paymentCreditType: 'patientcredit'
                    })
                }
                else {
                    this.setState({
                        paymentCreditType: this.props.opBillPatientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                            this.props.opBillPatientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                                this.props.opBillPatientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                    })
                }
            }
            if (this.props.DamographData !== this.state.DemographicData && prevProps !== this.props && this.props.isFromQuickBilling && this.props.DamographData !== undefined) {
                let DemoData = this.props.DamographData
                if ((DemoData.insurance_company_name === '' && this.state.DemographicData?.insurance_company_name != '') || (DemoData.company_name === '' && this.state.DemographicData?.company_name != '')) {
                    this.setState({
                        selPayemtnCreditType: 'patientcredit',
                        paymentCreditType: 'patientcredit'
                    })
                }
                this.setState({
                    DemographicData: this.props.DamographData,
                    templateDate: this.props.DamographData?.appointmentDateTime,
                    descripDate: this.props.DamographData?.appointmentDateTime,
                    followupID: this.props.followupID ? this.props.followupID : null
                    // DateTimeChanged : true
                }, () => {
                    // Auto Billing
                    if (
                        (this.props.DamographData?.PatientID !== prevProps.DamographData?.PatientID) ||
                        (this.props.DamographData?.selectedAppointTypeId?.id !== prevProps.DamographData?.selectedAppointTypeId?.id && this.props.DamographData?.selectedAppointTypeId !== null) ||
                        (this.props.DamographData?.selectedDocId?.doctor_id !== prevProps.DamographData?.selectedDocId?.doctor_id && this.props.DamographData?.selectedDocId !== null) ||
                        ((this.props.DamographData?.appointmentDateTime !== prevProps.DamographData?.appointmentDateTime) && DateTime.fromJSDate(this.props.DamographData?.appointmentDateTime).toFormat('yyyy-MM-dd') !== "Invalid DateTime")
                    ) {
                        //Fetching AutoBill for QuickBilling
                        if (this.props.DamographData?.selectedAppointTypeId !== null && this.props.DamographData?.selectedDocId !== null && !this.props.DamographData?.isNurse && !this.props.forCancel) {
                            this.getAutoBillData(true, this.state.DemographicData?.PatientID, this.props.DamographData?.appointmentDateTime, this.props.DamographData?.selectedAppointTypeId?.appoinment_type, this.props.DamographData?.selectedDocId?.doctor_id)
                        }

                    }
                })
            }
            if (this.props.DamographData?.isNurse && prevProps.DamographData?.isNurse != this.props.DamographData?.isNurse) {
                this.setState({
                    billingList: []
                })
            }
            if (this.props.BillData && Object.keys(this.props.BillData).length > 0 && prevProps.BillData !== this.props.BillData) {
                this.props.BillData?.services.forEach((element) => {
                    element.discountPercentage = element.service_discount_percentage
                    element.discountAmount = element.service_discount_amount
                    element.amount = element.service_rate_per_unit
                    element.old_time_taken = element.service_old_unit_count ? element.service_old_unit_count : null
                    element.totalAmount = ((element.service_unit_count?.length === 5) || (element.service_unit_count?.length === 6) || (element.service_unit_count?.length === 8) || (element.service_unit_count?.length === 9)) ? this.CalculateHourAmount((+element.service_rate_per_unit), (element.service_unit_count.split(":")[0] + ":" + element.service_unit_count.split(":")[1]), (element.old_time_taken ? element.old_time_taken : element.service_unit_count.split(":")[0] + ":" + element.service_unit_count.split(":")[1]), element?.minimum_time, element?.minimum_amount) : ((+element.service_rate_per_unit) * (+element.service_unit_count))
                    element.is_edit_discount = element.is_edit_discount
                    element.is_edit_amount = element.is_edit_amount
                    element.is_edit_unit = element.is_edit_unit
                    element.unit = ((element.service_unit_count?.length === 5) || (element.service_unit_count?.length === 6) || (element.service_unit_count?.length === 8) || (element.service_unit_count?.length === 9)) ? null : element.service_unit_count
                    element.time_taken = ((element.service_unit_count?.length === 5) || (element.service_unit_count?.length === 6) || (element.service_unit_count?.length === 8) || (element.service_unit_count?.length === 9)) ? (element.service_unit_count.split(":")[0] + ":" + element.service_unit_count.split(":")[1]) : null
                    element.doctor_fee = CommonValidation.calculatePercentage(element.doctor_percentage, element.old_doctor_fee)
                    element.hospital_fee = CommonValidation.calculatePercentage(element.hospital_percentage, element.old_hospital_fee)
                    element.discount_doctor_fee = (element.old_doctor_fee - element.new_doctor_fee).toFixed(2)
                    element.discount_hospital_fee = (element.old_hospital_fee - element.new_hospital_fee).toFixed(2)
                    element.discount_doctor_percentage = CommonValidation.amountPercentage(element.discount_doctor_fee, element.old_doctor_fee)
                    element.discount_hospital_percentage = CommonValidation.amountPercentage(element.discount_hospital_fee, element.old_hospital_fee)
                    element.doctor_percentage = element.doctor_percentage ? element.doctor_percentage : 0
                    element.hospital_percentage = element.hospital_percentage ? element.hospital_percentage : 0
                    element.service_catagory__catagory_name = element.service_catagory__catagory_name ? element.service_catagory__catagory_name : ''
                    element.is_auto_billing = element?.is_auto_billing
                    element.start_date = element.procedure_start_time ? element.procedure_start_time : moment(this.state.patientData?.appointment_date)
                    element.end_date = element.procedure_end_time
                    element.min_amount = element?.minimum_amount ? element?.minimum_amount : 0
                    element.minimum_time = element?.minimum_time ? element?.minimum_time : '00:00:00'
                    element.isSplitBlock = false
                    element.isDiscountBlock = false
                    element.individual_discount_comments = element?.individual_discount_comments ? element?.individual_discount_comments : ""
                    if ((element?.service_catagory__catagory_name === 'Consultation') || (element?.service_catagory__catagory_name === 'OP Procedures')) {
                        element.service_fee = {
                            "total_amt": element.old_doctor_fee + element.old_hospital_fee,
                            "doctor_fee": element.new_doctor_fee,
                            "amount_type": element.amount_type,
                            "hospital_fee": element.new_hospital_fee,
                            "doctor_percentage": element.doctor_percentage ? element.doctor_percentage : 0,
                            "hospital_percentage": element.hospital_percentage ? element.hospital_percentage : 0,
                            "discount_doctor_fee": Math.round(element.old_doctor_fee - element.new_doctor_fee),
                            "discount_hospital_fee": Math.round(element.old_hospital_fee - element.new_hospital_fee),
                            "original_doctor_fee": element.old_doctor_fee,
                            "original_hospital_fee": element.old_hospital_fee,
                            "discount_doctor_percentage": CommonValidation.amountPercentage(element.discount_doctor_fee, element.old_doctor_fee),
                            "discount_hospital_percentage": CommonValidation.amountPercentage(element.discount_hospital_fee, element.old_hospital_fee),
                        }
                    }
                    this.state.billingList.push(element)
                })
                this.setState({
                    billingList: this.state.billingList,
                    BillData: this.props.BillData,
                    CashAmount: this.props.BillData?.amount_cash === 0 || this.props.isForEditBill ? "" : this.props.BillData?.amount_cash,
                    CardAmount: this.props.BillData?.amount_card === 0 || this.props.isForEditBill ? "" : this.props.BillData?.amount_card,
                    BankTfrAmount: this.props.BillData?.bank_transfer_amount === 0 || this.props.isForEditBill ? "" : this.props.BillData?.bank_transfer_amount,
                    UPIAmount: this.props.BillData?.upi_amount === 0 || this.props.isForEditBill ? "" : this.props.BillData?.upi_amount,
                    ChequeAmount: this.props.BillData?.cheque_amount === 0 || this.props.isForEditBill ? "" : this.props.BillData?.cheque_amount,
                    isCard: this.props.BillData?.amount_card === 0 || this.props.isForEditBill ? false : true,
                    isCash: this.props.BillData?.amount_cash === 0 || this.props.isForEditBill ? false : true,
                    isUPI: this.props.BillData?.upi_amount === 0 || this.props.isForEditBill ? false : true,
                    isBankTranfer: this.props.BillData?.bank_transfer_amount === 0 || this.props.isForEditBill ? false : true,
                    isCheque: this.props.BillData?.cheque_amount === 0 || this.props.isForEditBill ? false : true,
                    CardTransactionNumber: this.props.BillData?.card_ref_no,
                    UPINumber: this.props.BillData?.upi_ref_no,
                    BankTfrNumber: this.props.BillData?.bank_ref_no,
                    ChequeNumber: this.props.BillData?.cheque_ref_no,
                    totalDiscountedPercentage: +this.props.BillData?.discount_percent,
                    discount: +this.props.BillData?.discount,
                    forCancel: (this.props.forCancel && this.props?.isForEditBill !== true),
                    isForEditBill: this.props.isForEditBill,
                    discountReason: this.props.BillData?.remarks,
                    BillReceivedAmount: this.props.BillData?.received_amount ? this.props.BillData?.received_amount : 0,
                    templateDate: (this.state.forCancel || this.state.isForEditBill) ? this.props.BillData?.invoice_date : this.state.templateDate,
                    descripDate: (this.state.forCancel || this.state.isForEditBill) ? this.props.BillData?.invoice_date : this.state.templateDate,
                    DateTimeChanged: (this.state.forCancel || this.state.isForEditBill) ? true : false,
                    CancelledBillNumber: this.props.BillData?.invoice_number ? this.props.BillData?.invoice_number : "",
                    savedDiscountReason: this.props.BillData?.remarks,
                    totalDiscountedAmount: +this.props.BillData?.discount,
                }, () => {
                    // this.calculateTotalDiscount()
                    if (this.props.forCancel && !this.props.isForEditBill) {
                        // this.getCancelRefundDetails()
                        let paymentMode = '';
                        if (this.props.BillData?.payment_mode?.length === 1) {
                            if ((this.props.BillData?.payment_mode[0] === 1) || (this.props.BillData?.payment_mode[0] === 3) || (this.props.BillData?.payment_mode[0] === 5)) {
                                paymentMode = this.props.BillData?.payment_mode[0];
                            }
                        }
                        this.setState({
                            isCard: false,
                            isCash: paymentMode === 1 ? true : false,
                            isUPI: paymentMode === 3 ? true : false,
                            isBankTranfer: paymentMode === 5 ? true : false,
                            isCheque: false,
                            CashAmount: paymentMode === 1 ? this.state.CashAmount : "",
                            CardAmount: "",
                            CardTransactionNumber: "",
                            UPINumber: "",
                            UPIAmount: paymentMode === 3 ? this.state.UPIAmount : "",
                            BankTfrAmount: paymentMode === 5 ? this.state.BankTfrAmount : "",
                            BankTfrNumber: "",
                            ChequeNumber: "",
                            ChequeAmount: "",
                        })
                    }
                })
            }
            if (this.props?.SuccessFromCancel && this.props?.SuccessFromCancel !== prevProps?.SuccessFromCancel) {
                this.getServiceTemplateData()
                this.getServiceDescriptionData()
            }
        } catch (e) {
            this.successErrorMessgae(e?.message, 'error')
        }

    }

    getCancelRefundDetails = () => {
        try {
            let patientModuleId = this.state.BillData?.appointment_id ? this.state.BillData?.appointment_id : null
            let billAmt = this.state.BillData?.amount_net ? this.state.BillData?.amount_net : 0
            let ID = this.state.BillData?.id ? this.state.BillData?.id : null
            RestAPIService.getAll(Serviceurls.OP_CANCEL_BILL + '?bill_id=' + ID + "&patient_module_id=" + patientModuleId + "&bill_amount=" + billAmt)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            BillRefundData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.successErrorMessgae(error.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(error.message, 'error')
                    }
                })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    getServiceTemplateData = () => {
        try {
            let date = this.state.templateDate ? this.state.templateDate : this.state.descripDate
            let startDate = new Date(date)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}` : ""
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSRCTEMPLATE + '?template_name=' + this.state.serviceSearch + '&billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceTemplateData: response.data.data,
                            serviceTemplateDataNew: JSON.stringify(response.data.data)
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.successErrorMessgae(error.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(error.message, 'error')
                    }
                })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }
    getInsuranceDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            insuranceData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.successErrorMessgae(error.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(error.message, 'error')
                    }
                })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }
    getCorporateDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            corporateData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.successErrorMessgae(error.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(error.message, 'error')
                    }
                })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    getServiceDescriptionData = () => {
        try {
            let date = this.state.templateDate ? this.state.templateDate : this.state.descripDate
            let startDate = new Date(date)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}` : ""
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSRCNAME + '?service_type=' + this.state.descriptionSerach + '&billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        // let NewServive = []
                        // response?.data?.data.forEach((item) => {NewServive = [...NewServive,...item.data]})
                        this.setState({
                            serviceDescriptionData: response?.data?.data,
                            serviceDescriptionDataNew: JSON.stringify(response?.data?.data),
                            newDescription: [],
                            description: "",
                            newDescription: [],
                            dupNewDescription: '[]',
                            searchServiceName: '',
                            disableBtn: false,
                        })
                    }
                }).catch((error) => {
                    this.setState({ disableBtn: false })
                    if (error?.response?.data?.status) {
                        this.successErrorMessgae(error.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(error.message, 'error')
                    }
                })
        } catch (error) {
            this.setState({ disableBtn: false })
            this.successErrorMessgae(error.message, 'error')
        }
    }

    getAutoBillData = (FromQuickBilling = false, PatientId, dateTime = null, AppointType = null, DoctorId = null) => {
        try {
            let QueryParams = ""
            const currentDate = new Date().toISOString().split('T')[0];
            const currentTime = DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS)
            if (FromQuickBilling) {
                var AptDate = dateTime !== null ? DateTime.fromJSDate(dateTime).toFormat('yyyy-MM-dd') : null
                var Aptime = dateTime !== null ? DateTime.fromJSDate(dateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : null
                var patient_id = PatientId === null ? "" : PatientId
                QueryParams = Serviceurls.OP_AUTO_BILLING + '?patient_id=' + patient_id + "&appointment_date=" + AptDate + "&appointment_time=" + Aptime + "&appointment_type=" + AppointType + "&doctor_id=" + DoctorId + "&is_quick_billing=true" + "&billing_date=" + currentDate + "&billing_time=" + currentTime
            } else {
                QueryParams = Serviceurls.OP_AUTO_BILLING + '?patient_id=' + PatientId + "&doctor_id=" + DoctorId + "&is_quick_billing=false" + "&billing_date=" + currentDate + "&billing_time=" + currentTime
            }

            RestAPIService.getAll(QueryParams)
                .then((response => {
                    if (response.data.status === 'success') {
                        var data = response.data.data
                        this.state.billingList = []
                        data?.forEach((element) => {
                            if (element.service_type === 'Consultation') {
                                let serviceCharge = element?.service_charges[0]
                                let holidayFee = serviceCharge?.holiday_fee?.total_amt
                                let emergencyFee = serviceCharge?.emergency_fee?.total_amt
                                let newPatient = serviceCharge?.new_patient?.total_amt
                                let reviewpatient = serviceCharge?.review_patient?.total_amt
                                let oldPatient = serviceCharge?.old_patient?.total_amt
                                let holidayDoctorFee = serviceCharge?.holiday_fee?.doctor_fee
                                let holidayHospitalFee = serviceCharge?.holiday_fee?.hospital_fee
                                let holidayDoctorPercentage = serviceCharge?.holiday_fee?.doctor_percentage
                                let holidayHospitalPercentage = serviceCharge?.holiday_fee?.hospital_percentage
                                let emergencyDoctorFee = serviceCharge?.emergency_fee?.doctor_fee
                                let emergencyHospitalFee = serviceCharge?.emergency_fee?.hospital_fee
                                let emergencyDoctorPercentage = serviceCharge?.emergency_fee?.doctor_percentage
                                let emergencyHospitalPercentage = serviceCharge?.emergency_fee?.hospital_percentage
                                let newDoctorFee = serviceCharge?.new_patient?.doctor_fee
                                let newHospitalFee = serviceCharge?.new_patient?.hospital_fee
                                let newDoctorPercentage = serviceCharge?.new_patient?.doctor_percentage
                                let newHospitalPercentage = serviceCharge?.new_patient?.hospital_percentage
                                let reviewDoctorFee = serviceCharge?.review_patient?.doctor_fee
                                let reviewHospitalFee = serviceCharge?.review_patient?.hospital_fee
                                let reviewDoctorPercentage = serviceCharge?.review_patient?.doctor_percentage
                                let reviewHospitalPercentage = serviceCharge?.review_patient?.hospital_percentage
                                let oldDoctorFee = serviceCharge?.old_patient?.doctor_fee
                                let oldHospitalFee = serviceCharge?.old_patient?.hospital_fee
                                let oldDoctorPercentage = serviceCharge?.old_patient?.doctor_percentage
                                let oldHospitalPercentage = serviceCharge?.old_patient?.hospital_percentage
                                if (element.is_holiday) {
                                    element.amount = holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== '' ? emergencyFee : element.patient_type === 'new_patient' ? newPatient : element.patient_type === 'review_patient' ? reviewpatient : oldPatient : element.patient_type === 'new_patient' ? newPatient : element.patient_type === 'review_patient' ? reviewpatient : oldPatient
                                    element.totalAmount = element.amount
                                    element.service_fee = {
                                        doctor_fee: holidayDoctorFee !== '' ? holidayDoctorFee : element.is_emergency ? emergencyDoctorFee !== '' ? emergencyDoctorFee : element.patient_type === 'new_patient' ? newDoctorFee : element.patient_type === 'review_patient' ? reviewDoctorFee : oldDoctorFee : element.patient_type === 'new_patient' ? newDoctorFee : element.patient_type === 'review_patient' ? reviewDoctorFee : oldDoctorFee,
                                        doctor_percentage: holidayDoctorPercentage !== '' ? holidayDoctorPercentage : element.is_emergency ? emergencyDoctorPercentage !== '' ? emergencyDoctorPercentage : element.patient_type === 'new_patient' ? newDoctorPercentage : element.patient_type === 'review_patient' ? reviewDoctorPercentage : oldDoctorPercentage : element.patient_type === 'new_patient' ? newDoctorPercentage : element.patient_type === 'review_patient' ? reviewDoctorPercentage : oldDoctorPercentage,
                                        hospital_percentage: holidayHospitalPercentage !== '' ? holidayHospitalPercentage : element.is_emergency ? emergencyHospitalPercentage !== '' ? emergencyHospitalPercentage : element.patient_type === 'new_patient' ? newHospitalPercentage : element.patient_type === 'review_patient' ? reviewHospitalPercentage : oldHospitalPercentage : element.patient_type === 'new_patient' ? newHospitalPercentage : element.patient_type === 'review_patient' ? reviewHospitalPercentage : oldHospitalPercentage,
                                        hospital_fee: holidayHospitalFee !== '' ? holidayHospitalFee : element.is_emergency ? emergencyHospitalFee !== '' ? emergencyHospitalFee : element.patient_type === 'new_patient' ? newHospitalFee : element.patient_type === 'review_patient' ? reviewHospitalFee : oldHospitalFee : element.patient_type === 'new_patient' ? newHospitalFee : element.patient_type === 'review_patient' ? reviewHospitalFee : oldHospitalFee,
                                        total_amount: element.amount
                                    }
                                } else if (!element.is_holiday && element.is_emergency) {
                                    element.amount = emergencyFee !== '' ? emergencyFee : element.patient_type === 'new_patient' ? newPatient : element.patient_type === 'review_patient' ? reviewpatient : oldPatient
                                    element.totalAmount = element.amount
                                    element.service_fee = {
                                        doctor_fee: emergencyDoctorFee !== '' ? emergencyDoctorFee : element.patient_type === 'new_patient' ? newDoctorFee : element.patient_type === 'review_patient' ? reviewDoctorFee : oldDoctorFee,
                                        doctor_percentage: emergencyDoctorPercentage !== '' ? emergencyDoctorPercentage : element.patient_type === 'new_patient' ? newDoctorPercentage : element.patient_type === 'review_patient' ? reviewDoctorPercentage : oldDoctorPercentage,
                                        hospitalPercentage: emergencyHospitalPercentage !== '' ? emergencyHospitalPercentage : element.patient_type === 'new_patient' ? newHospitalPercentage : element.patient_type === 'review_patient' ? reviewHospitalPercentage : oldHospitalPercentage,
                                        hospital_fee: emergencyHospitalFee !== '' ? emergencyHospitalFee : element.patient_type === 'new_patient' ? newHospitalFee : element.patient_type === 'review_patient' ? reviewHospitalFee : oldHospitalFee,
                                        total_amount: element.amount
                                    }
                                } else {
                                    element.amount = element.patient_type === 'new_patient' ? newPatient : element.patient_type === 'review_patient' ? reviewpatient : oldPatient
                                    element.totalAmount = element.amount
                                    element.service_fee = {
                                        doctor_fee: element.patient_type === 'new_patient' ? newDoctorFee : element.patient_type === 'review_patient' ? reviewDoctorFee : oldDoctorFee,
                                        doctor_percentage: element.patient_type === 'new_patient' ? newDoctorPercentage : element.patient_type === 'review_patient' ? reviewDoctorPercentage : oldDoctorPercentage,
                                        hospital_percentage: element.patient_type === 'new_patient' ? newHospitalPercentage : element.patient_type === 'review_patient' ? reviewHospitalPercentage : oldHospitalPercentage,
                                        hospital_fee: element.patient_type === 'new_patient' ? newHospitalFee : element.patient_type === 'review_patient' ? reviewHospitalFee : oldHospitalFee,
                                        total_amount: element.amount
                                    }
                                }
                            } else {

                                let serviceCharge = element?.service_charges[0]?.service_fee ? element?.service_charges[0]?.service_fee : ''
                                let holidayFee = element?.service_charges[0]?.holiday_fee?.total_amt ? element?.service_charges[0]?.holiday_fee?.total_amt : element?.service_charges[0]?.holiday_fee ? element?.service_charges[0]?.holiday_fee : ''
                                let emergencyFee = element?.service_charges[0]?.emergency_fee?.total_amt ? element?.service_charges[0]?.emergency_fee?.total_amt : element?.service_charges[0]?.emergency_fee ? element?.service_charges[0]?.emergency_fee : ''
                                let serviceFee = element?.service_charges[0]?.service_fee?.total_amt ? element?.service_charges[0]?.service_fee?.total_amt : element?.service_charges[0]?.service_fee ? element?.service_charges[0]?.service_fee : element?.service_charges[0]?.service_amount
                                let minimumFee = element?.unit === "hrs" && element?.service_charges[0]?.minimum_amount ? element?.service_charges[0]?.minimum_amount : null
                                let holidayDoctorFee = element?.service_charges[0]?.holiday_fee?.doctor_fee ? element?.service_charges[0]?.holiday_fee?.doctor_fee : 0
                                let holidayHospitalFee = element?.service_charges[0]?.holiday_fee?.hospital_fee ? element?.service_charges[0]?.holiday_fee?.hospital_fee : 0
                                let holidayDoctorPercentage = element?.service_charges[0]?.holiday_fee?.doctor_percentage ? element?.service_charges[0]?.holiday_fee?.doctor_percentage : 0
                                let holidayHospitalPercentage = element?.service_charges[0]?.holiday_fee?.hospital_percentage ? element?.service_charges[0]?.holiday_fee?.hospital_percentage : 0
                                let emergencyDoctorFee = element?.service_charges[0]?.emergency_fee?.doctor_fee ? element?.service_charges[0]?.emergency_fee?.doctor_fee : 0
                                let emergencyHospitalFee = element?.service_charges[0]?.emergency_fee?.hospital_fee ? element?.service_charges[0]?.emergency_fee?.hospital_fee : 0
                                let emergencyDoctorPercentage = element?.service_charges[0]?.emergency_fee?.doctor_percentage ? element?.service_charges[0]?.emergency_fee?.doctor_percentage : 0
                                let emergencyHospitalPercentage = element?.service_charges[0]?.emergency_fee?.hospital_percentage ? element?.service_charges[0]?.emergency_fee?.hospital_percentage : 0
                                let serviceDoctorFee = element?.service_charges[0]?.service_fee?.doctor_fee ? element?.service_charges[0]?.service_fee?.doctor_fee : 0
                                let serviceHospitalFee = element?.service_charges[0]?.service_fee?.hospital_fee ? element?.service_charges[0]?.service_fee?.hospital_fee : 0
                                let serviceDoctorPercentage = element?.service_charges[0]?.service_fee?.doctor_percentage ? element?.service_charges[0]?.service_fee?.doctor_percentage : 0
                                let serviceHospitalPercentage = element?.service_charges[0]?.service_fee?.hospital_percentage ? element?.service_charges[0]?.service_fee?.hospital_percentage : 0

                                if (element.is_holiday) {
                                    element.amount = holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== "" ? emergencyFee : serviceFee ? serviceFee : element?.service_charges[0]?.service_amount ? element?.service_charges[0]?.service_amount : 0 : serviceFee ? serviceFee : element?.service_charges[0]?.service_amount ? element?.service_charges[0]?.service_amount : 0
                                    element.totalAmount = element.amount + +minimumFee
                                    element.service_fee = {
                                        doctor_fee: holidayDoctorFee !== '' ? holidayDoctorFee : element.is_emergency ? emergencyDoctorFee !== "" ? emergencyDoctorFee : serviceDoctorFee ? serviceDoctorFee : 0 : serviceDoctorFee ? serviceDoctorFee : 0,
                                        doctor_percentage: holidayDoctorPercentage !== '' ? holidayDoctorPercentage : element.is_emergency ? emergencyHospitalPercentage !== "" ? emergencyHospitalPercentage : serviceDoctorPercentage ? serviceDoctorPercentage : 0 : serviceDoctorPercentage ? serviceDoctorPercentage : 0,
                                        hospital_fee: holidayHospitalFee !== '' ? holidayHospitalFee : element.is_emergency ? emergencyHospitalFee !== "" ? emergencyHospitalFee : serviceHospitalFee ? serviceHospitalFee : 0 : serviceHospitalFee ? serviceHospitalFee : 0,
                                        hospital_percentage: holidayHospitalPercentage !== '' ? holidayHospitalPercentage : element.is_emergency ? emergencyHospitalPercentage !== "" ? emergencyHospitalPercentage : serviceHospitalPercentage ? serviceHospitalPercentage : 0 : serviceHospitalPercentage ? serviceHospitalPercentage : 0,
                                        total_amount: element.amount
                                    }
                                } else if (!element.is_holiday && element.is_emergency) {
                                    element.amount = emergencyFee !== '' ? emergencyFee : serviceFee ? serviceFee : element?.service_charges[0]?.service_amount
                                    element.totalAmount = element.amount + +minimumFee
                                    element.service_fee = {
                                        doctor_fee: emergencyDoctorFee !== '' ? emergencyDoctorFee : serviceDoctorFee ? serviceDoctorFee : 0,
                                        doctor_percentage: emergencyDoctorPercentage !== '' ? emergencyDoctorPercentage : serviceDoctorPercentage ? serviceDoctorPercentage : 0,
                                        hospital_fee: emergencyHospitalFee !== '' ? emergencyHospitalFee : serviceHospitalFee ? serviceHospitalFee : 0,
                                        hospital_percentage: emergencyHospitalPercentage !== '' ? emergencyHospitalPercentage : serviceHospitalPercentage ? serviceHospitalPercentage : 0,
                                        total_amount: element.amount
                                    }
                                } else {
                                    element.amount = serviceFee ? serviceFee : element?.service_charges[0]?.service_amount ? element?.service_charges[0]?.service_amount : 0
                                    element.totalAmount = element?.amount + +minimumFee
                                    element.service_fee = {
                                        doctor_fee: serviceDoctorFee ? serviceDoctorFee : element?.service_charges[0] ? element?.service_charges[0]?.service_fee?.doctor_fee : 0,
                                        doctor_percentage: serviceDoctorPercentage ? serviceDoctorPercentage : element?.service_charges[0] ? element?.service_charges[0]?.service_fee?.doctor_percentage : 0,
                                        hospital_fee: serviceHospitalFee ? serviceHospitalFee : element?.service_charges[0] ? element?.service_charges[0]?.service_fee?.hospital_fee : 0,
                                        hospital_percentage: serviceHospitalPercentage ? serviceHospitalPercentage : element?.service_charges[0] ? element?.service_charges[0]?.service_fee?.hospital_percentage : 0,
                                        total_amount: element.amount
                                    }
                                }
                            }
                            let Unit = element.time_taken ? element.time_taken : element.unit
                            element.discountPercentage = element.discount_percentage ? element.discount_percentage : 0
                            element.discountAmount = element.discount_amount ? element.discount_amount : 0
                            // element.totalAmount = element.unit === 'hrs' ? this.CalculateHourAmount(element.amount, element.time_taken) : 
                            element.unit = Unit
                            element.time_taken = element.time_taken ? element.time_taken : null
                            element.is_edit_front_office = element.is_edit_front_office
                            element.is_edit_discount = false
                            element.is_edit_amount = false
                            element.is_edit_unit = false
                            element.service_name = element.service_name
                            element.service_type = element.service_type
                            element.is_auto_billing = true
                            element.min_amount = element?.time_taken && element?.service_charges[0]?.minimum_amount ? element?.service_charges[0]?.minimum_amount : 0
                            element.minimum_time = element?.minimum_time ? element?.minimum_time : '00:00:00'

                            this.state.billingList.push(element)
                        })
                        this.setState({
                            billingList: this.state.billingList,
                        })
                    }
                })).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.successErrorMessgae(e.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(e.message, 'error')
                    }
                })
        } catch (e) {
            this.successErrorMessgae(e.message, 'error')
        }
    }

    getServiceCodeListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.OP_SERVICE_CODE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceCodeDataList: response.data.data,
                            dupServiceCodeDataList: JSON.stringify(response.data.data),
                            frequentServiceCodeList: response.data.frequent_service_codes
                        })
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.successErrorMessgae(e.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(e.message, 'error')
                    }
                })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    clearTable = () => {
        clearCachevalue("OP_CreateAppointment_PatientId");
        clearCachevalue("AppRequestPatientData");
        clearCachevalue("QRRequestPatientData");
        this.setState({
            billingList: [],
            serviceList: [],
            descriptionList: [],
            totalDiscountedAmount: "",
            totalDiscountedPercentage: "",
            payableAmount: 0,
            roundOff: 0,
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            CashAmount: "",
            CardAmount: "",
            BankTfrAmount: "",
            ChequeAmount: "",
            UPIAmount: "",
            UPINumber: "",
            CardTransactionNumber: "",
            ChequeNumber: "",
            BankTfrNumber: "",
            isCredit: false,
            isCashless: false,
            isRegular: true,
            totalServiceAmount: 0,
            corporatePay: "",
            isSavedBillClicked: false,
            FullAmountEntered: false,
            isEditDiscount: false,
            isEditRate: false,
            isEditunit: false,
            serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew),
            serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew),
            ClearClicked: false,
            autoBillFetched: false,
            CreatedPatientId: "",
            PatientID: null,
            isReceivePaymentClicked: false,
            OutstandingAmount: 0,
            CancelledNetAmount: 0,
            forCancel: null,
            isForEditBill: false,
            templateDate: new Date(),
            descripDate: new Date(),
            discountReason: "",
            savedDiscountReason: "",
            concessionRestrictAmount: '',
            serviceCodeList: [],
            value: this.state.showCode ? 'three' : 'two'
        }, () => {
            if (this.props.isFromQuickBilling) {
                this.props.ClearClicked()
                this.setState({
                    paymentCreditType: 'patientcredit'
                })
            }
            this.props.ClearBillDatas()
        })
    }

    CalculateAmount() {
        var states = this.state
        let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
        let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
        let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
        let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
        let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
        if (states.totalPayableAmount === "") {
            return false
        }
        else if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt) <= +states.totalPayableAmount) {
            return true
        }
    }

    lodaerFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    closeSplitPopup = () => {
        this.setState({ docSplitPopup: false, selectedData: JSON.parse(this.state.dupliSelectedData) })
    }

    splitTimeTaken = (time_taken) => {
        if (time_taken && typeof time_taken === "string") {
            let timeTakenStr = time_taken?.split(':');
            let timeTaken = timeTakenStr?.length > 0 && timeTakenStr?.slice(0, 2)?.join(':');
            return timeTaken;
        }
        return time_taken;
    }

    opPatientBilling = (AppointId = null) => {
        try {
            if (this.state.billingList.length > 0) {
                let payment = []
                if (this.state.isCash) {
                    payment.push(1)
                }
                if (this.state.isCard) {
                    payment.push(2)
                }
                if (this.state.isUPI) {
                    payment.push(3)
                }
                if (this.state.isCheque) {
                    payment.push(4)
                }
                if (this.state.isBankTranfer) {
                    payment.push(5)
                }
                let noDiscountAmount = 0;
                this.state.billingList.forEach(element => {
                    noDiscountAmount = noDiscountAmount + (element.unit * +element.amount)
                })
                let date = this.state.templateDate ? this.state.templateDate : this.state.descripDate
                let startDate = new Date(date)
                let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                let invoice_date = date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : ""
                let creditType = this.state.paymentCreditType === "patientcredit" ? "Patient credit" :
                    this.state.paymentCreditType === "corporatecredit" ? "Corprate credit" :
                        this.state.paymentCreditType === "insurancecredit" ? "Insurance credit" : 'Patient credit'
                this.setState({
                    noDiscountAmount: noDiscountAmount
                }, () => {
                    let services = [];
                    this.state.billingList.forEach(element => {
                        let Unit = element.time_taken ? this.splitTimeTaken(element.time_taken) : element.unit
                        let old_Unit = element.old_time_taken ? element.old_time_taken : element.time_taken ? element.time_taken : null
                        services.push({
                            service_name: element.service_name ? CommonValidation.removeSpace(element.service_name) : '',
                            service_type: element.service_type ? CommonValidation.removeSpace(element.service_type) : '',
                            amount_net: ((element.time_taken ? this.CalculateHourAmount(+element?.amount, Unit, old_Unit, element?.minimum_time, +element?.min_amount) : +element.amount * element.unit) - +element.discountAmount),
                            amount_gross: element.amount === "" ? 0 : (element.time_taken ? this.CalculateHourAmount(+element?.amount, Unit, old_Unit, element?.minimum_time, +element?.min_amount) : +element.amount * element.unit),
                            service_discount_percentage: element.discountPercentage === "" ? 0 : +element.discountPercentage,
                            service_discount_amount: element.discountAmount === "" ? 0 : +element.discountAmount,
                            service_rate_per_unit: element.amount === "" ? 0 : +element.amount,
                            service_unit_count: Unit ? Unit : "1",
                            service_old_unit_count: old_Unit ? old_Unit : null,
                            item_id: this.props.BillData && Object.keys(this.props.BillData).length > 0 ? element.line_item_id : null,
                            template_id: element.templateId ? element.templateId : null,
                            service_id: element?.op_service_id ? element.op_service_id : element?.id ? element.id : null,
                            doctor_fee: element.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : element.new_doctor_fee ? element.new_doctor_fee : 0,
                            doctor_percentage: element.service_fee?.doctor_percentage ? element?.service_fee?.doctor_percentage : element.doctor_percentage ? element.doctor_percentage : 0,
                            hospital_fee: element.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : element.new_hospital_fee ? element.new_hospital_fee : 0,
                            hospital_percentage: element.service_fee?.hospital_percentage ? element?.service_fee?.hospital_percentage : element.hospital_percentage ? element.hospital_percentage : 0,
                            service_catagory_id: element.service_catagory_id ? element.service_catagory_id : null,
                            old_doctor_fee: element?.service_fee?.original_doctor_fee ? element?.service_fee?.original_doctor_fee : element?.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : 0,
                            old_hospital_fee: element?.service_fee?.original_hospital_fee ? element?.service_fee?.original_hospital_fee : element?.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : 0,
                            new_doctor_fee: element.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : 0,
                            new_hospital_fee: element.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : 0,
                            amount_type: element.service_fee?.amount_type ? element.service_fee?.amount_type : element.amount_type ? element.amount_type : 'rupee',
                            is_auto_billing: element?.is_auto_billing,
                            procedure_start_time: null,
                            procedure_end_time: null,
                            minimum_time: element?.minimum_time ? element?.minimum_time : '00:00',
                            minimum_amount: element.time_taken && element?.min_amount ? element?.min_amount : null,
                            individual_discount_comments: element?.individual_discount_comments ? element?.individual_discount_comments : ""
                        })
                    })
                    let amount = ((+this.state.CashAmount) + (+this.state.CardAmount) + (+this.state.UPIAmount) + (+this.state.BankTfrAmount) + (+this.state.ChequeAmount))
                    let status = (amount === 0) ? 'not paid' : (amount < this.state.totalPayableAmount) ? 'partial' : (amount === this.state.totalPayableAmount ? 'paid' : 'not paid')
                    let detailsCredit = this.props.isFromQuickBilling ? this.state.DemographicData : this.props?.opBillPatientData ? this.props?.opBillPatientData : this.state.patientData
                    let ins_name = detailsCredit?.insurance_name ? detailsCredit?.insurance_name : detailsCredit?.insurance_company_name
                    let ins_no = detailsCredit?.policy_no ? detailsCredit?.policy_no : detailsCredit?.policy_number
                    let comp_id = detailsCredit?.employer_id ? detailsCredit?.employer_id : null
                    let employee_no = detailsCredit?.employee_id ? detailsCredit?.employee_id : detailsCredit?.id_no
                    let data = {
                        "invoice": {
                            appointment_id: this.props.appointmentId ? this.props.appointmentId : AppointId !== null ? AppointId : AppointId,
                            amount_invoice: + this.state.totalPayableAmount,
                            amount_net: +this.state.totalPayableAmount,
                            discount_percent: +this.state.totalDiscountedPercentage,
                            discount: +this.state.totalDiscountedAmount,
                            total_sub_amount: +(this.state.totalServiceAmount),
                            // invoice_date: date,
                            amount_gross: this.state.noDiscountAmount ? +this.state.noDiscountAmount : +this.state.totalPayableAmount,
                            amount_cash: +this.state.CashAmount,
                            amount_card: +this.state.CardAmount,
                            upi_amount: +this.state.UPIAmount,
                            bank_transfer_amount: +this.state.BankTfrAmount,
                            cheque_ref_no: this.state.ChequeNumber,
                            credit_type: creditType,
                            bank_ref_no: this.state.BankTfrNumber,
                            upi_ref_no: this.state.UPINumber,
                            card_ref_no: this.state.CardTransactionNumber,
                            cheque_amount: +this.state.ChequeAmount,
                            round_off: +this.state.roundOff,
                            invoice_date: invoice_date,
                            payment_mode: (+this.state.totalDiscountedPercentage === 100 || this.state.totalServiceAmount === 0) ? [1] : payment,
                            amount_paid: amount,
                            remarks: this.state.discountReason ? this.state.discountReason : '',
                            // insurance_name: this.state.selInsName ? this.state.selInsName : ins_name ? ins_name : '',
                            // policy_no: this.state.selPolicyNo ? this.state.selPolicyNo : ins_no ? ins_no : '',
                            employer_id: creditType === "Corprate credit" ? (this.state.selCompanyID ? this.state.selCompanyID : comp_id ? comp_id : null) : null,
                            employee_no: creditType === "Corprate credit" ? (this.state.selEmployeeNo ? this.state.selEmployeeNo : employee_no ? employee_no : "") : "",
                        },
                        removable_ids: this.state.removable_ids,
                        services: services,
                        payment_status: (+this.state.totalDiscountedPercentage === 100 || this.state.totalServiceAmount === 0) ? "paid" : status,
                        bill_type: (+this.state.totalDiscountedPercentage === 100 || this.state.totalServiceAmount === 0) ? 'regular' : (status === 'partial' || status === 'not paid') ? 'credit' : 'regular'
                    }
                    if (this.props.BillData && Object.keys(this.props.BillData).length > 0) { data["bill_id"] = this.props.BillData?.id }
                    if (this.state.isCard || this.state.isCash || this.state.isUPI || this.state.isCheque || this.state.isBankTranfer || this.state.isCredit) {
                        if (!this.CalculateAmount()) {
                            this.successErrorMessgae("Amount Shouldn't Be Greater Than Payable Amount", 'error')
                        } else if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                            this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                            this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                            this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") {
                            this.successErrorMessgae("Please,Enter Reference Number", 'error')
                        } else if (!this.state.isCredit && (status === 'partial' || status === "not paid") && this.state.totalServiceAmount != 0) {
                            this.successErrorMessgae("Amount is Lesser than Payable Amount, Select Add to balance credit", 'error')
                        } else if (this.state.discountReason === '' && ((this.state.totalDiscountedPercentage !== '' && this.state.totalDiscountedPercentage !== 0) || (this.state.totalDiscountedAmount !== '' && this.state.totalDiscountedAmount !== 0))) {
                            this.successErrorMessgae("Reason is mandatory.Click Note icon to enter reason", 'error')
                        } else {
                            this.lodaerFunction(true)
                            RestAPIService.create(data, Serviceurls.FO_PATIEMT_BILLING)
                                .then((response) => {
                                    if (response.data.status === "success") {
                                        clearCachevalue("OP_CreateAppointment_PatientId");
                                        clearCachevalue("AppRequestPatientData");
                                        clearCachevalue("QRRequestPatientData");
                                        if (data.bill_type === 'regular' || data.bill_type === 'credit') {
                                            this.setState({ SuccessInvoice_ID: response.data.bill_id, bill_receipt_id: response.data.receipt_id, isCreditBill: payment?.length === 0 ? true : false })
                                        }
                                        if (this.state.isForEditBill) {
                                            clearCachevalue("OPCancelBilldata");
                                            clearCachevalue("isForCancelBill");
                                            this.clearTable()
                                            this.props.OverallBillNavigate(true)
                                        }
                                        this.setState({ DisableBtn: false })
                                        this.lodaerFunction(false)
                                        this.successErrorMessgae(response.data.message, 'success')
                                        if (this.props.isFromQuickBilling) { this.props.QuickBillPostSucess() }
                                        this.clearTable()
                                        this.getServiceDescriptionData()
                                        this.getServiceTemplateData()
                                        this.setState({ onAddService: false, discountReason: '', removable_ids: [] })
                                        if (this.props.DamographData?.isNurse) {
                                            this.props.changeNursestate(false)
                                        }
                                    }
                                }).catch((error) => {
                                    this.setState({ isSavedBillClicked: false, isReceivePaymentClicked: false, DisableBtn: false }, () => {
                                        if (error?.response?.data?.status) {
                                            this.lodaerFunction(false)
                                            this.successErrorMessgae(error.response.data.message, 'error')
                                        } else {
                                            this.lodaerFunction(false)
                                            this.successErrorMessgae(error.message, 'error')
                                        }
                                    })
                                })
                        }
                    } else {
                        if (!this.checkSatus()) {
                            this.successErrorMessgae("Please,Select Payment Type", 'error')
                        } else if (this.state.discountReason === '' && ((this.state.totalDiscountedPercentage !== '' && this.state.totalDiscountedPercentage !== 0) || (this.state.totalDiscountedAmount !== '' && this.state.totalDiscountedAmount !== 0))) {
                            this.successErrorMessgae("Reason is mandatory.Click Note icon to enter reason", 'error')
                        } else {
                            this.lodaerFunction(true)
                            RestAPIService.create(data, Serviceurls.FO_PATIEMT_BILLING)
                                .then((response) => {
                                    if (response.data.status === "success") {
                                        clearCachevalue("OP_CreateAppointment_PatientId");
                                        clearCachevalue("AppRequestPatientData");
                                        clearCachevalue("QRRequestPatientData");
                                        this.lodaerFunction(false)
                                        this.successErrorMessgae(response.data.message, 'success')
                                        if (data.bill_type === 'regular' || data.bill_type === 'credit') {
                                            this.setState({ SuccessInvoice_ID: response.data.bill_id, isCreditBill: payment?.length === 0 ? true : false })
                                        }
                                        if (this.props.isFromQuickBilling) { this.props.QuickBillPostSucess() }
                                        this.clearTable()
                                        this.getServiceDescriptionData()
                                        this.getServiceTemplateData()
                                        this.setState({ onAddService: false })
                                    }
                                }).catch((error) => {
                                    this.setState({ isSavedBillClicked: false, isReceivePaymentClicked: false }, () => {
                                        if (error?.response?.data?.status) {
                                            this.lodaerFunction(false)
                                            this.successErrorMessgae(error.response.data.message, 'error')
                                        } else {
                                            this.lodaerFunction(false)
                                            this.successErrorMessgae(error.message, 'error')
                                        }
                                    })
                                })
                        }
                    }
                })
            } else {
                this.successErrorMessgae("No Service Added", 'error')
            }
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }

    }
    successErrorMessgae = (message, type) => {
        if (type === 'error') {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: message,
                DisableBtn: false
            })
        } else {
            this.setState({
                successMsg: true,
                successMsgText: message,
                DisableBtn: false
            })
        }
    }
    concessionManditoryPopup = (names) => {
        if ((this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount) && (this.state.discountReason === "")) {
            if (names === 'totalDiscountedPercentage' || names === 'totalDiscountedAmount') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    inputTextBox = (names, value, key, placeholder, height, diasble) => {
        let search = names === "serviceSearch" || names === "descriptionSerach" || names === 'serviceCodeSearch'
        return (
            <TextField

                //mui chip label style

                // onKeyDown={(e) => {
                //     if (e.key === "Enter") {
                //         if (names === "serviceSearch") {
                //             this.getServiceTemplateData()
                //         }
                //         if (names === "descriptionSerach") {
                //             this.getServiceDescriptionData()
                //         }
                //     }
                // }}
                // InputProps={{
                //     startAdornment: names === "totalDiscountedAmount" ? <InputAdornment position="start">₹</InputAdornment> : null,
                //     endAdornment: names === "totalDiscountedPercentage" ? <InputAdornment position="start">%</InputAdornment> :
                //         names === "descriptionSerach" || names === "serviceSearch" ? <InputAdornment
                //             onClick={() => { names === "descriptionSerach" ? this.getServiceDescriptionData() : this.getServiceTemplateData() }}
                //             id='eMed_Serivce_Search' position="end"><SearchIcon sx={{ color: '#04B7B1' }} /></InputAdornment> : null,
                // }}

                autoComplete='off'
                size='small'
                className={search ? 'eMed_bill_search' : 'eMed_discount_txt'}
                placeholder={placeholder}
                disabled={(this.state.corporatePay || this.state.forCancel) ? true : diasble}
                onBlur={(e) => { this.concessionManditoryPopup(names) }}
                inputProps={{ style: { height: height, fontSize: '0.8vw' } }}
                name={names}
                value={value}
                onChange={(e) => { this.onChangeTableHandler(e, key) }}
            />
        )
    }

    calculateTotalDiscount = () => {
        this.state.totalDiscountedAmount = CommonValidation.calculatePercentage(this.state.concessionRestrictAmount, this.state.totalDiscountedPercentage)
        this.state.totalDiscountedPercentage = CommonValidation.amountPercentage(this.state.totalDiscountedAmount, this.state.concessionRestrictAmount)
        this.setState({
            totalDiscountedAmount: this.state.totalDiscountedAmount,
            totalDiscountedPercentage: this.state.totalDiscountedPercentage
        })
    }

    onChangeTableHandler = (e, key) => {
        try {
            const { name, value } = e.target
            let { billingList } = this.state
            switch (name) {
                case 'rateUnit':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1) || value === "") {
                        if (((billingList[key]?.service_catagory__catagory_name === "Consultation") || (billingList[key]?.service_catagory__catagory_name === 'OP Procedures')) && (billingList[key]?.service_fee?.doctor_fee || billingList[key]?.service_fee?.hospital_fee)) {
                            billingList[key]['totalAmount'] = billingList[key]["time_taken"] ? this.CalculateHourAmount(value, billingList[key]['time_taken'], (billingList[key]["old_time_taken"] ? billingList[key]["old_time_taken"] : billingList[key]['time_taken']), billingList[key]['minimum_time'], billingList[key]["min_amount"]) : (billingList[key]['unit'] * value)
                            billingList[key]['amount'] = +value
                            billingList[key]['service_fee']['doctor_fee'] = CommonValidation.calculatePercentage(billingList[key]['service_fee']['doctor_percentage'], (value * billingList[key]['unit']))
                            billingList[key]['service_fee']['hospital_fee'] = CommonValidation.calculatePercentage(billingList[key]['service_fee']['hospital_percentage'], (value * billingList[key]['unit']))
                            billingList[key]['service_fee']['total_amt'] = billingList[key]['unit'] * +value
                            billingList[key]['service_fee']['discount_hospital_fee'] = billingList[key]['service_fee']['discount_hospital_percentage'] ? CommonValidation.calculatePercentage(billingList[key]['service_fee']['discount_hospital_percentage'], billingList[key]['service_fee']['hospital_fee']) : 0;
                            billingList[key]['service_fee']['discount_doctor_fee'] = billingList[key]['service_fee']['discount_doctor_percentage'] ? CommonValidation.calculatePercentage(billingList[key]['service_fee']['discount_doctor_percentage'], billingList[key]['service_fee']['doctor_fee']) : 0;
                            billingList[key]['discountAmount'] = billingList[key]['service_fee']['discount_doctor_fee'] + billingList[key]['service_fee']['discount_hospital_fee']
                            billingList[key]['discountPercentage'] = CommonValidation.amountPercentage(billingList[key]['discountAmount'], billingList[key]['service_fee']['total_amt'])

                        } else {
                            billingList[key]['totalAmount'] = billingList[key]["time_taken"] ? this.CalculateHourAmount(value, billingList[key]['time_taken'], (billingList[key]["old_time_taken"] ? billingList[key]["old_time_taken"] : billingList[key]['time_taken']), billingList[key]['minimum_time'], billingList[key]["min_amount"]) : (billingList[key]['unit'] * value)
                            billingList[key]['discountAmount'] = CommonValidation.calculatePercentage(billingList[key]['totalAmount'], billingList[key]['discountPercentage'])
                            billingList[key]['discountPercentage'] = CommonValidation.amountPercentage(billingList[key]['discountAmount'], billingList[key]['totalAmount'])
                            billingList[key]['amount'] = +value
                        }
                        this.setState({ billingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: ''
                            })
                        })
                    }
                    break;
                case 'percentage':
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
                        billingList[key]['discountAmount'] = CommonValidation.calculatePercentage(value, billingList[key]['totalAmount'])
                        billingList[key]['discountPercentage'] = value
                        this.setState({ billingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: ''
                            })
                        })
                    }
                    break;
                case 'discountAmount':
                    let totalAmount = billingList[key]['totalAmount']
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= totalAmount) || value === "") {
                        billingList[key]['discountPercentage'] = CommonValidation.amountPercentage(value, billingList[key]['totalAmount'])
                        billingList[key]['discountAmount'] = value
                        this.setState({ billingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: ''
                            })
                        })
                    }
                    break;
                case 'totalDiscountedPercentage':
                    let percentRestrict = +this.state.billConcessionType === 1 ? 100 : CommonValidation.amountPercentage(this.state.concessionRestrictAmount, this.state.totalServiceAmount)
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= percentRestrict) || value === "") {
                        this.state.totalDiscountedAmount = CommonValidation.calculatePercentage(value, this.state.totalServiceAmount)
                        this.setState({
                            totalDiscountedPercentage: value
                        }, () => {
                            if (+value === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case 'totalDiscountedAmount':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.concessionRestrictAmount) || value === "") {
                        this.state.totalDiscountedPercentage = CommonValidation.amountPercentage(value, this.state.totalServiceAmount)
                        this.setState({
                            totalDiscountedAmount: value
                        }, () => {
                            if (+this.state.totalDiscountedPercentage === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case "serviceSearch":
                    this.setState({
                        serviceSearch: value
                    }, () => {
                        // if (this.state.serviceSearch === "") {
                        //     this.getServiceTemplateData()
                        //     this.setState({
                        //         service: ""
                        //     })
                        // } else {
                        //     this.getServiceTemplateData()
                        // }
                        if (this.state.serviceSearch === "") {
                            // this.getServiceTemplateData()
                            this.setState({
                                service: "",
                                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)
                            })
                        } else {
                            // this.getServiceTemplateData()
                            this.setState({
                                service: "",
                                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)?.filter((list) => (list?.template_name?.toLowerCase()?.includes(this.state.serviceSearch?.toLowerCase())))
                            })
                        }
                    })
                    break;
                case "descriptionSerach":
                    this.setState({
                        descriptionSerach: value
                    }, () => {
                        // if (this.state.descriptionSerach === "") {
                        //   // this.getServiceDescriptionData()
                        //   this.setState({
                        //     newDescriptionName: "",
                        //     description: "",
                        //     newDescription: [],
                        //     dupNewDescription: '[]',
                        //     searchServiceName: '',
                        //   })
                        // } else {
                        //   // this.getServiceDescriptionData()
                        // }
                        if (this.state.descriptionSerach === "") {
                            // this.getServiceTemplateData()
                            this.setState({
                                newDescriptionName: "",
                                description: "",
                                newDescription: [],
                                dupNewDescription: '[]',
                                searchServiceName: '',
                                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)
                            })
                        } else {
                            // this.getServiceTemplateData()
                            this.setState({
                                newDescriptionName: "",
                                description: "",
                                newDescription: [],
                                dupNewDescription: '[]',
                                searchServiceName: '',
                                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)?.filter((list) => (list?.service_type?.toLowerCase()?.includes(this.state.descriptionSerach?.toLowerCase())))
                            })
                        }
                    })
                    break;
                case 'serviceCodeSearch':
                    this.setState({ serviceCodeSearch: value })
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    CalculateServiceValues = (index) => {
        try {
            let { billingList } = this.state
            billingList[index].totalAmount = Math.round(billingList[index].unit * billingList[index].amount)
            billingList[index].service_fee.total_amt = Math.round(billingList[index].unit * billingList[index].amount)
            billingList[index].service_fee.original_doctor_fee = CommonValidation.calculatePercentage(billingList[index]?.service_fee?.doctor_percentage, billingList[index]?.service_fee?.total_amt)
            billingList[index].service_fee.original_hospital_fee = CommonValidation.calculatePercentage(billingList[index]?.service_fee?.hospital_percentage, billingList[index]?.service_fee?.total_amt)
            billingList[index].service_fee.discount_hospital_fee = billingList[index].service_fee?.discount_hospital_percentage ? CommonValidation.calculatePercentage(billingList[index].service_fee?.discount_hospital_percentage, billingList[index].service_fee.original_hospital_fee) : 0;
            billingList[index].service_fee.discount_doctor_fee = billingList[index].service_fee?.discount_doctor_percentage ? CommonValidation.calculatePercentage(billingList[index].service_fee?.discount_doctor_percentage, billingList[index].service_fee.original_doctor_fee) : 0;
            billingList[index].discountAmount = billingList[index]?.service_fee?.discount_doctor_fee + billingList[index]?.service_fee?.discount_hospital_fee
            billingList[index].discountPercentage = CommonValidation.amountPercentage(billingList[index]?.discountAmount, billingList[index]?.service_fee?.total_amt)
            billingList[index].service_fee.doctor_fee = CommonValidation.formatAmount(billingList[index].service_fee.original_doctor_fee - (billingList[index].service_fee.discount_doctor_fee ? billingList[index].service_fee.discount_doctor_fee : 0))
            billingList[index].service_fee.hospital_fee = CommonValidation.formatAmount(billingList[index].service_fee.original_hospital_fee - (billingList[index].service_fee.discount_hospital_fee ? billingList[index].service_fee.discount_hospital_fee : 0))
            billingList[index].discountAmount = billingList[index]?.service_fee?.discount_doctor_fee + billingList[index]?.service_fee?.discount_hospital_fee
            billingList[index].discountPercentage = CommonValidation.amountPercentage(billingList[index]?.discountAmount, billingList[index]?.service_fee?.total_amt)
            this.setState({ billingList }, () => {
                this.setState({
                    totalDiscountedAmount: '',
                    totalDiscountedPercentage: ''
                })
                //  this.calculateTotalDiscount() 
            })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    onChangeUnit = (item, type, index) => {
        try {
            let { billingList } = this.state
            switch (type) {
                case 'increase':
                    if (item.unit >= 1) {
                        item.unit++
                        item.totalAmount = item.unit * item.amount
                        if (((item?.service_catagory__catagory_name === "Consultation") || (item?.service_catagory__catagory_name === 'OP Procedures')) && (item?.service_fee?.doctor_fee || item?.service_fee?.hospital_fee || item?.service_fee?.discount_doctor_percentage || item?.service_fee?.discount_hospital_percentage)) {
                            this.setState({ billingList }, () => {
                                this.CalculateServiceValues(index)
                            })
                        } else {
                            item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                            item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                            this.setState({ billingList }, () => {
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: ''
                                })
                            })
                        }
                    }
                    break;
                case 'decrease':
                    if (item.unit > 1) {
                        item.unit--
                        item.totalAmount = item.unit * item.amount
                        if (((item?.service_catagory__catagory_name === "Consultation") || (item?.service_catagory__catagory_name === 'OP Procedures')) && (item?.service_fee?.doctor_fee || item?.service_fee?.hospital_fee || item?.service_fee?.discount_doctor_percentage || item?.service_fee?.discount_hospital_percentage)) {
                            this.setState({ billingList }, () => {
                                this.CalculateServiceValues(index)
                            })
                        } else {
                            item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                            item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                            this.setState({ billingList }, () => {
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: ''
                                })
                            })
                        }
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    onClearHandler = () => {
        this.setState({
            serviceAmount: 0,
            descriptionAmount: 0,
            description: "",
            service: "",
            serviceSearch: "",
            descriptionSerach: "",
            newDescriptionName: "",
            newDescription: [],
            serviceList: [],
            descriptionList: [],
            serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew),
            serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew),
            templateDate: this.state.isForEditBill ? this.state.templateDate : new Date(),
            descripDate: this.state.isForEditBill ? this.state.descripDate : new Date(),
            DateTimeChanged: this.state.isForEditBill ? true : false,
            searchServiceName: '',
            dupNewDescription: '[]',
            disableBtn: false,
            serviceCodeText: '',
            serviceNameCodeText: '',
            serviceTypeCodeText: '',
            serviceCodeAmt: 0,
            selectedServiceCodeText: '',
            selectedServiceNameCodeText: '',
            selectedServiceTypeCodeText: '',
            serviceCodeList: [],
            serviceCodeData: null,
            codeData: [],

        }, () => {
            // this.getServiceDescriptionData()
            // this.getServiceTemplateData()
        })
    }

    onClearLeftContent = () => {
        this.state.billingList.forEach(element => {
            this.state.removable_ids.push(element.line_item_id)
        })
        this.setState({
            billingList: [],
            serviceList: [],
            totalServiceAmount: 0,
            totalDiscountedAmount: "",
            totalDiscountedPercentage: "",
            payableAmount: 0,
            descriptionList: [],
            newDescription: [],
            description: "",
            descriptionAmount: 0,
            serviceAmount: 0,
            isEditDiscount: false,
            isEditRate: false,
            isEditunit: false,
            CardAmount: "",
            CashAmount: "",
            ChequeAmount: "",
            UPIAmount: "",
            BankTfrAmount: "",
            CardTransactionNumber: "",
            ChequeNumber: "",
            UPINumber: "",
            BankTfrNumber: "",
            isDeleteAll: false,
            ClearClicked: false,
            serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew),
            serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew),
            removable_ids: this.state.removable_ids,
            newDescriptionName: '',
            service: '',
            serviceCodeList: []
        })
    }

    onClickChipHandler = (item, fromAuto = false) => {
        let states = this.state
        let TotalAmt = 0;
        let templateTotalAmt = 0;
        let templateServiceAmt = 0;
        let finalTemplateTotalAmt = 0;
        if (this.state.value === 'one') {
            item?.service_data?.forEach(element => {
                let ServiceCharges = element.service_charges[0]
                if ((element.service_catagory__catagory_name === "Consultation")) {
                    if (element.is_holiyday) {
                        let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                        let newPatient = ServiceCharges?.new_patient?.total_amt;
                        let oldPatient = ServiceCharges?.old_patient?.total_amt;
                        let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                        let emergencyFee = ServiceCharges?.emergency_fee?.total_amt

                        templateTotalAmt += (holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient)
                    } else if (!element.is_holiyday && element.is_emergency) {
                        let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                        let newPatient = ServiceCharges?.new_patient?.total_amt;
                        let oldPatient = ServiceCharges?.old_patient?.total_amt;
                        let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                        templateTotalAmt += (emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient)
                    } else {
                        let newPatient = ServiceCharges?.new_patient?.total_amt;
                        let oldPatient = ServiceCharges?.old_patient?.total_amt;
                        let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                        templateTotalAmt += states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    }
                } else if ((element.service_catagory__catagory_name === "OP Procedures")) {
                    let newServiceAmount = ServiceCharges?.service_fee?.total_amt
                    let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                    let holidayFee = ServiceCharges?.holiday_fee?.total_amt

                    if (element.is_holiyday) {
                        templateTotalAmt += (holidayFee !== '' ? holidayFee : element?.is_emergency ? emergencyFee !== '' ? emergencyFee : newServiceAmount : newServiceAmount)
                    } else if (!element?.is_holiyday && element?.is_emergency) {
                        templateTotalAmt += (emergencyFee !== '' ? emergencyFee : newServiceAmount)
                    } else {
                        templateServiceAmt += newServiceAmount
                    }
                } else {
                    if (element.is_holiyday) {
                        templateServiceAmt += (ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : element?.is_emergency ? ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount : ServiceCharges.service_amount)
                    } else if (!element?.is_holiyday && element?.is_emergency) {
                        templateServiceAmt += (ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount)
                    } else {
                        templateServiceAmt += ServiceCharges.service_amount
                    }
                }
                finalTemplateTotalAmt = (+templateTotalAmt) + (+templateServiceAmt)
            })
        } else {
            if (item?.service_charges) {
                let ServiceCharges = item.service_charges[0]
                if ((item.service_catagory__catagory_name === "Consultation")) {
                    if (item?.is_holiyday) {
                        let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                        let newPatient = ServiceCharges?.new_patient?.total_amt;
                        let oldPatient = ServiceCharges?.old_patient?.total_amt;
                        let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                        let emergencyFee = ServiceCharges?.emergency_fee?.total_amt

                        TotalAmt = holidayFee !== '' ? holidayFee : item?.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    } else if (!item?.is_holiyday && item?.is_emergency) {
                        let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                        let newPatient = ServiceCharges?.new_patient?.total_amt;
                        let oldPatient = ServiceCharges?.old_patient?.total_amt;
                        let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                        TotalAmt = emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    } else {
                        let newPatient = ServiceCharges?.new_patient?.total_amt;
                        let oldPatient = ServiceCharges?.old_patient?.total_amt;
                        let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                        TotalAmt = states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                    }
                } else if ((item.service_catagory__catagory_name === "OP Procedures")) {
                    let serviceAmt = ServiceCharges?.service_fee?.total_amt
                    let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                    let emergencyFee = ServiceCharges?.emergency_fee?.total_amt

                    if (item?.is_holiyday) {
                        TotalAmt = holidayFee !== '' ? holidayFee : serviceAmt
                    } else if (!item?.is_holiyday && item?.is_emergency) {
                        TotalAmt = emergencyFee !== '' ? emergencyFee : serviceAmt
                    } else {
                        TotalAmt = serviceAmt
                    }
                } else {
                    if (item?.is_holiyday) {
                        TotalAmt = ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : item?.is_emergency ? ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount : ServiceCharges.service_amount
                    } else if (!item?.is_holiyday && item?.is_emergency) {
                        TotalAmt = ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                    } else {
                        TotalAmt = ServiceCharges.service_amount
                    }
                }
            }
        }
        if (fromAuto) {
            this.setState({
                serviceCodeText: '',
                serviceNameCodeText: '',
                serviceTypeCodeText: '',
                serviceCodeAmt: item?.amount ? item?.amount : TotalAmt,
                codeData: null,
                serviceCodeData: item,
                selectedServiceCodeText: item?.op_service_code,
                selectedServiceNameCodeText: item?.service_name,
                selectedServiceTypeCodeText: item?.service_type,
            })
        } else {
            this.setState({
                service: item?.template_name,
                serviceAmount: item?.total_amount ? item?.total_amount : finalTemplateTotalAmt,
                serviceData: item?.service_data,
                descriptionAmount: item?.amount ? item?.amount : TotalAmt,
                descriptionData: item,
                templateId: item?.id,
                newDescriptionName: item?.service_name,

                serviceCodeText: item?.op_service_code,
                serviceNameCodeText: item?.service_name,
                serviceTypeCodeText: item?.service_type,
                serviceCodeData: null,
                serviceCodeAmt: item?.amount ? item?.amount : TotalAmt,
                codeData: item,
                selectedServiceCodeText: '',
                selectedServiceNameCodeText: '',
                selectedServiceTypeCodeText: '',
            })
        }
    }

    onAddHandler = () => {
        let states = this.state
        try {
            if (this.state.value === 'one') {
                let templateServiceList = [];
                this.state.serviceTemplateData.forEach(element => {
                    templateServiceList.push(element.service_data)
                })
                if (this.state.service !== "") {
                    let templateFlag = false;
                    this.state.billingList.forEach(element => {
                        if (this.state.service === element.template_name) {
                            templateFlag = true
                        }
                    })
                    this.state.serviceData.forEach((element) => {
                        let ServiceCharges = element.service_charges[0]
                        if ((element.service_catagory__catagory_name === "Consultation")) {
                            if (element.is_holiyday) {
                                let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                                let newPatient = ServiceCharges?.new_patient?.total_amt;
                                let oldPatient = ServiceCharges?.old_patient?.total_amt;
                                let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                                let emergencyFee = ServiceCharges?.emergency_fee?.total_amt

                                element.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : element.is_emergency ? emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                                element.totalAmount = holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "new_patient" ? +newPatient : +oldPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                                element.amount = element.totalAmount

                            } else if (!element.is_holiyday && element.is_emergency) {
                                let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                                let newPatient = ServiceCharges?.new_patient?.total_amt;
                                let oldPatient = ServiceCharges?.old_patient?.total_amt;
                                let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                                element.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                                element.totalAmount = emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                                element.amount = element.totalAmount
                            } else {
                                let newPatient = ServiceCharges?.new_patient?.total_amt;
                                let oldPatient = ServiceCharges?.old_patient?.total_amt;
                                let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                                element.service_fee = states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                                element.totalAmount = states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                                element.amount = element.totalAmount
                            }
                        } else if ((element.service_catagory__catagory_name === "OP Procedures")) {
                            let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                            let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                            let serviceFee = ServiceCharges?.service_fee?.total_amt

                            if (element.is_holiyday) {

                                element.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : element.is_emergency ? emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee : ServiceCharges?.service_fee
                                element.totalAmount = holidayFee !== '' ? holidayFee : element.is_emergency ? emergencyFee !== '' ? emergencyFee : serviceFee : serviceFee
                                element.amount = element.totalAmount
                                // ====> This flow has stopped <===== //
                                // element.amount = holidayFee !== '' ? holidayFee : serviceFee
                                // element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                // element.end_date = null
                                // element.changed_start_time = null
                                // element.changed_end_time = null
                                // element.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : ServiceCharges?.service_fee
                                // element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken) : holidayFee !== '' ? holidayFee : serviceFee
                                // element.amount = element.totalAmount
                            } else if (!element.is_holiyday && element.is_emergency) {

                                element.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee
                                element.totalAmount = emergencyFee !== '' ? emergencyFee : serviceFee
                                element.amount = element.totalAmount
                                // ====> This flow has stopped <===== //
                                // element.amount = emergencyFee !== '' ? emergencyFee : serviceFee
                                // element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                // element.end_date = null
                                // element.changed_start_time = null
                                // element.changed_end_time = null
                                // element.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee
                                // element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken) : emergencyFee !== '' ? emergencyFee : serviceFee
                                // element.amount = element.totalAmount

                            } else {
                                element.service_fee = ServiceCharges?.service_fee
                                element.totalAmount = serviceFee
                                element.amount = element.totalAmount
                                // ====> This flow has stopped <===== //
                                // element.amount = serviceFee
                                // element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                // element.end_date = null
                                // element.changed_start_time = null
                                // element.changed_end_time = null
                                // element.service_fee = ServiceCharges?.service_fee
                                // element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken) : serviceFee
                                // element.amount = element.totalAmount
                            }
                        } else {
                            if (element.is_holiyday) {
                                element.amount = (ServiceCharges.holiday_fee !== '') ? ServiceCharges.holiday_fee : element.is_emergency ? (ServiceCharges.emergency_fee !== '') ? ServiceCharges.emergency_fee : ServiceCharges.service_amount : ServiceCharges.service_amount
                                element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                element.end_date = null
                                element.changed_start_time = null
                                element.changed_end_time = null
                                element.old_time_taken = element.time_taken
                                element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : ServiceCharges.service_amount
                                element.service_fee = { doctor_fee: '', hospital_fee: '', service_amount: element.amount }
                            } else if (!element.is_holiyday && element.is_emergency) {
                                element.amount = ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                                element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                element.end_date = null
                                element.changed_start_time = null
                                element.changed_end_time = null
                                element.old_time_taken = element.time_taken
                                element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                                element.service_fee = { doctor_fee: '', hospital_fee: '', service_amount: element.amount }
                            } else {
                                element.amount = ServiceCharges.service_amount
                                element.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                                element.end_date = null
                                element.changed_start_time = null
                                element.changed_end_time = null
                                element.old_time_taken = element.time_taken
                                element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.service_amount
                                element.service_fee = { doctor_fee: '', hospital_fee: '', service_amount: element.amount }
                            }
                        }
                        element.min_amount = ServiceCharges.minimum_amount ? +ServiceCharges.minimum_amount : 0
                        element.unit = "1"
                        element.discountPercentage = ""
                        element.discountAmount = ""
                        // element.totalAmount = +element.amount
                        element.template_name = this.state.service
                        element.templateId = this.state.templateId
                        element.is_auto_billing = false
                        element.isSplitBlock = false
                        element.noDiscountAmount = ""
                        element.individual_discount_comments = ""
                        if (!templateFlag) {
                            this.state.serviceList.push(element)
                        } else {
                            this.successErrorMessgae("Service Template Already Exist", 'error')
                            this.onClearHandler()
                        }
                    })
                    this.state.billingList = this.state.billingList.concat(this.state.serviceList)
                    this.setState({
                        billingList: this.state.billingList
                    }, () => {
                        this.setState({
                            totalDiscountedAmount: '',
                            totalDiscountedPercentage: ''
                        })
                        // this.calculateTotalDiscount()
                        this.onClearHandler()
                    }
                    )
                } else {
                    this.successErrorMessgae("Please, Select Service Template", 'error')
                }
            } else if (this.state.value === 'two') {
                if (this.state.newDescriptionName !== "") {
                    let object = this.state.descriptionData
                    let ServiceCharges = object.service_charges[0]
                    if ((object.service_catagory__catagory_name === "Consultation")) {
                        if (object.is_holiyday) {
                            let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                            let newPatient = ServiceCharges?.new_patient?.total_amt;
                            let oldPatient = ServiceCharges?.old_patient?.total_amt;
                            let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                            let emergencyFee = ServiceCharges?.emergency_fee?.total_amt

                            object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : object.is_emergency ? emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient : states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                            object.totalAmount = holidayFee !== '' ? holidayFee : object.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                            object.amount = object.totalAmount

                        } else if (!object.is_holiyday && object.is_emergency) {
                            let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                            let newPatient = ServiceCharges?.new_patient?.total_amt;
                            let oldPatient = ServiceCharges?.old_patient?.total_amt;
                            let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                            object.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                            object.totalAmount = emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                            object.amount = object.totalAmount
                        } else {
                            let newPatient = ServiceCharges?.new_patient?.total_amt;
                            let oldPatient = ServiceCharges?.old_patient?.total_amt;
                            let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                            object.totalAmount = states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                            object.amount = object.totalAmount
                            object.service_fee = states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                        }
                    } else if ((object.service_catagory__catagory_name === "OP Procedures")) {
                        let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                        let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                        let serviceFee = ServiceCharges?.service_fee?.total_amt

                        if (object.is_holiyday) {
                            object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : object.is_emergency ? emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee : ServiceCharges?.service_fee
                            object.totalAmount = holidayFee !== '' ? holidayFee : object.is_emergency ? emergencyFee !== '' ? emergencyFee : serviceFee : serviceFee
                            object.amount = object.totalAmount

                            // =====> Commented for flow stopped <==== //
                            // object.amount = holidayFee !== '' ? holidayFee : serviceFee
                            // object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            // object.end_date = null
                            // object.changed_start_time = null
                            // object.changed_end_time = null
                            // object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken) : holidayFee !== '' ? holidayFee : serviceFee
                            // object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : ServiceCharges?.service_fee

                        } else if (!object.is_holiyday && object.is_emergency) {

                            object.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee
                            object.totalAmount = emergencyFee !== '' ? emergencyFee : serviceFee
                            object.amount = object.totalAmount

                            // =====> Commented for flow stopped <==== //
                            // object.amount = emergencyFee !== '' ? emergencyFee : serviceFee
                            // object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            // object.end_date = null
                            // object.changed_start_time = null
                            // object.changed_end_time = null
                            // object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken) : emergencyFee !== '' ? emergencyFee : serviceFee
                            // object.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee

                        } else {

                            object.totalAmount = serviceFee
                            object.amount = object.totalAmount
                            object.service_fee = ServiceCharges?.service_fee

                            // =====> Commented for flow stopped <==== //
                            // object.amount = serviceFee
                            // object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            // object.end_date = null
                            // object.changed_start_time = null
                            // object.changed_end_time = null
                            // object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken) : serviceFee
                            // object.service_fee = ServiceCharges?.service_fee

                        }
                    } else {
                        if (object.is_holiyday) {
                            object.amount = ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : object.is_emergency ? ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount : ServiceCharges.service_amount
                            object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            object.end_date = null
                            object.changed_start_time = null
                            object.changed_end_time = null
                            object.old_time_taken = object.time_taken
                            object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : ServiceCharges.service_amount
                            object.service_fee = ServiceCharges

                        } else if (!object.is_holiyday && object.is_emergency) {
                            object.amount = ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                            object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            object.end_date = null
                            object.changed_start_time = null
                            object.changed_end_time = null
                            object.old_time_taken = object.time_taken
                            object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                            object.service_fee = ServiceCharges
                        } else {
                            object.amount = ServiceCharges.service_amount
                            object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            object.end_date = null
                            object.changed_start_time = null
                            object.changed_end_time = null
                            object.old_time_taken = object.time_taken
                            object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.service_amount
                            object.service_fee = ServiceCharges
                        }
                    }
                    object.min_amount = ServiceCharges.minimum_amount ? +ServiceCharges.minimum_amount : 0
                    object.unit = "1"
                    object.discountPercentage = ""
                    object.discountAmount = ""
                    object.noDiscountAmount = ""
                    object.individual_discount_comments = ""
                    object.is_auto_billing = false
                    object.isSplitBlock = false
                    let flag = false;
                    this.state.billingList.forEach(element => {
                        if (element.service_name === object.service_name && element.service_type === object.service_type && element?.amount === object?.amount || element.service_name === object.service_name && element.service_type === object.service_type && element?.totalAmount === object?.totalAmount) {
                            flag = true
                        }
                    })
                    if (!flag) {
                        this.state.descriptionList.push(object)
                        this.state.billingList = this.state.billingList.concat(this.state.descriptionList)
                        this.setState({
                            billingList: this.state.billingList
                        }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: ''
                            })
                            this.onClearHandler()
                        })
                    } else {
                        this.successErrorMessgae("Service Type Already Exist", 'error')
                        this.onClearHandler()
                    }
                } else {
                    this.successErrorMessgae("Please, Select Service Type", 'error')
                }
            } else if (this.state.value === 'three') {
                // if (this.state.selectedServiceCodeText !== '' || this.state.serviceCodeText !== '') {
                    let object = this.state.serviceCodeData ? this.state.serviceCodeData : this.state.codeData
                    let ServiceCharges = object.service_charges[0]
                    if ((object.service_catagory__catagory_name === "Consultation")) {
                        if (object.is_holiyday) {
                            let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                            let newPatient = ServiceCharges?.new_patient?.total_amt;
                            let oldPatient = ServiceCharges?.old_patient?.total_amt;
                            let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                            let emergencyFee = ServiceCharges?.emergency_fee?.total_amt

                            object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : object.is_emergency ? emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient : states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                            object.totalAmount = holidayFee !== '' ? holidayFee : object.is_emergency ? emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                            object.amount = object.totalAmount

                        } else if (!object.is_holiyday && object.is_emergency) {
                            let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                            let newPatient = ServiceCharges?.new_patient?.total_amt;
                            let oldPatient = ServiceCharges?.old_patient?.total_amt;
                            let reviewPatient = ServiceCharges?.review_patient?.total_amt;

                            object.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : states.patientData.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                            object.totalAmount = emergencyFee !== '' ? emergencyFee : states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                            object.amount = object.totalAmount
                        } else {
                            let newPatient = ServiceCharges?.new_patient?.total_amt;
                            let oldPatient = ServiceCharges?.old_patient?.total_amt;
                            let reviewPatient = ServiceCharges?.review_patient?.total_amt;
                            object.totalAmount = states.patientData?.patient_type === "review_patient" ? +reviewPatient : states.patientData?.patient_type === "old_patient" ? +oldPatient : +newPatient;
                            object.amount = object.totalAmount
                            object.service_fee = states.patientData?.patient_type === 'review_patient' ? ServiceCharges?.review_patient : states.patientData?.patient_type === 'old_patient' ? ServiceCharges?.old_patient : ServiceCharges?.new_patient
                        }
                    } else if ((object.service_catagory__catagory_name === "OP Procedures")) {
                        let holidayFee = ServiceCharges?.holiday_fee?.total_amt
                        let emergencyFee = ServiceCharges?.emergency_fee?.total_amt
                        let serviceFee = ServiceCharges?.service_fee?.total_amt

                        if (object.is_holiyday) {
                            object.service_fee = holidayFee !== '' ? ServiceCharges?.holiday_fee : object.is_emergency ? emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee : ServiceCharges?.service_fee
                            object.totalAmount = holidayFee !== '' ? holidayFee : object.is_emergency ? emergencyFee !== '' ? emergencyFee : serviceFee : serviceFee
                            object.amount = object.totalAmount
                        } else if (!object.is_holiyday && object.is_emergency) {
                            object.service_fee = emergencyFee !== '' ? ServiceCharges?.emergency_fee : ServiceCharges?.service_fee
                            object.totalAmount = emergencyFee !== '' ? emergencyFee : serviceFee
                            object.amount = object.totalAmount
                        } else {
                            object.totalAmount = serviceFee
                            object.amount = object.totalAmount
                            object.service_fee = ServiceCharges?.service_fee
                        }
                    } else {
                        if (object.is_holiyday) {
                            object.amount = ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : object.is_emergency ? ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount : ServiceCharges.service_amount
                            object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            object.end_date = null
                            object.changed_start_time = null
                            object.changed_end_time = null
                            object.old_time_taken = object.time_taken
                            object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.holiday_fee !== '' ? ServiceCharges.holiday_fee : ServiceCharges.service_amount
                            object.service_fee = ServiceCharges

                        } else if (!object.is_holiyday && object.is_emergency) {
                            object.amount = ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                            object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            object.end_date = null
                            object.changed_start_time = null
                            object.changed_end_time = null
                            object.old_time_taken = object.time_taken
                            object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.emergency_fee !== '' ? ServiceCharges.emergency_fee : ServiceCharges.service_amount
                            object.service_fee = ServiceCharges
                        } else {
                            object.amount = ServiceCharges.service_amount
                            object.start_date = this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date)
                            object.end_date = null
                            object.changed_start_time = null
                            object.changed_end_time = null
                            object.old_time_taken = object.time_taken
                            object.totalAmount = object.unit === "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object.minimum_time, ServiceCharges.minimum_amount) : ServiceCharges.service_amount
                            object.service_fee = ServiceCharges
                        }
                    }
                    object.min_amount = ServiceCharges.minimum_amount ? +ServiceCharges.minimum_amount : 0
                    object.unit = "1"
                    object.discountPercentage = ""
                    object.discountAmount = ""
                    object.noDiscountAmount = ""
                    object.individual_discount_comments = ""
                    object.is_auto_billing = false
                    object.isSplitBlock = false
                    let flag = false;
                    this.state.billingList.forEach(element => {
                        if (
                            (element.op_service_code === object.op_service_code ||
                                element.service_name === object.service_name) &&
                            element.service_type === object.service_type &&
                            (element?.amount === object?.amount && element.service_name === object.service_name) &&
                            element.service_type === object.service_type &&
                            element?.totalAmount === object?.totalAmount
                        ) {
                            flag = true
                        }
                    })
                    if (!flag) {
                        this.state.serviceCodeList.push(object)
                        this.state.billingList = this.state.billingList.concat(this.state.serviceCodeList)
                        this.setState({
                            billingList: this.state.billingList
                        }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: ''
                            })
                            this.onClearHandler()
                        })
                    } else {
                        this.successErrorMessgae("Service Code Already Exist", 'error')
                        this.onClearHandler()
                    }
                // } else {
                //     this.successErrorMessgae('Please,Select Service Type', 'error')
                // }
            }
        } catch (error) {
            this.successErrorMessgae("Please Select Service Template or Description", 'error')
        }
    }

    // CalculateHourAmount = (amount, hour, ) => {

    //     var timeString = hour;
    //     var ratePerHour = amount;
    //     var timeParts = timeString.split(":");
    //     var hours = parseFloat(timeParts[0]);
    //     var minutes = parseFloat(timeParts[1]);

    //     var totalHours = hours + Math.ceil(minutes / 60)

    //     var totalCost = totalHours * ratePerHour;

    //     return totalCost.toFixed(2);
    // }

    convertTimeToDecimal(time) {
        let [hours, minutes] = time?.split(':').map(Number);
        return hours * 60 + minutes;
    }

    CalculateHourAmount(ratePerHour, changedTime, fixedTime, minTime, minAmount) {
        let fixedTimes = this.convertTimeToDecimal(fixedTime);
        let changedTimes = this.convertTimeToDecimal(changedTime);
        let minTimes = this.convertTimeToDecimal(minTime ? minTime : '00:00')
        let changeAndFixedTime = changedTimes - fixedTimes
        let time = changeAndFixedTime > 0 ? changeAndFixedTime : 0
        let timeCalculateTime = (time === 0 && minTimes === 0) ? 0 : Math.ceil(time / minTimes) === Infinity ? 0 : Math.ceil(time / minTimes)
        let totalCost = ((+ratePerHour) + (timeCalculateTime * (minAmount ? minAmount : 0)))
        // let minTimeHourConvert = minTimes / 60
        // let totalCost = Math.ceil((changedTimes / fixedTimes) - minTimeHourConvert) * ratePerHour;

        return totalCost;
    }

    billingCalculation = (params, type) => {
        let amount = 0; let payableAmount = 0;
        switch (type) {
            case 'newAmount':
                let noDiscountAmount = 0;
                params.forEach(element => {
                    noDiscountAmount = noDiscountAmount + (element.unit * +element.amount)
                })
                return AmountFormat(noDiscountAmount)
            case 'discountAmount':
                let discountedAmount = 0;
                params.forEach(element => {
                    discountedAmount = discountedAmount + (element.discountAmount)
                })
                return AmountFormat(discountedAmount);
            case 'totalServiceAmount':
                let totalAmt = 0;
                params.forEach(element => {
                    totalAmt = totalAmt + ((element.service_fee?.hospital_fee || (element.service_fee?.hospital_fee === 0)) ? element.service_fee?.hospital_fee : (element.totalAmount - element.discountAmount))
                    amount = amount + (element.totalAmount - element.discountAmount)
                    this.state.totalServiceAmount = +amount
                    this.state.concessionRestrictAmount = (+this.state.billConcessionType === 2) ? +totalAmt : +amount
                })
                console.log(params, "dsfg");
                return AmountFormat(this.state.totalServiceAmount)
            case 'roundOff':
                this.state.roundOff = CommonValidation.roundOff(this.state.totalServiceAmount, this.state.totalDiscountedAmount)
                return this.state.roundOff;
            case 'payableAmount':
                if (this.state.forCancel) {
                    this.state.totalPayableAmount = Math.round(+this.state.BillReceivedAmount)
                    return AmountFormat(this.state.totalPayableAmount)
                } else {
                    let discount = this.state.totalDiscountedAmount ? this.state.totalDiscountedAmount : 0
                    payableAmount = this.state.totalServiceAmount - discount
                    this.state.totalPayableAmount = Math.round(+payableAmount)
                    return AmountFormat(this.state.totalPayableAmount)
                }

            default:
                break;
        }
    }

    rightBottomCard = (title, value, names, serviceAmount) => {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        return (
            <Box component={'div'}>
                <Box className='eMed_right_sub_grid' justifyContent={'center'}>
                    <Box component={'div'} className='eMed_date_picker_grid' >
                        <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{title}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                open={this.state.DateOpen}
                                onOpen={() => { this.setState({ DateOpen: true }) }}
                                onClose={() => { this.setState({ DateOpen: false }) }}
                                disabled={UserData?.clinic_id === 118 ? false : true} // (this.props.isFromQuickBilling || this.state.isForEditBill) --> previous condition
                                inputFormat='DD-MM-YYYY & hh:mm A'
                                value={value}
                                name={names}
                                minDate={UserData?.is_user ? new Date() : this.state.patientData?.appointment_date}
                                maxDate={UserData?.is_user ? new Date() : new Date((new Date).getFullYear(), (new Date).getMonth(), (new Date).getDate() + 1)}
                                onChange={(newDate) => {
                                    if (newDate === null && names === 'templateDate') {
                                        this.setState({ templateDate: new Date(), DateTimeChanged: true })
                                    } else if (newDate === null && names === 'descripDate') {
                                        this.setState({ descripDate: new Date(), DateTimeChanged: true })
                                    } else {
                                        this.setState({ templateDate: newDate, descripDate: newDate, DateTimeChanged: true })
                                    }
                                }}
                                onAccept={(value) => {
                                    if (value === null && names === 'templateDate') {
                                        this.getServiceTemplateData()
                                    } else if (value === null && names === 'descripDate') {
                                        this.getServiceDescriptionData()
                                    } else {
                                        this.getServiceDescriptionData()
                                        this.getServiceTemplateData()
                                    }
                                }}
                                renderInput={(params) => <TextField size='small' autoComplete='off'
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => { this.setState({ DateOpen: true }) }}
                                    {...params}
                                    fullWidth
                                    variant='standard'
                                    sx={{ marginTop: "1vw", marginLeft: "0.5vw", marginRight: "0.5vw", width: "15vw" }}
                                />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box component={'div'} className='eMed_date_picker_grid_amount'>
                        <Typography ml={'0.5vw'} color='#616161' fontWeight={'600'}>{t("ServiceAmount")}</Typography>
                        <Typography variant='h6' ml={'0.5vw'} mt={'1vw'} fontWeight={'600'}>{serviceAmount ? AmountFormat(serviceAmount) : AmountFormat(0)}</Typography>
                    </Box>
                </Box>
                <Box component={'div'} display='flex' justifyContent={'center'} position='relative' top={'0.5vw'}>
                    <Button variant='outlined'
                        emed_tid="create_bill_clear_btn"
                        disabled={this.state.disableBtn ? true : (this.state.withOutAppointment?.is_op || (this.state.forCancel))}
                        size='small'
                        className='eMed_bill_btn_one'
                        onClick={() => { this.onClearHandler() }}
                    >{t("Clear")}</Button>
                    <Button variant='contained'
                        size='small'
                        className='eMed_bill_btn_one'
                        disabled={(this.state.withOutAppointment?.is_op || this.state.isButtonDisabled || (this.state.forCancel))}
                        onClick={() => {
                            this.onAddHandler()
                            this.setState({ onAddService: true })
                        }}
                    >{t("Add")}</Button>
                </Box>
            </Box>
        )
    }

    serviceTemplateTab = () => {
        const { t } = this.props
        return (
            <Box component={'div'}>
                <Box component={'div'} margin='0.5vw'>
                    {this.inputTextBox('serviceSearch', this.state.serviceSearch, "", 'Search Service Template...', "", false)}
                    {/* <Box component={'span'}
                        onClick={() => { this.getServiceTemplateData() }}><SearchIcon className='eMed_search_icon' /></Box>*/}
                </Box>
                <Box component={'div'} className='eMed_op_Bill_list_bill'>
                    {this.state.serviceTemplateData?.length > 0 ? this.state.serviceTemplateData.map((item, index) => (
                        <Chip
                            className='eMed_chip'
                            size="small"
                            label={item.template_name?.length > 15 ? <Tooltip placement='top' title={item.template_name}><div style={{ fontSize: '0.7vw' }}>{item.template_name?.slice(0, 15) + '..'}</div></Tooltip> : item.template_name}
                            key={index}
                            onClick={() => {
                                this.onClickChipHandler(item)
                            }}
                            variant={item.template_name != this.state.service ? 'outlined' : 'contained'}
                            color={'primary'}
                            clickable
                        />
                    )) : <Typography display={'flex'} justifyContent={'center'} position={'relative'} top={'4vw'}>{t("NoRecordsFound")}</Typography>}
                </Box>
                {this.rightBottomCard("Bill Date and Time", this.state.templateDate, "templateDate", this.state.serviceAmount)}
            </Box>
        )
    }

    serviceDescriptionTab = () => {
        const { t } = this.props
        return (
            <Box component={'div'}>
                <Box component={'div'} margin='0.5vw'>
                    {this.inputTextBox('descriptionSerach', this.state.descriptionSerach, "", 'Search Service Description...', "", false)}
                    {/* <Box component={'span'}
                        onClick={() => { this.getServiceDescriptionData() }}><SearchIcon className='eMed_search_icon' /></Box> */}
                </Box>
                {/* <Box component={'div'} className='eMed_op_Bill_list'> */}
                <Box component={'div'} className='eMed_service_type'>
                    {/* <Divider textAlign='left' sx={{ marginLeft: '0.5vw' }}>
                        <Typography component={'p'} className='eMed_Service_title'>Service Name</Typography></Divider> */}
                    <Box sx={{ height: '7vw', overflow: 'scroll' }}>
                        {this.state.serviceDescriptionData?.length > 0 ? this.state.serviceDescriptionData.map((item, index) => (
                            <Chip
                                className='eMed_chip'
                                label={item.service_type?.length > 15 ? <Tooltip placement='top' title={item.service_type}><div style={{ fontSize: '0.7vw' }}>{item.service_type?.slice(0, 15) + '...'}</div></Tooltip> : item.service_type}
                                size="small"
                                key={index}
                                onClick={() => {
                                    let newDescription = []
                                    // newDescription.push(item)
                                    item?.data?.forEach((item) => { newDescription.push(item) })
                                    this.setState({
                                        newDescription: newDescription,
                                        dupNewDescription: JSON.stringify(newDescription),
                                        description: item?.service_type,
                                        newDescriptionName: "",
                                        descriptionAmount: 0,
                                        serviceCatagory: item?.service_catagory,
                                        searchServiceName: ''
                                    })
                                }}
                                variant={((item.service_catagory === this.state.serviceCatagory) && (item.service_type === this.state.description)) ? 'contained' : 'outlined'}
                                color={'primary'}
                            />
                        )) : <Typography display={'flex'} justifyContent={'center'} position={'relative'} top={'4vw'}>{t("NoRecordsFound")}</Typography>}
                    </Box>
                </Box>
                {this.state.serviceDescriptionData?.length > 0 ?
                    <Box component={'div'} className='eMed_service_type'>
                        <Divider textAlign='left' sx={{ marginLeft: '0.5vw' }}>
                            <Typography component={'p'} className='eMed_Service_title'>Service Names</Typography></Divider>
                        <Box sx={{ height: '7vw', overflow: 'scroll' }}>
                            {this.state.description ?
                                <Box component={'div'}>
                                    <TextField
                                        size='small'
                                        sx={{ m: '0.5vw', width: '20vw' }}
                                        placeholder='Search Service Name'
                                        value={this.state.searchServiceName}
                                        inputProps={{ style: { height: '', fontSize: '0.8vw' } }}
                                        onChange={(e) => {
                                            this.setState({ searchServiceName: e.target.value }, () => {
                                                let dupNewDescription = JSON.parse(this.state.dupNewDescription)
                                                let filterServiceName = dupNewDescription?.filter((item) => {
                                                    let itemServiceName = CommonValidation.removeSpace(item?.service_name)
                                                    let searchServiceName = CommonValidation.removeSpace(this.state.searchServiceName)
                                                    return (
                                                        searchServiceName ?
                                                            itemServiceName === null ? null : itemServiceName?.toLowerCase()?.includes(searchServiceName?.toLowerCase()) :
                                                            item
                                                    )
                                                })
                                                this.setState({ newDescription: filterServiceName }, () => this.setState({ newDescriptionName: '' }))
                                            })
                                        }}
                                    />
                                </Box> : null}

                            {this.state.newDescription.map((item, index) => (
                                <Chip
                                    className='eMed_chip'
                                    label={item.service_name?.length > 15 ? <Tooltip placement='top' title={item.service_name}><div style={{ fontSize: '0.7vw' }}>{item.service_name?.slice(0, 15) + '...'}</div></Tooltip> : item.service_name}
                                    size="small"
                                    key={index}
                                    onClick={() => { this.onClickChipHandler(item) }}
                                    variant={item.service_name != this.state.newDescriptionName ? 'outlined' : 'contained'}
                                    color={'primary'}
                                />
                            ))}
                        </Box>
                    </Box> : null}

                {/* </Box> */}
                {this.rightBottomCard("Bill Date and Time", this.state.descripDate, "descripDate", this.state.descriptionAmount)}
            </Box>
        )
    }

    serviceCodeTab = () => {
        try {
            return (
                <Box component={'div'}>
                    <Box component={'div'} height={'38dvh'}>
                        <Box component={'div'} p={'0.5vw'} borderBottom={'2px solid lightgray'}>
                            <Autocomplete
                                size='small'
                                sx={{ width: '25vw' }}
                                options={this.state.serviceCodeDataList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : `${option.op_service_code ? option.op_service_code : '-'} - ${option.service_type} - ${option.service_name}`}
                                value={this.state.serviceCodeData}
                                onChange={(e, value) => { this.onClickChipHandler(value, true) }}
                                renderInput={(params) => <TextField {...params} placeholder='Search Service Code...' InputProps={{ ...params.InputProps, emed_tid: 'srv_code_srh' }} />}
                            />
                            <Box component={'div'}>
                                {this.state.frequentServiceCodeList?.length > 0 ? this.state.frequentServiceCodeList?.map((item, index) => (
                                    <Chip
                                        className='eMed_chip'
                                        label={item.op_service_code}
                                        size="small"
                                        key={index}
                                        onClick={() => { this.onClickChipHandler(item) }}
                                        variant={item.op_service_code != this.state.serviceCodeText ? 'outlined' : 'contained'}
                                        color={'primary'}
                                    />
                                )) : null}
                            </Box>
                        </Box>
                        {/* {this.state.serviceCodeText || this.state.selectedServiceCodeText ? */}
                            <Box component={'div'} pl={'0.5vw'}>
                                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'4dvh'} display={'flex'} alignItems={'center'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>Service Details</Typography>
                                </Box>
                                <Box component={'div'}>
                                    <Box component={'div'} display={'flex'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Code :</Typography>
                                        <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceCodeText ? this.state.serviceCodeText : this.state.selectedServiceCodeText ? this.state.selectedServiceCodeText : '-'}</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Type :</Typography>
                                        <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceTypeCodeText ? this.state.serviceTypeCodeText : this.state.selectedServiceTypeCodeText}</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Name :</Typography>
                                        <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceNameCodeText ? this.state.serviceNameCodeText : this.state.selectedServiceNameCodeText}</Typography>
                                    </Box>
                                </Box>
                            </Box> 
                            {/* : null} */}
                    </Box>
                    {this.rightBottomCard("Bill Date and Time", this.state.descripDate, "descripDate", this.state.serviceCodeAmt)}
                </Box>
            )
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    renderRightContent = () => {
        const { t } = this.props
        return (
            <Box component={'div'} className={this.props.opPatientBilling ? 'eMed_OP_bill_right' : 'eMed_OP_bill_right_card'}>
                <Grid xs={12} item className='eMed_right_grid_one'>
                    <Tabs
                        value={this.state.value}
                        onChange={(e, newValue) => this.setState({
                            value: newValue,
                            service: "",
                            serviceAmount: 0,
                            newDescriptionName: "",
                            description: "",
                            descriptionAmount: 0,
                            descriptionSerach: "",
                            serviceSearch: ""
                        }, () => {
                            // this.state.value === 'two' ? this.getServiceDescriptionData() : this.getServiceTemplateData()
                        })}
                        sx={{ backgroundColor: Colors.DataTblHeaderbg }}
                        className='eMed_bill_Tab'
                    >
                        <Tab value="two" label="Service Description" className='eMed_tab_header eMed_bill_Tab' />
                        <Tab value="one" label="Service Template" className='eMed_tab_header eMed_bill_Tab' />
                        {this.state.showCode ?
                            <Tab value="three" label="Service Code" className='eMed_tab_header eMed_bill_Tab' /> : null}
                    </Tabs>
                    {this.state.value === "one" ? this.serviceTemplateTab() : this.state.value === "two" ? this.serviceDescriptionTab() : this.state.value === 'three' ? this.serviceCodeTab() : ""}
                </Grid>
            </Box>
        )
    }

    handleCheckBox = (e, key) => {
        let creditPopup = false
        let details = this.props.opBillPatientData ? this.props.opBillPatientData : this.state.DemographicData
        let ins_name = (details?.insurance_name || details?.insurance_name === '') ? details?.insurance_name : (details?.insurance_company_name || details?.insurance_company_name === '') ? details?.insurance_company_name : this.state.selInsName
        let ins_no = details?.policy_no ? details?.policy_no : details?.policy_number ? details?.policy_number : this.state.selPolicyNo
        let comp_name = (details?.company_name || details?.company_name === '') ? details?.company_name : this.state.selCompanyName
        let employee_no = details?.employee_id ? details?.employee_id : details?.id_no ? details?.id_no : this.state.selEmployeeNo

        if (key === "isCredit" && (details?.is_billed === false || this.props.isFromQuickBilling)) {
            if (e.target.checked === false) {
                this.setState({
                    paymentCreditType: details?.primary_payment_type === "Patient credit" ? 'patientcredit' : details?.primary_payment_type === "Corprate credit" ? 'corporatecredit' : details?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                    selPayemtnCreditType: details?.primary_payment_type === "Patient credit" ? 'patientcredit' : details?.primary_payment_type === "Corprate credit" ? 'corporatecredit' : details?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                })
            } else {
                if (!comp_name && !ins_name) {
                    creditPopup = true

                    // } else if (ins_name) {
                    //     creditPopup = false
                    //     this.setState({
                    //         paymentCreditType: 'insurancecredit',
                    //         selPayemtnCreditType: 'insurancecredit',
                    //         selInsName: ins_name,
                    //         selPolicyNo: ins_no,
                    //     })

                } else if (comp_name) {
                    creditPopup = false
                    this.setState({
                        paymentCreditType: 'corporatecredit',
                        selPayemtnCreditType: 'corporatecredit',
                        selCompanyName: comp_name,
                        selEmployeeNo: employee_no ? employee_no : this.state?.patientData?.employee_id ? this.state?.patientData?.employee_id : '',
                        selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : '',
                    })
                } else {
                    creditPopup = true
                }
            }

        }

        if (key === 'isRegular') {
            this.setState({
                isCashless: false,
                isRegular: true
            })
        }
        if (key === 'isCashless') {
            this.setState({
                isRegular: false,
                isCashless: true
            })
        }
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ CashAmount: "" }) }
            if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" }) }
            if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
            if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
            if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && key !== "isCredit" && !states.isCredit) {
                this.setState({ CashAmount: this.state.totalPayableAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
            if (this.state.isCredit && creditPopup) {
                this.setState({
                    creditChange: true,
                }, () => {
                    this.getCorporateDetails()
                    this.getInsuranceDetails()
                })
            }
        })
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                // disabled={this.state.forCancel}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20 }}
                // disabled={this.state.forCancel}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    if (number || e.target.value === "") {
                        states[key] = e.target.value.toUpperCase()
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    FindByID(List, Id, IdKey, ValueKey) {
        let Data = ""
        Data = List.length > 0 ? List.filter((item) => (item[IdKey] === Id)) : []
        Data = Data.length === 1 ? Data[0][ValueKey] : ""
        return Data
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason'>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Concession Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false, discountReason: '' })
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false })
                                    } else {
                                        this.successErrorMessgae("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    tableFooter = (params) => {
        const { t } = this.props
        let disable = this.state.billingList.length > 0 ? false : true
        let corporate = this.props.corporateList?.find((item) => item.id === this.state.DemographicData.employer_id)
        return (
            <Box component={'div'} className='eMed_op_bill_tbl_footer'>
                <Box component={'div'} style={{ display: "flex", justifyContent: "flex-end" }} className='eMed_op_bill_tbl_one'>
                    {/* <Box component={'div'} className='eMed_flex_one'>
                        <Box component={'div'} className='eMed_payment_op_detail'>
                            <Box component={'div'} className='eMed_op_pay_one'>
                                <Typography fontSize={'0.95vw'}>Payment Method</Typography>
                            </Box>
                            // Hidden
                            // <Box component={'div'} className='eMed_op_pay_two'>
                            //     <FormControlLabel control={<Checkbox size='small' checked={this.state.isRegular ? true : false} onChange={(e) => { this.handleCheckBox(e, 'isRegular') }} />} label="Regular" />
                            //     <FormControlLabel control={<Checkbox size='small' checked={this.state.isCashless ? true : false} onChange={(e) => { this.handleCheckBox(e, 'isCashless') }} />} label="Cashless" />
                            // </Box>
                        </Box>
                        <Box component={'div'} className='eMed_cor_txt'>
                            <FormControl>
                                <RadioGroup value={this.state.corporatePay} onClick={(e) => {
                                    if (e.target.value === this.state.corporatePay) {
                                        this.setState({
                                            corporatePay: "",
                                            totalDiscountedPercentage: "",
                                            totalDiscountedAmount: ""
                                        })
                                    } else {
                                        this.setState({
                                            corporatePay: e.target.value,
                                            totalDiscountedPercentage: this.state.employerDiscount ? this.state.employerDiscount : corporate?.discount_percent
                                        }, () => this.calculateTotalDiscount())
                                    }
                                }}>
                                    <FormControlLabel value={"Corporate"} control={<Radio size="small" />} label={<Typography id="eMed_recPayment_radioCorpTxt">
                                        <span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Corporate")}: `}</span>{`${t("PatientUnder")} `}
                                        <span className='eMed_cor_font'>{corporate?.company_name ? corporate?.company_name : this.state.patientData?.company_name}</span>  {`${t("CorporatePlan")} (${t("ID.No.")}`}
                                        <span className='eMed_cor_font'>{this.state.DemographicData?.employee_id ? this.state.DemographicData?.employee_id : this.state.patientData?.employee_id}</span> {") "}
                                        <span className='eMed_cor_font'>{corporate?.discount_percent ? corporate?.discount_percent : this.state.patientData?.employer_discount} %</span>
                                        {` ${t("corporateTxt")}`}</Typography>} />
                                    <FormControlLabel value={"Insurance"} control={<Radio size="small" />} label={<Typography id="eMed_recPayment_radioCorpTxt">
                                        <span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Insurance")}: `}</span>{`${t("PatientRegisteredUnder")} (${t("RegNo")})`}</Typography>} />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box> */}
                    <Box component={'div'} className='eMed_flex_two'>
                        <Box component={'div'} className='eMed_bill_tbl_footer'>
                            <Typography mt={0.5} fontSize={'0.9vw'}>{t("Sub Amount")}</Typography>
                            <Typography mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
                        </Box>
                        <Box component={'div'} className='eMed_bill_tbl_footer' sx={{ width: '25vw' }}>
                            <Typography component={'div'} width={"30%"} fontSize={'0.9vw'} display={'flex'} alignItems={'center'}>
                                {+this.state.billConcessionType === 1 ? null : <Box component={'span'}>
                                    <Tooltip placement='top' title={`Maximum Concession Should be ${CurrencySymbol} ${this.state.concessionRestrictAmount}`}>
                                        <ErrorOutlineIcon color='primary' sx={{ height: '1.3vw', width: '1.3vw' }} />
                                    </Tooltip>
                                </Box>}{`${t("concession")} %`}
                            </Typography>
                            {this.inputTextBox('totalDiscountedPercentage', this.state.totalDiscountedPercentage, params, "0", "0.7vw", disable)}
                            {this.inputTextBox('totalDiscountedAmount', this.state.totalDiscountedAmount, params, "0", "0.7vw", disable)}
                            {this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount ?
                                <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason}>
                                    <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                                </Tooltip> :
                                <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                            }
                        </Box>
                        <Box component={'div'} className='eMed_bill_tbl_footer'>
                            <Typography mt={0.5} fontSize={'0.9vw'}>{t("RoundOff")}</Typography>
                            <Typography mt={0.5} mr={'0.8vw'}>
                                {this.billingCalculation(params, 'roundOff')}
                            </Typography>
                        </Box>
                        <Box component={'div'} className='eMed_bill_tbl_footer' borderTop={"2px solid gray"}>
                            <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{this.state.forCancel ? "Refundable Amount" : t("PayableAmount")}</Typography>
                            <Typography mt={0.5} mr={'0.8vw'}>
                                {this.billingCalculation(params, 'payableAmount')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_op_bill_tbl_two'>
                    <Box ml={"0.5vw"} visibility={((this.state.forCancel && this.state.totalPayableAmount === 0) || this.state.isForEditBill) ? 'hidden' : 'visible'}>
                        <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' disabled={this.state.totalPayableAmount === 0 ? true : false} />} label={t("Cash")} />
                        {this.state.forCancel ? null : <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' disabled={this.state.totalPayableAmount === 0 ? true : false} />} label={t("Card")} />}
                        <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} disabled={this.state.totalPayableAmount === 0 ? true : false} />
                        <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} disabled={this.state.totalPayableAmount === 0 ? true : false} />
                        {this.state.forCancel ? null : <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} disabled={this.state.totalPayableAmount === 0 ? true : false} />}
                    </Box>

                    <Box component={'div'} display={'flex'} ml={'0.5vw'} overflow={'scroll'} mt={'-0.3vw'}>
                        {
                            this.state.isCash ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                </Box> : null
                        }
                        {
                            this.state.isCard ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                    {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                </Box>
                                : null
                        }
                        {
                            this.state.isUPI ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                    {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                </Box>
                                : null
                        }
                        {
                            this.state.isBankTranfer ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                    {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                </Box>
                                : null
                        }
                        {
                            this.state.isCheque ?
                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                    {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                    {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                </Box>
                                : null
                        }
                    </Box>
                </Box>
            </Box>
        )
    }

    SavedBillPost(id) {
        try {
            if (this.state.billingList.length > 0) {
                let services = [];
                this.state.billingList.forEach(element => {
                    let Unit = element.time_taken ? element.time_taken : element.unit
                    let old_Unit = element.old_time_taken ? element.old_time_taken : element.time_taken
                    let time = element.time_taken ? this.CalculateHourAmount((+element.amount), Unit, old_Unit, element.minimum_time, +element?.min_amount) : +element.amount * element.unit
                    services.push({
                        service_name: element.service_name ? CommonValidation.removeSpace(element.service_name) : '',
                        service_type: element.service_type ? CommonValidation.removeSpace(element.service_type) : '',
                        amount_net: element.time_taken ? ((+time) - (+element.discountAmount)) : ((+element.amount * element.unit) - +element.discountAmount),
                        amount_gross: element.amount === "" ? 0 : +time,
                        service_discount_percentage: element.discountPercentage === "" ? 0 : +element.discountPercentage,
                        service_discount_amount: element.discountAmount === "" ? 0 : +element.discountAmount,
                        service_rate_per_unit: element.amount === "" ? 0 : +element.amount,
                        service_unit_count: Unit ? Unit : '1',
                        service_old_unit_count: old_Unit ? old_Unit : null,
                        item_id: this.props.BillData && Object.keys(this.props.BillData).length > 0 ? element.line_item_id : null,
                        template_id: element.templateId ? element.templateId : null,
                        service_id: element.op_service_id ? element.op_service_id : element.id ? element.id : null,
                        doctor_fee: element?.service_fee?.doctor_fee ? +(element?.service_fee?.doctor_fee).toFixed(2) : element.doctor_fee ? +(element.doctor_fee).toFixed(2) : 0,
                        doctor_percentage: element.service_fee?.doctor_percentage ? element?.service_fee?.doctor_percentage : element.doctor_percentage ? element.doctor_percentage : 0,
                        hospital_fee: element.service_fee?.hospital_fee ? +(element?.service_fee?.hospital_fee).toFixed(2) : element.hospital_fee ? +(element.hospital_fee).toFixed(2) : 0,
                        hospital_percentage: element.service_fee?.hospital_percentage ? element?.service_fee?.hospital_percentage : element.hospital_percentage ? element.hospital_percentage : 0,
                        service_catagory_id: element.service_catagory_id ? element.service_catagory_id : null,
                        old_doctor_fee: element?.service_fee?.original_doctor_fee ? element?.service_fee?.original_doctor_fee : element?.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : 0,
                        old_hospital_fee: element?.service_fee?.original_hospital_fee ? element?.service_fee?.original_hospital_fee : element?.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : 0,
                        new_doctor_fee: element.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : 0,
                        new_hospital_fee: element.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : 0,
                        amount_type: element.service_fee?.amount_type ? element.service_fee?.amount_type : element.amount_type ? element.amount_type : 'rupee',
                        is_auto_billing: element?.is_auto_billing,
                        procedure_start_time: element?.start_date ? element.start_date : null,
                        procedure_end_time: element?.end_date ? element.end_date : null,
                        minimum_amount: element.time_taken && element?.min_amount ? element?.min_amount : null,
                        minimum_time: element?.minimum_time,
                        individual_discount_comments: element?.individual_discount_comments ? element?.individual_discount_comments : ""
                    })
                })
                let date = this.state.templateDate ? this.state.templateDate : this.state.descripDate
                let startDate = new Date(date)
                let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                let invoice_date = date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : ""
                let data = {
                    "invoice": {
                        appointment_id: id,
                        amount_invoice: 0,
                        amount_net: +this.state.totalPayableAmount,
                        discount_percent: +this.state.totalDiscountedPercentage,
                        discount: +this.state.totalDiscountedAmount,
                        total_sub_amount: +(this.state.totalServiceAmount),
                        // invoice_date: date,
                        amount_gross: 0,
                        amount_cash: 0,
                        amount_card: 0,
                        upi_amount: 0,
                        bank_transfer_amount: 0,
                        cheque_ref_no: "",
                        credit_type: "",
                        bank_ref_no: "",
                        upi_ref_no: "",
                        card_ref_no: "",
                        cheque_amount: 0,
                        round_off: 0,
                        invoice_date: invoice_date,
                        payment_mode: [],
                        amount_paid: 0,
                        remarks: this.state.discountReason ? this.state.discountReason : ''
                    },
                    removable_ids: this.state.removable_ids,
                    services: services,
                    payment_status: 'not paid',
                    bill_type: "saved"
                }
                if (this.props.BillData && Object.keys(this.props.BillData).length > 0) { data["bill_id"] = this.props.BillData?.id }
                this.lodaerFunction(true)
                RestAPIService.create(data, Serviceurls.FO_PATIEMT_BILLING)
                    .then((response) => {
                        if (response.data.status === "success") {
                            clearCachevalue("OP_CreateAppointment_PatientId");
                            clearCachevalue("AppRequestPatientData");
                            clearCachevalue("QRRequestPatientData");
                            this.lodaerFunction(false)
                            this.setState({ DisableBtn: false })
                            this.successErrorMessgae(response.data.message, 'success')
                            if (this.props.isFromQuickBilling) { this.props.QuickBillPostSucess() }
                            this.clearTable()
                            this.setState({ discountReason: '', removable_ids: [] })
                            this.getServiceDescriptionData()
                            this.getServiceTemplateData()
                            this.props.changeNursestate(false)
                        }
                    }).catch((error) => {
                        this.setState({ isSavedBillClicked: false, DisableBtn: false }, () => {
                            this.lodaerFunction(false)
                            this.successErrorMessgae(error?.response?.data?.message, 'error')
                        })
                    })
            }
            else {
                this.setState({ DisableBtn: false })
                this.successErrorMessgae("No Service Added", 'error')
                this.lodaerFunction(false)
            }
        }
        catch (e) {
            this.setState({ DisableBtn: false })
            this.successErrorMessgae(e.message, "error")
            this.lodaerFunction(false)
        }
    }

    convertTo24Hour = (time12h) => {
        const [time, period] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (period.toLowerCase() === 'pm' && hours !== '12') {
            hours = String(Number(hours) + 12);
        }

        if (period.toLowerCase() === 'am' && hours === '12') {
            hours = '00';
        }

        return `${hours}:${minutes}:00`;
    }

    postAppointmentCreate = (id) => {
        var states = this.state.DemographicData
        let { history } = this.props;

        var AptDateTime = new Date(states.appointmentDateTime)

        var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')

        var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        var data = {
            patient_id: id,
            doctor_id: states.selectedDocId?.doctor_id ? states.selectedDocId?.doctor_id : null,
            speciality_id: states.selectedSpecId?.id ? states.selectedSpecId?.id : null,
            purpose_of_visit: states.purposeOfVisit,
            patient_tag_id: states.selectedTagId?.id,
            appointment_date: AptDate,
            appointment_start_time: this.props?.DamographData?.selectedSlot != "" ? `${this.props?.DamographData?.selectedSlot}:00` : Aptime,
            patient_appointment_type: states.selectedAppointTypeId === null ? null : states.selectedAppointTypeId?.id,
            nurse_id: states.selectedNurseId?.staff_id ? states.selectedNurseId?.staff_id : null,
            request_id: states.RequestedPatientData?.id ? states.RequestedPatientData?.id : null,
            qr_request_id: states.QRPatientData?.id ? states.QRPatientData?.id : null,
            followup_id: states?.followupID ? states?.followupID : null
        }

        RestAPIService.create(data, Serviceurls.FO_PATIENT_APPOINTMENT)
            .then((response) => {
                if (response.data.status === 'success') {
                    this.setState({ onAddService: false })
                    if (this.state.isSavedBillClicked) {
                        this.SavedBillPost(response.data?.data?.appointment_id)
                    }
                    else {
                        this.opPatientBilling(response.data?.data?.appointment_id)
                    }
                }
            }).catch((e) => {
                this.lodaerFunction(false)
                if (e?.response?.data?.message) {
                    this.successErrorMessgae(e.response.data.message, 'error')
                } else {
                    this.successErrorMessgae(e.message, 'error')
                    this.lodaerFunction(false)
                }
            })
    }

    // postNewInsurance = () => {
    //     var states = this.state.DemographicData

    //     var data = {
    //         "insurance_name": states.insurance_company_name
    //     }

    //     RestAPIService.create(data, Serviceurls.FO_INSURANCE_LIST)
    //         .then((response) => {
    //             if (response.data.status === 'success') {
    //             }
    //         }).catch((e) => {
    //             if (e?.response?.data?.message) {
    //                 this.errorMessage(e.response.data.message)
    //             } else {
    //                 this.errorMessage(e.message)
    //             }
    //         })
    // }

    MandatoryCheck() {
        let states = this.state.DemographicData;
        let MandatoryCount = +(states.mandotory_count)
        let flag = false;
        for (let i = 0; i < MandatoryCount; i++) {
            if (CommonValidation.removeSpace(states[states.layoutData[i].field_name]) === "" || states[states.layoutData[i].field_name] === null) {
                flag = false
                this.successErrorMessgae(`${states.layoutData[i].label} is Mandatory !`, 'error')
                break
            } else {
                flag = true
            }
        }
        return flag
    }

    CreatAppointmentApi(PatientId = null) {
        try {
            let { history } = this.props;
            let states = this.state.DemographicData;

            var date = new Date(states.dob)

            var Dob = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
            const dataForm = new FormData();


            dataForm.append("first_name", states.first_name === null ? "" : CommonValidation.removeSpace(states.first_name))
            dataForm.append("last_name", states.surname === null ? "" : CommonValidation.removeSpace(states.surname))
            dataForm.append("middle_name", states.middle_name === null ? "" : CommonValidation.removeSpace(states.middle_name))
            dataForm.append("father_husband_name", states.father_husband_name === null ? "" : CommonValidation.removeSpace(states.father_husband_name))
            dataForm.append("father_husband_age", states.father_husband_age === null ? "" : CommonValidation.removeSpace(states.father_husband_age))
            dataForm.append("gender", states.gender === null ? "" : states.gender)
            dataForm.append("title", states.title === null ? "" : states.title)
            dataForm.append("occupation", states.occupation === null ? "" : CommonValidation.removeSpace(states.occupation))
            dataForm.append("dob", Dob === null ? "" : Dob)
            dataForm.append("approx_dob", Dob === null ? "" : Dob)
            dataForm.append("marital_status", states.marital_status === null ? "" : CommonValidation.removeSpace(states.marital_status))
            dataForm.append("mobile_number", states.mobile_no === null ? "" : CommonValidation.removeSpace(states.mobile_no))
            dataForm.append("mobile_number_2", "")
            dataForm.append("email", states.email_id === null ? "" : CommonValidation.removeSpace(states.email_id))
            dataForm.append("address_line_1", states.address_1 === null ? "" : CommonValidation.removeSpace(states.address_1))
            dataForm.append("address_line_2", states.address_2 === null ? "" : CommonValidation.removeSpace(states.address_2))
            dataForm.append("pincode", states.pin_code === null ? "" : CommonValidation.removeSpace(states.pin_code))
            dataForm.append("city_name", states.city === null ? "" : CommonValidation.removeSpace(states.city))
            dataForm.append("state_name", states.state === null ? "" : CommonValidation.removeSpace(states.state))
            dataForm.append("country", states.country === null ? "" : CommonValidation.removeSpace(states.country))
            dataForm.append("reffered_by", states.referred_by === null ? "" : CommonValidation.removeSpace(states.referred_by))
            dataForm.append("referral_source", states.referral_source === null ? "" : CommonValidation.removeSpace(states.referral_source))
            dataForm.append("id_no", states.employee_id === null ? "" : CommonValidation.removeSpace(states.employee_id))
            dataForm.append("employer_id", states.employer_id === null ? "" : CommonValidation.removeSpace(states.employer_id))
            dataForm.append("caretaker_name", states.emergency_contact_person === null ? "" : CommonValidation.removeSpace(states.emergency_contact_person))
            dataForm.append("caretaker_mobile", states.emergency_contact_mobile === null ? "" : CommonValidation.removeSpace(states.emergency_contact_mobile))
            dataForm.append("caretaker_relationship", states.relationship === null ? "" : CommonValidation.removeSpace(states.relationship))
            dataForm.append("receive_email", states.receive_email === null ? "" : states.receive_email)
            dataForm.append("receive_whatsapp", states.receive_whatsapp === null ? "" : CommonValidation.removeSpace(states.receive_whatsapp))
            dataForm.append("receive_sms", states.receive_sms === null ? "" : states.receive_sms)
            dataForm.append("insurance_company_name", states.insurance_company_name === null ? "" : CommonValidation.removeSpace(states.insurance_company_name))
            dataForm.append("policy_number", states.policy_number === null ? "" : CommonValidation.removeSpace(states.policy_number))
            dataForm.append("guardian", states.guardian === null ? "" : CommonValidation.removeSpace(states.guardian))
            dataForm.append("mother_name", states.mother_name === null ? "" : CommonValidation.removeSpace(states.mother_name))
            dataForm.append("mother_age", states.mother_age === null ? "" : CommonValidation.removeSpace(states.mother_age))
            dataForm.append("old_referral_no", states.old_referral_no === "" ? "" : CommonValidation.removeSpace(states.old_referral_no))

            if (PatientId !== null) { dataForm.append("patient_id", PatientId) }
            else if (this.state.CreatedPatientId !== "") { dataForm.append("patient_id", this.state.CreatedPatientId) }
            let ApiUrl = "";
            if (PatientId === null && this.state.CreatedPatientId === "") {
                ApiUrl = Serviceurls.FO_PATIENT_CREATE
            }
            else {
                ApiUrl = Serviceurls.FO_PATIENT_UPDATE
            }
            RestAPIService.create(dataForm, ApiUrl)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        // if ((states.insurance_company_name != "" && states.insurance_company_name != null) && states.NewInsurance) {
                        //     this.postNewInsurance()
                        // }
                        var Data = response.data.data
                        if (PatientId === null && this.state.CreatedPatientId === "") {
                            if (Data.patient_id) {
                                this.setState({
                                    CreatedPatientId: Data.patient_id
                                }, () => { this.postAppointmentCreate(Data.patient_id) })
                            }
                        }
                        else if (this.state.CreatedPatientId !== "") {
                            this.postAppointmentCreate(this.state.CreatedPatientId)
                        }
                        else {
                            this.postAppointmentCreate(PatientId)
                        }
                    }
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.successErrorMessgae(e.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(e.message, 'error')
                    }
                })
        }
        catch (e) {
            this.successErrorMessgae(e.message, "error")
        }
    }

    DemoValidation() {
        try {
            let states = this.state.DemographicData;
            let Email = (states.MandatoryLabels.includes("Email ID") || states.email_id !== "") ? CommonValidation.emailValidation(states.email_id) : true
            Email = states.email_id == null ? true : true;
            let Corporate = (states.employer_id === "" || states.employer_id === null) ? true : (states.employee_id !== "" && states.employee_id !== null)
            let Insurance = (states.insurance_company_name === "" || states.insurance_company_name === null) ? true : (states.policy_number !== "" && states.policy_number !== null)
            if (this.MandatoryCheck()) {
                if (((states.isNurse ? states.selectedNurseId !== null : ((states.selectedDocId !== null || !states?.isDoctorMandatory) && states.selectedSpecId !== null)) && DateTime.fromJSDate(states.appointmentDateTime).toFormat('yyyy-MM-dd') !== "Invalid DateTime" && (new Date((new Date).getFullYear(), (new Date).getMonth() - 2, 1) < states.appointmentDateTime && new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0) > states.appointmentDateTime) && states.mobile_no.length === 10 && Email && Corporate && Insurance && (new Date(states.dob) < new Date() && new Date(states.dob) > new Date("1872-01-01")) && CommonValidation.removeSpace(states.purposeOfVisit) !== "")) {
                    if (this.state.billingList.length > 0) {
                        if (this.state.isCard || this.state.isCash || this.state.isUPI || this.state.isCheque || this.state.isBankTranfer || this.state.isSavedBillClicked || this.state.isCredit) {
                            if (!this.CalculateAmount() && !this.state.isSavedBillClicked) {
                                this.successErrorMessgae("Amount Shouldn't Be Greater Than Payable Amount", 'error')
                            } else if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
                                this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
                                this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
                                this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") {
                                if (!this.state.isSavedBillClicked) {
                                    this.successErrorMessgae("Please,Enter Reference Number", 'error')
                                } else {
                                    this.lodaerFunction(true)
                                    if (states.PatientID === null) {
                                        this.CreatAppointmentApi()
                                    }
                                    else {
                                        this.CreatAppointmentApi(states.PatientID)
                                        // this.postAppointmentCreate(states.PatientID)
                                    }
                                }
                            } else {
                                this.lodaerFunction(true)
                                if (states.PatientID === null) {
                                    this.CreatAppointmentApi()
                                }
                                else {
                                    this.CreatAppointmentApi(states.PatientID)
                                    // this.postAppointmentCreate(states.PatientID)
                                }
                            }
                        } else {
                            if (!this.checkSatus()) {
                                this.successErrorMessgae("Please,Select Payment Type", 'error')
                            } else {
                                this.lodaerFunction(true)
                                if (states.PatientID === null) {
                                    this.CreatAppointmentApi()
                                }
                                else {
                                    this.CreatAppointmentApi(states.PatientID)
                                    // this.postAppointmentCreate(states.PatientID)
                                }
                            }
                        }
                    } else {
                        this.successErrorMessgae("No Service Added", 'error')
                    }


                }
                else {
                    this.setState({ DisableBtn: false }, () => {
                        if (states.mobile_no.length !== 10) { this.successErrorMessgae("Invalid Mobile Number", 'error') }
                        else if (!Email) { this.successErrorMessgae("Invalid Email ID", 'error') }
                        else if (!(new Date(states.dob) < new Date() && new Date(states.dob) > new Date("1872-01-01"))) { this.successErrorMessgae("Invalid Date of Birth", 'error') }
                        else if (!Corporate) { this.successErrorMessgae("Employee ID No is empty", 'error') }
                        else if (!Insurance) { this.successErrorMessgae("Insurance Policy No is empty", 'error') }
                        else if (states.selectedSpecId === null && states.isNurse === false) { this.successErrorMessgae("Select Specialization", 'error') }
                        else if ((states.selectedDocId === null && states?.isDoctorMandatory) && states.isNurse === false) { this.successErrorMessgae("Select Doctor", 'error') }
                        else if (states.selectedNurseId === null && states.isNurse) { this.successErrorMessgae("Select Nurse", 'error') }
                        else if (CommonValidation.removeSpace(states.purposeOfVisit) === "") { this.successErrorMessgae("Enter Purpose of Visit", 'error') }
                        else if (DateTime.fromJSDate(states.appointmentDateTime).toFormat('yyyy-MM-dd') === "Invalid DateTime" || !(new Date((new Date).getFullYear(), (new Date).getMonth() - 2, 1) < states.appointmentDateTime && new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0) > states.appointmentDateTime)) { this.successErrorMessgae("Invalid Appointment Date & Time !", 'error') }
                    })
                }
            }
        }
        catch (e) {
            this.successErrorMessgae(e.message, 'error')
        }
    }

    FullAmountEntered() {
        try {
            let states = this.state;

            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
            if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt) == +states.totalPayableAmount && (+states.totalPayableAmount !== 0 || (this.state?.forCancel))) {
                if (
                    (CardAmt === 0 || states.CardTransactionNumber !== "") &&
                    (UPIAmt === 0 || states.UPINumber !== "") &&
                    (bankTransferAmt === 0 || states.BankTfrNumber !== "") &&
                    (ChequeAmt === 0 || states.ChequeNumber !== "")
                ) {
                    return true
                }
            }
            else {
                return false
            }
        }
        catch (e) {
            return false
        }
    }

    checkSatus = () => {
        if ((this.state.totalServiceAmount === 0 || +this.state.totalDiscountedPercentage === 100)) {
            return true
        }
    }

    checkButtonDisable = () => {
        if ((this.state.totalServiceAmount === 0 || +this.state.totalDiscountedPercentage === 100) && this.state.billingList?.length > 0) {
            return false
        } else if (this.state.withOutAppointment?.is_op || !(this.state.isCredit || this.FullAmountEntered()) || this.state.isButtonDisabled) {
            return true
        }
    }

    paymentButton = () => {
        const { t } = this.props
        var states = this.state
        let details = this.props.opBillPatientData ? this.props.opBillPatientData : this.state.DemographicData

        return (
            <Box component={'div'} className='eMed_table_bottom'>
                <Box component={'div'} ml={'0.5vw'} className='eMed_Tbl_btm_one'>
                    <FormControlLabel disabled={this.state.totalPayableAmount === 0 ? true : (this.FullAmountEntered() || this.state.billingList.length === 0) || states?.per_access?.op?.is_credit === false} control={<Checkbox size='small' checked={this.state.isCredit} onChange={(e) => { this.handleCheckBox(e, "isCredit") }} />} label="Add To Credit" />
                    <Tooltip title={'Change Credit Type'} placement='top'>
                        <IconButton size='small'
                            disabled={(this.FullAmountEntered() || this.state.billingList.length === 0) || states?.per_access?.op?.is_credit === false || !this.state.isCredit}
                            onClick={() => {
                                this.setState({
                                    creditChange: true,
                                }, () => {
                                    this.getInsuranceDetails()
                                    this.getCorporateDetails()
                                })
                            }}>
                            <img style={{ width: '1.2vw', height: '1.2vw' }} src={(this.FullAmountEntered() || this.state.billingList.length === 0) || states?.per_access?.op?.is_credit === false || !this.state.isCredit ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                        </IconButton>
                    </Tooltip>
                    {states?.paymentCreditType === 'patientcredit' ? null :
                        states?.paymentCreditType === 'corporatecredit' ?
                            <Typography fontSize={'0.6vw'} color={'#616161'} fontWeight={'bold'}>{`( Corporate:  ${states.selCompanyName ? states.selCompanyName : details?.company_name ? details?.company_name : '-'} | ${states.selEmployeeNo ? states.selEmployeeNo : details?.employee_id ? details?.employee_id : details?.id_no ? details?.id_no : '-'} )`}</Typography> :
                            states?.paymentCreditType === 'insurancecredit' ?
                                <Typography fontSize={'0.6vw'} color={'#616161'} fontWeight={'bold'}>{`( Insurance:  ${states.selInsName ? states.selInsName : details?.insurance_name ? details?.insurance_name : details?.insurance_company_name ? details?.insurance_company_name : '-'} | ${states.selPolicyNo ? states.selPolicyNo : details?.policy_no ? details?.policy_no : details?.policy_number ? details?.policy_number : '-'} )`}</Typography> : null}
                </Box>
                <Box component={'div'} className='eMed_Tbl_btm_two'>
                    <Box component={'div'} padding={'0.5vw'}>
                        <Button variant='outlined' disabled={this.state.withOutAppointment?.is_op} onClick={() => this.setState({ ClearClicked: true })} sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} size='small'>{t("Clear")}</Button>
                        <Button variant='outlined' onClick={() => {
                            this.setState({ isSavedBillClicked: true, DisableBtn: true }, () => {
                                setTimeout(() => {
                                    this.setState({ isSavedBillClicked: false })
                                }, 7000);
                                if (this.props.opPatientBilling) {
                                    this.SavedBillPost(this.props.appointmentId)
                                }
                                else if (this.props.isFromQuickBilling) {
                                    this.DemoValidation()
                                    this.lodaerFunction(false)
                                }
                            })
                        }} disabled={this.state.DisableBtn || this.state.isCredit || this.FullAmountEntered() || this.state.billingList.length === 0 || this.state.withOutAppointment?.is_op || this.state.isCard || this.state.isCash || this.state.isUPI || this.state.isCheque || this.state.isBankTranfer || this.state.isSavedBillClicked} sx={{ marginRight: "0.5vw", textTransform: "capitalize" }} size='small'>{t("Save As Draft")}</Button>

                        <Button
                            disabled={this.checkButtonDisable() || this.state.isReceivePaymentClicked || this.state.DisableBtn}
                            variant='contained'
                            sx={{ textTransform: "capitalize", width: '10vw' }}
                            size='small'
                            onClick={() => {
                                this.setState({
                                    DisableBtn: true
                                }, () => {
                                    if (this.props.opPatientBilling) {
                                        this.opPatientBilling()
                                    }
                                    else if (this.props.isFromQuickBilling) {
                                        this.DemoValidation()
                                        this.lodaerFunction(false)
                                        this.setState({
                                            isReceivePaymentClicked: true
                                        }, () => {
                                            setTimeout(() => {
                                                this.setState({ isReceivePaymentClicked: false })
                                            }, 7000);
                                        })
                                    }
                                })
                            }}
                        >{this.state.isCredit ? t("Add To Credit") : t("Receive Payment")}</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    rederButtons = () => {
        if (this.props.isFromQuickBilling) {
            return this.paymentButton()
        }
        else if (CheckAccessFunc("front_office", "Home", "Billing", "Create Bill", "SubTab")?.editAccess) {
            return this.paymentButton()
        }
    }

    handleDelete = () => {
        this.state.removable_ids.push(this.state.billingList[this.state.deleteIndex].line_item_id)
        this.state.billingList.splice(this.state.deleteIndex, 1)
        this.state.serviceList.splice(this.state.deleteIndex, 1)
        let decIndex = this.state.serviceList?.length > 0 ? this.state.serviceList?.length : 0
        this.state.descriptionList.splice(this.state.deleteIndex - decIndex, 1)
        this.state.serviceCodeList.splice(this.state.deleteIndex - decIndex, 1)
        this.setState({
            billingList: this.state.billingList,
            serviceList: this.state.serviceList,
            descriptionList: this.state.descriptionList,
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: "",
            totalServiceAmount: this.state.billingList.length === 0 ? 0 : this.state.totalServiceAmount,
            description: "",
            newDescription: [],
            newDescriptionName: '',
            description: '',
            service: '',
            serviceAmount: 0,
            descriptionAmount: 0,
            serviceCodeList: this.state.serviceCodeList
        }, () => {
            //  this.calculateTotalDiscount()
            this.setState({
                totalDiscountedAmount: '',
                totalDiscountedPercentage: ''
            })
        })
    }

    popupClose = (data) => {
        if (data) {
            this.setState({
                isDeleteAll: false,
                ClearClicked: false
            })
        }
        this.setState({
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: ""
        })
    }

    clearAllData = () => {
        try {
            this.setState({ ClearClicked: false })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    feeValue = () => {
        try {
            let doctorValue = 0; let hospitalValue = 0;
            let duplicateSelectedValue = JSON.parse(this.state.dupliSelectedData)
            let serviceFee = duplicateSelectedValue?.service_fee
            doctorValue = serviceFee?.original_doctor_fee
            hospitalValue = serviceFee?.original_hospital_fee

            return { doctorValue, hospitalValue };
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    onChangeDiscountHandler = (e, names) => {
        try {
            let value = e.target.value
            let fee = this.feeValue()
            let states = this.state
            switch (names) {
                case 'discountDoctorFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(+value) && +value <= fee.doctorValue) || value === '') {
                            this.state.discountPercentDoctorFee = CommonValidation.amountPercentage(+value, fee.doctorValue)
                            this.setState({ discountDoctorFee: +value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_doctor_fee = value ? value : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountDoctorFee = CommonValidation.calculatePercentage(+value, fee.doctorValue)
                            this.setState({ discountPercentDoctorFee: +value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_doctor_fee = value ? CommonValidation.calculatePercentage(value, fee.doctorValue) : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    }
                    break;
                case 'discountHospitalFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && value <= fee.hospitalValue) || value === '') {
                            this.state.discountPercentHospitalFee = CommonValidation.amountPercentage(+value, fee.hospitalValue)
                            this.setState({ discountHospitalFee: +value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_hospital_fee = value ? value : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountHospitalFee = CommonValidation.calculatePercentage(+value, fee.hospitalValue)
                            this.setState({ discountPercentHospitalFee: +value }, () => {
                                let serviceFee = states.selectedData?.service_fee
                                serviceFee.discount_hospital_fee = value ? CommonValidation.calculatePercentage(value, fee.hospitalValue) : 0
                                if (!serviceFee.discount_hospital_fee && !serviceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ states })
                            })
                        }
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    splitUpTextBox = (names, values, label, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    value={values}
                    label={label}
                    autoComplete='off'
                    onChange={(e) => this.onChangeDiscountHandler(e, names)}
                    InputProps={{
                        endAdornment: this.state.selectedType === 'rupee' ?
                            // <CurrencyRupee color='primary'></CurrencyRupee> :
                            CurrencySymbol :
                            <PercentIcon color='primary' />
                    }}
                />
            )
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    shareValue = () => {
        try {
            let states = this.state
            let doctorFee = 0; let discountDoctor = 0;
            let hospitalFee = 0; let discountHospital = 0
            let serviceFee = states.selectedData?.service_fee

            doctorFee = CommonValidation.formatAmount(serviceFee?.original_doctor_fee ? serviceFee?.original_doctor_fee : 0)
            discountDoctor = CommonValidation.formatAmount(serviceFee?.discount_doctor_fee ? serviceFee?.discount_doctor_fee : 0)
            hospitalFee = CommonValidation.formatAmount(serviceFee?.original_hospital_fee ? serviceFee?.original_hospital_fee : 0)
            discountHospital = CommonValidation.formatAmount(serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : 0)

            return { doctorFee, discountDoctor, hospitalFee, discountHospital };
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    onSaveDiscountAmount = () => {
        try {
            let states = this.state
            let serviceFee = states.selectedData?.service_fee
            let totalDiscountAmount = (+states.discountDoctorFee) + (+states.discountHospitalFee)
            let total = 0; let discount = 0;
            let discountDoctor = this.state.discountPercentDoctorFee ? +this.state.discountPercentDoctorFee : serviceFee?.discount_doctor_fee ? serviceFee?.discount_doctor_fee : 0
            let discountHospital = this.state.discountPercentHospitalFee ? +this.state.discountPercentHospitalFee : serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : 0
            // if (discountDoctor !== 0 && discountHospital !== 0) {
            let doctorAmount = CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            let hospitalAmount = CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            discount = doctorAmount + hospitalAmount
            total = serviceFee?.original_doctor_fee + serviceFee?.original_hospital_fee
            let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            let discountPercent = CommonValidation.amountPercentage(discount, total)
            states.selectedData.discountAmount = states.selectedType === 'rupee' ? ((+states.discountDoctorFee) + (+states.discountHospitalFee)) : discount
            states.selectedData.discountPercentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, total) : discountPercent
            // } else if (discountDoctor !== 0 && discountHospital === 0) {
            //     discount = +this.state.discountPercentDoctorFee
            //     let doctorAmount = CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            //     let hospitalAmount = CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            //     discount = doctorAmount + hospitalAmount
            //     total = serviceFee?.original_doctor_fee + serviceFee?.original_hospital_fee
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     let discountPercentAmount = CommonValidation.calculatePercentage(discount, (+total))
            //     states.selectedData.discountAmount = states.selectedType === 'rupee' ? ((+states.discountDoctorFee) + (+states.discountHospitalFee)) : discountPercentAmount
            //     states.selectedData.discountPercentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, total) : discountPercent
            // } else {
            //     discount = +this.state.discountPercentHospitalFee
            //     let doctorAmount = CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            //     let hospitalAmount = CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            //     discount = doctorAmount + hospitalAmount
            //     total = serviceFee?.original_doctor_fee + serviceFee?.original_hospital_fee
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     let discountPercentAmount = CommonValidation.calculatePercentage(discount, (+total))
            //     states.selectedData.discountAmount = states.selectedType === 'rupee' ? ((+states.discountDoctorFee) + (+states.discountHospitalFee)) : discountPercentAmount
            //     states.selectedData.discountPercentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, total) : discountPercent
            // }

            serviceFee.discount_doctor_fee = states.selectedType === 'rupee' ? +states.discountDoctorFee : CommonValidation.calculatePercentage(states.discountPercentDoctorFee, serviceFee?.original_doctor_fee)
            serviceFee.discount_doctor_percentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(states.discountDoctorFee, serviceFee?.original_doctor_fee) : +states.discountPercentDoctorFee
            serviceFee.discount_hospital_fee = states.selectedType === 'rupee' ? +states.discountHospitalFee : CommonValidation.calculatePercentage(states.discountPercentHospitalFee, serviceFee?.original_hospital_fee)
            serviceFee.discount_hospital_percentage = states.selectedType === 'rupee' ? CommonValidation.amountPercentage(states.discountHospitalFee, serviceFee?.original_hospital_fee) : +states.discountPercentHospitalFee
            serviceFee.doctor_fee = ((serviceFee?.original_doctor_fee) - (serviceFee.discount_doctor_fee ? serviceFee.discount_doctor_fee : +states.discountDoctorFee))
            serviceFee.hospital_fee = ((serviceFee?.original_hospital_fee) - (serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : +states.discountHospitalFee))
            serviceFee.amount_type = this.state.selectedType
            serviceFee.total_amt = serviceFee.doctor_fee + serviceFee.hospital_fee
            states.selectedData.individual_discount_comments = states.IndivDiscountComments
            states.selectedData.service_fee = serviceFee
            states.billingList[this.state.discountIndex] = states.selectedData
            if (states.discountDoctorFee > 0 || states.discountHospitalFee > 0 || states.discountPercentDoctorFee > 0 || states.discountPercentHospitalFee > 0) {
                states.selectedData.isSplitBlock = true
            }

            this.setState({
                states,
                serviceDiscountpopup: false,
                discountIndex: null
            }, () => {
                // this.calculateTotalDiscount()
                this.setState({
                    IndivDiscountComments: '',
                    discountDoctorFee: '',
                    discountHospitalFee: '',
                    discountPercentDoctorFee: '',
                    discountPercentHospitalFee: '',
                    selectedType: 'rupee',
                    totalDiscountedAmount: '',
                    totalDiscountedPercentage: ''
                })
            })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    onChangeSymbol = (e) => {
        try {
            let states = this.state
            if (states.selectedData?.service_charges?.length > 0) {
                let serviceFee = states.selectedData?.service_fee
                serviceFee.discount_doctor_fee = 0
                serviceFee.discount_hospital_fee = 0

            } else {
                let serviceFee = states.selectedData?.service_fee
                serviceFee.discount_doctor_fee = 0
                serviceFee.discount_hospital_fee = 0
                // states.selectedData.discount_doctor_fee = 0
                // states.selectedData.discount_hospital_fee = 0
            }
            this.setState({
                selectedType: e.target.value,
                discountDoctorFee: '',
                discountHospitalFee: '',
                discountPercentDoctorFee: '',
                discountPercentHospitalFee: '',
                selectedData: this.state.selectedData
            })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    serviceIndividualDiscountPopup = () => {
        try {
            let states = this.state
            let fee = this.shareValue()
            let doctorShare = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee
            let hospitalShare = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee
            return (
                <Modal open={this.state.serviceDiscountpopup}>
                    <Box className='eMed_Service_Discount'>
                        <Box m={'1vw'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Split-Up</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box display={'flex'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600} alignSelf={'center'}>Doctor Consultation - </Typography>
                                <Typography color={'gray'} fontSize={'0.8vw'} alignSelf={'center'}>{
                                    states.patientData?.patient_type === 'new_patient' ? ` New Patient` :
                                        states.patientData?.patient_type === 'review_patient' ? ` Review Patient` :
                                            states.patientData?.patient_type === 'old_patient' ? ` Old Patient` : null
                                }</Typography>
                            </Box>
                            <Box>
                                <Typography color={'gray'}>Bill Amount</Typography>
                                <Typography fontWeight={600} color={Colors.SecondaryText}>{states.selectedData?.totalAmount ? AmountFormat((states.selectedData?.totalAmount.toFixed(2))) : AmountFormat(states.selectedData?.total_amt ? states.selectedData?.total_amt.toFixed(2) : states.selectedData?.totalAmount.toFixed(2))}</Typography>
                            </Box>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            {/* {((+fee.doctorFee === 0) && (+fee.hospitalFee === 0)) ?
                                <Typography alignSelf={'center'} color={'red'} fontSize={'0.8vw'}>Hospital and Doctor Share Not Configured</Typography> : null} */}
                            <Typography fontWeight={600} color={Colors.SecondaryText}>Select Type:
                                <Select
                                    size='small'
                                    sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                    value={this.state.selectedType}
                                    onChange={(e) => { this.onChangeSymbol(e) }}
                                >
                                    {Unit_Types.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'space-evenly'}>
                            {this.splitUpTextBox('discountDoctorFee', doctorShare, 'Doctor Discount', '13.5vw')}
                            {this.splitUpTextBox('discountHospitalFee', hospitalShare, 'Hospital Discount', '13.5vw')}
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Doctor Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.doctorFee} - ${fee.discountDoctor ? fee.discountDoctor : 0} = ${((fee.doctorFee) - (+fee.discountDoctor)) ? AmountFormat((fee.doctorFee) - (+fee.discountDoctor)) : 0}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Hospital Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.hospitalFee} - ${fee.discountHospital ? fee.discountHospital : 0} = ${((fee.hospitalFee) - (+fee.discountHospital)) ? AmountFormat((fee.hospitalFee) - (+fee.discountHospital)) : 0}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingX: '0.5vw' }}>
                            <TextField
                                label={"Discount Comments"}
                                sx={{ width: '31vw' }}
                                multiline={true}
                                onChange={(e) => {
                                    this.setState({
                                        IndivDiscountComments: e.target.value
                                    })
                                }}
                                inputProps={{ maxLength: 250 }}
                                disabled={(!doctorShare && !hospitalShare)}
                                value={this.state.IndivDiscountComments}
                                rows={2} />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} m={'1vw'}>
                            <Button variant='outlined' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({
                                        serviceDiscountpopup: false,
                                        discountDoctorFee: '',
                                        discountHospitalFee: '',
                                        discountPercentDoctorFee: '',
                                        discountPercentHospitalFee: '',
                                        IndivDiscountComments: '',
                                        selectedType: 'rupee',
                                        selectedData: JSON.parse(this.state.dupliSelectedData),
                                    })
                                }}>Close</Button>
                            <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                                onClick={() => { this.onSaveDiscountAmount() }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    onClickAddDiscount = (item, index) => {
        try {
            let serviceFee = item?.service_fee

            let discountDoctorFee = serviceFee?.discount_doctor_fee ? serviceFee?.discount_doctor_fee : 0
            let discountHospitalFee = serviceFee?.discount_hospital_fee ? serviceFee?.discount_hospital_fee : 0
            let discountDoctorPercent = serviceFee?.discount_doctor_percentage ? serviceFee?.discount_doctor_percentage : 0
            let discountHospitalPercent = serviceFee?.discount_hospital_percentage ? serviceFee?.discount_hospital_percentage : 0

            let doctorFee = serviceFee?.doctor_fee ? serviceFee?.doctor_fee?.toFixed(2) : 0
            let hospitalFee = serviceFee?.hospital_fee ? serviceFee?.hospital_fee?.toFixed(2) : 0

            serviceFee.original_doctor_fee = (serviceFee.original_doctor_fee ? serviceFee.original_doctor_fee : +doctorFee)
            serviceFee.original_hospital_fee = (serviceFee.original_hospital_fee ? serviceFee.original_hospital_fee : +hospitalFee)
            serviceFee.share_discount = true
            this.setState({
                serviceDiscountpopup: true,
                selectedData: JSON.parse(JSON.stringify(item)),
                dupliSelectedData: JSON.stringify(item),
                selectedType: item?.service_fee?.amount_type,
                discountIndex: index,
            }, () => {
                if (this.state.selectedType === 'rupee') {
                    this.setState({
                        discountDoctorFee: discountDoctorFee,
                        discountHospitalFee: discountHospitalFee,
                    })
                } else {
                    this.setState({
                        discountPercentDoctorFee: discountDoctorPercent,
                        discountPercentHospitalFee: discountHospitalPercent
                    })
                }
            })
            this.setState({
                IndivDiscountComments: item?.individual_discount_comments ? item?.individual_discount_comments : ""
            })

        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    FindTimeDifference = (startDate, endDate) => {

        var diff = Math.abs(new Date(endDate) - new Date(startDate));
        var hours = Math.floor(diff / 3.6e6);
        return hours
    }

    renderOpBillingTable = () => {
        const { t } = this.props
        let { billingList } = this.state
        return (
            <Box component={'div'} className={this.props.opPatientBilling ? 'eMed_OP_bill_table' : 'eMed_OP_bill_table_card'}>
                <TableContainer className='eMed_op_bill_tbl'>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    {(this.state.forCancel) ? null :
                                        <Button className='eMed_edit_btn_bill'
                                            disabled={(this.state.withOutAppointment?.is_op || this.state.forCancel)}
                                            sx={{ visibility: (this.state.withOutAppointment?.is_op || this.state.forCancel) ? "hidden" : "visible" }}
                                            onClick={() => { this.setState({ isDeleteAll: this.state.billingList?.length > 0 ? true : false }) }}>
                                            <Tooltip placement='top' title='Delete' arrow>
                                                <img className='eMed_delete_img' src={ImagePaths.Delete.default} alt='del' />
                                            </Tooltip>
                                        </Button>}
                                </TableCell>
                                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("Description")}</Typography>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("Unit")}</Typography>
                                        <Button className='eMed_edit_btn_bill'
                                            disabled={this.state.withOutAppointment?.is_op}
                                            sx={{ visibility: (this.state.withOutAppointment?.is_op || (this.state.forCancel)) ? "hidden" : "visible" }}
                                            onClick={() => { this.setState({ isEditunit: !this.state.isEditunit }) }}
                                        >
                                            <Tooltip placement='top' title='Edit' arrow>
                                                <img className='eMed_header_img' src={ImagePaths.NewEditcon.default} alt='edit' />
                                            </Tooltip>
                                        </Button>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("Rate Unit")}</Typography>
                                        <Button className='eMed_edit_btn_bill'
                                            disabled={this.state.withOutAppointment?.is_op}
                                            sx={{ visibility: (this.state.withOutAppointment?.is_op || this.state.forCancel) ? "hidden" : "visible" }}
                                            onClick={() => { this.setState({ isEditRate: !this.state.isEditRate }) }}
                                        >
                                            <Tooltip placement='top' title='Edit' arrow>
                                                <img className='eMed_header_img' src={ImagePaths.NewEditcon.default} alt='edit' />
                                            </Tooltip>
                                        </Button>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{t("concession")}</Typography>
                                        <Button className='eMed_edit_btn_bill'
                                            disabled={this.state.withOutAppointment?.is_op}
                                            sx={{ visibility: (this.state.withOutAppointment?.is_op || this.state.forCancel) ? "hidden" : "visible" }}
                                            onClick={() => { this.setState({ isEditDiscount: !this.state.isEditDiscount }) }}
                                        >
                                            <Tooltip placement='top' title='Edit' arrow>
                                                <img className='eMed_header_img' src={ImagePaths.NewEditcon.default} alt='edit' />
                                            </Tooltip>
                                        </Button>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                        <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.8vw" }}>{`${t("TotalAmount")} (${CurrencySymbol})`}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.billingList?.length > 0 ? this.state.billingList.map((item, index) => {
                                let serviceName = `${item?.service_name ? item?.service_name : "-"} 
                                - ${item?.service_type === "emergency time" ? "Emergency Consultation"
                                        : item?.service_type ? item?.service_type : "-"}`
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {
                                                (this.state.forCancel) ? null :
                                                    <Button className='eMed_edit_btn_bill'
                                                        disabled={(this.state.withOutAppointment?.is_op || (this.state.forCancel))}
                                                        onClick={() => this.setState({ isDeleteCliked: true, deleteIndex: index, deleteService: item.service_type })}>
                                                        <Tooltip placement='top' title='Delete' arrow>
                                                            <img className='eMed_delete_img' src={ImagePaths.Delete.default} alt='del' />
                                                        </Tooltip>
                                                    </Button>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Typography id="eMed_opip_billTxt">{serviceName?.length > 50 ? <Tooltip placement='top' title={serviceName} arrow><div id="eMed_opip_billTxt">{serviceName.slice(0, 35) + "..."}</div></Tooltip>
                                                : serviceName ? serviceName : "-"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {item?.time_taken == null || item?.time_taken == undefined ?
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    {!item?.is_auto_billing && this.state.isEditunit && item.is_edit_unit ?
                                                        <Box component={'div'} display={'flex'} flexDirection={'row'}>
                                                            <Button className='eMed_Bill_add_btn'
                                                                disabled={this.state.withOutAppointment?.is_op}
                                                                onClick={() => { this.onChangeUnit(item, 'decrease', index) }}
                                                            >
                                                                <Typography>-</Typography>
                                                            </Button>
                                                            <Typography paddingRight={"1vw"}>{item.unit}</Typography>
                                                            <Button className='eMed_Bill_add_btn'
                                                                disabled={this.state.withOutAppointment?.is_op}
                                                                onClick={() => { this.onChangeUnit(item, 'increase', index) }}
                                                            >
                                                                <Typography>+</Typography>
                                                            </Button>
                                                        </Box> :
                                                        <Typography>{item?.unit ? item?.unit : 1}</Typography>
                                                    }
                                                </Box> :
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    {
                                                        !item?.is_auto_billing && this.state.isEditunit && item.field && item.is_edit_unit ?
                                                            <div className='eMed_Time_field'>
                                                                <OutlinedInput
                                                                    value={item?.hour}
                                                                    onChange={(e) => {
                                                                        var today = new Date();
                                                                        today.setHours(23, 59, 59, 999);
                                                                        var AptDate = DateTime.fromJSDate(today).toFormat('yyyy-MM-dd')
                                                                        var Aptime = DateTime.fromJSDate(today).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

                                                                        var startDate = this.state.patientData?.appointment_date ? this.state.patientData?.appointment_date : `${DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')}T${DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`;
                                                                        var endDate = (AptDate + "T" + Aptime);
                                                                        let maxHours = this.FindTimeDifference(startDate, endDate)

                                                                        if ((CommonValidation.numberOnly(e.target.value) && +e.target.value <= maxHours || +e.target.value == "")) {
                                                                            item.hour = +e.target.value;
                                                                            this.setState({ billingList })
                                                                        }
                                                                    }}
                                                                    style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                                                />
                                                                <OutlinedInput
                                                                    value={item?.minute}
                                                                    onChange={(e) => {
                                                                        if ((CommonValidation.numberOnly(e.target.value) && +e.target.value <= 59 || +e.target.value == "")) {
                                                                            item.minute = +e.target.value;
                                                                            this.setState({ minuteField: e.target.value })
                                                                        }
                                                                    }}
                                                                    style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                                                />
                                                                <IconButton onClick={() => { this.TimeFieldCalculation(item) }} size="small" ><img className="eMed_Tick_img" src={ImagePaths.Tick.default} alt={"Tick"} /></IconButton>
                                                            </div> :
                                                            <div style={{ display: "flex", cursor: "pointer", paddingTop: "0.25vw" }}>
                                                                <div onClick={() => { this.TimeFieldEnable(item?.time_taken, item) }} style={{ paddingRight: "0.25vw", fontSize: "0.95vw", color: this.state.isEditunit && !item?.is_auto_billing ? "rgb(2, 128, 123)" : null }}>{item?.time_taken.length > 5 ? item.time_taken.slice(0, 5) : item?.time_taken}</div>
                                                                <div className='eMed_Time_cont' onClick={() => { this.setState({ Timepopup: this.state.isEditunit && item.is_edit_unit ? true : false, selectedService: item, selectedIndex: index }) }}>
                                                                    {this.state.isEditunit && !item?.is_auto_billing ?
                                                                        <div className='eMed_Time_cont'>
                                                                            <img className='eMed_Time_img' src={ImagePaths.HighlightedClock.default} alt='edit' />
                                                                        </div> :
                                                                        <div className='eMed_Time_cont'>
                                                                            <img className='eMed_Time_img' src={ImagePaths.NormalClock.default} alt='edit' />
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                    }
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                                {!item?.is_auto_billing && this.state.isEditRate && item.is_edit_amount && ((item?.service_catagory__catagory_name === 'Consultation') || (item?.service_catagory__catagory_name === 'OP Procedures')) && ((item?.service_fee?.doctor_fee && item?.service_fee?.hospital_fee) || (item?.service_fee?.discount_doctor_fee > 0 || item?.service_fee?.discount_hospital_fee > 0) || (item?.service_fee?.doctor_percentage === 100 || item?.service_fee?.hospital_percentage === 100)) ?
                                                    <>
                                                        <Button size='small' sx={{ textTransform: "capitalize" }} disabled={this.state.disableSplitSharePopup} onClick={() => { this.addShare(item, index) }}>{`Add Share ${item.amount ? `(${AmountFormat(item?.amount)})` : 0}`}</Button>
                                                    </>
                                                    : !item?.is_auto_billing && this.state.isEditRate && item.is_edit_amount ?
                                                        <>
                                                            {this.inputTextBox('rateUnit', item.amount, index, "0", "0.7vw", false)}
                                                        </>
                                                        :
                                                        <Typography component={'div'} position={'relative'} right={"3vw"}>{item.amount ? item.amount : 0}</Typography>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                                {
                                                    !item?.is_auto_billing && this.state.isEditDiscount && item.is_edit_discount && ((item?.service_catagory__catagory_name === 'Consultation') || (item?.service_catagory__catagory_name === 'OP Procedures')) && ((item?.service_fee?.doctor_fee && item?.service_fee?.hospital_fee) || (item?.service_fee?.discount_doctor_fee > 0 || item?.service_fee?.discount_hospital_fee > 0) || (item?.service_fee?.doctor_percentage === 100 || item?.service_fee?.hospital_percentage === 100)) ?
                                                        <>
                                                            <Button
                                                                startIcon={<AddCircle />}
                                                                endIcon={item?.discountAmount ? `(${AmountFormat(item?.discountAmount)})` : null}
                                                                sx={{ textTransform: 'capitalize', height: '1.5vw' }}
                                                                onClick={() => { this.onClickAddDiscount(item, index) }}
                                                            >Add Discount</Button> </>

                                                        :
                                                        !item?.is_auto_billing && this.state.isEditDiscount && item.is_edit_discount ?
                                                            <>
                                                                {this.inputTextBox('percentage', item.discountPercentage, index, "0 %", "0.7vw", false)}
                                                                {this.inputTextBox('discountAmount', item.discountAmount, index, `${CurrencySymbol} 0`, "0.7vw", false)}
                                                            </> :
                                                            <Typography position={'relative'} right={"2.5vw"}>{item.discountAmount ? item.discountAmount : 0}</Typography>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                                <Typography>{AmountFormat((item.totalAmount - item.discountAmount).toFixed(2))}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            }) : this.props?.isBillServicesLoading ? <TableRow sx={{ position: "relative", top: '3.5vw' }}>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} align="center"><CircularProgress size={25} /></TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            </TableRow> : <TableRow sx={{ position: "relative", top: '3.5vw' }}>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} align="center">{t("NoRecordsFound")}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                                <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.tableFooter(this.state.billingList)}
                {(this.state.forCancel) ?
                    <Box component={'div'} className='eMed_table_bottom' style={{ padding: "1vw 1vw", alignItems: 'center', display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button
                            variant='contained'
                            sx={{ textTransform: "capitalize", width: '10vw' }}
                            disabled={((this.state.CancelledNetAmount > 0 && this.state.totalPayableAmount === 0) || (this.FullAmountEntered())) ? false : true}
                            size='small'
                            color='error'
                            onClick={() => {
                                this.setState({
                                    cancelBillClicked: true
                                })
                            }}
                        >
                            {t("CancelBill")}
                        </Button>
                    </Box>
                    :
                    this.state.isForEditBill ?
                        <Box component={'div'} className='eMed_table_bottom' style={{ padding: "1vw 1vw", alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography fontWeight={600}>{`Invoice Number : ${this.state.CancelledBillNumber}`}</Typography>
                            <Button
                                variant='contained'
                                sx={{ textTransform: "capitalize", width: '10vw' }}
                                disabled={this.state.billingList.length === 0 || this.state.DisableBtn}
                                size='small'
                                onClick={() => {
                                    this.setState({ isCredit: true, DisableBtn: true }, () => { this.opPatientBilling() })
                                }}
                            >
                                {t("Save Changes")}
                            </Button>
                        </Box> : this.rederButtons()
                }
                { }
            </Box>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    ClosePopUp() {
        this.setState({ SuccessInvoice_ID: null, bill_receipt_id: null, patientID: null, isCreditBill: false })
    }

    ReasonPopupClose = () => {
        this.setState({
            cancelBillClicked: false
        })
    }

    handleClear() {
        this.clearTable()
    }
    addCancelComments = (comments, ReceiptType) => {
        this.CancelBill(comments, ReceiptType)
    }
    RefundMode = (mode) => {
        this.setState({
            selectedRefundMode: mode
        })
    }

    BillMode = (mode) => {
        this.setState({
            selectedBillMode: mode,
            cancelReceipt: true
        })
    }

    changeupiReferNum = (text) => {
        this.setState({
            upi_ref_num: text
        })
    }

    changeReceiptCheck = (check) => {
        this.setState({
            cancelReceipt: check
        })
    }

    TimeFieldCalculation = (item) => {
        let { billingList } = this.state
        let Hours = (item.hour == "" ? "00" : (item.hour).toString().length < 2 ? ("0" + item.hour) : item.hour) + ":" + (item.minute == "" ? "00" : (item.minute).toString().length < 2 ? ("0" + item.minute) : item.minute) + ":" + "00"
        item.totalAmount = this.CalculateHourAmount(item.amount, Hours, item.old_time_taken, item?.minimum_time, item?.min_amount)
        item.time_taken = Hours.split(":")[0] + ":" + Hours.split(":")[1]
        this.setState({ billingList }, () => {
            item.field = false
            this.setState({
                billingList
            })
        })
    }

    TimeFieldEnable = (Time, item) => {
        if (item.is_edit_unit && this.state.isEditunit) {
            let { billingList } = this.state
            item.hour = Time.split(":")[0];
            item.minute = Time.split(":")[1]
            this.setState({
                billingList
            }, () => {
                item.field = true
                this.setState({
                    billingList
                })
            })
        }
    }

    formatDateToDDMMYYYY = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get correct month
        const year = String(date.getFullYear());

        return `${day}:${month}:${year}`;
    }

    buttonOneAction = () => {
        let { selectedService } = this.state;
        if (!selectedService?.procedure_start_time && !selectedService?.procedure_end_time) {
            selectedService["start_date"] = (selectedService?.changed_start_time == null ? this.props.isFromQuickBilling ? new Date() : moment(this.state.patientData?.appointment_date) : selectedService?.changed_start_time);
            selectedService["end_date"] = selectedService?.changed_end_time;
        } else {
            selectedService["start_date"] = selectedService?.start_date
            selectedService["end_date"] = selectedService?.end_date
        }
        this.setState({
            selectedService
        }, () => {
            this.setState({ Timepopup: false })
        })
    }

    buttonTwoAction = (item) => {
        let { billingList, ChangedUnit, timeFormate } = this.state
        let changedTime = ChangedUnit == 0 ? item.service_unit_count : ChangedUnit
        if (this.state?.selectedService?.end_date && this.state?.selectedService?.end_date != null && !timeFormate) {
            var timeParts = changedTime?.split(":");
            var hours = parseFloat(timeParts[0]);
            var minutes = parseFloat(timeParts[1]);
            if (hours >= 0 || minutes >= 0) {
                item.totalAmount = this.CalculateHourAmount(item.amount, changedTime, item.old_time_taken, item?.minimum_time, item?.min_amount)
                item.time_taken = changedTime
                item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                item.changed_start_time = item.start_date
                item.changed_end_time = item.end_date
                this.setState({ billingList, Timepopup: false }, () => {
                    this.setState({
                        totalDiscountedAmount: '',
                        totalDiscountedPercentage: ''
                    })
                    // this.calculateTotalDiscount()
                })
            } else {
                this.successErrorMessgae("Enter Correct End Date/Time", 'error')
            }
        } else {
            this.successErrorMessgae("Enter Correct End Date/Time", 'error')
        }
    }

    CalculateTimeDiff = () => {
        var startTime = new Date(this.state?.selectedService?.start_date);
        var endTime = new Date(this.state?.selectedService?.end_date);

        const startTimes = new Date(startTime);
        const endTimes = new Date(endTime);

        if (endTimes > startTimes) {
            this.setState({
                timeFormate: false
            })
        } else {
            this.setState({
                timeFormate: true
            })
        }

        startTime.setSeconds(0);
        endTime.setSeconds(0);

        var timeDiffMillis = endTime - startTime;

        var hours = Math.floor(timeDiffMillis / (1000 * 60 * 60));
        var minutes = Math.floor((timeDiffMillis % (1000 * 60 * 60)) / (1000 * 60));

        let formattedHours = hours > 9 ? hours.toString() : `0${hours}`;
        let formattedMinutes = minutes > 9 ? minutes.toString() : `0${minutes}`;

        let changedTime = `${formattedHours}:${formattedMinutes}:00`;
        this.setState({
            ChangedUnit: changedTime
        })
    }

    DateConvertion = (date) => {
        let outputDateString = date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        return outputDateString
    }

    TimeConvertion = (inputDate) => {
        let outputDateString = inputDate.getFullYear() + '-' +
            ('0' + (inputDate.getMonth() + 1)).slice(-2) + '-' +
            ('0' + inputDate.getDate()).slice(-2) + 'T' +
            ('0' + inputDate.getHours()).slice(-2) + ':' +
            ('0' + inputDate.getMinutes()).slice(-2) + ':' +
            ('0' + inputDate.getSeconds()).slice(-2);
        return moment(outputDateString)
    }

    renderCancelContent = () => {
        const { t } = this.props
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        return (
            <Box marginTop={"16vw"}>
                <Box component={'div'} className='eMed_date_picker_grid_amount'>
                    <Typography ml={'0.5vw'} color='#616161' fontWeight={'600'}>{t("Invoice Number")}</Typography>
                    <Typography ml={'0.5vw'} mt={'1vw'} fontWeight={'600'}>{this.state.CancelledBillNumber}</Typography>
                </Box>
                <Box className='eMed_right_sub_grid'>
                    <Box component={'div'} className='eMed_date_picker_grid' >
                        <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{"Bill Date and Time"}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                open={this.state.DateOpen}
                                disabled={true}
                                inputFormat='DD-MM-YYYY & hh:mm A'
                                value={this.state.templateDate}
                                renderInput={(params) => <TextField size='small' autoComplete='off'
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => { this.setState({ DateOpen: true }) }}
                                    {...params}
                                    fullWidth
                                    variant='standard'
                                    sx={{ marginTop: "1vw", marginLeft: "0.5vw", marginRight: "0.5vw", width: "15vw" }}
                                />}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
            </Box>
        )
    }

    CancelBill = (reason, ReceiptType) => {
        try {
            let payment = []
            if (this.state.isCash) {
                payment.push(1)
            }
            if (this.state.isUPI) {
                payment.push(3)
            }
            if (this.state.isBankTranfer) {
                payment.push(5)
            }
            let data = {
                bill_id: this.state.BillData?.id,
                bill_type: 'cancelled',
                cancel_reason: reason ? reason : "",
                cash_amount: this.state.CashAmount ? +this.state.CashAmount : 0,
                upi_amount: this.state.UPIAmount ? +this.state.UPIAmount : 0,
                bank_transfer_amount: this.state.BankTfrAmount ? +this.state.BankTfrAmount : 0,
                bank_ref_no: this.state.BankTfrNumber ? this.state.BankTfrNumber : "",
                upi_ref_no: this.state.UPINumber ? this.state.UPINumber : "",
                received_amount: this.state.totalPayableAmount ? this.state.totalPayableAmount : 0,
                net_amount: this.props.BillData?.amount_net ? this.props.BillData?.amount_net : 0,
                payment_mode: payment,
                receipt_type: ReceiptType
            }
            let URL = Serviceurls.FO_PATIEMT_BILLING
            this.lodaerFunction(true)
            RestAPIService.create(data, URL)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.lodaerFunction(false)
                        this.ReasonPopupClose()
                        this.clearTable()
                        clearCachevalue("OPCancelBilldata");
                        clearCachevalue("isForCancelBill");
                        this.props.OverallBillNavigate(true)
                    }
                }).catch((error) => {
                    this.lodaerFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.successErrorMessgae(error.response.data.message, 'error')
                    } else {
                        this.successErrorMessgae(error.message, 'error')
                    }
                })
        }
        catch (e) {

        }
    }

    amtCalculate = (e, name) => {
        let states = JSON.parse(JSON.stringify(this.state))
        let value = e.target.value ? e.target.value : "0"
        let serviceFee = JSON.parse(JSON.stringify(states.selectedData?.service_fee))
        switch (name) {
            case 'doctorAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.total_amt = (+value) + (+states.hospitalAmt)
                    states.selectedData.amount = serviceFee.total_amt
                    states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)

                    this.setState({
                        docAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+value) + (+states.hospitalAmt)
                        this.setState({
                            totalBillAmt: (+value) + (+states.hospitalAmt)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            case 'hospitalAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.total_amt = (+states.docAmt) + (+value)
                    states.selectedData.amount = serviceFee.total_amt
                    states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)

                    this.setState({
                        hospitalAmt: +value,
                        selectedData: this.state.selectedData
                    }, () => {
                        let totalAmt = (+states.docAmt) + (+value)
                        this.setState({
                            totalBillAmt: (+states.docAmt) + (+value)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+states.docAmt / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            default:
                break;
        }
    }

    newShareSplitUp = () => {
        try {
            let { billingList, selectedData } = this.state
            billingList[this.state.rowIndex].amount = this.state.totalBillAmt ? +(+this.state.totalBillAmt / billingList[this.state.rowIndex].unit).toFixed(2) : 0
            billingList[this.state.rowIndex].service_fee.doctor_percentage = this.state.docPercent ? +this.state.docPercent : 0
            billingList[this.state.rowIndex].service_fee.hospital_percentage = this.state.hospitalPercent ? +this.state.hospitalPercent : 0
            billingList[this.state.rowIndex].service_fee.original_doctor_fee = this.state.docAmt ? +this.state.docAmt : 0
            billingList[this.state.rowIndex].service_fee.original_hospital_fee = this.state.hospitalAmt ? +this.state.hospitalAmt : 0
            billingList[this.state.rowIndex].service_fee.discount_hospital_percentage = this.state.hospitalAmt ? billingList[this.state.rowIndex]?.service_fee.discount_hospital_percentage : 0
            billingList[this.state.rowIndex].service_fee.discount_doctor_percentage = this.state.docAmt ? billingList[this.state.rowIndex]?.service_fee.discount_doctor_percentage : 0
            this.setState({ billingList }, () => {
                this.setState({ docSplitPopup: false }, () => {
                    this.CalculateServiceValues(this.state.rowIndex)
                })
            })
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    splitScreenPopup = () => {
        return (
            <Modal open={this.state.docSplitPopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "31vw", height: "60vh" }}>
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                            Split-Up
                        </Typography>
                        <div className="eMed_DialogBox_Close">
                            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closeSplitPopup} />
                        </div>
                    </div>
                    <hr />
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>Doctor Consultation</Typography>
                        {/* <div style={{display:'flex'}}>
                                    <Typography sx={{ fontSize: '1vw' }}>Select Type</Typography>
                                    <Select 
                                        size='small'
                                        sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                        
                                    ><MenuItem>%</MenuItem></Select>
                                    </div> */}
                    </div>
                    <Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} pl={'0.5vw'}>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}> Rate / Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{AmountFormat(this.state.totalBillAmt / this.state.selectedData?.unit)}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{this.state.selectedData?.unit ? this.state.selectedData?.unit : "-"}</Typography>
                            </Box>
                        </Box>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Doctor Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.docAmt} onChange={(e) => this.amtCalculate(e, 'doctorAmt')}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.docPercent}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Hospital Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.hospitalAmt} onChange={(e) => { this.amtCalculate(e, 'hospitalAmt') }}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.hospitalPercent}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Total</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Amount' value={this.state.totalBillAmt}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.totalBillPercent}></TextField>
                            </div>
                        </div>
                    </Box>
                    <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                            onClick={this.closeSplitPopup}
                        >Close</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw' }}
                            // onClick={() => {this.postSplitShare()}}
                            onClick={() => this.newShareSplitUp()}
                        >Save</Button>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    addShare = (item, index) => {
        this.setState({
            docAmt: item?.service_fee?.original_doctor_fee ? item.service_fee?.original_doctor_fee : item.service_fee?.doctor_fee ? item.service_fee?.doctor_fee : '',
            docPercent: item.service_fee?.doctor_percentage ? item.service_fee?.doctor_percentage : '',
            hospitalAmt: item?.service_fee?.original_hospital_fee ? item.service_fee?.original_hospital_fee : item.service_fee?.hospital_fee ? item.service_fee?.hospital_fee : '',
            hospitalPercent: item.service_fee?.hospital_percentage ? item.service_fee?.hospital_percentage : '',
            totalBillPercent: '100',
            rowIndex: index,
            selectedData: item,
            dupliSelectedData: JSON.stringify(item),
        }, () => {
            this.setState({ docSplitPopup: true, totalBillAmt: +this.state.docAmt + +this.state.hospitalAmt })
        })
    }

    renderCreditType = () => {
        let { patientData } = this.state
        let details = this.props.opBillPatientData ? this.props.opBillPatientData : this.state.DemographicData
        // let ins_name = (details?.insurance_name || details?.insurance_name === '') ? details?.insurance_name : (details?.insurance_company_name || details?.insurance_company_name === "") ? details?.insurance_company_name : this.state.selInsName 
        // let ins_no = details?.policy_no ? details?.policy_no : details?.policy_number ? details?.policy_number : this.state.selPolicyNo
        let comp_name = (details?.company_name || details?.company_name === '') ? details?.company_name : this.state.selCompanyName
        let employee_no = details?.employee_id ? details?.employee_id : details?.id_no ? details?.id_no : this.state.selEmployeeNo
        return (
            <Box>
                <Box component={'div'} display={'flex'} flexDirection={'row'} width={'30vw'} justifyContent={'space-between'}>
                    <Typography fontSize={'1vw'} fontWeight={'bold'}>Select the Credit Type</Typography>
                    <IconButton size='small' onClick={() => {
                        this.setState({
                            creditChange: false,
                            addInsurance: false,
                            addCorporate: false,
                            paymentCreditType: this.state.selPayemtnCreditType ? this.state.selPayemtnCreditType : this.props.opBillPatientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                                this.props.opBillPatientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                                    this.props.opBillPatientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                            insuranceName: null,
                            insuranceNo: '',
                            corpName: null,
                            employeeNo: '',
                            selInsName: this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '',
                            selPolicyNo: this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                            selCompanyName: this.state.selCompanyName ? this.state.selCompanyName : this.state?.patientData?.company_name ? this.state?.patientData?.company_name : '',
                            selEmployeeNo: this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.employee_id ? this.state?.patientData?.employee_id : '',
                            selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employee_id ? this.state?.patientData?.employee_id : ''

                        })
                    }}>
                        <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
                    </IconButton>
                </Box>
                <Box component={'div'} sx={{ height: '5vw', marginTop: '2vw', display: 'flex' }}>
                    <Box component={'div'} sx={{ width: '13vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                        <Typography>Patient Credit</Typography>
                        <FormControlLabel value={'patientcredit'} control={<Radio checked={this.state.paymentCreditType === 'patientcredit'} size='small' />}
                            onClick={(e) => {
                                this.setState({
                                    paymentCreditType: e.target.value,
                                    addInsurance: false,
                                    addCorporate: false,
                                    corpName: null,
                                    employeeNo: '',
                                    insuranceName: null,
                                    insuranceNo: ''
                                })
                            }} label="Patient Credit" />
                    </Box>
                    {/* <Box component={'div'} sx={{ width: '15vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Insurace Credit</Typography>
                            {ins_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addInsurance: true,
                                        addCorporate: false,
                                        paymentCreditType: 'insurancecredit',
                                        insuranceName: ins_name,
                                        insuranceNo: ins_no,
                                        corpName: null,
                                        employeeNo: ''
                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {ins_name ?
                            <FormControlLabel value={'insurancecredit'} control={<Radio checked={this.state.paymentCreditType === 'insurancecredit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false, }) }}
                                label={<Box>
                                    {ins_name?.length > 18 ?
                                        <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                                    {ins_no?.length > 18 ?
                                        <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addInsurance: true,
                                        addCorporate: false,
                                        paymentCreditType: 'insurancecredit',
                                        insuranceName: null,
                                        insuranceNo: ''
                                    })
                                }}
                            >Add Insurance</Button>}
                    </Box> */}
                    <Box component={'div'} sx={{ width: '15vw', height: '5vw', marginLeft: '1vw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Corporate Credit</Typography>
                            {comp_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'corporatecredit',
                                        corpName: comp_name,
                                        employeeNo: employee_no,
                                        insuranceName: null,
                                        insuranceNo: '',

                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {comp_name ?
                            <FormControlLabel value={'corporatecredit'} control={<Radio checked={this.state.paymentCreditType === 'corporatecredit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false }) }}
                                label={<Box>
                                    {comp_name?.length > 18 ?
                                        <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                                    {employee_no?.length > 18 ?
                                        <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'corporatecredit',
                                    })
                                }}
                            >
                                Add Corporate</Button>}
                    </Box>
                </Box>
                {this.state.addInsurance ?
                    <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                        <Autocomplete
                            size='small'
                            id="combo-box-demo-ins"
                            options={this.state.insuranceData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
                            onChange={(e, value) => {
                                this.setState({
                                    insuranceName: value,
                                })
                            }}
                            value={this.state.insuranceName}
                            sx={{ width: '13vw', marginRight: '1vw' }}
                            renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                        />
                        <TextField
                            size='small'
                            label="Insurance No"
                            variant="outlined"
                            sx={{ width: '13vw' }}
                            value={this.state.insuranceNo}
                            onChange={(e) => {
                                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                if (e.target.value === "" || alphanum) {
                                    this.setState({
                                        insuranceNo: e.target.value
                                    })
                                }
                            }}
                        />
                    </Box> :
                    this.state.addCorporate ?
                        <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo-ins"
                                options={this.state.corporateData}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                                onChange={(e, value) => {
                                    this.setState({
                                        corpName: value,
                                    })
                                }}
                                value={this.state.corpName}
                                sx={{ width: '13vw', marginRight: '1vw' }}
                                renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
                            />
                            <TextField
                                size='small'
                                label="Employee ID"
                                variant="outlined"
                                sx={{ width: '13vw' }}
                                value={this.state.employeeNo}
                                onChange={(e) => {
                                    let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                    if (e.target.value === "" || alphanum) {
                                        this.setState({
                                            employeeNo: e.target.value
                                        })
                                    }
                                }} />
                        </Box> : <Box marginTop={'1vw'} display={'flex'} height={'5vw'}></Box>}
                <Box marginTop={'2vw'} display={'flex'} justifyContent={'flex-end'}>
                    <Stack spacing={2} direction="row">
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
                            onClick={() => {
                                this.setState({
                                    creditChange: false,
                                    addInsurance: false,
                                    addCorporate: false,
                                    paymentCreditType: this.state.selPayemtnCreditType ? this.state.selPayemtnCreditType : this.props.opBillPatientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                                        this.props.opBillPatientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                                            this.props.opBillPatientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                                    insuranceName: null,
                                    insuranceNo: '',
                                    corpName: null,
                                    employeeNo: '',
                                    selInsName: this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '',
                                    selPolicyNo: this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                                    selCompanyName: this.state.selCompanyName ? this.state.selCompanyName : this.state?.patientData?.company_name ? this.state?.patientData?.company_name : '',
                                    selEmployeeNo: this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.employee_id ? this.state?.patientData?.employee_id : '',
                                    selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employee_id ? this.state?.patientData?.employee_id : ''

                                })
                            }}>Back</Button>
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
                            if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                                this.successErrorMessgae("Invalid Insurance Details", 'error')
                            } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                                this.successErrorMessgae("Invalid Corporate Details", 'error')
                            } else {
                                this.setState({
                                    creditChange: false,
                                    selPayemtnCreditType: this.state.paymentCreditType,
                                    selInsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '',
                                    selPolicyNo: this.state.insuranceNo ? this.state.insuranceNo : this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                                    selCompanyName: this.state.corpName?.company_name ? this.state.corpName?.company_name : this.state.selCompanyName ? this.state.selCompanyName : this.state?.patientData?.company_name ? this.state?.patientData?.company_name : '',
                                    selEmployeeNo: this.state.employeeNo ? this.state.employeeNo : this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.employee_id ? this.state?.patientData?.employee_id : '',
                                    selCompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : ''
                                })
                            }
                        }}>Submit</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    render() {

        const { t } = this.props;
        let states = this.state
        let { billingList } = this.state
        let isRefundRequired = this.state.BillRefundData?.is_refund ? true : false;
        let AppointmentDate = this.props.isFromQuickBilling ? this.formatDateToDDMMYYYY(new Date()) : this.state.patientData?.appointment_date?.split("T")[0];
        if (this.state.isLoader) {
            return <Loader loaderOpen={this.state.isLoader} />
        } else {
            return (
                <Box component={'div'} display={'flex'} >
                    <Box component={'div'} flex={0.7}>
                        {this.renderOpBillingTable()}
                    </Box>
                    <Box component={'div'} flex={0.3}>
                        {(this.state.forCancel) ? this.renderCancelContent() : this.renderRightContent()}
                    </Box>
                    {this.state.isDeleteCliked ? <DeletePopup
                        DeletTitle={`${t("deleteMsg")} ${this.state.deleteService ? this.state.deleteService : "-"} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.handleDelete.bind(this)}
                    /> : null}
                    {this.state.ClearClicked ? <DeletePopup
                        DeletTitle={`Are you sure? Do you want to clear all data?`}
                        deleteAlertPopupClose={this.clearAllData.bind(this)}
                        removeData={this.handleClear.bind(this)}
                        ButtonText={"Clear"}
                    /> : null}
                    {this.state.isDeleteAll && this.state.billingList.length > 0 ? <DeletePopup
                        DeletTitle={`${t("deleteMsg")} ${t("All")} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this, "all")}
                        removeData={this.onClearLeftContent.bind(this)}
                    /> : null}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.Timepopup ?
                        <Modal open={true}>
                            <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "37vw", height: "12vw" }}>
                                <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <Tooltip placement='top' title={states?.selectedService?.service_name} arrow>
                                        <Typography sx={{ color: "#616161", fontWeight: 600 }}>{`Kindly Select Date and Time of ${states?.selectedService?.service_name?.length > 22 ?
                                            states?.selectedService?.service_name.slice(0, 20) + "..." : states?.selectedService?.service_name}`}
                                        </Typography>
                                    </Tooltip>
                                    <IconButton onClick={() => { this.buttonOneAction() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                                </div>
                                <div className='emedhub_DatePicker_container'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            value={states?.selectedService?.start_date}
                                            name={"datePicker"}
                                            open={this.state.startTimeOpen}
                                            onOpen={() => { this.setState({ startTimeOpen: true }) }}
                                            onClose={() => { this.setState({ startTimeOpen: false }) }}
                                            inputFormat='DD-MM-YYYY hh:mm A'
                                            label={t('Start Date & Time')}
                                            minDate={AppointmentDate}
                                            maxDate={new Date()}
                                            minTime={AppointmentDate === this.DateConvertion(new Date(states?.selectedService?.start_date)) ? this.props.isFromQuickBilling ? this.formatDateToDDMMYYYY(new Date()) : moment(this.state.patientData?.appointment_date) : null}
                                            onChange={
                                                (newDate) => {
                                                    billingList[states?.selectedIndex]["start_date"] = newDate
                                                    this.setState({
                                                        billingList
                                                    }, () => { this.CalculateTimeDiff() })
                                                }}
                                            renderInput={(params) => <TextField onClick={() => { this.setState({ startTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            value={states?.selectedService?.end_date ? states?.selectedService?.end_date : null}
                                            name={"datePicker"}
                                            open={this.state.endTimeOpen}
                                            onOpen={() => { this.setState({ endTimeOpen: true }) }}
                                            onClose={() => { this.setState({ endTimeOpen: false }) }}
                                            inputFormat='DD-MM-YYYY hh:mm A'
                                            label={t('End Date & Time')}
                                            minDate={states?.selectedService?.start_date}
                                            maxDate={new Date()}
                                            minTime={this.DateConvertion(new Date(states?.selectedService?.start_date)) === this.DateConvertion(new Date(states?.selectedService?.end_date)) ? this.TimeConvertion(new Date(states?.selectedService?.start_date)) : null}
                                            onChange={
                                                (newDate) => {
                                                    billingList[states?.selectedIndex]["end_date"] = newDate ? newDate : null
                                                    this.setState({
                                                        billingList
                                                    }, () => { this.CalculateTimeDiff() })
                                                }}
                                            renderInput={(params) => <TextField onClick={() => { this.setState({ endTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1vw" }}>
                                    <Button size="small" sx={{ marginRight: "1vw", textTransform: "capitalize" }} variant={"outlined"} onClick={() => { this.buttonOneAction() }}>{"Cancel"}</Button>
                                    <Button variant="contained" size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.buttonTwoAction(this.state?.selectedService) }}>{"Add"}</Button>
                                </div>
                            </Paper>
                        </Modal>
                        : null
                    }
                    {this.state.SuccessInvoice_ID !== null ? <BillSuccessPoPUp isFromOP={true} isCredit={this.state.isCredit} InvoiceId={this.state.SuccessInvoice_ID} Receipt_id={this.state.bill_receipt_id} patientID={this.props.opBillPatientData?.patient_id} ClosePopUp={this.ClosePopUp.bind(this)} successTxt={this.state.totalPayableAmount === 0 ? null : this.state.isCreditBill ? "Credit Added Successfully" : null} /> : null}
                    {this.state.cancelBillClicked ?
                        <ReasonPopupWithRefund
                            title={"Cancel Bill"}
                            AlertPopupClose={this.ReasonPopupClose.bind(this)}
                            label={"Enter the Cancel Reason"}
                            btntext={'Close'}
                            btnvarient={'outlined'}
                            btncolor={'error'}
                            btntext1={'Confirm'}
                            btnvarient1={'contained'}
                            sendCmt={this.addCancelComments.bind(this)}
                            BillReceivedAmount={this.state.BillReceivedAmount} />
                        : null
                    }
                    {this.discountReasonPopup()}
                    {this.serviceIndividualDiscountPopup()}
                    {this.splitScreenPopup()}
                    {this.state.creditChange ?
                        <Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.creditChange}
                            maxWidth={"md"}
                        >
                            <div className='emed_Dialog_Appoin' style={{ width: '32vw', height: "45vh" }}>
                                {this.renderCreditType()}
                            </div>
                        </Dialog> : null}
                </Box>
            )
        }
    }
}

export default withTranslation()(CommonOpBilling)