import React, { Component } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import { Trans, withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import DatePicker from '../DatePicker/DatePicker';
import FutureDatePicker from '../FutureDatepicker/FutureDatePicker';
import { localGetItem } from '../../Utility/Services/CacheProviderService';

class CommonDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateFilter: this.props.DateIndex ? this.props.DateIndex : 0,
            QueryFromDate: this.props.FromDate ? this.props.FromDate : "",
            QueryToDate: this.props.ToDate ? this.props.ToDate : "",
            datePickerPop: false,
        }
    }

    componentWillReceiveProps(props){
        if(props.DateIndex !== this.props.DateIndex){
            this.setState({
                dateFilter: props.DateIndex ? props.DateIndex : 0,
                QueryFromDate: props.FromDate ? props.FromDate : "",
                QueryToDate: props.ToDate ? props.ToDate : "",
            })}
    }
    DateHelperText = () => {
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1 && this.props.HideTodayMenu != true) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2 || (this.props.HideTodayMenu === true && this.state.dateFilter === 1)) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
            const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
            return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`  
            // return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 4) {
            const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
            const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
            return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`  
            // return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }

    handleDate = () => {
        var today = DateTime.now().toFormat('yyyy-MM-dd')
        var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
        var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
        var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd')
        var endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd')

        if (this.state.dateFilter === 0) {
            this.setState({
                QueryFromDate: "",
                QueryToDate: "",
            },()=>{this.props.SelectedDates(this.state.QueryFromDate, this.state.QueryToDate , this.state.dateFilter)})
        }
        else if (this.state.dateFilter === 1) {
            this.setState({
                QueryFromDate: this.props.HideTodayMenu ? yesterday : today,
                QueryToDate: this.props.HideTodayMenu ? yesterday : today,
            },()=>{this.props.SelectedDates(this.state.QueryFromDate, this.state.QueryToDate , this.state.dateFilter)})
        }
        else if (this.state.dateFilter === 2) {
            this.setState({
                QueryFromDate: yesterday,
                QueryToDate: yesterday,
            },()=>{this.props.SelectedDates(this.state.QueryFromDate, this.state.QueryToDate , this.state.dateFilter)})
        }
        else if (this.state.dateFilter === 3) {
            this.setState({
                QueryFromDate: startOfPreviousWeek,
                QueryToDate: endOfPreviousWeek,
            },()=>{this.props.SelectedDates(this.state.QueryFromDate, this.state.QueryToDate , this.state.dateFilter)})
        }
        else if (this.state.dateFilter === 4) {
            this.setState({
                QueryFromDate: startOfPreviousMonth,
                QueryToDate: endOfPreviousMonth,
            },()=>{this.props.SelectedDates(this.state.QueryFromDate, this.state.QueryToDate , this.state.dateFilter)})
        }
        else if (this.state.dateFilter === 5) {
            this.setState({ datePickerPop: true })
        }
    }
    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        },()=>{this.props.SelectedDates(this.state.QueryFromDate, this.state.QueryToDate, this.state.dateFilter)})
    }
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
        },()=>{
            this.props.SelectedDates(DateTime.now().toFormat('yyyy-MM-dd'), DateTime.now().toFormat('yyyy-MM-dd'), 1)
        })
    }
    renderCustumPopup(flag){
        if(flag){
          return  this.state.datePickerPop ? <FutureDatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} PharmaDateFilterRestriction={this.props.PharmaDateFilterRestriction} days={this.props.days} MaxDaysCount={this.props?.MaxDaysCount ? this.props?.MaxDaysCount : null} RestrictPast={this.props.Past}/> : null
        }
        else{
           return this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} PharmaDateFilterRestriction={this.props.PharmaDateFilterRestriction} days={this.props.days} MaxDaysCount={this.props?.MaxDaysCount ? this.props?.MaxDaysCount : null} fromStockValue={this.props.fromStockValue}/> : null
        }
    }
    render() {
        const { t } = this.props
        let Settings = localGetItem("PharmaSettings") === null ? {} : JSON.parse(localGetItem("PharmaSettings"))
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let PharmaRestirictionWeek = (RoleData?.module_name == "Pharmacy") ? RoleData.is_user ? (this.props.PharmaDateFilterRestriction === true && (RoleData?.permission_access?.pharma?.isViewRpt === "last7days" || RoleData?.permission_access?.pharma?.isViewRpt === "last30days")) : true : true
        let PharmaRestirictionMonth = (RoleData?.module_name == "Pharmacy") ? RoleData.is_user? (this.props.PharmaDateFilterRestriction === true && (RoleData?.permission_access?.pharma?.isViewRpt === "last30days" )) : true : true
        return (
            <div>
                <FormControl fullWidth>
                    <InputLabel>{this.props.LabelText ? this.props.LabelText : t("SelectDate")}</InputLabel>
                    <Select sx={{ height: this.props.height ? '2.5vw' : this.props.HideDate ? '1.8vw': '2.2vw', cursor: "pointer", marginRight: "1vw"}}
                        label={t("SelectDate")}
                        size='small'
                        onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                        value={this.state.dateFilter}
                    >
                        {this.props.HideAllMenu === true ? null :
                        <MenuItem value={0}>{t("All")}</MenuItem>
                        }
                        {this.props.HideTodayMenu === true ? null :
                        <MenuItem value={1}>{t("Today")}</MenuItem>
                        }
                        {this.props.OutOfOffice ? null : <MenuItem value={this.props.HideTodayMenu === true ? 1 : 2}>{t("Yesterday")}</MenuItem>}
                        {this.props.OutOfOffice ? null : (PharmaRestirictionWeek ? <MenuItem value={3}>{t("Lastweek")}</MenuItem> : null) }
                        {this.props.OutOfOffice ? null : (PharmaRestirictionMonth ? <MenuItem value={4}>{t("LastMonth")}</MenuItem> : null) }
                        <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
                    </Select>
                    {this.props.HideDate ? null : <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>}
                </FormControl>
                {this.renderCustumPopup(this.props.Future)}


            </div>
        )
    }
}
export default withTranslation()(CommonDatePicker)
