import {
    Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Modal, Radio,
    RadioGroup, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs,
    TextField, Tooltip, Typography, ListItem, ListItemText, ListItemButton, List, Divider,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { ADD, AXIS_OPTIONS, BASE_OPTION, BCDVA_OPTIONS, BCNVA, CYL_OPTIONS, DEFAULT_CHARTUSED_SR, DEFAULT_MUSCLES_SR, DEFAULT_OCCUPATIONS_SR, LENS_COATING_OPTIONS, LENS_TYPE_OPTIONS, PRISM_OPTIONS, SPH_OPTIONS, VISION_OPTION, UCVA_OPTIONS, DEFAULT_LENS_TYPE, OPTICAL_CRM_API_MODE } from "../../../../Utility/Constants";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import ShowComponents from "./ShowComponent";
import { sha256, sha224 } from 'js-sha256';
import { localGetItem, localSetItem } from "../../../../Utility/Services/CacheProviderService";
import moment from 'moment/moment';
import { ConfirmPopup } from "../../../../Components/Common Components/CommonComponents";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

export default class DocGlassPrescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            templateData: [],
            value: "Two",
            LeftEye: {
                UCVA: "",
                SPH: "",
                CYL: "",
                AXIS: "",
                BCDVA: "",
                ADD: "",
                BCNVA: "",
                PRISM: "",
                VISION: "",
                BASE: "",
                comments: ""
            },
            RightEye: {
                UCVA: "",
                SPH: "",
                CYL: "",
                AXIS: "",
                BCDVA: "",
                ADD: "",
                BCNVA: "",
                PRISM: "",
                VISION: "",
                BASE: "",
                comments: ""
            },
            Leftchecked: false,
            Rightchecked: false,
            postLoad: false,
            templateDataLeft: [],
            templateDataRight: [],
            Occupation: "",
            Chart: "",
            Muscle: "",
            PreviousData: [],
            LensType: [],
            LensCoat: [],
            coatingCom: "",
            wearingIns: "",
            sameGlass: false,
            lens_id: "",
            opticalHouseBtn: false,
            cID: null,
            isEdit: false,
            subjectiveDataLeft: [],
            subjectiveDataRight: [],
            SubjectiveData: this.props.SubjectiveData,
            selectedValue: "",
            selectedCheck: false,
            showSubjective: false,
            oldDataLeft: [],
            oldDataRight: [],
            currentData: [],
            SPHselectedValues: "Minus",
            CYLselectedValues: "Minus",
            occupationList: [],
            isOccupationadd: false,
            occupationName: '',
            addPostLoad: false,
            fromOptometry: this.props.fromOptometry ? this.props.fromOptometry : false
        }
        this.UCVA = React.createRef();
        this.SPH = React.createRef();
        this.CYL = React.createRef();
        this.AXIS = React.createRef();
        this.ADD = React.createRef();
        this.BCDVA = React.createRef();
        this.BCNVA = React.createRef();
        this.PRISM = React.createRef();
        this.VISION = React.createRef();
        this.BASE = React.createRef();
    }

    apiCalls = () => {
        this.getGlassPrescription()
        this.getSubjectiveRefraction()
        this.getOccupationList()
        if (!this.state.fromOptometry) {
            this.getOpticalCRMcustomerDetails()
            this.getPreviousGlassPrescrip()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.triggerGlassPrescription) {
            this.setState({
                showSubjective: this.props.subjectiveRefraction?.is_active ? true : false
            })
        }
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getPreviousGlassPrescrip = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_GLASS_PRESCRIPTION + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []
                        data = response.data.glass_prescription ? response.data.glass_prescription : [];
                        this.setState({
                            PreviousData: data
                        }, () => {
                            var oldDataLeft = []; var oldDataRight = [];
                            if (data.length > 0) {
                                data.forEach(item => {
                                    if (item.eye_type === "LE") {
                                        oldDataLeft.push(item);
                                    } else if (item.eye_type === "RE") {
                                        oldDataRight.push(item);
                                    }
                                });
                            }
                            this.setState({
                                oldDataLeft,
                                oldDataRight
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }

    }

    getSubjectiveRefraction = () => {
        let { subjectiveDataLeft, subjectiveDataRight } = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_SUBJECTIVEREFRACTION + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = response.data.data.subrefraction || [];
                        if (data.length > 0) {
                            data.forEach(item => {
                                if (item.eye_type === "LE") {
                                    subjectiveDataLeft.push(item);
                                } else if (item.eye_type === "RE") {
                                    subjectiveDataRight.push(item);
                                }
                            });
                            this.setState({
                                subjectiveDataLeft,
                                subjectiveDataRight,
                                showSubjective:this.props.subjectiveRefraction?.is_active ? true : false
                            }, () => {
                                if (this.state.callSubjective) {
                                    this.CopySubjectiveData()
                                }
                            });
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getOccupationList = () => {
        try {
            let url = Serviceurls.DOC_OCCUPATION_GET;
            if (this.state.fromOptometry && this.props.doctorID) {
                url = `${url}?doctor_id=${this.props.doctorID}`
            }
            RestAPIService.getAll(url)
            .then((response => {
              if (response.data.status === 'success') {
                this.setState({
                    occupationList: response.data.data ? response.data.data : []
                })
              }
            })).catch(e => {
              if (e?.response?.data?.message) {
                this.errorMessage(e.response.data.message)
              } else {
                this.errorMessage(e.message)
              }
    
            })
        } catch (e) {
          this.errorMessage(e.message)
        }
      }

    lensetypesforCRM(lenseType) {
        if (lenseType.length > 0) {
            var str = "";
            for (var i = 0; i < lenseType.length; i++) {
                str += lenseType[i].value + ", ";
            }
            return str;
        }
    }

    postPrescriptionData = async (cusID = null) => {
        let PrescLeftData = this.state.templateDataLeft[0] ? this.state.templateDataLeft[0] : {}
        let PrescRightData = this.state.templateDataRight[0] ? this.state.templateDataRight[0] : {}
        let doctorData = localGetItem("loggedInUserInfo") ? localGetItem("loggedInUserInfo") : "{}"
        let doctorDetails = JSON.parse(doctorData)
        var prescription_data = {
            "mode": OPTICAL_CRM_API_MODE,
            "customerNumber": this.state.CRMCustomer?.customer_number,
            "branchID": this.state.CRMCustomer?.branch_id,
            "cID": cusID,
            "prescriptionDate": moment(new Date()).format("DD-MM-YYYY"),
            "prescriptionType": "Eyewear",
            "patientTitle": this.props.PatientData?.title ? this.props.PatientData?.title : "",
            "patientName": this.props.PatientData?.first_name,
            "doctorTitle": doctorDetails?.doctor_title ? doctorDetails?.doctor_title : '',
            "doctorName": doctorDetails?.doctor_name ? doctorDetails?.doctor_name : '',
            "doctorRegistrationNumber": doctorDetails?.registeration_number ? doctorDetails?.registeration_number : '',
            "doctorSpecialization": doctorDetails?.specialization_name ? doctorDetails?.specialization_name : '',
            "doctorMobileNumber": doctorDetails?.mobile_number ? doctorDetails?.mobile_number : '',
            "doctorEmail": doctorDetails?.email ? doctorDetails?.email : '',
            "rightSPH": PrescRightData?.DV_SPH,
            "rightCYL": PrescRightData?.DV_CYL,
            "rightAxis": PrescRightData?.DV_AXIS,
            "rightPD": "-",
            "rightVA": PrescRightData?.DV_BCDVA,
            "rightNear": "",
            "rightADD": PrescRightData?.add,
            "rightPRISM": PrescRightData?.DV_PRISM,
            "leftSPH": PrescLeftData?.DV_SPH,
            "leftCYL": PrescLeftData?.DV_CYL,
            "leftAxis": PrescLeftData?.DV_AXIS,
            "leftPD": "-",
            "leftVA": PrescLeftData?.DV_BCDVA,
            "leftNear": "",
            "leftADD": PrescLeftData?.add,
            "leftPRISM": PrescLeftData?.DV_PRISM,
            "lensType": this.lensetypesforCRM(this.state.LensType),
            "lensCoating": this.state.LensCoat ? this.state.LensCoat?.toString() : "NIL",
            "leftIOP": "",
            "rightIOP": "",
            "dominantEye": "Right,Left",
            "prescriptionNotes": "Wearing Instruction:" + this.state.wearingIns + ". Comment:" + this.state.coatingCom,
            "refractiveCorrection": "",
            "complains": "",
            "symptoms": "",
            "diagnosis": "",
            "prescribedMedication": "",
            "treatment": "",
            "medicalHistory": ""
        }
        if (this.state.LensType.length > 0) {
            try {
                const response = await RestAPIService.OPTICAL_CRM_POST_WITH_URL(Serviceurls.OPTICAL_CRM_PRESCRIPTION_POST, prescription_data)
                if (response.data.status === "Success") {
                    this.setState({ opticalHouseBtn: false })
                }
                else if (response?.data?.error_message) {
                    this.errorMessage(response.data.error_message);
                }
                else {
                    this.errorMessage("something went wrong");
                }
            } catch (e) {
                this.errorMessage(e.message);
            }
        } else {
            this.errorMessage("Please select atleast one lense type");
        }
    }

    getOpticalCRMcustomerDetails = async () => {
        try {
            const response = await RestAPIService.getAll(Serviceurls.OPTICAL_CRM_CUSTOMER_DETAIL)
            if (response?.data?.status && response?.data?.data) {
                if (response?.data?.status === "success") {
                    this.setState({
                        opticalHouseBtn: true,
                        CRMCustomer: response?.data?.data ? response?.data?.data : []
                    }, () => {
                        localSetItem("OPTICAL_CRM_API_TOKEN", sha256(response.data.data?.customer_number + response.data.data?.api_key))
                    })
                }
            }
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    opticalCRMTransfer = async () => {
        try {
            const response = await RestAPIService.getAll(Serviceurls.OPTICAL_CRM_PATIENT_DETAIL + `?patient_id=${this.state.patientId}&uhid=${this.props.PatientData?.patient_account_number}`)
            if (response.data.status === "success") {
                this.postPrescriptionData(response.data.data.crm_patient_id)
            }
            else {
                var patient_info_OpticalCRM = {
                    "mode": OPTICAL_CRM_API_MODE,
                    "customerNumber": this.state.CRMCustomer?.customer_number,
                    "branchID": this.state.CRMCustomer?.branch_id,
                    "customerTitle": "",
                    "fullName": this.props.PatientData.first_name,
                    "referralCode": "",
                    "membershipID": this.props.PatientData.patient_account_number,
                    "mobileNumber": this.props.PatientData.mobile_number,
                    "mobileNumber1": "",
                    "mobileNumber2": "",
                    "mobileNumber3": "",
                    "mobileNumber4": "",
                    "email": "",
                    "taxIdentificationNumber": "",
                    "customerCategory": "",
                    "gender": this.props.PatientData.gender == "m" ? "m" : "f",
                    "insuranceSchemeName": "",
                    "insuranceSchemeNumber": "",
                    "customerGSTNumber": "",
                    "companyName": "",
                    "line1": "",
                    "line2": "",
                    "locality": "",
                    "country": "",
                    "state": "",
                    "city": "",
                    "pincode": "",
                    "dateOfBirth": this.props.PatientData.dob ? moment(this.props.PatientData.dob, 'YYYY-MM-DD').format("DD-MM-YYYY") : moment(this.props.PatientData.approx_dob, 'YYYY-MM-DD').format("DD-MM-YYYY"),
                    "dateOfAnniversary": "",
                    "customerAge": parseInt(this.props.PatientData.patient_age),
                    "notes": ""
                }
                const CRMCusAdd = await RestAPIService.OPTICAL_CRM_POST_WITH_URL(Serviceurls.OPTICAL_CRM_CUSTOMER_ADD, patient_info_OpticalCRM)
                if (CRMCusAdd.data.status === "Success") {
                    let patientInfo = {
                        "emedhub_patient_id": this.props.PatientData.patient_id,
                        "crm_patient_id": CRMCusAdd.data.data.cID,
                    }
                    const EmedCusAdd = await RestAPIService.create(patientInfo, Serviceurls.OPTICAL_CRM_PATIENT_DETAIL)
                    if (EmedCusAdd.data.status === "success") {
                        this.postPrescriptionData(CRMCusAdd.data.data.cID)
                    }
                    else {
                        this.errorMessage("something went wrong");
                    }
                } else if (CRMCusAdd?.data?.error_message) {
                    this.errorMessage(CRMCusAdd.data.error_message);
                }
                else {
                    this.errorMessage("something went wrong");
                }
            }
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    getGlassPrescription = async () => {
        try {
            const response = await RestAPIService.getAll(Serviceurls.DOC_OPT_GLASS_PRESCRIPTION + `?appointment_id=${this.state.appointmentId}`);

            if (response.data.status === 'success') {
                const { glass_prescription: data = [], lens: SecData = {} } = response.data.data;

                const { LeftEye, RightEye } = this.state;
                const { Left, Right } = this.extractEyeData(data);

                this.setState(
                    {
                        disableLeft: false,
                        disableRight: false,
                        LeftEye: { ...LeftEye, comments: Left?.comment || '' },
                        RightEye: { ...RightEye, comments: Right?.comment || '' },
                        templateDataLeft: Left ? [Left] : [],
                        templateDataRight: Right ? [Right] : [],
                        ...this.extractSecData(SecData),
                        currentData: response.data?.data?.glass_prescription
                    });
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    };

    extractEyeData = (data) => {
        const Left = data.find((item) => item.eye_type === 'LE');
        const Right = data.find((item) => item.eye_type !== 'LE');

        return { Left, Right };
    };

    extractSecData = (SecData) => {
        return {
            Chart: SecData.chart_used || "",
            coatingCom: SecData.coating || "",
            SelectedLens: SecData.continue_with_same_lense || "",
            lens_id: SecData.id || "",
            LensCoat: SecData.lens_coating || "",
            LensType: SecData.lens_type || "",
            Muscle: SecData.muscle || "",
            Occupation: SecData.occupation || "",
            wearingIns: SecData.wearing_instructions || "",
            sameGlass: SecData.same_glass || "",
            selectedCheck: SecData.same_glass ? true : false,
            disableRadio: SecData.same_glass ? true : false
        };
    };


    onEditHandler = (data) => {
        if (data.eye_type === "LE") {
            if (this.state.templateDataRight?.length > 0) {
                let item = this.state.templateDataRight[0]
                this.setState({
                    RightEye: {
                        UCVA: item?.DV_UCVA,
                        SPH: item?.DV_SPH,
                        CYL: item?.DV_CYL,
                        AXIS: item?.DV_AXIS,
                        BCDVA: item?.DV_BCDVA,
                        ADD: item?.add,
                        BCNVA: item?.DV_BCNVA,
                        id: item?.id,
                        PRISM: item?.DV_PRISM,
                        VISION: item?.DV_VISION,
                        BASE: item?.DV_BASE,
                        comments: item.comment
                    }
                })
            }
            this.setState({
                value: "One",
                openPopUp: true,
                Occupation: data.occupation,
                Chart: data.chart_used,
                Muscle: data.muscle,
                LeftEye: {
                    UCVA: data?.DV_UCVA,
                    SPH: data?.DV_SPH,
                    CYL: data?.DV_CYL,
                    AXIS: data?.DV_AXIS,
                    BCDVA: data?.DV_BCDVA,
                    ADD: data?.add,
                    BCNVA: data?.DV_BCNVA,
                    id: data?.id,
                    PRISM: data?.DV_PRISM,
                    VISION: data?.DV_VISION,
                    BASE: data?.DV_BASE,
                    comments: data.comment
                },
                disableRight: true
            })
        } else {
            if (this.state.templateDataLeft?.length > 0) {
                let item = this.state.templateDataLeft[0]
                this.setState({
                    LeftEye: {
                        UCVA: item?.DV_UCVA,
                        SPH: item?.DV_SPH,
                        CYL: item?.DV_CYL,
                        AXIS: item?.DV_AXIS,
                        BCDVA: item?.DV_BCDVA,
                        ADD: item?.add,
                        BCNVA: item?.DV_BCNVA,
                        id: item?.id,
                        PRISM: item?.DV_PRISM,
                        VISION: item?.DV_VISION,
                        BASE: item?.DV_BASE,
                        comments: item.comment
                    }
                })
            }
            this.setState({
                value: "Two",
                openPopUp: true,
                Occupation: data.occupation,
                Chart: data.chart_used,
                Muscle: data.muscle,
                RightEye: {
                    UCVA: data?.DV_UCVA,
                    SPH: data?.DV_SPH,
                    CYL: data?.DV_CYL,
                    AXIS: data?.DV_AXIS,
                    BCDVA: data?.DV_BCDVA,
                    ADD: data?.add,
                    BCNVA: data?.DV_BCNVA,
                    id: data?.id,
                    PRISM: data?.DV_PRISM,
                    VISION: data?.DV_VISION,
                    BASE: data?.DV_BASE,
                    comments: data.comment
                },
                disableLeft: true
            })
        }
    }

    returnVarient = (items, key, Type) => {
        if (Type == "LensCoat") {
            return key.includes(items) ? "contained" : "outlined";
        } else {
            if (key) {
                let value = key.find(obj => obj?.value === items?.value);
                return value?.value ? "contained" : "outlined";
            }
        }
    }

    returnClassName = (items, key, Type) => {
        if (Type == "LensCoat") {
            return key.includes(items) ? "eMed_Chip_OnClick" : "eMed_Chip_Border";
        } else {
            if (key) {
                let value = key.find(obj => obj?.value === items?.value);
                return value?.value ? "eMed_Chip_OnClick" : "eMed_Chip_Border";
            } else {
                return "eMed_Chip_Border"
            }
        }
    }

    renderChip = (values, key, Type) => {
        let states = this.state
        return (
            <div style={{ maxHeight: "7vw", overflow: "scroll", marginLeft: "0.2vw" }}>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${this.returnClassName(item, key, Type)}`}
                                style={{ width: "8vw" }}
                                label={Type == "LensCoat" ? item : item?.value}
                                variant={this.returnVarient(item, key, Type)}
                                disabled={this.state.fromOptometry}
                                key={index}
                                onClick={() => {
                                    // if (Type == "LensCoat") {
                                    // if (item === key) {
                                    //     states[Type] = ""
                                    //     this.setState({ states })
                                    // } else {
                                    //     states[Type] = item
                                    //     this.setState({ states })
                                    // }
                                    // } else {
                                    const selectedIndex = states[Type].indexOf(item);
                                    let newSelected = [...states[Type]];
                                    if (selectedIndex === -1) {
                                        if (states.updateId) {
                                            newSelected = item
                                        } else {
                                            newSelected.push(item);
                                        }
                                    } else {
                                        newSelected = newSelected.filter((_, index) => index !== selectedIndex);
                                    }
                                    states[Type] = newSelected
                                    this.setState({ states });
                                    // }
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    // occupation add popup start
    occupationPopUpCls = () => {
        this.setState({
            isOccupationadd: false,
            occupationName: '',
            addPostLoad: false
        })
    }

    handleAdd = () => {
        const { occupationName, occupationList } = this.state;

        const normalizeString = (str) => str.trim().toLowerCase();
        const isDuplicate = occupationList?.some(item => normalizeString(item) === normalizeString(occupationName));

        if (isDuplicate) {
            this.errorMessage(`Occupation ${occupationName} is already exists!`);
            return;
        }

        this.setState((prevState) => ({
            addPostLoad: true,
            occupationList: [...prevState.occupationList, prevState.occupationName]
        }), () => {
            this.occupationPopUpCls();
        });
    }

    renderOccupationAdd = () => {
        return (
            <Dialog open={true} >
                <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                    <Typography marginLeft={"1dvw"}>{"Add Occupation Name"}</Typography>
                    <Button onClick={() => { this.occupationPopUpCls() }} >
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </DialogTitle>
                <DialogContent sx={{ padding: "2dvw", width: '25dvw' }}>
                    <TextField
                        inputProps={{ maxLength: 30 }}
                        sx={{ width: "20dvw", marginTop: "2dvw" }}
                        size='small'
                        autoComplete="off"
                        label={"Occupation Name"}
                        value={this.state.occupationName}
                        onChange={(event) => {
                            let alphanum = CommonValidation.alphanumValidation(event.target.value);
                            if (alphanum || event.target.value === '') {
                                this.setState({
                                    occupationName: event.target.value
                                })
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({ occupationName: '' }) }}>{"Clear"}</Button>
                    <Button variant='contained' size='small'disabled = {!this.state.occupationName || this.state.addPostLoad}  className='eMed_Filter_Btns' onClick={() => { this.handleAdd() }}>{"Add"}</Button>
                </DialogActions>
            </Dialog>
        )
    }
    // occupation add popup end

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>UCVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>ADD</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCDVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCNVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PRISM</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>VISION</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BASE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.oldDataRight?.length > 0 ? this.state.oldDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right</TableCell>
                                    <TableCell>
                                        {item?.DV_UCVA ? item?.DV_UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.DV_UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_SPH ? item?.DV_SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_CYL ? item?.DV_CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_AXIS ? item?.DV_AXIS : '-'}</TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.DV_BCDVA ? item?.DV_BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.DV_BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BCNVA ? item?.DV_BCNVA : '-'}</TableCell>
                                    <TableCell>
                                        {item?.DV_PRISM ? item?.DV_PRISM?.length > 10 ?
                                            <Tooltip placement='top' title={item?.DV_PRISM}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_VISION ? item?.DV_VISION : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BASE ? item?.DV_BASE : '-'}</TableCell>
                                </TableRow>
                            )) : null}
                            {this.state.oldDataLeft?.length > 0 ? this.state.oldDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left</TableCell>
                                    <TableCell>
                                        {item?.DV_UCVA ? item?.DV_UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.DV_UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_SPH ? item?.DV_SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_CYL ? item?.DV_CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_AXIS ? item?.DV_AXIS : '-'}</TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.DV_BCDVA ? item?.DV_BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.DV_BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BCNVA ? item?.DV_BCNVA : '-'}</TableCell>
                                    <TableCell>
                                        {item?.DV_PRISM ? item?.DV_PRISM?.length > 10 ?
                                            <Tooltip placement='top' title={item?.DV_PRISM}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_VISION ? item?.DV_VISION : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BASE ? item?.DV_BASE : '-'}</TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPrescriotionTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '53vw', overflow: 'auto' }} component={'div'}>
                    {this.state.comparePopup ? null : <Box component={"div"} style={{ marginBottom: "0.75vw" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "0.5vw" }}>
                            <FormControl size='small' style={{ width: '32%', marginTop: "0.75vw" }}>
                                <InputLabel id="Dis_lay_Diet">{'Chart Used'}</InputLabel>
                                <Select
                                    value={this.state.Chart ? this.state.Chart : ""}
                                    label={'Chart Used'}
                                    disabled={this.state.fromOptometry}
                                    onChange={(event) => {
                                        let values = event.target.value;
                                        this.setState({
                                            Chart: values
                                        })
                                    }}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                    }}
                                >
                                    <MenuItem value={""}>{"Select Chart Used"}</MenuItem>
                                    {DEFAULT_CHARTUSED_SR.map((item, index) => {
                                        return <MenuItem key={index} value={item.value}>{(item.label ? item.label : "")}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl size='small' style={{ width: '32%', marginTop: "0.75vw", marginLeft: "0.75vw" }}>
                                <InputLabel id="Dis_lay_Diet">{'Muscle'}</InputLabel>
                                <Select
                                    value={this.state.Muscle ? this.state.Muscle : ""}
                                    label={'Muscle'}
                                    disabled={this.state.fromOptometry}
                                    onChange={(event) => {
                                        let values = event.target.value;
                                        this.setState({
                                            Muscle: values
                                        })
                                    }}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                    }}
                                >
                                    <MenuItem value={""}>{"Select Muscle"}</MenuItem>
                                    {DEFAULT_MUSCLES_SR.map((item, index) => {
                                        return <MenuItem key={index} value={item.value}>{(item.label ? item.label : "")}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl size='small' style={{ width: '32%', marginTop: "0.75vw", marginLeft: "0.75vw" }}>
                                <InputLabel id="Dis_lay_Diet">{'Occupation'}</InputLabel>
                                <Select
                                    value={this.state.Occupation ? this.state.Occupation : ""}
                                    label={'Occupation'}
                                    disabled={this.state.fromOptometry}
                                    onChange={(event) => {
                                        let values = event.target.value;
                                        this.setState({
                                            Occupation: values
                                        })
                                    }}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                    }}
                                >
                                    {/* <MenuItem value={""}>{"Select Occupation"}</MenuItem> */}
                                    {/* {DEFAULT_OCCUPATIONS_SR.map((item, index) => {
                                        return <MenuItem key={index} value={item.value}>{(item.label ? item.label : "")}</MenuItem>
                                    })} */}
                                    {this.state.occupationList?.length > 0 ? this.state.occupationList?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )) : <Typography align='center'>{"No Data Found..."}</Typography>}
                                    <Divider />
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => {
                                                this.setState({
                                                    isOccupationadd: true,
                                                    occupationName: '',
                                                })
                                            }
                                            }>
                                                <ListItemText primary={'Add New Occupation'} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Select>
                            </FormControl>
                        </div>
                    </Box>}

                    <Table stickyHeader size='small' style={{ height: "6vw" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>UCVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>ADD</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCDVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCNVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PRISM</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>VISION</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BASE</TableCell>
                                {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.templateDataRight?.length > 0 ? this.state.templateDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right</TableCell>
                                    <TableCell>
                                        {item?.DV_UCVA ? item?.DV_UCVA?.length > 15 ?
                                            <Tooltip placement='top' title={item?.DV_UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_SPH ? item?.DV_SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_CYL ? item?.DV_CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_AXIS ? item?.DV_AXIS : '-'}</TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.DV_BCDVA ? item?.DV_BCDVA?.length > 15 ?
                                            <Tooltip placement='top' title={item?.DV_BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BCNVA ? item?.DV_BCNVA : '-'}</TableCell>
                                    <TableCell>
                                        {item?.DV_PRISM ? item?.DV_PRISM?.length > 15 ?
                                            <Tooltip placement='top' title={item?.DV_PRISM}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_VISION ? item?.DV_VISION : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BASE ? item?.DV_BASE : '-'}</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked || this.state.sameGlass || this.state.fromOptometry}
                                            >
                                                {(this.state.isLocked || this.props.isLocked || this.state.sameGlass || this.state.fromOptometry) ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                            {this.state.templateDataLeft?.length > 0 ? this.state.templateDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left</TableCell>
                                    <TableCell>
                                        {item?.DV_UCVA ? item?.DV_UCVA?.length > 15 ?
                                            <Tooltip placement='top' title={item?.DV_UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_SPH ? item?.DV_SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_CYL ? item?.DV_CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_AXIS ? item?.DV_AXIS : '-'}</TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.DV_BCDVA ? item?.DV_BCDVA?.length > 15 ?
                                            <Tooltip placement='top' title={item?.DV_BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BCNVA ? item?.DV_BCNVA : '-'}</TableCell>
                                    <TableCell>
                                        {item?.DV_PRISM ? item?.DV_PRISM?.length > 15 ?
                                            <Tooltip placement='top' title={item?.DV_PRISM}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.DV_PRISM}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_VISION ? item?.DV_VISION : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.DV_BASE ? item?.DV_BASE : '-'}</TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked || this.state.sameGlass || this.state.fromOptometry}
                                            >
                                                {(this.state.isLocked || this.props.isLocked || this.state.sameGlass || this.state.fromOptometry) ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                    {this.state.templateDataLeft?.length > 0 || this.state.templateDataRight?.length > 0 ? null :
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "4vw", marginTop: "-2.5vw" }}>No Records To be Shown</div>
                    }

                    {this.state.comparePopup ? null : <Box>
                        <Box sx={{ marginY: "0.65vw" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Lens Type</Typography>
                            {this.renderChip(LENS_TYPE_OPTIONS, this.state.LensType, "LensType")}
                        </Box>
                        <Box sx={{ marginY: "0.65vw" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Lens Coating</Typography>
                            {this.renderChip(LENS_COATING_OPTIONS, this.state.LensCoat, "LensCoat")}
                        </Box>
                    </Box>}
                    {this.state.comparePopup ? null : <Box>
                        <Box>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Coating & Wearing Comments</Typography>
                            <TextField
                                multiline
                                rows={3}
                                sx={{ width: '97%', marginTop: "0.5vw", marginLeft: "0.5vw" }}
                                value={this.state.coatingCom}
                                disabled={this.state.fromOptometry}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value?.length < 50) {
                                        this.setState({ coatingCom: e.target.value })
                                    } else {
                                        this.errorMessage('Allowed only 50 characters')
                                    }
                                }}
                            />
                        </Box>
                        {/* <Box sx={{ marginY: "0.5vw" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Wearing Instructions</Typography>
                            <TextField
                                multiline
                                rows={3}
                                sx={{ width: '97%', marginTop: "0.5vw", marginLeft: "0.5vw" }}
                                value={this.state.wearingIns}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value?.length < 250) {
                                        this.setState({ wearingIns: e.target.value })
                                    } else {
                                        this.errorMessage('Allowed only 250 characters')
                                    }
                                }}
                            />
                        </Box> */}
                        <Box>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Continue with same contact lens</Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <FormControl size='small' style={{ width: '64.5%', marginTop: "0.75vw", marginLeft: "0.5vw" }}>
                                    <InputLabel id="Dis_lay_Diet">{'Select Lens'}</InputLabel>
                                    <Select
                                        value={this.state.SelectedLens ? this.state.SelectedLens : ""}
                                        label={'Select Lense'}
                                        disabled={this.state.fromOptometry}
                                        onChange={(event) => {
                                            let values = event.target.value;
                                            this.setState({
                                                SelectedLens: values
                                            })
                                        }}
                                        MenuProps={{
                                            style: { maxHeight: 350 },
                                        }}
                                    >
                                        <MenuItem value={""}>{"Select Wearing Instruction"}</MenuItem>
                                        {DEFAULT_LENS_TYPE.map((item, index) => {
                                            return <MenuItem key={index} value={item.value}>{(item ? item.label : "")}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                {this.state.opticalHouseBtn &&
                                    <Button
                                        disabled={this.props.isLocked || this.state.fromOptometry || this.state.DisableBtn}
                                        variant='contained'
                                        sx={{ height: '2vw', width: '15vw', textTransform: 'capitalize', mt: '0.65vw', ml: '0.75vw' }}
                                        onClick={() => {
                                            this.setState({
                                                DisableBtn : true
                                            }, ()=>{
                                                this.state.LensType ? this.opticalCRMTransfer() : this.setState({alertPop: true})
                                            })
                                        }}
                                    >Transfer to Optical House</Button>}
                            </Box>
                        </Box>
                    </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            DisableBtn : false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            DisableBtn : false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            alertPop: false,
            DisableBtn : false,
        })
    }

    checkValidation = () => {
        var { LeftEye, RightEye } = this.state
        if ((LeftEye.UCVA == "" && LeftEye.SPH == "" && LeftEye.CYL == "" && LeftEye.AXIS == "" && LeftEye.BCDVA == "" && LeftEye.ADD == "" && LeftEye.BCNVA == "" && LeftEye.PRISM == "" && LeftEye.VISION == "" && LeftEye.BASE == "") &&
            (RightEye.UCVA == "" && RightEye.SPH == "" && RightEye.CYL == "" && RightEye.AXIS == "" && RightEye.BCDVA == "" && RightEye.ADD == "" && RightEye.BCNVA == "" && RightEye.PRISM == "" && RightEye.VISION == "" && RightEye.BASE == "")) {
            return "Select Any Eye Value"
        } else {
            return null
        }
    }

    addGPLensDetails = () => {
        let states = this.state
        let data = {}
        try {
            data = {
                "appointment_id": this.state.appointmentId,
                "chart_used": states?.sameGlass ? "NIL" : states.Chart ? states.Chart : "NIL",
                "muscle": states?.sameGlass ? "NIL" : states.Muscle ? states.Muscle : "NIL",
                "occupation": states?.sameGlass ? "NIL" : states.Occupation ? states.Occupation : "NIL",
                "wearing_instructions": states?.sameGlass ? null : states.wearingIns,
                "continue_with_same_lense": states?.sameGlass ? "NIL" : states.SelectedLens ? states.SelectedLens : "NIL",
                "lens_type": states?.sameGlass ? [] : states.LensType ? states.LensType : [],
                "lens_coating": states?.sameGlass ? "NIL" : states.LensCoat ? states.LensCoat : "NIL",
                "coating": states?.sameGlass ? null : states.coatingCom,
                "same_glass": states.sameGlass ? states.sameGlass : false,
            }
            if (states?.lens_id) { data['id'] = states?.lens_id }
            this.setState({ postLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_OPT_GLASS_PRESCRIPTION + "lens/")
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.handleClear()
                        this.setState({ postLoad: false, openPopUp: false }, () => {
                            this.getGlassPrescription()
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    PostGlassPrescription = () => {
        var { LeftEye, RightEye, appointmentId } = this.state
        let data = []
        try {
            if (
                ((LeftEye.UCVA != "" && LeftEye.UCVA != undefined) || (LeftEye.SPH != "" && LeftEye.SPH != undefined) || (LeftEye.CYL != "" && LeftEye.CYL != undefined) || (LeftEye.AXIS != "" && LeftEye.AXIS != undefined) || (LeftEye.BCDVA != "" && LeftEye.BCDVA != undefined) || (LeftEye.ADD != "" && LeftEye.ADD != undefined) || (LeftEye.BCNVA != "" && LeftEye.BCNVA != undefined) || (LeftEye.PRISM != "" && LeftEye.PRISM != undefined) || (LeftEye.VISION != "" && LeftEye.VISION != undefined) || (LeftEye.BASE != "" && LeftEye.BASE != undefined)) &&
                ((RightEye.UCVA != "" && RightEye.UCVA != undefined) || (RightEye.SPH != "" && RightEye.SPH != undefined) || (RightEye.CYL != "" && RightEye.CYL != undefined) || (RightEye.AXIS != "" && RightEye.AXIS != undefined) || (RightEye.BCDVA != "" && RightEye.BCDVA != undefined) || (RightEye.ADD != "" && RightEye.ADD != undefined) || (RightEye.BCNVA != "" && RightEye.BCNVA != undefined) || (RightEye.PRISM != "" && RightEye.PRISM != undefined) || (RightEye.VISION != "" && RightEye.VISION != undefined) || (RightEye.BASE != "" && RightEye.BASE != undefined))
            ) {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        DV_UCVA: LeftEye.UCVA ? LeftEye.UCVA : "",
                        DV_SPH: LeftEye.SPH ? LeftEye.SPH : "",
                        DV_CYL: LeftEye.CYL ? LeftEye.CYL : "",
                        DV_AXIS: LeftEye.AXIS ? LeftEye.AXIS : "",
                        DV_BCDVA: LeftEye.BCDVA ? LeftEye.BCDVA : "",
                        add: LeftEye.ADD ? LeftEye.ADD : "",
                        DV_BCNVA: LeftEye.BCNVA ? LeftEye.BCNVA : "",
                        DV_PRISM: LeftEye.PRISM ? LeftEye.PRISM : "",
                        DV_VISION: LeftEye.VISION ? LeftEye.VISION : "",
                        DV_BASE: LeftEye.BASE ? LeftEye.BASE : "",
                        comment: LeftEye.comments ? LeftEye.comments : ""
                    },
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        DV_UCVA: RightEye.UCVA ? RightEye.UCVA : "",
                        DV_SPH: RightEye.SPH ? RightEye.SPH : "",
                        DV_CYL: RightEye.CYL ? RightEye.CYL : "",
                        DV_AXIS: RightEye.AXIS ? RightEye.AXIS : "",
                        DV_BCDVA: RightEye.BCDVA ? RightEye.BCDVA : "",
                        add: RightEye.ADD ? RightEye.ADD : "",
                        DV_BCNVA: RightEye.BCNVA ? RightEye.BCNVA : "",
                        DV_PRISM: RightEye.PRISM ? RightEye.PRISM : "",
                        DV_VISION: RightEye.VISION ? RightEye.VISION : "",
                        DV_BASE: RightEye.BASE ? RightEye.BASE : "",
                        comment: RightEye.comments ? RightEye.comments : ""
                    }
                ]

                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
                if (RightEye.id) { data[1]['id'] = RightEye.id }
            }
            else if ((LeftEye.UCVA != "" && LeftEye.UCVA != undefined) || (LeftEye.SPH != "" && LeftEye.SPH != undefined) || (LeftEye.CYL != "" && LeftEye.CYL != undefined) || (LeftEye.AXIS != "" && LeftEye.AXIS != undefined) || (LeftEye.BCDVA != "" && LeftEye.BCDVA != undefined) || (LeftEye.ADD != "" && LeftEye.ADD != undefined) || (LeftEye.BCNVA != "" && LeftEye.BCNVA != undefined) || (LeftEye.PRISM != "" && LeftEye.PRISM != undefined) || (LeftEye.VISION != "" && LeftEye.VISION != undefined) || (LeftEye.BASE != "" && LeftEye.BASE != undefined)) {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        DV_UCVA: LeftEye.UCVA ? LeftEye.UCVA : "",
                        DV_SPH: LeftEye.SPH ? LeftEye.SPH : "",
                        DV_CYL: LeftEye.CYL ? LeftEye.CYL : "",
                        DV_AXIS: LeftEye.AXIS ? LeftEye.AXIS : "",
                        DV_BCDVA: LeftEye.BCDVA ? LeftEye.BCDVA : "",
                        add: LeftEye.ADD ? LeftEye.ADD : "",
                        DV_BCNVA: LeftEye.BCNVA ? LeftEye.BCNVA : "",
                        DV_PRISM: LeftEye.PRISM ? LeftEye.PRISM : "",
                        DV_VISION: LeftEye.VISION ? LeftEye.VISION : "",
                        DV_BASE: LeftEye.BASE ? LeftEye.BASE : "",
                        comment: LeftEye.comments ? LeftEye.comments : ""
                    }
                ]
                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
            }
            else if ((RightEye.UCVA != "" && RightEye.UCVA != undefined) || (RightEye.SPH != "" && RightEye.SPH != undefined) || (RightEye.CYL != "" && RightEye.CYL != undefined) || (RightEye.AXIS != "" && RightEye.AXIS != undefined) || (RightEye.BCDVA != "" && RightEye.BCDVA != undefined) || (RightEye.ADD != "" && RightEye.ADD != undefined) || (RightEye.BCNVA != "" && RightEye.BCNVA != undefined) || (RightEye.PRISM != "" && RightEye.PRISM != undefined) || (RightEye.VISION != "" && RightEye.VISION != undefined) || (RightEye.BASE != "" && RightEye.BASE != undefined)) {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        DV_UCVA: RightEye.UCVA ? RightEye.UCVA : "",
                        DV_SPH: RightEye.SPH ? RightEye.SPH : "",
                        DV_CYL: RightEye.CYL ? RightEye.CYL : "",
                        DV_AXIS: RightEye.AXIS ? RightEye.AXIS : "",
                        DV_BCDVA: RightEye.BCDVA ? RightEye.BCDVA : "",
                        add: RightEye.ADD ? RightEye.ADD : "",
                        DV_BCNVA: RightEye.BCNVA ? RightEye.BCNVA : "",
                        DV_PRISM: RightEye.PRISM ? RightEye.PRISM : "",
                        DV_VISION: RightEye.VISION ? RightEye.VISION : "",
                        DV_BASE: RightEye.BASE ? RightEye.BASE : "",
                        comment: RightEye.comments ? RightEye.comments : ""
                    }
                ]
                if (RightEye.id) { data[0]['id'] = RightEye.id }
            }
            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_OPT_GLASS_PRESCRIPTION)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.handleClear()
                            this.setState({ postLoad: false, openPopUp: false, isEdit: false, callSubjective: false }, () => {
                                this.getGlassPrescription()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    renderGlassPower = (Heading, values, key, Tab, ref, scrollTo) => {
        let states = this.state
        let sortedValue = values
        let { SPHselectedValues, CYLselectedValues } = this.state
        if(Heading == "SPH"){
            if(SPHselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => SPHselectedValues === "plus"  ? item?.value >= 0 : SPHselectedValues === "Minus" ? item?.value <= 0 : sortedValue)
            }
        }
        else if (Heading == "CYL"){
            if(CYLselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => CYLselectedValues === "plus"  ? item?.value >= 0 : CYLselectedValues  === "Minus" ? item?.value <= 0 : sortedValue)
            }
        }
        return (
            <div >
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <Box component={'div'} sx={{ display: "flex", flexWrap: "wrap", marginLeft: "1vw" }}>
                        {
                           ( Heading === "SPH" ||   Heading === "CYL") ?
                                <RadioGroup
                                    value={ Heading === "SPH"  ? this.state.SPHselectedValues  : this.state.CYLselectedValues}
                                    sx={{ display: 'flex', flexDirection: 'row' ,  marginTop :"-4vh", marginLeft :"2vw"  }}
                                    onChange={(e, values) => {
                                        if(Heading === "SPH"){
                                            this.setState({ SPHselectedValues: e.target.value })
                                        }
                                        else if(Heading === "CYL"){
                                            this.setState({ CYLselectedValues: e.target.value })
                                        }
                                    }}
                                >
                                    {Heading === "SPH" ||   Heading === "CYL" ? null : <FormControlLabel control={<Radio  inputProps = {{emed_tid : "All_testID"}} size='small' value={"All"}  />} label="All" />}
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "plus_testID"}}  size='small' value={"plus"}  />} label="+" />
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "Minus_testID"}}  size='small' value={"Minus"} />} label="-" />
                                </RadioGroup> : 
                               null
                        }
                    </Box>
                <div ref={ref} style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        sortedValue && sortedValue.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: item?.label.length > 6 ? "5vw" : "4vw" }}
                                label={item?.label?.length > 6 ? <Tooltip placement='top' title={item?.label}><div style={{ fontSize: '0.7vw' }}>{item?.label?.slice(0, 5)+ '...'}</div></Tooltip> : item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (Tab == "LeftEye" && this.state.Leftchecked) {
                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                    } else if (Tab == "RightEye" && this.state.Rightchecked) {
                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                    }
                                    if (item.label === key) {
                                        states[Tab][Heading] = ""
                                        this.setState({ states })
                                    } else {
                                        states[Tab][Heading] = item?.value
                                        this.setState({ states })
                                    }
                                    this.scrollToRef(scrollTo)
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    renderRightSideValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div>{Heading}</div>
                <div className="eMed_BoxShadow">
                    {value?.length > 13 ? <Tooltip title={value} placement='top' arrow><div>{value?.slice(0, 10) + "..."}</div></Tooltip>: value}
                </div>
            </div>
        )
    }

    CopyLefttoRight = (value) => {
        let { LeftEye } = this.state
        let states = this.state
        if (value) {
            states.RightEye["UCVA"] = LeftEye.UCVA
            states.RightEye["SPH"] = LeftEye.SPH
            states.RightEye["CYL"] = LeftEye.CYL
            states.RightEye["AXIS"] = LeftEye.AXIS
            states.RightEye["ADD"] = LeftEye.ADD
            states.RightEye["BCDVA"] = LeftEye.BCDVA
            states.RightEye["BCNVA"] = LeftEye.BCNVA
            states.RightEye["PRISM"] = LeftEye.PRISM
            states.RightEye["VISION"] = LeftEye.VISION
            states.RightEye["BASE"] = LeftEye.BASE
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                value: this.state.isEdit ? "One" : "Two",
                states
            })
        } else {
            states.RightEye["UCVA"] = ""
            states.RightEye["SPH"] = ""
            states.RightEye["CYL"] = ""
            states.RightEye["AXIS"] = ""
            states.RightEye["ADD"] = ""
            states.RightEye["BCDVA"] = ""
            states.RightEye["BCNVA"] = ""
            states.RightEye["PRISM"] = ""
            states.RightEye["VISION"] = ""
            states.RightEye["BASE"] = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let { RightEye } = this.state
        let states = this.state
        if (value) {
            states.LeftEye["UCVA"] = RightEye.UCVA
            states.LeftEye["SPH"] = RightEye.SPH
            states.LeftEye["CYL"] = RightEye.CYL
            states.LeftEye["AXIS"] = RightEye.AXIS
            states.LeftEye["ADD"] = RightEye.ADD
            states.LeftEye["BCDVA"] = RightEye.BCDVA
            states.LeftEye["BCNVA"] = RightEye.BCNVA
            states.LeftEye["PRISM"] = RightEye.PRISM
            states.LeftEye["VISION"] = RightEye.VISION
            states.LeftEye["BASE"] = RightEye.BASE
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                value: this.state.isEdit ? "Two" : "One",
                states
            })
        } else {
            states.LeftEye["UCVA"] = ""
            states.LeftEye["SPH"] = ""
            states.LeftEye["CYL"] = ""
            states.LeftEye["AXIS"] = ""
            states.LeftEye["ADD"] = ""
            states.LeftEye["BCDVA"] = ""
            states.LeftEye["BCNVA"] = ""
            states.LeftEye["PRISM"] = ""
            states.LeftEye["VISION"] = ""
            states.LeftEye["BASE"] = ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    handleClear = () => {
        let states = this.state
        if (this.state.isEdit) {
            this.setState({
                LeftEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    PRISM: "",
                    VISION: "",
                    BASE: "",
                    comments: "",
                    id: states?.LeftEye?.id
                },
                RightEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    PRISM: "",
                    VISION: "",
                    BASE: "",
                    comments: "",
                    id: states?.RightEye?.id
                },
                Leftchecked: false,
                Rightchecked: false,
                Occupation: "",
                Chart: "",
                Muscle: ""
            })
        } else {
            this.setState({
                LeftEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    PRISM: "",
                    VISION: "",
                    BASE: "",
                    comments: ""
                },
                RightEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    PRISM: "",
                    VISION: "",
                    BASE: "",
                    comments: ""
                },
                Leftchecked: false,
                Rightchecked: false,
                postLoad: false,
                value: "Two",
                disableLeft: false,
                disableRight: false,
                Occupation: "",
                Chart: "",
                Muscle: "",
                isEdit: false
            })
        }
    }

    getPreviousGlassPrescripPrint = () => {
        try {
            RestAPIService.getAllPrint(Serviceurls.DOC_GP_PRINT + `?appointment_id=${this.state.appointmentId}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CopySubjectiveData = () => {
        let { subjectiveDataLeft, subjectiveDataRight } = this.state
        this.setState({
            LeftEye: {
                UCVA: subjectiveDataLeft[0]?.UC,
                SPH: subjectiveDataLeft[0]?.SPH,
                CYL: subjectiveDataLeft[0]?.CYL,
                AXIS: subjectiveDataLeft[0]?.AXIS,
                BCDVA: subjectiveDataLeft[0]?.BCDVA,
                ADD: subjectiveDataLeft[0]?.add,
                BCNVA: subjectiveDataLeft[0]?.BCNVA,
                id: this.state.templateDataLeft[0]?.id,
                comments: subjectiveDataLeft[0]?.comment
            },
            RightEye: {
                UCVA: subjectiveDataRight[0]?.UC,
                SPH: subjectiveDataRight[0]?.SPH,
                CYL: subjectiveDataRight[0]?.CYL,
                AXIS: subjectiveDataRight[0]?.AXIS,
                BCDVA: subjectiveDataRight[0]?.BCDVA,
                ADD: subjectiveDataRight[0]?.add,
                BCNVA: subjectiveDataRight[0]?.BCNVA,
                id: this.state.templateDataRight[0]?.id,
                comments: subjectiveDataRight[0]?.comment
            }
        }, () => { this.PostGlassPrescription() })
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    CopyPreviousData = () => {
        let { oldDataLeft, oldDataRight } = this.state
        this.setState({
            LeftEye: {
                UCVA: oldDataLeft[0]?.DV_UCVA,
                SPH: oldDataLeft[0]?.DV_SPH,
                CYL: oldDataLeft[0]?.DV_CYL,
                AXIS: oldDataLeft[0]?.DV_AXIS,
                BCDVA: oldDataLeft[0]?.DV_BCDVA,
                ADD: oldDataLeft[0]?.add,
                BCNVA: oldDataLeft[0]?.DV_BCNVA,
                PRISM: oldDataLeft[0]?.DV_PRISM,
                VISION: oldDataLeft[0]?.DV_VISION,
                BASE: oldDataLeft[0]?.DV_BASE,
                id: this.state.templateDataLeft[0]?.id,
                comments: oldDataLeft[0]?.comment
            },
            RightEye: {
                UCVA: oldDataRight[0]?.DV_UCVA,
                SPH: oldDataRight[0]?.DV_SPH,
                CYL: oldDataRight[0]?.DV_CYL,
                AXIS: oldDataRight[0]?.DV_AXIS,
                BCDVA: oldDataRight[0]?.DV_BCDVA,
                ADD: oldDataRight[0]?.add,
                BCNVA: oldDataRight[0]?.DV_BCNVA,
                PRISM: oldDataRight[0]?.DV_PRISM,
                VISION: oldDataRight[0]?.DV_VISION,
                BASE: oldDataRight[0]?.DV_BASE,
                id: this.state.templateDataRight[0]?.id,
                comments: oldDataRight[0]?.comment
            }
        }, () => { this.PostGlassPrescription() })
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "55vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Glass Prescription</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Glass Prescription</Typography>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                                            <Tooltip placement='top' title='Copy'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                            <Box component={'div'} sx={{ marginTop: "0.65vw" }}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Glass Prescription</Typography>
                                    {this.renderPrescriotionTable()}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    GlassPrescriotionPopup = () => {
        let { LeftEye, RightEye } = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "78vw", height: "90vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Glass Prescription</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false, isEdit: false }, () => { this.handleClear() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "60%" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={(e, newValue) => this.setState({
                                            value: newValue
                                        }, () => this.scrollToRef(this.UCVA))}
                                    >
                                        <Tab disabled={this.state.disableRight} value="Two" label={"Right Eye"} className='eMed_tab_header' />
                                        <Tab disabled={this.state.disableLeft} value="One" label={"Left Eye"} className='eMed_tab_header' />
                                    </Tabs>
                                    {
                                        this.state.value === "One" ?
                                            <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                    <div>
                                                        {this.renderGlassPower("UCVA", UCVA_OPTIONS, LeftEye.UCVA, "LeftEye", this.UCVA, this.SPH)}
                                                        {this.renderGlassPower("SPH", SPH_OPTIONS, LeftEye.SPH, "LeftEye", this.SPH, this.CYL)}
                                                        {this.renderGlassPower("CYL", CYL_OPTIONS, LeftEye.CYL, "LeftEye", this.CYL, this.AXIS)}
                                                        {this.renderGlassPower("AXIS", AXIS_OPTIONS, LeftEye.AXIS, "LeftEye", this.AXIS, this.ADD)}
                                                        {this.renderGlassPower("ADD", ADD, LeftEye.ADD, "LeftEye", this.ADD, this.BCDVA)}
                                                        {this.renderGlassPower("BCDVA", BCDVA_OPTIONS, LeftEye.BCDVA, "LeftEye", this.BCDVA, this.BCNVA)}
                                                        {this.renderGlassPower("BCNVA", BCNVA, LeftEye.BCNVA, "LeftEye", this.BCNVA, this.PRISM)}
                                                        {this.renderGlassPower("PRISM", PRISM_OPTIONS, LeftEye.PRISM, "LeftEye", this.PRISM, this.VISION)}
                                                        {this.renderGlassPower("VISION", VISION_OPTION, LeftEye.VISION, "LeftEye", this.VISION, this.BASE)}
                                                        {this.renderGlassPower("BASE", BASE_OPTION, LeftEye.BASE, "LeftEye", this.BASE, this.BASE)}
                                                    </div>
                                                </Box>
                                            </Box>
                                            : this.state.value === "Two" ?
                                                <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                    <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                        <div>
                                                            {this.renderGlassPower("UCVA", UCVA_OPTIONS, RightEye.UCVA, "RightEye", this.UCVA, this.SPH)}
                                                            {this.renderGlassPower("SPH", SPH_OPTIONS, RightEye.SPH, "RightEye", this.SPH, this.CYL)}
                                                            {this.renderGlassPower("CYL",CYL_OPTIONS, RightEye.CYL, "RightEye", this.CYL, this.AXIS)}
                                                            {this.renderGlassPower("AXIS", AXIS_OPTIONS, RightEye.AXIS, "RightEye", this.AXIS, this.ADD)}
                                                            {this.renderGlassPower("ADD", ADD, RightEye.ADD, "RightEye", this.ADD, this.BCDVA)}
                                                            {this.renderGlassPower("BCDVA", BCDVA_OPTIONS, RightEye.BCDVA, "RightEye", this.BCDVA, this.BCNVA)}
                                                            {this.renderGlassPower("BCNVA", BCNVA, RightEye.BCNVA, "RightEye", this.BCNVA, this.PRISM)}
                                                            {this.renderGlassPower("PRISM", PRISM_OPTIONS, RightEye.PRISM, "RightEye", this.PRISM, this.VISION)}
                                                            {this.renderGlassPower("VISION", VISION_OPTION, RightEye.VISION, "RightEye", this.VISION, this.BASE)}
                                                            {this.renderGlassPower("BASE", BASE_OPTION, RightEye.BASE, "RightEye", this.BASE, this.BASE)}
                                                        </div>
                                                    </Box>
                                                </Box>
                                                : null
                                    }
                                </div>
                                <div style={{ width: "40%" }}>
                                    <Box component={'div'} style={{ marginTop: "4.4vw", marginLeft: "1vw", height: "67vh", overflow: "scroll" }}>
                                        <div style={{ fontWeight: "600", marginBottom: "0.75vw", marginTop: "0.5vw" }}>{this.state.value === "One" ? "Left Eye" : "Right Eye"}</div>
                                        {this.state.value === "One" ?
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                    {this.renderRightSideValues("UCVA", LeftEye.UCVA)}
                                                    {this.renderRightSideValues("SPH", LeftEye.SPH)}
                                                    {this.renderRightSideValues("CYL", LeftEye.CYL)}
                                                    {this.renderRightSideValues("AXIS", LeftEye.AXIS)}
                                                    {this.renderRightSideValues("ADD", LeftEye.ADD)}
                                                    {this.renderRightSideValues("BCDVA", LeftEye.BCDVA)}
                                                    {this.renderRightSideValues("BCNVA", LeftEye.BCNVA)}
                                                    {this.renderRightSideValues("PRISM", LeftEye.PRISM)}
                                                    {this.renderRightSideValues("VISION", LeftEye.VISION)}
                                                    {this.renderRightSideValues("BASE", LeftEye.BASE)}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                                </Box>
                                            </div> :
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                    {this.renderRightSideValues("UCVA", RightEye.UCVA)}
                                                    {this.renderRightSideValues("SPH", RightEye.SPH)}
                                                    {this.renderRightSideValues("CYL", RightEye.CYL)}
                                                    {this.renderRightSideValues("AXIS", RightEye.AXIS)}
                                                    {this.renderRightSideValues("ADD", RightEye.ADD)}
                                                    {this.renderRightSideValues("BCDVA", RightEye.BCDVA)}
                                                    {this.renderRightSideValues("BCNVA", RightEye.BCNVA)}
                                                    {this.renderRightSideValues("PRISM", RightEye.PRISM)}
                                                    {this.renderRightSideValues("VISION", RightEye.VISION)}
                                                    {this.renderRightSideValues("BASE", RightEye.BASE)}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                                </Box>
                                            </div>}
                                        {/* <div style={{ marginTop: "1vw" }}>
                                            <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{"Comments"}</div>
                                            <div >
                                                <TextField
                                                    label={("Enter Comments")}
                                                    style={{ height: "5vw", marginTop: "0.5vw", width: "95%" }}
                                                    multiline={true}
                                                    rows={3}
                                                    variant="outlined"
                                                    value={this.state.value === "One" ? LeftEye.comments : RightEye.comments}
                                                    onChange={(event) => {
                                                        var value = event.target.value
                                                        if (this.state.value === "One") {
                                                            if (value?.length <= 250) {
                                                                LeftEye.comments = value
                                                                this.setState({
                                                                    LeftEye
                                                                })
                                                            } else { this.errorMessage('Allowed only 250 characters') }
                                                        } else {
                                                            if (value?.length <= 250) {
                                                                RightEye.comments = event.target.value
                                                                this.setState({
                                                                    RightEye
                                                                })
                                                            }
                                                            else { this.errorMessage('Allowed only 250 characters') }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div> */}
                                    </Box>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginTop: "-0.75vw", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.PostGlassPrescription() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    confirmSave = () =>{
        this.setState({
            alertPop: false
        },()=>{
            this.opticalCRMTransfer()
        })
    }

    render() {        
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} height={'3.5vw'}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>Glass Prescription</Typography>
                            <div style={{ marginLeft: "0.65vw", display: "flex", flexDirection: "row" }}>
                                {/* <FormGroup>
                                    <FormControlLabel disabled={this.state.isLocked || this.props.isLocked || this.state.disableCheck} control={<Checkbox checked={this.state.selectedCheck}
                                        onChange={(e) => {
                                            this.setState({ selectedCheck: !this.state.selectedCheck, disableRadio: true }, () => {
                                                this.setState({
                                                    sameGlass: !this.state.sameGlass
                                                }, () => { this.addGPLensDetails() })
                                            })
                                        }} />} label="Continue With Same Glass" />
                                </FormGroup> */}
                                {this.state.fromOptometry ? null : <div>
                                    {/* <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={this.state.selectedRadio}
                                            onChange={(e) => {
                                                this.setState({ selectedRadio: e.target.value }, () => {
                                                    if (!this.props.isLocked && this.state.selectedRadio == "SameGlass") {
                                                        this.setState({
                                                            sameGlass: !this.state.sameGlass
                                                        }, () => { this.addGPLensDetails() })
                                                    }
                                                })
                                            }}
                                        >
                                            <FormControlLabel disabled={this.state.isLocked || this.props.isLocked} value="SameGlass" control={<Radio />} label="Continue With Same Glass" />
                                        </RadioGroup>
                                        </FormControl> */}
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={this.state.selectedValue}
                                            onChange={(e) => {
                                                this.setState({ selectedValue: e.target.value, disableCheck: true }, () => {
                                                    if ((this.state.subjectiveDataLeft?.length > 0 || this.state.subjectiveDataRight?.length > 0) && !this.props.isLocked && this.state.selectedValue == "Subjective") {
                                                        this.setState({
                                                            callSubjective: true
                                                        }, () => {
                                                            this.getSubjectiveRefraction()
                                                        })
                                                    }
                                                })
                                            }}
                                        >
                                            <FormControlLabel disabled={this.props.isLocked || this.state.sameGlass || this.state.disableRadio || !this.state.showSubjective} value="Subjective" control={<Radio />} label="Copy from Subjective Refraction" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>}
                            </div>
                        </div>
                        {this.state.fromOptometry ? null : <div style={{ display: "flex", flexDirection: "row" }}>
                            <Button
                                disabled={this.state.isLocked || this.props.isLocked || this.state.sameGlass}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                onClick={() => { this.addGPLensDetails() }}
                            >Save</Button>
                            <Button
                                disabled={this.state.isLocked || this.props.isLocked || (this.state.templateDataRight?.length > 0 && this.state.templateDataLeft?.length > 0) || this.state.sameGlass}
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw', mr: "0.75vw" }}
                                onClick={() => {
                                    this.setState({ 
                                        openPopUp: true,
                                        SPHselectedValues : "Minus",
                                        CYLselectedValues : "Minus",
                                     })
                                }}
                            >Add</Button>
                            <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                                <Tooltip placement='top' title='Copy Previous Glass Prescription'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0) && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                <Tooltip placement='top' title='Compare'>
                                    <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                            <Tooltip placement='top' title='Print'>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.getPreviousGlassPrescripPrint()}
                                    style={{ marginTop: "-0.15vw" }}
                                    disabled={this.state.templateDataRight?.length > 0 || this.state.templateDataLeft?.length > 0 ? false : true}
                                >
                                    <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'2vw'} width={'2vw'} />
                                </Button>
                            </Tooltip>
                        </div>}
                    </Box>
                    <Box component={'div'} p={'0.5vw'}>
                        <Box component={'div'}>
                            {this.renderPrescriotionTable()}
                        </Box>
                    </Box>
                    {this.GlassPrescriotionPopup()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.alertPop ?
                    <ConfirmPopup 
                        cnfTitle={'Confirmation'}
                        cnfContent = {"Do you want to proceed without selecting Lens type ?"}
                        cnfAlertPopupClose={this.msgClose.bind(this)}
                        cnfData={this.confirmSave.bind(this)}
                    />
                    : null}
                    {(this.state.isOccupationadd) ? this.renderOccupationAdd() : null}
                </Box>
            </ShowComponents>
        )
    }

}