import { Box, Button, Drawer, Stack, TextField, Tooltip, Typography, IconButton, Menu, MenuItem } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import { PharmaBranchCommonPopup } from './PharmaBranchCommonPopup'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import Loader from '../../../Components/Loader';
import PharmaBranchReportFilter from './PharmaBranchReportFilter'
import { DateTime } from 'luxon'
import { AddCircle } from '@material-ui/icons'
import PharmaBranchTransitDetails from './PharmaBranchTransitDetails'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default class PharmaBranchReturn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      returnRequestData: [],
      page: 0,
      pageSize: 10,
      returnRequestList: [],
      isViewClick: false,
      selectedData: {},
      selectedRowId: null,
      pageName: 'pharmaBranchReturn',
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
      },
      isTransit: false,
    }
  }
  componentDidMount() {
    this.getReturnRequestData()
  }

  getReturnRequestData = () => {
    try {
      this.LoaderFunction(true)
      var {filterDatas} = this.state
      RestAPIService.getAll(Serviceurls.PHA_BRANCH_REQ_POST + `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              returnRequestData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    this.setState({
      isViewClick: false,
      selectedData: {},
      selectedRowId: null,
    }, ()=>{
      this.getReturnRequestData()
    })
  }
  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
    }, () => {
        this.getReturnRequestData()
    })
  }
  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: this.state.filterDatas.fromDate },
      { label: "To Date", value: this.state.filterDatas.toDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  transitClosePop = () => {
    this.setState({
      isTransit: false
    }, ()=>{
      this.getReturnRequestData()
    })
  }

  getPrint = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.PHA_BRANCH_LINE_OF_ITEM + `?request_id=${id}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((e) => {
          if (e?.reponse?.data?.message) {
            this.errorMessage(e?.response?.data?.message)
          } else {
            this.errorMessage(e?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  MoreMenus = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "Print", label: "Print", dis: false },
    ]
    return (
      <div>
        <Tooltip title={"More"} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data?.row?.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data?.row?.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
              {multi_option.map((option) => (
            <MenuItem disabled={option.dis} onClick={() => {
              if(option.value === "Print"){
                this.getPrint(data?.row?.id)
              }
            }}>
              {option.label}
            </MenuItem>))}
          </Menu>
          : null}
      </div>
    )
  }

  render() {
    this.state.returnRequestData.forEach((element, index) => element.sno = index + 1)
    const column = [
      {
        field: 'request_number', headerName: 'Return Stock Transfer No.', flex: 0.125,
        renderCell: (params) => (<Box>{params?.row?.request_number ? params?.row?.request_number : '-'}</Box>)
      },
      {
        field: 'request_date', headerName: 'Stock Transfer Date', flex: 0.125,
        renderCell: (params) => (<Box>{params?.row?.request_date ? params?.row?.request_date : '-'}</Box>)
      },
      {
        field: 'transfer_from', headerName: 'Transferred From', flex: 0.125,
        renderCell: (params) => (<Box>{params?.row?.transfer_from ? params?.row?.transfer_from : '-'}</Box>)
      },
      {
        field: 'transfer_to', headerName: 'Transferred To', flex: 0.125,
        renderCell: (params) => (<Box>{params?.row?.transfer_to ? params?.row?.transfer_to : '-'}</Box>)
      },
      {
        field: "transit_name", flex: 0.14, headerName: "Transit Detail", headerAlign: "center", align: "center",
        renderCell: (params) => ((params.row.transit_name && params.row.transit_number) ? 
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              {params?.row?.transit_name?.length > 12 ?
                <Tooltip title={params?.row?.transit_name} placement='top'><Typography sx={{ fontWeight: 'bold' }}>{params?.row?.transit_name.slice(0, 12) + "..."}</Typography></Tooltip> : <Typography fontWeight={'bold'}>{params?.row?.transit_name ? params?.row?.transit_name : '-'}</Typography>}
                <Tooltip title={params?.row?.transit_track_id} placement='top'><IconButton size='small' onClick={()=>{
                   if (params?.row?.transit_track_id) {
                    navigator.clipboard.writeText(params.row.transit_track_id).then(
                      () => {
                        this.successMessage('Track ID Copied Successfully');
                      },
                      (err) => {
                        this.errorMessage('Failed to copy text');
                      }
                    );
                  }
                }}><img src={ImagePaths.InfoFill.default} style={{width: '.9vw', height: '.9vw'}} alt='copy'/></IconButton></Tooltip>
            </Box>
            <Typography sx={{color: Colors.themeDark}}>{params?.row?.transit_number ? params.row.transit_number : '-'}</Typography>
        </Box> : 
          params.row.status === 'In Transit' ? 
          <Box>
            <Button size="small" variant='oulined' sx={{color: Colors.themeDark, fontSize: '1vw', textTransform: 'capitalize'}} onClick={()=>{this.setState({isTransit: true, selectedRowId: params.row.id})}} className='Common_Btn_Min_Width' startIcon={<AddCircle/>}>{'Add Transit Details'}</Button>
          </Box> : '-'
        )      },
      {
        field: 'no_of_product', headerName: 'No of Product', flex: 0.125, type: 'number',
        renderCell: (params) => (<Box>{params?.row?.no_of_product ? params?.row?.no_of_product : '-'}</Box>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.125, headerAlign: 'center', align: 'center',
        renderCell: (params) => (
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Button className='Common_Btn_Min_Width'
              onClick={() => {
                this.setState({
                  isViewClick: true,
                  selectedData: params?.row,
                  selectedRowId: params?.row?.id
                })
              }}>
              <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'2vw'} width={'2vw'} />
            </Button>
            {this.MoreMenus(params)}
          </Box>
        )
      }
    ]
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '80vh' }}>
        <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <Button
              size='small'
              disabled={CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement",  "Return Request", "SubTab")?.editAccess === false}
              sx={{ mr: '1vw', height: '2vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => {
                this.props.history.push({ pathname: "/PharmacyStockTransfer/RaiseRequest", state: { page: "branchreturn" } })
              }}
            >Return Request</Button>
          </Box>
          <Box component={'div'} height={'7vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Box pr={'1vw'}>
              <Button
                className='eMed_common'
                sx={{ width: '2vw', height: '2vw', mr: '1vw' }}
                onClick={() => this.setState({ filterOpen: true })}
              >
                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box>
            {/* need to work start */}
            {/* <Box>
              <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', ml: '1vw', mr: '1vw' }}>
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
              <Button className='eMed_common' sx={{ width: '2vw', height: '2vw', mr: '1vw' }}>
                <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box> */}
            {/* need to work end */}
          </Box>
        </Box>
        <Box component={'div'} height={'73vh'} p={'1vw'}>
          <DataGrid
            sx={{ backgroundColor: 'white' }}
            columns={column}
            disableColumnFilter
            rows={this.state.returnRequestData}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
          />
          <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaBranchReportFilter
            pageName={this.state.pageName}
            filterDatas={this.state.filterDatas}
            closeFilter={this.ClosePopUp.bind(this)}
            filteredData={this.renderFilter.bind(this)}
            ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        </Box>
        {this.state.isViewClick ?
          <PharmaBranchCommonPopup
            Data={this.state.selectedData}
            title={"Return Details"}
            handlePopUpClose={this.popupClose}
            RowId={this.state.selectedRowId}
          />
          : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.state.isTransit ? 
          <PharmaBranchTransitDetails
          transitClose={this.transitClosePop}
          id={this.state.selectedRowId}
          /> : null}
          <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}